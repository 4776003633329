<template>
  <div>
    <div class="title_text flex-row">
      <span class="word3">两小时气象模拟</span>
    </div>
    <div class="mod3">
      <div id="weatherChart" ref="chartDom" :style="{ width: '0px', height: '30vh' }" />
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import moment from 'moment';
import {
  getCurrentPointFactorData, getCurrentPointFactorDataByCY,
  getCurrentPointFactorDataOfPrecp,
  getCurrentPointTagsTimeData,
} from '@/api/weather_hd';
import echarts from '@/plugins/echarts';
import { productionMap } from '@/config/MapConfig';
import { modeFactorMap } from '@/config/MapConfigT4';
import { handlerPrecpData } from '@/config/Utils';
import Bus from '@/bus/index';

export default {
  components: {
  },
  props: {
    productType: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      input: '',
      lineData: [],
      chartObj: null,
      showPopover: false,
      actions: [],
      metrics: [],
      factorSelected: '',
      latlon: '',
      xAxisWidth: 0,
    };
  },
  computed: {
    ...mapState(['currentData', 'isMobile']),
    ...mapState('map', ['t2Mode', 'layerType', 'factor', 'popupVal']),
    ...mapGetters('map', ['currentLayerDetails', 'isT2Mode', 'currentTime']),
    popupShow: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit('update:show', val);
      },
    },
    factorKey() {
      return this.factor;
    },
    series() {
      const seriesData = [];
      this.lineData.forEach((item) => {
        const { forecastDetails, factorCode } = item;
        const data = {};
        data.name = this.getFactorText(factorCode);
        data.data = forecastDetails.map((items) => Number(items.value).toFixed(2));
        data.forecastDetails = forecastDetails;
        seriesData.push(data);
      });
      return seriesData;
    },
    xAxis() {
      return this.series[0].forecastDetails.map((item) => moment(item.time, 'YYYYMMDDHHmm').format('HH:mm'));
    },
    legend() {
      return this.lineData.map((item) => {
        const { factorCode } = item;
        return this.getFactorText(factorCode);
      });
    },
    getMetrics() {
      return this.metrics;
    },
  },
  created() {
  },
  mounted() {
    this.initActions();
  },
  methods: {
    getUrlEncode(obj) {
      let str = '';
      Object.keys(obj).forEach((key) => {
        str += `${key}=${obj[key]}&`;
      });
      return str.slice(0, -1);
    },
    initActions() {
      const data = [];
      this.metrics.forEach((v) => {
        data.push({
          text: v.text,
          icon: v.id === this.factor ? 'success' : '',
          id: v.id,
        });
      });
      this.actions = data;
    },
    getProductionFactorCode(obj) {
      // 判断是否为订正要素
      const isOld = ['early', 'late'].includes(obj.mode);
      const production = (this.isT2Mode ? undefined : productionMap[obj.factorCode]) ?? (isOld ? obj.production : 't2');
      let factorCode = obj.factorCode;
      if (['tmp2m', 't2mz'].includes(obj.factorCode)) {
        factorCode = !this.isT2Mode ? 'tmp2m' : 't2mz';
      }
      return { production, factorCode };
    },
    async initChart(latlon, factorCode, metrics, precpData) {
      this.latlon = latlon;
      this.factorSelected = factorCode;
      const { lat, lng } = latlon;
      const { production, baseTimeString, mode } = this.currentLayerDetails;
      if (metrics) {
        this.buildFactorItems(metrics, mode);
      }

      // const cy = `v2.6/MnT4KLsMDFUGT42v/${lng},${lat}/minutely?unit=metric:v2`
      // const resCY = await getCurrentPointFactorDataByCY(cy);
      // const baseTimestamp =  Math.floor(new Date(this.currentData.timestamp).getTime()/1000);
      // const res = handlerPrecpData(resCY, baseTimestamp)
      // const {values} = res
      // const forecast = [{
      //   factorCode,
      //   forecastDetails:values
      // }]
      const { values } = precpData;
      const forecast = [{
        factorCode,
        forecastDetails: values,
      }];
      if (values && values.length) {
        this.lineData = forecast;
        this.xAxisWidth = forecast[0]?.forecastDetails?.length * 40 || 24 * 40;
        document.getElementById('weatherChart').style.width = `${this.xAxisWidth}px`;
        this.renderChart();
      }
    },
    buildFactorItems(metricsList, mode) {
      // 展示当前模式下的所有要素
      let modeName = '';
      const modeFirstName = ['early', 'late'].includes(mode) ? '天机2/' : '聚合';
      if (mode === 'early') {
        modeName = `${modeFirstName}DA`;
      } else if (mode === 'late') {
        modeName = `${modeFirstName}ND`;
      } else {
        modeName = modeFirstName;
      }
      const mode2Factor = modeFactorMap[modeName];
      const metricData = [];
      // 根据要素来展示模式选择
      metricsList.forEach((metric) => {
        if (metric.id !== 'satellite' && metric.id !== 'typhoon') {
          if (metric.children && metric.children.length > 0) {
            metric.children.forEach((v) => {
              if (mode2Factor.includes(v.id)) {
                metricData.push(v);
              }
            });
          } else if (mode2Factor.includes(metric.id)) {
            metricData.push(metric);
          }
        }
      });
      this.metrics = metricData;
    },
    changeFactor(item) {
      this.initChart(this.latlon, item.id);
      this.showPopover = false;
    },
    renderChart() {
      if (!this.chartObj) {
        const chartDom = document.getElementById('weatherChart');
        this.chartObj = echarts.init(chartDom);
      }
      const option = this.buildOption(this.xAxis, this.legend, this.series);
      this.chartObj.setOption(option);
      window.onresize = function() {
        // 自适应大小
        this.chartObj.resize();
      };
    },
    buildOption(timeData, legendData, data) {
      const seriesData = data && data.length > 0 ? data[0].data : [];
      const name = data && data.length > 0 ? data[0].name : '';
      return {
        grid: {
          left: '6%',
          right: '3%',
          top: '12%',
          bottom: '12%',
        },
        xAxis: {
          type: 'category',
          data: timeData,
          axisLabel: {
            interval: 0,
            fontSize: 10,
            color: '#fff',
          },
          boundaryGap: true,
          axisTick: {
            alignWithLabel: true, // 让刻度线与刻度标签对齐
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(245, 248, 253,0.7)',
            },
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(245, 248, 253,0.2)',
            },
            show: false,
          },
        },
        tooltip: {
          trigger: 'axis',
        },
        yAxis: {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#fff',
            },
          },
          splitLine: { show: false },
          axisLabel: {
            formatter: '{value} mm', // 这里添加单位
          },
        },
        series: [
          {
            data: seriesData,
            type: 'line',
            smooth: true,
            itemStyle: {
              color: '#EE6666',
            },
            lineStyle: {
              color: '#EE6666',
            },
            label: {
              show: true,
              position: 'top',
              top: 10,
              color: '#fff', // 颜色
              fontStyle: 'normal', // 风格
              fontWeight: 'normal', // 粗细
              fontFamily: 'Microsoft yahei', // 字体
              fontSize: 12, // 大小
              align: 'center', // 水平对齐
            },
          },
        ],
      };
    },

    getFactorText(id) {
      const res = this.metrics.filter((item) => item.id === id);
      return res[0]?.text || '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/common.scss";

.active {
  ::v-deep {
    .van-grid-item__content {
      background-color: rgba(87, 127, 255, 1);
      border-radius: 20px;
      padding: 6px 4px;
    }
  }
}

.title_text {
  width: 100%;
  height: 20px;
  margin: 20px 0 0 12px;
  justify-content: space-between;
  align-items: center;

  .word3 {
    width: 28px;
    height: 14px;
    color: #fff;
    font-size: 14px;
    font-family: PingFangSC-Medium;
    text-align: left;
    white-space: nowrap;
    line-height: 14px;
  }

  .qb-div {
    font-size: 14px;
    margin-right: 25px;
    padding: 12px 15px;
    color: #FFFFFF;
    border-radius: 50px;
    display: flex;
    z-index: 999;
    background: rgba(50, 50, 50, 0.5);
    backdrop-filter: blur(1px);
    box-shadow: 0 0 0 0;
    width: auto;
    height: 20px;
    align-items: center;
    justify-content: center;

    .mode-text {
      display: flex;
      align-items: flex-end;
      margin-right: 5px;
    }
  }
}

.img_ {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}
.mod3::-webkit-scrollbar {
  display: none;
}
.mod3 {
  //background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  margin: 10px;
  //border: 1px solid #c8c9cc;
  background-color: rgba(66, 66, 66, 0.75);
  overflow-x: scroll;
  .text_ {
    color: #FFFFFF;
    margin-top: 10px;
  }

  .icon {
    width: 30px;
    height: 30px;
  }

  .change_factor_item_class {
    border: 3px solid rgba(87, 127, 255, 1);
    border-radius: 50px;
  }

  justify-content: flex-center;
}
::v-deep {
  .van-grid-item__content {
    background-color: rgba(0, 0, 0, 0);
    padding: 10px 4px;
  }
  .van-badge--fixed {
    top: 5px;
    right: 5px;
  }
  .van-badge {
    //border: 0;
    border: 1px solid #FFFFFF !important;
  }
}

::v-deep {
  .van-grid-item__content {
    padding: 6px 4px;
  }
}
</style>
