<template>
  <div class="earth-wrap" @click="clickMap">
    <div id="infobox" />
    <div id="cesiumContainer" />
    <canvas id="windy_img" class="windy" />
    <!-- <div class="zoom">
      <div class="control-single" title="放大一级" @click="big">
        <svg-icon icon-class="control-expend" />
      </div>
      <div class="control-single" title="缩小一级" @click="samll">
        <svg-icon icon-class="control-reduce" />
      </div>
      <div class="control-single" title="定位到当前位置" @click="locate">
        <svg-icon icon-class="map-location" />
      </div>
    </div> -->
  </div>
</template>
<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { Message } from 'element-ui';
import { CESIUM_KEY, TIANDITU_KEY } from '@/config';
import locateIcon from '@/assets/images/dot.png';
import { ImageryTheme } from '@/views/cesium/imageProvider';
import { CesiumPopup } from '@/utils/CesiumPopup';
import ParseWind from '@/views/map/ParseWind';
import { getImage } from '@/api/image_hd';
import { getImage as getImageT3 } from '@/api/image_t3';
import { getCurrentPointFactorData, getDataByFactor } from '@/api/weather_hd';
import VelocityLayer from '@/plugins/LeafletVelocity/VelocityLayer';
import { VectorTile, Pbf } from '@/plugins/Leaflet.VectorGrid';
import Bus from '@/bus/index';
import { factorMap, geoserverUrl, unitMap, unit } from '@/config/MapConfigT4';
import 'leaflet.tilelayer.colorfilter';
import { getJsonData } from '@/api/cityJson';
import { productionMap } from '@/config/MapConfig';
import { getPointDataLevel } from '@/config/Utils';

export default {
  data() {
    return {
      // 实例
      viewer: null,
      // 弹出框实例
      popup: null,
      // 当前可视区域的范围
      bounds: null,
      // 辅助播放，记录当前时间
      time: Date.now(),
      // 延时器id
      timer: 0,
      // 当需要重新渲染风场时，是否更新的标志
      update: true,
      // 当需要重新渲染海平面气压图层时，是否更新的标志
      slpUpdate: true,

      show: true,
      loadMapLayerFactors: ['wgrd10m', 'w100m', 't2mz', 'rh2m', 'tmp2m'],
      // 当前位置的维度
      currentLatitude: 35,

      // 当前位置的经度
      currentLogitude: 118,

      // 要素值
      factorValue: 0,

      // 记录当前位置
      locationMarker: null,

      // 记录搜索位置的经纬度
      searchPosition: null,

      // 当前点预报的数据详情
      forecastDetails: null,

      // 图
      heatmaplayerProvider: null,

      // 图层
      heatmaplayer: null,

      // 复数
      heatmaplayers: [],

      // 风
      image: null,

      aaarrryyy: {},

      // 锁
      lock: false,

      // 地形图
      shadeTileLayer: null,

      // windy地图
      windyTileLayer: null,

      // 底图
      imageProviderShadow: null,

      // 风
      windy_obj: null,

      // 层级
      zoomLevel: 0,

      // 城市
      citys: {},

      // 城市图层
      cityheatmaplayer: null,

      // 当前显示的城市图层
      cityZoom: 1,

      // 天地图
      tiandituVectorLayer: null,

      // 线段描述
      polyLabels: [],

      // 线段
      polyLines: [],

      // 高度描述
      hlLabels: [],

      // 搜索定位记录
      searchLocal: null,

      // 缩小防抖
      timeOut: null,

      // 初始化锁定
      initLock: false,

      // 是否定位
      isLocate: false,

      // 沙尘要素
      dustFactors: ['dust_conc', 'dod', 'dust_ddep', 'dust_emis'],

      // 单点标记点
      marker: '',
    };
  },
  computed: {
    ...mapState(['isMobile', 'layerType', 'mapCenter']),
    ...mapState('map', [
      'factor',
      'index',
      'status',
      'currentBaseTime',
      'windy',
      'city',
      'isobar',
      'region',
      'elevation',
      'worldLayer',
      'shadowLayer',
      'layerType',
      'hd',
    ]),
    ...mapGetters('map', [
      'isT2Mode',
      'playCurrent',
      'currentData',
      'allDataIndex',
      'current',
      'timeList',
      'currentTime',
      'windMeta',
      'currentWindLayerDetails',
      'currentLayerDetails',
      'currentWindyId',
    ]),

    factorKey() {
      return factorMap[this.factor];
    },
    playLayerUrl() {
      const { production, baseTimeString, mode, region, factorCode } = this.currentLayerDetails;
      const index = (this.index + 1) % this.timeList.length;
      const { forecastTimeString } = this.playCurrent(index);
      return `/main/tiles/${production}/${mode}/${region}/${baseTimeString}/${forecastTimeString}/${factorCode}/{mz}/{x}/{reverseY}.png`;
      // return `https://cancon.hpccube.com:65018/xje-tiles/${production}/${mode}/${region}/${baseTimeString}/${forecastTimeString}/${factorCode}/{mz}/{x}/{reverseY}.png`;
    },
    // 当前要素加载的瓦片图层地址
    layerUrl() {
      const { production, baseTimeString, mode, region, factorCode } = this.currentLayerDetails;
      const { forecastTimeString } = this.current;
      return `/main/tiles/${production}/${mode}/${region}/${baseTimeString}/${forecastTimeString}/${factorCode}/{mz}/{x}/{reverseY}.png`;
    },
    cityLayer() {
      return '/cities/{mz}/{x}/{reverseY}.json';
    },
    windyUrl() {
      const { production, baseTimeString, mode, region, factorCode } = this.currentLayerDetails;
      const { forecastTimeString } = this.current;
      const modify_production = (this.factor === 'tmp2m' || production === 'c2_5km') ? 'c10km' : (production === 't1' ? 't2' : 'c10km');
      const windFactor = factorCode === 'w100m' ? factorCode : 'wgrd10m';
      return `/gwind/${modify_production}/${mode}/${region}/${baseTimeString}/${windFactor}/${forecastTimeString}.webp`;
    },

    // 渲染 地面要素等压线
    slpUrl() {
      const { baseTimeString } = this.currentLayerDetails;
      const { forecastTimeString } = this.current;
      return `/arxiv/json/t2/early/global/${baseTimeString}/slp/${forecastTimeString}/${forecastTimeString}_d.json`;
    },

    getCurrentMaxZoom() {
      const tileProduction = productionMap[this.factor];
      if (tileProduction === 'c10km' || tileProduction === 'c12km') {
        return 11;
      } if (tileProduction === 'c1km') {
        return 14;
      }
      return 13;
    },
  },

  watch: {
    factor() {
      this.popup ? this.popup.remove() : '';
    },
    // 监听起报时间，起报时间变化，清除图层
    currentBaseTime() {
      // this.clearLayer();
    },
    // 监听时间 或者 起报时间 或者 要素变化，重新渲染图层
    current() {
      this.render();
    },
    status(value) {
      if (value === 'pause') {
        if (this.windy === 'normal') {
          this.renderWind();
        }
        if (this.isobar === 'normal') {
          this.renderIsobar();
        }
      }

      if (this.status === 'play') {
        this.render();
      } else {
        // 暂停时更新时间轴，因为渲染条件不进入从这里更新
        this.timer = setTimeout(() => {
          const index = (this.index + 1) % this.timeList.length;
          this.setIndex(index);
        }, 500);
      }
    },
    // 监听城市图层控制标识变化
    city(value) {
      if (value === 'none') {
        this.removeItem(this.cityheatmaplayer);
        this.cityheatmaplayer = null;
        // eslint-disable-next-line guard-for-in
        // for (const key in this.citys) {
        //   // eslint-disable-next-line guard-for-in
        //   for (const ele in this.citys[key]) {
        //     const item = { ...this.citys[key][ele] };
        //     if (item.cityOverlay) {
        //       this.viewer.entities.remove(item.cityOverlay);
        //     }
        //   }
        // }
        // this.citys = {};
      } else {
        this.renderCity();
      }
    },
    layerType(value) {
      this.removeItem(this.shadeTileLayer);
      this.removeItem(this.cityheatmaplayer);

      if (value === 'graph') {
        this.hillshade('hillshade');
      } else if (value === 'shadow') {
        this.hillshade('satellite');
      }
      this.renderCity();
    },
    // status(value) {
    //   if (value === "pause") {
    //     if (this.windy === "normal") {
    //       this.renderWind();
    //     }
    //     if (this.isobar === "normal") {
    //       this.renderIsobar();
    //     }
    //   }
    // },
    // 监听动态风控制变量变化
    windy(value) {
      if (value === 'none') {
        this.clearLayer('wind');
      } else if (this.image) {
        this.generateWindLayer(this.image);
      } else {
        this.renderWind();
      }
    },
    // 监听等压线控制变量变化
    isobar(value) {
      if (value === 'none') {
        this.clearLayer('isobar');
      } else {
        this.renderIsobar();
      }
    },
    // 监听高精度值是否发生变化
    hd() {
      this.removeItem(this.heatmaplayer);
      this.render();
    },
  },

  created() {
    Bus.$on('big', this.big);
    Bus.$on('setLocation', this.setLocation);
    Bus.$on('small', this.small);
    Bus.$on('clearLayer', this.clearLayer);
    Bus.$on('clearHeatLayer', this.clearHeatLayer);
    Bus.$on('locate', this.locate);
    Bus.$on('removePopup', this.removeMarker);
  },

  beforeDestroy() {
    Bus.$off('big', this.big);
    Bus.$off('small', this.small);
    Bus.$off('setLocation', this.setLocation);
    Bus.$off('clearLayer', this.clearLayer);
    Bus.$off('clearHeatLayer', this.clearHeatLayer);
    Bus.$off('locate', this.locate);
    Bus.$off('removePopup', this.removeMarker);
  },

  mounted() {
    this.initMap();
  },
  methods: {
    ...mapMutations('map', ['setBounds', 'setIndex', 'setWindData', 'setWindy', 'setIsobar', 'setPopupShow', 'setPopupVal', 'setPopupUnit', 'setLatlng']),
    getUrlEncode(obj) {
      let str = '';
      Object.keys(obj).forEach((key) => {
        str += `${key}=${obj[key]}&`;
      });
      return str.slice(0, -1);
    },
    clearHeatLayer() {
      this.removeItem(this.heatmaplayer);
    },

    setLocation(position) {
      // if (this.searchLocal) {
      //   this.viewer.entities.remove(this.searchLocal.point);
      //   this.searchLocal = null;
      // }

      // this.searchLocal = {};
      // this.searchLocal.position = position;
      // // 创建一个标记
      // this.searchLocal.point = this.viewer.entities.add({
      //   position: Cesium.Cartesian3.fromDegrees(position.lon, position.lat), // 标记的位置经纬度
      //   billboard: {
      //     image: this.createRedDotImage(), // 创建红点图标
      //     width: 10, // 图标的宽度
      //     height: 10, // 图标的高度
      //     pixelOffset: new Cesium.Cartesian2(0, 10),
      //     disableDepthTestDistance: Number.POSITIVE_INFINITY,
      //     show: true,
      //   },
      // });

      // 获取当前相机高度
      const cameraHeight = this.viewer.camera.positionCartographic.height;
      // 设置相机位置
      this.viewer.camera.setView({
        destination: Cesium.Cartesian3.fromDegrees(position.lon, position.lat, cameraHeight),
        orientation: {
          heading: Cesium.Math.toRadians(0.0), // 水平方向的角度
          roll: Cesium.Math.toRadians(0.0), // 侧倾角度
        },
      });

      // 将经纬度坐标转换为场景坐标
      const positionSence = Cesium.Cartesian3.fromDegrees(position.lon, position.lat);
      this.getSingleForecast(position.lat, position.lon, positionSence);
    },

    createRedDotImage() {
      const canvas = document.createElement('canvas');
      canvas.width = 16;
      canvas.height = 16;
      const context = canvas.getContext('2d');

      // 绘制中间透明圆
      context.beginPath();
      context.arc(8, 8, 6, 0, 2 * Math.PI, false);
      context.fillStyle = 'rgba(0,0,0,0)';
      context.fill();

      // 绘制外圆环（使用贝塞尔曲线）
      context.beginPath();
      context.arc(8, 8, 7, 0, 2 * Math.PI, false); // 外环的半径稍微大一点
      context.strokeStyle = 'red';
      context.lineWidth = 2; // 外环的宽度
      context.stroke();

      return canvas.toDataURL(); // 返回图标的 data URL
    },

    /**
     * 单点数值计算
     * @param data
     */
    calCurrentPointVal(dataArr, index) {
      let val = '';
      if (this.factorKey === 'wind') {
        const data = dataArr[index]?.value ?? 0;
        if (Array.isArray(data)) {
          // 判断数据是否是数组
          const windData = [data[0], data[1]];
          const windSpeed = Math.sqrt(
            windData.reduce((sum, num) => sum + parseFloat(num) ** 2, 0),
          ).toFixed(2);
          val = unitMap[this.factorKey](windSpeed);
          this.setPopupVal(windSpeed);
          this.setPopupUnit(unit[this.factorKey]);
        }
      } else if (this.factorKey === 'vis_cats') {
        const data = dataArr[index]?.value ?? 0;
        val = getPointDataLevel(Number(data));
      } else {
        val = unitMap[this.factorKey](dataArr[index]?.value[0] ?? 0);
        this.setPopupVal(dataArr[index]?.value[0] ?? 0);
        this.setPopupUnit(unit[this.factorKey]);
      }
      return val;
    },

    async getSingleForecast(lat, lon, position) {
      const { production, baseTimeString, mode, region, pressure, factorCode } = this.currentLayerDetails;
      const params = {
        lat,
        lon,
        mode,
        baseTime: baseTimeString,
        production,
        pressure,
        region,
        factorCode,
      };
      const result = await getCurrentPointFactorData(this.getUrlEncode(params));
      this.forecastDetails = result?.forecast[0].forecastDetails;
      if (this.status === 'pause') {
        Bus.$emit('hiddenBottom');

        // if (this.searchLocal) {
        //   this.viewer.entities.remove(this.searchLocal);
        //   this.searchLocal = null;
        // }
        // this.searchLocal.point = this.viewer.entities.add({
        //   position: Cesium.Cartesian3.fromDegrees(lon, lat), // 标记的位置经纬度
        //   billboard: {
        //     image: this.createRedDotImage(), // 创建红点图标
        //     width: 10, // 图标的宽度
        //     height: 10, // 图标的高度
        //     pixelOffset: new Cesium.Cartesian2(0, 10),
        //     disableDepthTestDistance: Number.POSITIVE_INFINITY,
        //     show: true,
        //   },
        // });

        const val = this.calCurrentPointVal(this.forecastDetails, this.allDataIndex);
        const rightLng = ((lon - 180) % 360 + 360) % 360 - 180;
        const latS = parseFloat(lat) > 0.0 ? 'N' : 'S';
        const lngS = parseFloat(rightLng) > 0.0 ? 'E' : 'W';
        const html = ` <div class="custom-popup-content" style="align-items: center;white-space: nowrap;justify-content: space-between;line-height:1.3">
            <div class="line"></div>
            <div class="custom-popup-text" style="display: flex;white-space: nowrap;justify-content: space-between;flex-direction: column">
              <div style="font-size: 11px">${Math.abs(rightLng).toFixed(3)}${lngS},${Math.abs(lat).toFixed(3)}${latS} </div>
              <div style="display: flex;align-items: flex-end;justify-content: space-between">
              <div id="factor-value">${val}</div>
              <span class="more-factor-data el-icon-arrow-right" style="position:absolute;right:10px;width: 30px;height: 30px;text-align: center;line-height: 32px;border-radius: 50%;background-color: #027aff;color: #fff;font-size: 16px;font-weight: 700;cursor: pointer;" data-lat="${lat}" data-lng="${lon}"></span>
            </div>
          </div>`;
        if (this.isMobile) {
          if (this.marker) this.viewer.entities.remove(this.marker);
          this.marker = this.viewer.entities.add({
            position: Cesium.Cartesian3.fromDegrees(lon, lat), // 标记的位置经纬度
            billboard: {
              image: require('@/assets/images/location1.png'), // 图片路径
              width: 20, // 图片宽度
              height: 20, // 图片高度
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 设置图片垂直方向的起点位置
              disableDepthTestDistance: Number.POSITIVE_INFINITY, // 使图片不受深度测试影响
            },
          });
          this.setPopupShow(true);
        } else {
          this.popup = new CesiumPopup({ title: '预报' }).setPosition(position).setHTML(html);
          this.popup.closeHander = () => {
            this.popup.remove();
            Bus.$emit('hiddenBottom');
          };
          // thiscesium-popup-content-panel
          this.popupLocal = { lat, lon };
          this.popup.addTo(this.viewer);
        }
      }
    },

    /**
     * 国界图层
     * @returns {*}
     */
    setCountryBorder() {
      const url = `${geoserverUrl}/line-map/{z}/{x}/{y}.pbf`;
      const borderLayer = L.vectorGrid.protobuf(url, {
        rendererFactory: L.canvas.tile,
        pane: 'borderLayerPane',
        vectorTileLayerStyles: {
          interactive: true,
          world_line(properties) {
            if (properties.contry_sea === 1) {
              return {
                opacity: 0.9,
                fillColor: '#333',
                fillOpacity: 1,
                fill: true,
                color: '#333',
                weight: 1.2,
              };
            }

            return {
              opacity: 0.9,
              fillColor: '#333',
              fillOpacity: 1,
              fill: true,
              color: '#333',
              weight: 0.3,
            };
          },
          world_line_prov(properties) {
            if (properties.country_level === 1) {
              return {
                color: '#1d1d1d',
                weight: 1,
              };
            }
            return {
              color: '#333',
              dashArray: '2, 4',
              weight: 1,
            };
          },
          world_line_city(properties) {
            // 地形图配色
            if (properties.country_level === 3) {
              return {
                color: '#999',
                dashArray: '2, 6',
                weight: 1,
              };
            }
            if (properties.country_level === 4) {
              return {
                color: '#ccc',
                dashArray: '2, 8',
                weight: 1,
              };
            }
            if (properties.country_level === 2) {
              return {
                color: '#333',
                dashArray: '2, 4',
                weight: 1.2,
              };
            }
            return {
              color: '#1d1d1d',
              weight: 1,
            };
          },
        },
      });
      return borderLayer;
    },

    borderLayer() {
      const countiesBorder = new Cesium.UrlTemplateImageryProvider({
        url: '/line-map/{z}/{x}/{y}.pbf',
        maximumLevel: 8,
        minimumLevel: 1,
        tilingScheme: new Cesium.GeographicTilingScheme(),
        customTags: {
          mz(p, x, y, level) {
            return level + 1;
          },
        },
      });

      this.borderLayerPbf = this.viewer.imageryLayers.addImageryProvider(countiesBorder);

      const boderObj = this.setCountryBorder();

      // 1 1
      // 2 2
      // 3 4
      // 4 8
      // 5 11

      // 重写 imageryProvider.requestImage 方法
      countiesBorder.requestImage = async (x, y, level) => {
        const reverseY = 2 ** (level - 1) + y;

        const json = await fetch(`/line-map/${level + 1}/${x}/${reverseY}.pbf`, {}).then(
          (response) => {
            if (!response.ok) {
              return { layers: [] };
            }

            return response.blob().then((blob) => {
              const reader = new FileReader();
              return new Promise((resolve) => {
                reader.addEventListener('loadend', () => {
                  // reader.result contains the contents of blob as a typed array

                  // blob.type === 'application/x-protobuf'
                  const pbf = new Pbf(reader.result);

                  // 						console.log(pbf);
                  return resolve(new VectorTile(pbf));
                });
                reader.readAsArrayBuffer(blob);
              });
            });
          },
        );
        for (const layerName in json.layers) {
          if (json.layers.hasOwnProperty(layerName)) {
            const feats = [];

            for (let i = 0; i < json.layers[layerName].length; i++) {
              const feat = json.layers[layerName].feature(i);
              feat.geometry = feat.loadGeometry();
              feats.push(feat);
            }

            json.layers[layerName].features = feats;
          }
        }
        const coords = new L.Point(x, reverseY);
        coords.z = level + 1;
        const canvas = boderObj.updateCanvas(json, coords);

        const context = canvas.getContext('2d');
        const imageData = context.getImageData(0, 0, canvas.width, canvas.height);

        return new Promise((resolve) => {
          resolve(imageData);
        });
      };
    },

    async initMap() {
      const that = this;
      Cesium.Ion.defaultAccessToken = CESIUM_KEY;
      this.imageProviderShadow = new Cesium.UrlTemplateImageryProvider({
        url: `${geoserverUrl}/clear/{mz}/{x}/{reverseY}.png`, // 替换为您的瓦片URL
        maximumLevel: 8,
        minimumLevel: 1,
        tilingScheme: new Cesium.GeographicTilingScheme(),
        customTags: {
          mz(p, x, y, level) {
            return level + 1;
          },
        },
      });

      this.viewer = new Cesium.Viewer('cesiumContainer', {
        animation: false, // 动画
        homeButton: false, // home键
        geocoder: false, //  开启geo搜索框
        baseLayerPicker: false, // 图层选择控件
        timeline: false, // 时间轴
        maximumScreenSpaceError: 64,
        fullscreenButton: false, // 全屏显示
        infoBox: false, // 点击要素之后浮窗
        sceneModePicker: false, // 投影方式  三维/二维
        navigationInstructionsInitiallyVisible: true, // 导航指令
        navigationHelpButton: false, // 帮助信息
        selectionIndicator: false, // 选择
        imageryProvider: this.imageProviderShadow,
      });

      this.viewer.scene.screenSpaceCameraController.maximumZoomDistance = 21800000;
      this.viewer.scene.skyAtmosphere.show = false;
      this.viewer.scene.globe.baseColor = Cesium.Color.fromCssColorString('grey'); // 设置地球颜色
      this.viewer.scene.fxaa = false;
      this.viewer.scene.postProcessStages.fxaa.enabled = false;

      if (this.layerType === 'graph') {
        this.hillshade('hillshade');
      } else if (this.layerType === 'shadow') {
        this.hillshade('satellite');
      }

      this.viewer.scene.globe.enableLighting = false; // 关闭光照
      this.viewer.scene.globe.showGroundAtmosphere = false;
      this.viewer.shadows = false;

      let timeOut1 = null;

      // eslint-disable-next-line func-names
      this.viewer.camera.changed.addEventListener(() => {
        clearTimeout(timeOut1);
        timeOut1 = setTimeout(() => {
          // that.city === "normal" ? that.upDateCity() : "";
          if (that.isobar === 'normal') {
            that.zoomLevel > 3 ? that.upDatePlone() : '';
            that.zoomLevel > 2 ? that.upDateHl() : '';
          }
          const dom = document.getElementsByClassName('cesium-popup-panel');
          if (dom && dom[0]) {
            that.labelIsShow(that.popupLocal.lon, that.popupLocal.lat)
              ? (dom[0].style.display = 'block')
              : (dom[0].style.display = 'none');
          }

          // if (that.searchLocal) {
          //   // that.labelIsShow(that.searchLocal.position.lon, that.searchLocal.position.lat)
          //   //   ? (that.searchLocal.billboard.show = true)
          //   //   : (that.searchLocal.billboard.show = false);
          // }
        }, 0);
      });

      this.viewer.camera.moveEnd.addEventListener(() => {
        if (!that.initLock) {
          that.initLock = true;
          if (that.windy !== 'none') {
            setTimeout(() => {
              that.renderWind();
            }, 1000);
          }

          if (that.isobar !== 'none') {
            setTimeout(() => {
              that.renderIsobar();
            }, 1000);
          }
          setTimeout(() => {
            that.latitude = false;
            that.locate();
          }, 1500);
        }
        // 获取相机的高度
        const cameraHeight = that.viewer.camera.positionCartographic.height;

        const A = 40487.57;
        const B = 0.00007096758;
        const C = 91610.74;
        const D = -40467.74;

        const level = Math.round(D + (A - D) / (1 + (cameraHeight / C) ** B));

        const isNowLevel = that.zoomLevel === level;

        that.zoomLevel = level;

        if (that.zoomLevel > 8) {
          that.removeItem(that.cityheatmaplayer);
          that.cityheatmaplayer = null;
          // eslint-disable-next-line guard-for-in
          // for (const key in that.citys) {
          //   // eslint-disable-next-line guard-for-in
          //   for (const ele in that.citys[key]) {
          //     const item = { ...that.citys[key][ele] };
          //     if (item.cityOverlay) {
          //       that.viewer.entities.remove(item.cityOverlay);
          //     }
          //   }
          // }

          if (that.tiandituVectorLayer) return;
          const tiandituVector = new Cesium.UrlTemplateImageryProvider({
            url: `https://{s}.tianditu.gov.cn/DataServer?T=cta_w&X={x}&Y={y}&L={z}&tk=${TIANDITU_KEY}`,
            subdomains: ['t0', 't1', 't2', 't3', 't4', 't5', 't6'],
            maximumLevel: 18,
            tilingScheme: new Cesium.WebMercatorTilingScheme(),
            tileMatrixSetID: 'c',
            tileMatrixLabels: [
              '0',
              '1',
              '2',
              '3',
              '4',
              '5',
              '6',
              '7',
              '8',
              '9',
              '10',
              '11',
              '12',
              '13',
              '14',
              '15',
              '16',
              '17',
              '18',
            ],
          });

          // 创建地形图TileLayer
          that.tiandituVectorLayer = new Cesium.ImageryLayer(tiandituVector);

          // 将TileLayer添加到地图上
          that.viewer.imageryLayers.add(that.tiandituVectorLayer);
        } else {
          that.removeItem(that.tiandituVectorLayer);
          that.tiandituVectorLayer = null;

          if (that.city !== 'none' && that.status !== 'play') {
            that.renderCity(isNowLevel);
            // isNowLevel ? '' : that.renderCity(isNowLevel);
          }
        }

        if (that.isobar === 'normal') {
          if (that.zoomLevel > 2) {
            that.upDateHl();
          } else {
            that.upDateHl('delete');
          }
          if (that.zoomLevel > 3) {
            that.upDatePlone();
          } else {
            that.upDatePlone('delete');
          }
        }

        // that.city === "normal" ? that.upDateCity("update", isNowLevel) : "";
        return Math.round(D + (A - D) / (1 + (cameraHeight / C) ** B));
      });

      // 设置全球地形数据
      this.viewer.scene.globe.depthTestAgainstTerrain = true;

      const handler = new Cesium.ScreenSpaceEventHandler(this.viewer.scene.canvas);

      handler.setInputAction((movement) => {
        const earthPosition = this.viewer.camera.pickEllipsoid(
          movement.position,
          this.viewer.scene.globe.ellipsoid,
        );
        const cartographic = Cesium.Cartographic.fromCartesian(
          earthPosition,
          this.viewer.scene.globe.ellipsoid,
          new Cesium.Cartographic(),
        );
        const lat = Cesium.Math.toDegrees(cartographic.latitude);
        const lng = Cesium.Math.toDegrees(cartographic.longitude);
        // 场景坐标
        const position = this.viewer.scene.pickPosition(movement.position);
        console.log('123123123');
        this.setLatlng({ lat, lng });
        this.getSingleForecast(lat, lng, position);
      }, Cesium.ScreenSpaceEventType.LEFT_CLICK);

      const destination = Cesium.Cartesian3.fromDegrees(103.84, 31.15, 17850000);
      // 相机的位置。
      this.viewer.camera.flyTo({
        destination,
      });
      // cesium相关的logo隐藏
      this.viewer._cesiumWidget._creditContainer.style.display = 'none';

      const helper = new Cesium.EventHelper();
      let max = 0;
      helper.add(this.viewer.scene.globe.tileLoadProgressEvent, (e) => {
        if (e > max) {
          max = e;
        }

        const number = Math.floor(max / 3);

        if (e <= number && !that.lock) {
          // if (e > 0) return;
          that.heatmaplayer ? (that.heatmaplayer.alpha = 1) : '';
          that.lock = true;
          that.heatmaplayers.push(that.heatmaplayer);

          that.remove();
          const factorValueDom = document.getElementById('factor-value');
          // that.addTiles(that.layerUrl);
          if (that.heatmaplayer && that.status === 'play') {
            that.clearLayer('wind');
            const index = (that.index + 1) % that.timeList.length;
            that.setIndex(index);
            if (factorValueDom) {
              const currentVal = that.calCurrentPointVal(that.forecastDetails, index);
              factorValueDom.innerHTML = currentVal;
            }
          } else if (that.heatmaplayer && factorValueDom) {
            const currentVal = that.calCurrentPointVal(that.forecastDetails, that.index);
            factorValueDom.innerHTML = currentVal;
          }
        }
      });

      this.borderLayer();
    },

    fetchData(url) {
      return new Promise((resolve, reject) => {
        getJsonData(url)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    windyShade() {
      this.removeItem(this.imageProviderShadow);
      // 创建ImageryProvider
      const imageProviderShadow = new Cesium.UrlTemplateImageryProvider({
        url: 'https://cancon.hpccube.com:65018/relief/{mz}/{x}/{reverseY}.png', // 替换为您的瓦片URL
        maximumLevel: 8,
        minimumLevel: 1,
        tilingScheme: new Cesium.GeographicTilingScheme(),
        customTags: {
          mz(p, x, y, level) {
            return level + 1;
          },
        },
      });

      // 创建地形图TileLayer
      this.windyTileLayer = new Cesium.ImageryLayer(imageProviderShadow);

      // 将TileLayer添加到地图上
      this.viewer.imageryLayers.add(this.windyTileLayer);

      this.viewer.imageryLayers.lowerToBottom(this.windyTileLayer);
      this.viewer.imageryLayers.raise(this.windyTileLayer);
    },

    hillshade(type) {
      this.removeItem(this.imageProviderShadow);
      // 创建ImageryProvider
      const imageProviderShadow = new Cesium.UrlTemplateImageryProvider({
        url: `${geoserverUrl}/${type}/{mz}/{x}/{y}.png`, // 替换为您的瓦片URL
        maximumLevel: 8,
        minimumLevel: 1,
        tilingScheme: new Cesium.GeographicTilingScheme(),
        customTags: {
          mz(p, x, y, level) {
            return level + 1;
          },
        },
      });

      // 创建地形图TileLayer
      this.shadeTileLayer = new Cesium.ImageryLayer(imageProviderShadow);

      // 将TileLayer添加到地图上
      this.viewer.imageryLayers.add(this.shadeTileLayer);

      this.viewer.imageryLayers.lowerToBottom(this.shadeTileLayer);
      this.viewer.imageryLayers.raise(this.shadeTileLayer);
    },

    remove() {
      if (this.heatmaplayers && this.heatmaplayers.length > 1) {
        const last = this.heatmaplayers[this.heatmaplayers.length - 1];
        for (let i = 0; i < this.heatmaplayers.length - 1; i++) {
          // new TWEEN.Tween(this.heatmaplayers[i]).to({ alpha: 0 }, 1000).start().onComplete(() => {
          this.heatmaplayers[i] ? this.removeItem(this.heatmaplayers[i]) : '';
          // })// 隐藏当前的
          // this.heatmaplayers[i] ? this.viewer.imageryLayers.remove(this.heatmaplayers[i]) : '';
        }
        // TWEEN.update();
        this.heatmaplayers = [last];
      }
    },
    removeMarker() {
      if (this.marker) this.viewer.entities.remove(this.marker);
    },

    removeItem(item) {
      item ? this.viewer.imageryLayers.remove(item) : '';
    },

    clearLayer(type) {
      if (!type || type === 'wind') {
        this.windLayer = null;
        // FIX 修改有时不展示风场的问题
        this.image = null;

        if (this.windy_obj) {
          this.windy_obj.onRemove();
          this.windy_obj = null;
        }
      } else if (!type || type === 'isobar') {
        this.polyLabels.forEach((item) => {
          this.viewer.entities.remove(item.ployOverlay);
        });
        this.polyLabels = [];
        this.polyLines.forEach((item) => {
          this.viewer.entities.remove(item.lines);
        });
        this.polyLines = [];
        this.hlLabels.forEach((item) => {
          this.viewer.entities.remove(item.hloyOverlay);
        });
        this.hlLabels = [];
      }
    },

    // 创建城市名称的HTML元素
    createCityElement(cityName) {
      const element = document.createElement('div');
      element.className = 'city-label';
      element.textContent = cityName;
      return element;
    },

    labelIsShow(lat, lon) {
      const scene = this.viewer.scene;

      // 目标经纬度点
      const targetLongitude = lat; /* 目标经度 */
      const targetLatitude = lon; /* 目标纬度 */

      // 将经纬度点转换为屏幕空间坐标
      const targetPosition = Cesium.Cartesian3.fromDegrees(targetLongitude, targetLatitude);
      const screenSpacePoint = scene.cartesianToCanvasCoordinates(targetPosition);

      // 获取屏幕尺寸
      const screenWidth = scene.canvas.clientWidth;
      const screenHeight = scene.canvas.clientHeight;

      // 检查点是否在屏幕可视范围内
      const isPointVisibleOnScreen = screenSpacePoint
        && screenSpacePoint.x >= 0
        && screenSpacePoint.x <= screenWidth
        && screenSpacePoint.y >= 0
        && screenSpacePoint.y <= screenHeight;

      const ellipsoid = Cesium.Ellipsoid.WGS84;
      const camera = this.viewer.camera; // viewer为你的Cesium Viewer对象
      const point = Cesium.Cartesian3.fromDegrees(lat, lon);
      const occluder = new Cesium.EllipsoidalOccluder(ellipsoid, camera.position);

      return occluder.isPointVisible(point) && isPointVisibleOnScreen;
    },

    upDateCity(type, isNowLevel) {
      const that = this;
      if (type === 'update') {
        const zooms = this.zoomLevel;
        if (zooms && this.citys[zooms]) {
          for (const key in this.citys) {
            if (Number(key) !== Number(zooms) && this.citys[key]) {
              // eslint-disable-next-line guard-for-in
              for (const ele in this.citys[key]) {
                const item = { ...this.citys[key][ele] };
                if (item.cityOverlay) {
                  that.viewer.entities.remove(item.cityOverlay);
                  item.cityOverlay = '';
                }
              }
            } else if (this.citys[key]) {
              // eslint-disable-next-line guard-for-in
              for (const ele in this.citys[key]) {
                const item = { ...this.citys[key][ele] };
                const value = this.labelIsShow(item.info[3], item.info[4], item.param.label.text);

                if (!value) {
                  if (item.cityOverlay) {
                    // item.cityOverlay.label.show = value;
                    that.viewer.entities.remove(item.cityOverlay);
                  }
                } else {
                  let flag = false;
                  const labelTextToCheck = item.param.label.text; // 要检查的 Label 文本内容

                  this.viewer.entities.values.forEach((entity) => {
                    if (
                      entity.label
                      && entity.label.text !== undefined
                      && entity.label.text._value === labelTextToCheck
                    ) {
                      // 如果当前 Entity 是 Label 并且包含 label.text 属性，并且文本内容与指定的文本内容相同
                      if (isNowLevel) {
                        flag = true;
                      } else {
                        that.viewer.entities.remove(entity);
                      }
                      // flag = true;
                      // 如果存在指定文本内容的 Label，则设置标志变量为 true 并跳出循环
                    }
                  });

                  !flag
                    ? (this.citys[key][ele].cityOverlay = this.viewer.entities.add(item.param))
                    : '';
                }
              }
            }
          }
        }
        return;
      }

      if (this.zoomLevel && this.citys[this.zoomLevel]) {
        // eslint-disable-next-line guard-for-in
        for (const key in this.citys[this.zoomLevel]) {
          if (!this.citys[this.zoomLevel][key]) return;
          const items = { ...this.citys[this.zoomLevel] };
          const item = { ...items[key] };
          const value = this.labelIsShow(item.info[3], item.info[4], item.param.label.text);
          if (!value) {
            if (item.cityOverlay) {
              that.viewer.entities.remove(item.cityOverlay);
            }
          } else {
            let flag = false;
            const labelTextToCheck = item.param.label.text; // 要检查的 Label 文本内容

            this.viewer.entities.values.forEach((entity) => {
              if (
                entity.label
                && entity.label.text !== undefined
                && entity.label.text._value === labelTextToCheck
              ) {
                // 如果当前 Entity 是 Label 并且包含 label.text 属性，并且文本内容与指定的文本内容相同
                // that.viewer.entities.remove(entity);
                flag = true;
                // 如果存在指定文本内容的 Label，则设置标志变量为 true 并跳出循环
              }
            });

            !flag ? (items[key].cityOverlay = this.viewer.entities.add(item.param)) : '';
            // item.cityOverlay.label.show = value;
          }
        }
      }
    },

    drawCity(obj1, obj, flag) {
      const isShow = this.labelIsShow(obj1[3], obj1[4], obj[1]) && this.city === 'normal';
      const param = {
        position: Cesium.Cartesian3.fromDegrees(obj1[3], obj1[4]),
        label: {
          text: (obj1[1] = obj1[1].length > 10 ? obj1[1].substring(0, 10) : obj1[1]),
          font: '14px sans-serif',
          fillColor: Cesium.Color.WHITE,
          outlineColor: Cesium.Color.BLACK,
          outlineWidth: 2,
          style: Cesium.LabelStyle.FILL_AND_OUTLINE,
          disableDepthTestDistance: Number.POSITIVE_INFINITY,
          show: isShow,
        },
      };
      // 创建一个Overlay实体
      // var cityOverlay = this.viewer.entities.add(param);
      flag ? (obj.cityOverlay = this.viewer.entities.add(param)) : '';
      obj.param = param;
    },

    renderCity(isNowLevel) {
      // const heatmaplayerProvider = new Cesium.UrlTemplateImageryProvider({
      //   url: '/cities/{mz}/{x}/{reverseY}.json',
      //   maximumLevel: 15,
      //   minimumLevel: 1,
      //   tilingScheme: new Cesium.GeographicTilingScheme(),
      //   customTags: {
      //     mz(p, x, y, level) {
      //       return level + 1;
      //     },
      //   },
      // });

      const rectangle = { ...this.viewer.camera.computeViewRectangle() };
      rectangle.north += 0.5;

      console.log('this.viewer.camera.computeViewRectangle()', rectangle);
      const heatmaplayerProvider = new Cesium.UrlTemplateImageryProvider({
        url: '/cities/{mz}/{x}/{reverseY}.json',
        maximumLevel: this.zoomLevel < 1 ? 2 : this.zoomLevel,
        minimumLevel: this.zoomLevel < 1 ? 2 : this.zoomLevel,
        rectangle, // 根据当前相机位置计算可视区域的矩形
        tilingScheme: new Cesium.GeographicTilingScheme(),
      });

      const remberCity = this.cityheatmaplayer;
      this.cityheatmaplayer = this.viewer.imageryLayers.addImageryProvider(heatmaplayerProvider);
      const that = this;

      this.cityheatmaplayer.imageryProvider.readyPromise.then(() => {
        if (isNowLevel && remberCity) {
          setTimeout(() => {
            remberCity.alpha = 0.8;
            setTimeout(() => {
              remberCity.alpha = 0.4;
              setTimeout(() => {
                remberCity.alpha = 0.1;
                setTimeout(() => {
                  that.viewer.imageryLayers.remove(remberCity);
                }, 150);
              });
            }, 150);
          }, 150);
        } else if (remberCity) {
          that.viewer.imageryLayers.remove(remberCity);
        }

        console.log('所有瓦片加载完毕');
      });

      this.cityheatmaplayer.brightness = this.layerType === 'shadow' || this.layerType === 'default' ? 1.5 : 1;
      this.cityheatmaplayer.alpha = this.layerType === 'shadow' || this.layerType === 'default' ? 1 : 1.5;

      // 重写 imageryProvider.requestImage 方法
      heatmaplayerProvider.requestImage = async (x, y, level) => {
        const reverseY = 2 ** level - 1 - y;

        // if(level !== this.zoomLevel) {
        //   return new Promise((resolve) => { resolve(); });
        // }

        const cityData = await fetch(`/cities/${level + 1}/${x === 49 ? x : x}/${reverseY}.json`)
          .then((res) => res.json().then((data) => (data.length ? data : null)))
          .catch(() => null);

        // 创建一个新的canvas元素
        const canvas = document.createElement('canvas');

        // const canvas = new fabric.Canvas('c');

        // 设置canvas的宽度和高度
        canvas.width = 512;
        canvas.height = 512;
        // canvas.style.background = 'blue';
        // 获取canvas的2D渲染上下文
        const ctx = canvas.getContext('2d');

        // 设置字体样式
        ctx.font = '18px Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, SimSun, sans-serif';

        // 设置文字颜色
        ctx.fillStyle = this.layerType === 'shadow' || this.layerType === 'default' ? '#FFFFFF' : '#0F0E0E;';

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        if (!cityData || !cityData.length) {
          return new Promise((resolve) => {
            resolve(ctx.getImageData(0, 0, 512, 512));
          });
        }

        cityData.forEach((item, index) => {
          if (item && item.length) {
            let x = item[7] * 2 - 10;
            let y = item[8] * 2;
            // 计算文字宽度
            const textWidth = ctx.measureText(item[1]).width;
            const textHeight = parseInt(ctx.font); // 估算文本高度

            // 处理超出右边界的情况
            if (x + textWidth > canvas.width) {
              x -= x + textWidth - canvas.width;
            }

            // 处理超出下边界的情况
            if (y + textHeight > canvas.height) {
              y -= y + textHeight - canvas.height;
            }

            // 处理超出左边界的情况
            if (x < 0) {
              x = 0;
            }

            // 处理超出上边界的情况
            if (y < textHeight) {
              y = textHeight;
            }

            // 创建带有阴影效果的文本对象
            // var rect = new fabric.Text(item[1], {
            //   left: x,
            //   top: y,
            //   fill: "#0f0e0e",
            //   shadow: "-1px 0 1px rgb(255, 255, 255), 1px 0 1px rgb(255, 255, 255), 0 1px 1px rgb(255, 255, 255), 0 -1px 1px rgb(255, 255, 255)",
            // });

            // 添加矩形到画布上
            // canvas.add(rect);
            // canvas.renderAll();

            // 第一个阴影
            ctx.shadowColor = this.layerType === 'shadow' || this.layerType === 'default'
              ? 'rgba(0, 0, 0)'
              : 'rgba(255, 255, 255, 0.6)';
            ctx.shadowBlur = this.layerType === 'shadow' || this.layerType === 'default' ? 0 : 1;
            ctx.shadowOffsetX = -1;
            ctx.shadowOffsetY = 0;
            ctx.fillText(item[1], x, y);

            // 第二个阴影
            ctx.shadowOffsetX = 1;
            ctx.shadowOffsetY = 0;
            ctx.fillText(item[1], x, y);

            // 第三个阴影
            ctx.shadowOffsetX = 0;
            ctx.shadowOffsetY = 1;
            ctx.fillText(item[1], x, y);

            // 第四个阴影
            ctx.shadowOffsetX = 0;
            ctx.shadowOffsetY = -1;
            ctx.fillText(item[1], x, y);

            // ctx.fillText(item[1], x, y);
          }
        });

        const context = canvas.getContext('2d');
        const imageData = context.getImageData(0, 0, 512, 512);

        return new Promise((resolve) => {
          resolve(imageData);
        });
      };
    },

    render1() {
      const heatmaplayerProvider1 = new Cesium.UrlTemplateImageryProvider({
        url: `${geoserverUrl}/line-map/{mz}/{x}/{reverseY}.pbf`,
        maximumLevel: 5,
        minimumLevel: 1,
        tilingScheme: new Cesium.GeographicTilingScheme(),
        customTags: {
          mz(p, x, y, level) {
            return level + 1;
          },
        },
      });

      this.heatmaplayer1 = this.viewer.imageryLayers.addImageryProvider(heatmaplayerProvider1);

      // 重写 imageryProvider.requestImage 方法
      heatmaplayerProvider1.requestImage = (x, y, level) => {
        const reverseY = 2 ** level - 1 - y;

        fetch(`${geoserverUrl}/line-map/${level + 1}/${x}/${reverseY}.pbf`)
          .then((response) => {
            if (!response.ok) {
              return { layers: [] };
            }

            return response.blob().then((blob) => {
              const reader = new FileReader();
              return new Promise(() => {
                reader.addEventListener('loadend', () => {});
                reader.readAsArrayBuffer(blob);
              });
            });
          })
          .catch(() => {});

        return new Promise((resolve) => {
          resolve();
        });
      };
    },

    render() {
      // 如果不是播放则先清除图层
      if (this.status !== 'play') {
        this.removeItem(this.heatmaplayer);
      }

      const that = this;
      this.update = true;
      this.slpUpdate = true;
      const url = this.status === 'play' ? this.playLayerUrl : this.layerUrl;
      const num = this.hd === 'none' ? 0 : 3;
      this.heatmaplayerProvider = new Cesium.UrlTemplateImageryProvider({
        url,
        maximumLevel: this.getCurrentMaxZoom - num,
        minimumLevel: 2,
        tilingScheme: new Cesium.GeographicTilingScheme(),
        customTags: {
          mz(p, x, y, level) {
            return level + 1;
          },
        },
      });

      this.heatmaplayer = this.viewer.imageryLayers.addImageryProvider(this.heatmaplayerProvider);
      this.viewer.imageryLayers.raiseToTop(this.borderLayerPbf); // 将图层置顶
      this.cityheatmaplayer ? this.viewer.imageryLayers.raiseToTop(this.cityheatmaplayer) : ''; // 将图层置顶

      // 播放把透明度先透明为了过渡效果
      if (this.status === 'play') {
        this.heatmaplayer.alpha = 0;
      }

      const theme = new ImageryTheme({
        bgColor: 'red',
        alpha: 0,
        invert: true,
      });
      this.heatmaplayerProvider.requestImage = (x, y, level, request) => {
        let promise = theme._getNthThreeTile({ x, y, z: level + 1 }, url);
        if (promise) {
          promise = promise.then((image) => theme.processImage(image, request.url), {
            x,
            y,
            z: level,
          });
        }
        return promise;
      };
      that.lock = false;
      this.renderWind();
    },

    // 创建一个Promise，用于等待图层加载完毕
    waitLayerLoad(layer) {
      return new Promise((resolve, reject) => {
        const handler = layer.imageryProvider.loadStatusChanged.addEventListener(
          (isLoading, isDataAvailable, error) => {
            if (!isLoading && isDataAvailable) {
              // 图层加载完毕
              resolve();
              handler(); // 取消事件监听
            }
            if (error) {
              reject(error);
              handler(); // 取消事件监听
            }
          },
        );
      });
    },

    clickMap(event) {
      const { dataset, className } = event.target;
      // e.stopPropagation();
      if (className && className.length && className.includes('more-factor-data')) {
        const { lat, lng } = dataset;
        Bus.$emit('showBottom', { lat, lon: lng });
      }
    },

    async renderWind() {
      if (this.windy === 'normal' || localStorage.getItem('windy') === 'normal') {
        if (this.cancel && typeof this.cancel === 'function') this.cancel();
        const image = !this.t3
          ? await getImage(this.windyUrl, (cancel) => {
            this.cancel = cancel;
          })
          : await getImageT3(this.windyUrl, (cancel) => {
            this.cancel = cancel;
          });

        this.image = new Image();
        this.image.src = image;
        this.image.addEventListener('load', () => {
          this.generateWindLayer(this.image);
        });
      }
    },

    // 获取相机当前高，经，纬
    get_camera_height() {
      // 获取当前镜头位置的笛卡尔坐标
      const cameraPosition = this.viewer.camera.position;
      // 获取当前坐标系标准
      const ellipsoid = this.viewer.scene.globe.ellipsoid;
      // 根据坐标系标准，将笛卡尔坐标转换为地理坐标
      const cartographic = ellipsoid.cartesianToCartographic(cameraPosition);
      // 获取镜头的高度
      const height = cartographic.height;
      // 根据上面当前镜头的位置，获取该中心位置的经纬度坐标
      const centerLon = parseFloat(Cesium.Math.toDegrees(cartographic.longitude).toFixed(8));
      const centerLat = parseFloat(Cesium.Math.toDegrees(cartographic.latitude).toFixed(8));
      return { height, centerLon, centerLat };
    },
    /**
     * @description 使用腾讯地图进行定位
     * @returns {void}
     * @author yunpengliu
     */
    tencentMapLocation(dotIcon) {
      const that = this;
      this.$jsonp('https://apis.map.qq.com/ws/location/v1/ip', {
        key: 'UMKBZ-SSVK2-TSMUO-CIJ3D-FE4F6-2KFSI',
        output: 'jsonp',
      }).then((res) => {
        if (res.status === 0) {
          const longitude = res.result.location.lng; /* 经度 */
          const latitude = res.result.location.lat; /* 纬度 */
          // 获取当前相机高度
          const cameraHeight = that.viewer.camera.positionCartographic.height;
          // 设置相机位置
          that.viewer.camera.setView({
            destination: Cesium.Cartesian3.fromDegrees(longitude, latitude, cameraHeight),
            orientation: {
              heading: Cesium.Math.toRadians(0.0), // 水平方向的角度
              roll: Cesium.Math.toRadians(0.0), // 侧倾角度
            },
          });

          // that.latitude = true;
          // 要添加的 PNG 图像 URL 和经纬度范围
          // const imageUrl = dotIcon;
          // 创建一个标记
          that.viewer.entities.add({
            position: Cesium.Cartesian3.fromDegrees(longitude, latitude), // 标记的位置经纬度
            point: {
              show: true,
              pixelSize: 10, // 点像素大小
              color: new Cesium.Color(0.08, 0.44, 0.81, 1), // 点颜色，不能用rgb等css方法，需要用Cesium.Color
              outlineColor: Cesium.Color.WHITE,
              outlineWidth: 2,
              scale: 1.0, // 图片缩放比例
              disableDepthTestDistance: Number.POSITIVE_INFINITY,
              verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 设置图片垂直方向的起点位置
            },
          });
        }
      });
    },
    locate() {
      this.tencentMapLocation(locateIcon);
    },
    // 放大方法
    big() {
      this.viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(
          this.get_camera_height().centerLon,
          this.get_camera_height().centerLat,
          this.get_camera_height().height / 2,
        ),
        duration: 1.0,
      });
    },
    // 缩小方法
    samll() {
      // 获取相机的高度
      const cameraHeight = this.viewer.camera.positionCartographic.height;

      const A = 40487.57;
      const B = 0.00007096758;
      const C = 91610.74;
      const D = -40467.74;

      const zoomLevel = Math.round(D + (A - D) / (1 + (cameraHeight / C) ** B));

      if (zoomLevel <= 2) return;
      clearTimeout(this.timeOut);
      const that = this;
      this.timeOut = setTimeout(() => {
        that.viewer.camera.flyTo({
          destination: Cesium.Cartesian3.fromDegrees(
            that.get_camera_height().centerLon,
            that.get_camera_height().centerLat,
            that.get_camera_height().height * 2,
          ),
          duration: 1.0,
        });
      }, 50);
    },

    async generateWindLayer(image) {
      if (!this.windMeta) {
        const factorTempKey = this.factor === 'w100m ' ? 'w100m' : 'wgrd10m';
        const initWindData = await getDataByFactor(factorTempKey, 'c10km');
        this.setWindData(initWindData);
      }
      if (!this.windMeta) {
        Message({
          type: 'error',
          message: '动态风的数据正在解析中，请稍候再试',
        });
        this.setWindy('none');
        return;
      }

      const surfaceData = this.windMeta || this.current;
      const region = this.region;
      const width = surfaceData.width || 1024;
      const height = surfaceData.height || 512;
      let solutionX;
      let solutionY;

      if (this.t3) {
        solutionX = (region?.lonMax - region?.lonMin) / width;
        solutionY = (region?.latMax - region?.latMin) / height;
      } else {
        solutionX = 360 / width;
        solutionY = 180 / height;
      }
      const data = {
        width,
        height,
        umin: surfaceData.umin,
        umax: surfaceData.umax,
        vmin: surfaceData.vmin,
        vmax: surfaceData.vmax,
        lonmax: region?.lonMax ?? 180,
        lonmin: region?.lonMin ?? -180,
        latmin: region?.latMax ?? 90,
        latmax: region?.latMin ?? -90,
        origionxv: 0,
        origionyu: 0,
        origionxu: 0,
        origionyv: 0,
        levelu: 0,
        levelv: 1,
        solutionX,
        solutionY,
        image,
      };

      const windOptions = {
        colorScale: [
          'rgba(255, 255, 255, 0.2)',
          'rgba(255, 255, 255, 0.3)',
          'rgba(255, 255, 255, 0.4)',
          'rgba(255, 255, 255, 0.5)',
          'rgba(255, 255, 255, 0.6)',
          'rgba(255, 255, 255, 0.7)',
        ],
      };
      const windData = new ParseWind(data, !this.t3).getData();

      this.windy_obj = new VelocityLayer(
        Object.assign(windOptions, {
          lineWidth: 2,
          data: windData,
          velocityScale: 0.01,
          maxVelocity: 10,
          minVelocity: 1,
          particleMultiplier: 1 / 400,
          frameRate: 20,
          paneName: 'windLayerPane',
          canvas: document.getElementById('windy_img'),
          viewer: this.viewer,
        }),
      );
    },

    handleSlpData(data = []) {
      const slpData = {
        lineData: [],
        hlData: [],
      };
      data.forEach((item) => {
        const temp = [];
        const value = item.properties.value;
        const arrLan = item.geometry.coordinates;
        const type = item.geometry.type;
        if (type === 'LineString') {
          arrLan.forEach((l) => {
            temp.push([l[1], l[0], value]);
          });
          slpData.lineData.push(temp);
        } else {
          slpData.hlData.push([arrLan[0][1], arrLan[0][0], item.properties.HL, value]);
        }
      });
      return slpData;
    },

    upDatePlone(type) {
      if (type === 'delete') {
        this.polyLabels.forEach((item) => {
          item.ployOverlay.label.show = false;
        });
        return;
      }
      this.polyLabels.forEach((item) => {
        item.ployOverlay.label.show = this.labelIsShow(item.local[0], item.local[1], '1');
      });
    },

    upDateHl(type) {
      if (type === 'delete') {
        this.hlLabels.forEach((item) => {
          item.hloyOverlay.label.show = false;
        });
        return;
      }
      this.hlLabels.forEach((item) => {
        item.hloyOverlay.label.show = this.labelIsShow(item.local[0], item.local[1], '1');
      });
    },

    renderIsobar() {
      // 为保证图层准确匹配，slp图层名称直接从this.layerName中替换而来
      if (this.isobar === 'normal' || localStorage.getItem('isoline-slp') === 'normal') {
        if (this.slpUrl) {
          getJsonData(this.slpUrl)
            .then((res) => {
              const { lineData: data, hlData } = this.handleSlpData(res.data.features);
              data.forEach((item) => {
                // 将经纬度坐标转换为Cesium的笛卡尔坐标系
                const positions = item.map((coord) => Cesium.Cartesian3.fromDegrees(coord[1], coord[0], coord[2]));

                // 创建曲线实体
                const bezierCurveEntity = this.viewer.entities.add({
                  polyline: {
                    positions,
                    width: 0.5,
                    material: new Cesium.ColorMaterialProperty(Cesium.Color.BLACK.withAlpha(1)),
                    shadows: Cesium.ShadowMode.DISABLED, // 禁用阴影
                    outlineWidth: 0, // 设置为0，即不显示端点
                  },
                });

                this.polyLines.push({ lines: bezierCurveEntity });
                const isShow = this.labelIsShow(item[item.length - 1][1], item[item.length - 1][0], '1')
                  && this.zoomLevel > 3;
                const str = item[item.length - 1][2].toString();
                // 添加描述
                const labelEntity = this.viewer.entities.add({
                  position: Cesium.Cartesian3.fromDegrees(item[1][1], item[1][0]),
                  label: {
                    text: str,
                    font: '14px sans-serif',
                    showBackground: true,
                    horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                    rotation: Cesium.Math.toRadians(-45), // 设置标签的旋转角度为45度
                    fillColor: Cesium.Color.fromCssColorString('#333'), // 字体颜色为#333
                    backgroundColor: Cesium.Color.fromCssColorString('#fff'), // 背景色为#fff
                    disableDepthTestDistance: Number.POSITIVE_INFINITY,
                    verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                    show: isShow,
                  },
                });
                this.polyLabels.push({ ployOverlay: labelEntity, local: [item[1][1], item[1][0]] });
              });

              hlData.forEach((item) => {
                const ishl = item[2];
                const value = item[3];

                const hl = ishl === 1 ? 'H' : 'L';
                const descriptionHl = `${hl}\n${value}`;
                const isShow = this.zoomLevel > 2;
                // 添加描述
                const hlLabelEntity = this.viewer.entities.add({
                  position: Cesium.Cartesian3.fromDegrees(item[1], item[0]),
                  label: {
                    text: descriptionHl,
                    font: '14px sans-serif',
                    fillColor: Cesium.Color.fromCssColorString('#333'), // 字体颜色为#333
                    showBackground: true,
                    horizontalOrigin: Cesium.HorizontalOrigin.CENTER,
                    backgroundColor: new Cesium.Color(0, 0, 0, 0), // 背景色透明
                    disableDepthTestDistance: Number.POSITIVE_INFINITY,
                    pixelOffset: new Cesium.Cartesian2(0, 20), // 垂直向下偏移
                    verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
                    show: isShow,
                  },
                });

                !this.labelIsShow(item[1], item[0], '1') ? (hlLabelEntity.label.show = false) : '';

                this.hlLabels.push({ hloyOverlay: hlLabelEntity, local: [item[1], item[0]] });
              });
            })
            .catch(() => {
              // this.setIsobar('none');
            });
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.custom-popup-content {
  .custom-popup-text {
    width: 100%;
    display: flex;
    align-items: center;
    white-space: nowrap;
    justify-content: space-between;

    .current-value {
      font-size: 14px;
    }

    .more-factor-data {
      width: 30px;
      height: 30px;
      text-align: center;
      line-height: 30px;
      border-radius: 50%;
      background-color: $theme-color;
      color: #fff;
      font-size: 16px;
      font-weight: 700;
    }
  }
}

.earth-wrap {
  width: 100%;
  height: 100%;
  position: relative;

  .windy {
    height: 100%;
    width: 100%;
    z-index: 999;
    top: 0;
    pointer-events: none;
  }

  .zoom {
    position: fixed;
    right: 22px;
    top: 69px;

    .control-single {
      width: 45px;
      height: 45px;
      background: rgba(66, 66, 66, 0.55);
      backdrop-filter: blur(1px);
      margin-bottom: 1px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.25);
      font-weight: 700;

      &:first-child {
        border-radius: 6px 6px 0 0;
      }

      &:last-child {
        border-radius: 0 0 6px 6px;
        border-bottom: none;
      }
    }
  }
}

#cesiumContainer {
  width: 100%;
  height: 100%;
}
::v-deep {
  .cesium-popup-panel {
    font-size: 12px;
    opacity: 1;
    width: 168px;
    height: 63px;
    position: absolute;
    z-index: 999;
    color: #333;
    background: #fff;
    border: 1px solid #4674d6;
    border-radius: 5px;
  }
  .cesium-popup-tip-panel {
    width: 40px;
    height: 20px;
    position: absolute;
    left: 50%;
    bottom: -20px;
    margin-left: -20px;
    overflow: hidden;
    pointer-events: none;
    opacity: 0.8;
  }

  .cesium-popup-tip-bottom {
    width: 17px;
    background: rgba(23, 50, 108, 0.8);
    border-bottom: 1px solid #4674d6;
    height: 17px;
    padding: 1px;
    margin: -10px auto 0;
    transform: rotate(45deg);
  }

  .cesium-popup-header-panel {
    display: none;
    align-items: center;
    font-size: 14px;
    padding: 5px 15px;
    background: rgba(23, 50, 108, 0.8);
    border-bottom: 1px solid #4674d6;
  }

  .cesium-poput-header-title {
    font-size: 16px;
    font-weight: 400;
    color: #ffffff;
  }

  .cesium-popup-content-panel {
    padding: 5px 10px;
  }

  .cesium-popup-close-btn {
    float: right;
    position: relative;
    right: -23px;
    top: -11px;
    border-radius: 50%;
    background: rgba(66, 66, 66, 0.55);
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .cesium-popup-close-btn,
  .cesium-popup-close-btn:focus {
    cursor: pointer;
  }

  .cesium-popup-close-btn > svg:hover {
    color: #00fcf9 !important;
  }

  .cesium-popup-close-btn > svg {
    user-select: auto;
    color: #4674d6;
    fill: #ffffff;
    cursor: pointer;
    width: 15px;
  }
  .current-value {
    font-size: 15px;
    font-weight: 500
  }
  .current-factor {
    font-size: 12px;
  }
}
</style>
