<template>
  <van-action-sheet v-model:show="popupShow" title="反馈" class="feed-popup">
    <div>
      <van-form @submit="onSubmit">
        <van-field
          v-model="username"
          name="姓名"
          label="姓名"
          placeholder="请输入姓名"
          :rules="[{ required: false, message: '姓名' }]"
        />
        <van-field
          v-model="phone"
          name="手机"
          label="手机"
          placeholder="请输入手机"
          :rules="[{ required: false, validator: phoneValidator, message: '手机号格式错误' }]"
        />
        <van-field
          v-model="email"
          name="邮箱"
          label="邮箱"
          placeholder="请输入邮箱"
          :rules="[{ required: false, message: '请输入正确邮箱' }]"
        />
        <van-field
          v-model="content"
          name="内容"
          label="内容"
          required
          clearable
          placeholder="请输入内容"
          maxlength="500"
          :rules="[{ required: true, message: '' }]"
        />
        <div style="margin: 16px;">
          <van-button
            round
            block
            type="info"
            native-type="submit"
          >
            提交
          </van-button>
        </div>
      </van-form>
    </div>
  </van-action-sheet>
</template>
<script>
import { Dialog, Toast } from 'vant';
import { mapState, mapMutations } from 'vuex';
import { consult } from '@/api/user.js';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      username: '',
      phone: '',
      email: '',
      content: '',
    };
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapState('map', ['t2Mode']),
    ...mapState(['t1Mode']),
    popupShow: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit('update:show', val);
      },
    },
  },
  created() {
    console.log('&&&&');
  },
  mounted() {

  },
  methods: {
    phoneValidator(val) {
      if (val) {
        return /^1[3456789]\d{9}$/.test(val);
      }
    },
    contentValidator(val) {
      if (val) {
        return /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/.test(val);
      }
    },
    onSubmit() {
      const param = {
        name: this.userName,
        mobile: this.phone,
        email: this.email,
        content: this.content,
      };
      if (!this.phone && !this.email) {
        Dialog.confirm({
          title: '提示',
          message: '如果手机号和邮箱不提供，我们将无法及时反馈给您，是否继续?',
        }).then(() => {
          this.submitInfo(param);
        }).catch(() => {
          // on cancel
        });
      } else {
        this.submitInfo(param);
      }
    },

    submitInfo(param) {
      this.popupShow = false;
      Toast.loading({
        message: '提交中...',
        forbidClick: true,
        loadingType: 'spinner',
      });
      consult(param)
        .then((resp) => {
          if (resp.code == 200) {
            Toast.clear();
            Toast.success('提交成功');
          }
        })
        .catch((error) => {
          Toast.clear();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.feed-popup {
  background: $bg-popup;
  color: #FFFFFF;
  height: 50%;
  z-index: 99999;
}

.factor-div {
  border-radius: 8px;
  margin: 10px;
  background-color: rgba(66, 66, 66, 0.75);

  .grid-div {
    padding: 15px 5px;
  }

  .grid-item-div {
    ::v-deep {
      .van-grid-item__content {
        border-radius: 10px;
      }
    }

  }

  .text {
    font-size: 11px;
    padding-top: 10px;
  }

  .active {
    ::v-deep {
      .van-grid-item__content {
        background-color: rgba(87, 127, 255, 1);
      }
    }
  }
}

::v-deep {
  .van-form {
    background-color: $bg-popup;
    padding: 10px 4px;
    font-size: 16px;

    .van-field__label {
      color: #FFFFFF;
    }

    .van-field__control {
      color: #FFFFFF;
    }

    .van-cell {
      padding: 16px;
      margin: 10px 0;
      background-color: $bg-popup;
    }

    .van-button--normal{
      font-size: 16px;
    }
  }

  .van-grid-item__content {
    background-color: $bg-popup;
    padding: 10px 4px;
  }
  .van-grid-item {

  }
}
</style>
