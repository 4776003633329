<template>
  <div class="home">
    <v-map ref="map" @mapMouseMove="mapMouseMove" />
    <v-loading :loading="loading" />
    <v-title title="中科天机" border @change="$router.push('/')">
      <v-search v-if="!isMobile" slot="search" @locate="setLocation" />
    </v-title>

    <v-map-tile model-type="t2" />
    <v-legend :factor="factor" :mobile="isMobile" />
    <v-control show @setting="showSetting = !showSetting" />
    <v-player v-show="isShowTimeLine && !isMobile" model-type="t1" />
    <v-elevation v-if="!isMobile && elevationList && elevationList.length" />
    <template v-if="!isMobile">
      <v-metric-select :factor="factor" :metrics="metricT2" @change="factorChange" />
    </template>
    <v-bottom-more :metrics="metricT2" @controlTimeLine="controlTimeLine" />
    <!-- <template v-else>
      <v-mobile-bottom-more :metrics="metricT2" />
      <v-mobile-operate-menu
        :factor="factor"
        :windy="windy"
        :metrics="metricT2"
        :init-base-time="currentBaseTime"
        :base-time="baseTime"
        @handleBaseTimeChange="(val) => setCurrentBaseTime(val)"
        @change="factorChange"
        @setWindy="setWindy"
      />
    </template> -->
    <v-info v-if="!isMobile" keywords="tianji2" />
    <v-mode v-if="!isMobile" />
    <v-layer-select v-if="!isMobile" model-type="t2" />
    <mobile-right-btn
      v-if="isMobile"
      model-type="t2"
      :factor="factor"
      :metrics="metricT2"
      @change="factorChange"
      @locate="setLocation"
    />
    <div v-if="!isMobile" class="lnglattext">
      <span>{{ lnglatText }}</span>
    </div>
    <time-line v-if="isMobile && factor !== 'precp'" />
    <!--    <precp-time-line v-if="isMobile && factor==='precp'"></precp-time-line>-->
    <popup v-if="isMobile" />
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import { getDataByFactor, getPrecipData } from '@/api/weather_hd';
import { convertToISO8601WithOffset } from '@/config/Utils';
import { factorMap, mapForCn, allMetrics, dust_factorMap } from '@/config/MapConfigT4';
import Bus from '@/bus/index';
import VLegend from '@/components/Map/Legend.vue';
import VControl from '@/components/Map/Control.vue';
import VTitle from '@/components/Map/Title.vue';
import VSearch from '@/components/Map/Search.vue';
import VMetricSelect from '@/components/Map/MetricSelect.vue';
import VMobileOperateMenu from '@/components/Map/MobileOperateMenu.vue';
import VBottomMore from '@/components/Map/FactorDataMore.vue';
import VMobileBottomMore from '@/components/Map/MobileFactorDataMore.vue';
import VElevation from '@/components/Map/Elevation.vue';
import VInfo from '@/components/Map/Info.vue';
import VLayerSelect from '@/components/Map/LayerSelect.vue';
import VMapTile from '@/components/Map/MapTitle.vue';
import { TIANJI2_DA, TIANJI2_MERGE, TIANJI2_ND } from '@/config';
import VMode from './components/Mode.vue';
import VPlayer from './components/PlayerNew.vue';
import VMap from './Map.vue';
import VLoading from '@/components/Map/Loading.vue';
import MobileRightBtn from '@/components/Map/mobile/MobileRightBtn.vue';
import TimeLine from '@/components/Map/mobile/TimeLine.vue';
import precpTimeLine from '@/components/Map/mobile/precpTimeLine.vue';
import Popup from '@/components/Map/mobile/Popup.vue';
import { TIANJI1_DA, TIANJI1_ND } from '../../config';

export default {
  name: 'Home',
  components: {
    VLayerSelect,
    VElevation,
    VMap,
    VPlayer,
    VLegend,
    VTitle,
    VMetricSelect,
    VControl,
    VMobileOperateMenu,
    VSearch,
    VBottomMore,
    VMobileBottomMore,
    VInfo,
    VMapTile,
    VMode,
    VLoading,
    MobileRightBtn,
    TimeLine,
    precpTimeLine,
    Popup,
  },
  data() {
    return {
      showSetting: false,
      allMetrics,
      isShowTimeLine: true,
      startTime: '',
      lnglatText: '',
    };
  },
  computed: {
    ...mapState('map', ['factor', 'timeType', 'windy', 'isobar', 'currentBaseTime', 't2Mode', 'loading']),
    ...mapState(['isMobile']),
    ...mapGetters('map', [
      'baseTime',
      'currentTime',
      'elevationList',
      'currentMode',
      'timeList',
      'current',
      'index',
    ]),
    metricT2() {
      const temp = this.allMetrics.filter((item) => !item.private || item.private === 't2');
      if (this.t2Mode !== TIANJI2_MERGE) {
        return temp.filter((item) => item.type !== 'merge');
      }
      if (this.t2Mode === TIANJI2_MERGE) {
        return temp.filter((item) => item.type !== 'default');
      }
      return temp;
    },
    routeName() {
      console.log('this.$route.name', this.$route.name);
      return this.$route.name;
    },
    // 当前要素对应的key
    factorKey() {
      return factorMap[this.factor];
    },
    // 判断当前时间是否在预报的数据列表里面
    hasBaseTime() {
      return this.baseTime.includes(this.currentBaseTime);
    },
  },
  watch: {
    t2Mode() {
      console.log('9999999("tmode")===》', sessionStorage.getItem('tmode'));
      console.log('first');
      this.clearLayer();
      this.upPageTime(this.startTime, Date.now());
      this.init();
    },
  },
  created() {
    console.log('00000("tmode")===》', sessionStorage.getItem('tmode'));
    this.init();
    this.startTime = Date.now();
  },
  methods: {
    ...mapMutations('map', [
      'setFactorData',
      'setCurrentBaseTime',
      'setStatus',
      'setFactor',
      'setWindData',
      'setIndex',
      'setWindy',
      'setIsobar',
      'setLoading',
    ]),
    /**
     * @description 根据要素获取预测数据
     * @returns {Promise}
     * @author yujie
     */
    async init() {
      console.log('11111("tmode")===》', sessionStorage.getItem('tmode'));
      this.setLoading(true);
      // this.$store.commit("map/setTimeType", {
      //   type: 1,
      //   timeLength: 24,
      // });

      if (['tmp2m', 't2mz'].includes(this.factor)) {
        this.setFactor(this.t2Mode === TIANJI2_MERGE ? 'tmp2m' : 't2mz');
      }
      console.log(this.currentMode, 'currentMode');
      let mode = this.currentMode ? this.currentMode : (this.t2Mode === TIANJI1_DA || this.t2Mode === TIANJI1_ND) ? 't1' : 't2';
      if (dust_factorMap[this.factor]) {
        if (this.t2Mode === TIANJI1_DA || this.t2Mode === TIANJI1_ND || this.t2Mode === TIANJI2_ND || this.t2Mode === TIANJI2_DA) {
          mode = 't1';
        } else {
          mode = this.currentMode;
        }
      }
      // let data;
      // if (this.factor === 'precp'){
      //   const precpData = await getPrecipData()
      //   const result =  precpData['forecast'].map(item => ({
      //     ...item,
      //     forecastTime: convertToISO8601WithOffset(item.forecastTimeString),
      //   }));
      //
      //   precpData['baseTime'] = convertToISO8601WithOffset(precpData['baseTimeString'])
      //   precpData['mode'] = 'cy'
      //   precpData['production'] = 'radar'
      //   precpData['forecast'] = result
      //   data = [precpData]
      //   this.$store.commit("map/setTimeType", {
      //     type: 1,
      //     timeLength: 24,
      //   });
      // }else {
      //   data = (await getDataByFactor(this.factor, mode)).filter((item) => {
      //     this.$store.commit("map/setTimeType", {
      //       type: 1,
      //       timeLength: 24,
      //     });
      //     if (this.t2Mode === TIANJI2_ND || this.t2Mode === TIANJI1_ND) {
      //       return item.mode === "late";
      //     }
      //     if (this.t2Mode === TIANJI2_DA || this.t2Mode === TIANJI1_DA) {
      //       return item.mode === "early";
      //     }
      //     return item;
      //   });
      // }

      const data = (await getDataByFactor(this.factor, mode)).filter((item) => {
        this.$store.commit('map/setTimeType', {
          type: 1,
          timeLength: 24,
        });
        if (this.t2Mode === TIANJI2_ND || this.t2Mode === TIANJI1_ND) {
          return item.mode === 'late';
        }
        if (this.t2Mode === TIANJI2_DA || this.t2Mode === TIANJI1_DA) {
          return item.mode === 'early';
        }
        return item;
      });

      if (!data || !data.length) {
        this.setLoading(false);
        this.$message('当前要素无预测数据');
        return;
      }
      this.setFactorData(data);
      if (this.factor === 'w100m' || this.factor === 'wgrd10m') {
        this.setWindData(data);
      }
      if (!this.hasBaseTime) {
        // 切换天机1 天机2，已经有起报时间了, 更新起报时间
        const flag = this.initTimeLine();
        if (flag) return;

        this.setCurrentBaseTime(data[0].baseTimeModel);
        const now = this.getNowHasBaseTime();
        const flagIndex = this.timeList.findIndex(
          (item) => Date.parse(item.forecastTime) === Date.parse(now),
        );
        flagIndex ? this.setIndex(flagIndex) : this.setIndex(0);
      }
      if (this.currentBaseTime) {
        const times = this.currentBaseTime.split(' ');
        times[1] = data[0].baseTimeModel.split(' ')[1];
        this.setCurrentBaseTime(times.join(' '));
      }
      console.log('22222("tmode")===》', sessionStorage.getItem('tmode'));

      console.log(this.t2Mode, 154);
      if (!sessionStorage.getItem('current-mode')) {
        sessionStorage.setItem('current-mode', this.t2Mode);
      }
    },
    upPageTime(time1, time2) {
      const time = Math.ceil((time2 - time1) / (1000 * 60));
      this.$sensors.track('tj_pageTime', {
        tj_stayTime: time,
        page_type: '天机2',
        space_type: '二维',
        info: mapForCn[this.factor],
        mode: this.t2Mode,
      });
      this.startTime = time2;
      // 计算相差的分钟数
    },
    initTimeLine() {
      if (!sessionStorage.getItem('nowTime') || !sessionStorage.getItem('baseTime')) return false;

      const baseIndex = this.baseTime.findIndex((item) => {
        item = item.split(' ')[0];
        return Date.parse(item) === Date.parse(sessionStorage.getItem('baseTime'));
      });

      if (baseIndex !== -1) {
        this.setCurrentBaseTime(this.baseTime[baseIndex]);
        const flagIndex = this.timeList.findIndex(
          (item) => Date.parse(item.forecastTime) === Date.parse(sessionStorage.getItem('nowTime')),
        );
        if (flagIndex !== -1) {
          this.setIndex(flagIndex);
          return true;
        }
        return false;
      }
      return false;
    },
    setLocation(position) {
      Bus.$emit('setLocation', position);
    },
    getNowHasBaseTime() {
      const base = this.timeList[0].forecastTime;
      const date = base.split('T')[0].split('-');
      const time = base.split('T')[1].split(':');

      const now = new Date();

      date[1] = `0${now.getMonth() + 1}`.slice(-2);
      date[2] = `0${now.getDate()}`.slice(-2);

      time[0] = `0${Number(now.getHours()) + 1}`.slice(-2);

      return `${date.join('-')}T${time.join(':')}`;
    },
    /**
     * @description 要素改变事件
     * @returns {void}
     * @author yujie
     * @param metric
     */
    factorChange(metric) {
      this.clearLayer();
      this.upPageTime(this.startTime, Date.now());
      // Bus.$emit("autoSetMapLayers", metric.id);
      this.setStatus('pause');
      console.log('megre==>', metric.id);
      this.setFactor(metric.id);
      localStorage.setItem('factor', metric.id);
      this.init();
    },
    controlTimeLine(flag) {
      this.isShowTimeLine = flag;
    },
    clearLayer() {
      Bus.$emit('removePopup');
      Bus.$emit('hiddenBottom');
      Bus.$emit('clearLayer');
    },
    mapMouseMove(e) {
      this.lnglatText = e;
    },
  },
  beforeDestroy() {
    this.upPageTime(this.startTime, Date.now());
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  width: 100%;
  position: relative;
}
.lnglattext {
  position: fixed;
  z-index: 999;
  left: 80px;
  bottom: 50px;
  color: lightgrey;
  font-size: 11px;
  //font-weight: 500;
  //text-shadow: 0 0 1px rgba(0, 0, 0, 0.5), 1px 0 1px rgba(0, 0, 0, 0.5),
  //  -1px 0 1px rgba(0, 0, 0, 0.5), 0 1px 1px rgba(0, 0, 0, 0.5), 0 -1px 1px rgba(0, 0, 0, 0.5);
}
</style>
