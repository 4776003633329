<template>
  <div>
    <van-popup
      v-model="popupShow"
      class="search"
      position="top"
      :style="{ height: '54px' }"
      :z-index="99999999"
    >
      <form action="/">
        <van-search
          v-model="input"
          placeholder="搜索位置"
          :clearable="true"
          show-action
          shape="round"
          background="rgb(85, 85, 85)"
          @search="search"
          @clear="cleanIcon"
          @blur="blurChange"
        >
          <template #action>
            <div style="color: #fff;" class="search-bnt" @click="search">搜索</div>
          </template>
        </van-search>
      </form>
    </van-popup>
    <div v-show="showResult" class="result-wrap">
      <div class="arrow" />
      <ul
        v-if="resultData && resultData.length"
        class="result"
      >
        <li
          v-for="item in resultData"
          :key="item.id"
          @click="setLocate(item)"
        >
          <h3>
            <i
              class="el-icon-location-outline icon-locate"
              style="margin-right: 4px;"
            />{{ item.keyWord }}
          </h3>
          <p>经度：{{ item.lon ?? (item.lon).toFixed(2) }} / 纬度：{{ item.lat ?? (item.lat).toFixed(2) }}</p>
        </li>
      </ul>
      <ul
        v-else
        class="result"
      >
        <li style="background-color: rgba(66, 66, 66, 0.75)">
          <p style="line-height: 30px;">
            暂无数据
          </p>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { getLocation } from '@/api';
import { lonLatReg } from '@/utils/reg';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    modelType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      input: '',
      isFocus: false,
      loading: false,
      showResult: false,
      showNoData: false,
      resultData: [],
    };
  },
  computed: {
    ...mapState(['isMobile']),
    popupShow: {
      get() {
        return this.show;
      },
      set(val) {
        this.showResult = val;
        this.$emit('update:show', val);
      },
    },
  },
  mounted() {

  },
  methods: {
    ...mapMutations('map', [
      'setMode',
    ]),
    ...mapMutations([
      'setHistory',
      'setFactor',
    ]),
    ...mapMutations(['setFactor']),
    ...mapMutations('map', ['setMode', 'setLatlng']),
    focusHandle() {
      this.isFocus = true;
      this.showResult = !!(this.resultData && this.resultData.length);
    },
    setLocate(item) {
      const { lat, lon } = item;
      this.setLatlng({
        lat,
        lng: lon,
      });
      this.$emit('locate', item);
      this.showResult = false;
      this.popupShow = false;
    },
    search() {
      this.input = this.input.replace(/\s+/g, '');
      if (!this.input) {
        if (this.modelType === 'typhoon') {
          this.$emit('cleanIcon');
        }
        this.resultData = [];
        this.showResult = false;
        return;
      }
      if (lonLatReg.test(this.input)) {
        const [lon, lat] = this.input.replace(/,|，/g, ',').replace(/\s+/g, '').split(',');
        this.resultData = [
          {
            id: this.input,
            lat: Number(lat),
            lon: Number(lon),
            keyWord: this.input,
          },
        ];
        this.showResult = true;
        return;
      }
      this.loading = true;
      getLocation(this.input)
        .then((res) => {
          const { location } = res;
          const arr = [];
          if (location) {
            if (this.t3) {
              const { lat, lon } = location;
              // lon 经度的值比较
              // lat 纬度进行比较
              const {
                latMax, latMin, lonMax, lonMin,
              } = this.region;
              if (lat > latMin && lat < latMax && lon > lonMin && lon < lonMax) {
                arr.push(location);
              }
            } else {
              arr.push(location);
            }
          }
          this.resultData = arr;
          this.showResult = true;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => { this.loading = false; });
    },
    cleanIcon() {
      if (this.modelType === 'typhoon') {
        this.$emit('cleanIcon');
      }
      this.showResult = false;
    },
    blurChange() {
      if (this.modelType === 'typhoon') {
        this.input = this.input.replace(/\s+/g, '');
        if (!this.input) {
          this.$emit('cleanIcon');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  background: rgb(85, 85, 85);

  ::v-deep {
    .van-search__action:active {
      background-color: rgba(66, 66, 66, 0.75);
    }
  }
}
.result-wrap {
  width: 100%;
  position: absolute;
  top: 150px;
  z-index: 99999999999;

  .arrow {
    position: absolute;
    width: 0;
    height: 0;
    border-color: transparent;
    border-style: solid;
    border-width: 8px;
    color: rgba(66, 66, 66, 0.75);
    left: 20%;
    top: -85px;
    border-top-width: 0;
    border-bottom-color: currentColor;
    -webkit-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%);
  }

  .result {
    width: 95%;
    position: absolute;
    top: -85px;
    padding: 0;
    margin: 0 auto;
    left: 0;
    right: 0;
    background-color: rgba(66, 66, 66, 0.75);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    border-radius: 6px;

    &:hover {
      background-color: rgba($text-operate, 0.92);
    }

    li {
      list-style: none;
      border-bottom: 1px solid #ccc;
      padding: 5px 20px 5px 30px;
      color: #FFFFFF;

      .icon-locate {
        color: #FFFFFF;
      }

      &:last-child {
        border: none;
      }

      &.process {
        line-height: 40px;
        font-size: 14px;
      }

      h3 {
        font-size: 14px;
        color: #FFFFFF;
        line-height: 24px;
        margin: 0;
      }

      p {
        margin: 0;
        font-size: 12px;
        color: #FFFFFF;
        line-height: 16px;
      }
    }
  }
}

::v-deep {
  .van-search__content {
    background-color: rgba(66, 66, 66, 0.75);
  }

  .van-field__left-icon {
    color: #FFFFFF !important;
  }

  .van-field__control {
    color: #FFFFFF;
  }

  .van-field__left-icon .van-icon, .van-field__right-icon .van-icon {
    font-size: 18px;
  }
}
</style>
