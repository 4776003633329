/**
 * @description 天地图key
 * @type {String}
 */
// 1d109683f4d84198e37a38c442d68311 超图
// f00f9a8baec73f6c9550984464574522 星图
// a43290983c30ca0a258d8d3186f94470 刘云鹏
// 5aef953c1064698d5af6264c362aa8ac 于杰
export const TIANDITU_KEY = '1d109683f4d84198e37a38c442d68311';

// 腾讯地图获取位置的key
export const TENCENT_KEY = 'UMKBZ-SSVK2-TSMUO-CIJ3D-FE4F6-2KFSI';
export const CESIUM_KEY = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI3ZTc1MGViNS03ZDFkLTRlZjAtOTM0YS1hNWMxMThhY2NmNzMiLCJpZCI6MTI1OTM2LCJpYXQiOjE2NzcxMjI3Njh9.DheymWLCLLAAGVP3qycoQq-KAOWX0Js4T0f4A4c4Nx8';

export const TIANJI2_DA = '天机2/DA';
export const TIANJI2_ND = '天机2/ND';
export const TIANJI2_MERGE = '聚合';

export const TIANJI1_DA = '天机1/DA';
export const TIANJI1_ND = '天机1/ND';
export const TIANJI1_MERGE = '聚合';

export const CY = '短临降水数据来源:';
