var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend_loop",class:{
    mobile: _vm.mobile,
    left: _vm.viewHeight >= 800,
    right: _vm.viewHeight < 800,
  }},_vm._l((_vm.list),function(legend,i){return _c('div',{key:i,staticClass:"legend"},[(!_vm.isMobile)?_c('div',{staticClass:"color_wrap"},[_c('div',{staticClass:"left",class:{
          rain_left: legend.unit.indexOf('hr') > -1,
        },style:({
          background: _vm.getColor(legend),
          height: `${legend.colors.length * 20}px`,
        })}),_vm._v(" "),_c('div',{staticClass:"right"},_vm._l((legend.colors),function(item,index){return _c('div',{key:index,staticClass:"legend_single color",class:{ 'text-style': _vm.isShowStyle }},[_vm._v("\n          "+_vm._s(_vm.setFormatValue(legend.values[index], index, legend.colors.length - 1, _vm.mobile, legend.unit))+"\n        ")])}),0)]):_c('div',{staticClass:"color_wrap mobile",style:({
        background: _vm.getColor(legend),
      })},_vm._l((legend.colors),function(item,index){return _c('div',{key:index,staticClass:"legend_single color mobile"},[_vm._v("\n        "+_vm._s(_vm.setFormatValue(legend.values[index], index, legend.colors.length - 1, _vm.mobile, legend.unit))+"\n      ")])}),0)])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }