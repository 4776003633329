<template>
  <div
    class="player_satellite"
    :class="{
      vertical_player: isVertical,
    }"
  >
    <div class="drag_wrap">
      <v-base-time-select ref="timeSelect" />

      <div class="player_wrap">
        <div
          v-if="!isVertical"
          class="picker_wrap"
        >
          <div class="label">
            日期
          </div>
          <el-date-picker
            v-show="!isVertical"
            v-model="date"
            type="date"
            placeholder="选择日期"
            :picker-options="{ disabledDate }"
            :clearable="false"
          />
        </div>

        <template
          v-else
        >
          <v-icon-word
            v-if="status === 'pause'"
            icon="play"
            desc="播放"
            spec
            @click.native="play"
          />
          <v-icon-word
            v-else
            icon="pause"
            desc="暂停"
            spec
            @click.native="pause"
          />
        </template>

        <v-icon-word
          icon="p-first"
          desc="第一张"
          spec
          c-icon
          @click.native="goBorder(0);"
        />

        <v-icon-word
          icon="p-prev"
          desc="上一张"
          spec
          c-icon
          @click.native="prev"
        />

        <template v-if="!isVertical">
          <v-icon-word
            v-if="status === 'pause'"
            icon="play"
            desc="播放"
            spec
            center
            @click.native="play"
          />
          <v-icon-word
            v-else
            icon="pause"
            desc="暂停"
            spec
            @click.native="pause"
          />
        </template>
        <div
          v-else
          class="vertical_time_wrap"
        >
          <el-select
            v-show="isVertical"
            v-model="time"
          >
            <el-option
              v-for="item in timeList"
              :key="item.forecastTime"
              :label="item.forecastTime | formatCurrentTime"
              :value="item.forecastTime"
            />
          </el-select>
        </div>

        <v-icon-word
          icon="p-next"
          desc="下一张"
          spec
          c-icon
          @click.native="next();"
        />

        <v-icon-word
          icon="p-last"
          desc="最后一张"
          spec
          c-icon
          @click.native="goBorder(-1)"
        />
      </div>
    </div>
    <v-timeline
      v-show="!isVertical"
      ref="timeline"
      color
      :index="hourIndex"
      model-type="satellite"
      @change="setTime"
    />
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import moment from 'moment';
import Bus from '@/bus/index';
import VIconWord from '@/components/Map/IconWord.vue';
import VTimeline from './TimelineNew.vue';
import VBaseTimeSelect from './BaseTImeSelect.vue';

export default {
  components: {
    VIconWord,
    VTimeline,
    VBaseTimeSelect,
  },

  filters: {
    formatCurrentTime(value) {
      return moment(value).format('YYYY-MM-DD HH:mm:ss');
    },
  },

  props: {
    isVertical: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      position: 1,
      hourIndex: 1,
      timer: null,
    };
  },

  computed: {
    ...mapState('typhoon', ['timeSet', 'currentDateTime', 'status', 'isPause', 'index',
      'timeLineData', 'timeLineLength', 'timeLineDate', 'timeLineMaxLength', 'currentDateTime',
      'timeLineClickTime', 'timeSet']),
    ...mapGetters('typhoon', ['currentTime', 'currentPoint', 'dateSetInfo', 'timeLinePointHourList']),
    time: {
      get() {
        return this.currentDateTime;
      },
      set(value) {
        this.pause();
        if (value in this.timeMap) {
          this.setLoading(true);
          this.goToFrame(this.timeMap[value]);
        } else {
          this.$message('您选择的时间超出了预测范围');
        }
      },
    },
    // dateSet: {
    //   get() {
    //     return this.dateSetInfo;
    //   }
    // },
    // 时间选择器绑定的日期
    date: {
      get() {
        return moment(this.currentDateTime).format('YYYY-MM-DD');
      },
      set(value) {
        const current_time = moment(value).format('YYYYMMDD');
        this.changeCurrentTime(current_time);
        this.$refs.timeline.init();
      },
    },
    // 根据forecastTime生成hash表，方便计算索引
    timeMap() {
      return this.timeList.reduce((target, item, index) => {
        target[item.forecastTime] = index;
        return target;
      }, {});
    },
  },
  watch: {
    currentTime: {
      handler(value) {
        const hour = moment(value).hour();
        this.position = hour * 2 + 1;
        this.hourIndex = hour;
      },
      immediate: true,
    },
  },
  created() {
    Bus.$on('next', this.next);
    Bus.$on('pause', this.pause);
    Bus.$on('playNext', this.playNext);
  },

  beforeDestroy() {
    Bus.$off('next', this.next);
    Bus.$off('pause', this.pause);
    Bus.$off('playNext', this.playNext);
    clearInterval(this.timer);
  },

  methods: {
    ...mapMutations('typhoon', ['setCurrentDateTime', 'setStatus',
      'setIsPause', 'setIndex', 'setIsMorePlay', 'setTimeLineDate',
      'setTimeLineClickTime']),
    /**
     * @description 播放
     * @returns {void}
     * @author yujie
     */
    play() {
      if (this.status === 'pause') {
        this.$sensors.track('timePlayer', {
          space_type: this.$route.name === 'earth' ? '三维' : '二维',
          page_type: '台风',
        });
        this.setStatus('play');
        this.timer = setInterval(async () => {
          this.playToNext();
        }, 600);
      }
    },
    /**
     * @description 暂停
     * @returns {void}
     * @author yujie
     */
    pause() {
      if (this.status === 'play') {
        this.setStatus('pause');
        clearInterval(this.timer);
      }
    },
    /**
     * @description 播放上一张
     * @returns {void}
     * @author yujie
     */
    prev() {
      const cur_hour = moment(this.timeLineClickTime, 'YYYYMMDDHH').format('HH');
      const cur_index = this.timeLinePointHourList.findIndex((v) => v === cur_hour);
      if (cur_index > -1) {
        if (cur_index > 0) {
          const pre_time = this.timeLinePointHourList[cur_index - 1];
          this.setTimeLineClickTime(pre_time);
          Bus.$emit('tiggerTimeLineClick', this.currentDateTime + pre_time);
        } else {
          // 如果是第一个，则进行到上一个日期
          const preDate = moment(this.currentDateTime).add(-1, 'day').format('YYYYMMDD');
          if (this.timeSet.has(preDate)) {
            this.setCurrentDateTime(preDate);
            this.$refs.timeline.preInit();
          }
        }
      }

      // this.pause();
      // let currentPoint = this.currentPoint;
      // if (this.index === -1) {
      //   Bus.$emit('tiggerTimeLineClick', currentPoint);
      //   return;
      // }
      // if (this.index === 0 ){
      //   return;
      // }
      // const nextDate = moment(this.timeLineDate,'YYYYMMDDHH').add(-6,'hour')
      //   .format('YYYYMMDDHH');
      // this.setTimeLineDate(nextDate);
      // currentPoint = this.currentPoint;
      // Bus.$emit('tiggerTimeLineClick', currentPoint);
    },
    playNext(data) {
      this.setTimeLineDate(data.time);
      // console.log('currentPoint',this.currentPoint);
      const currentPoint = this.currentPoint;
      if (currentPoint) {
        Bus.$emit('tiggerTimeClick', currentPoint);
      }
    },
    /**
     * @description 播放下一张
     * @returns {void}
     * @author yujie
     */
    next() {
      // if (click) {
      //   this.pause();
      // }
      // 获取下一个小时
      const cur_hour = moment(this.timeLineClickTime, 'YYYYMMDDHH').format('HH');
      const cur_index = this.timeLinePointHourList.findIndex((v) => v === cur_hour);
      if (cur_index > -1) {
        if (cur_index < this.timeLinePointHourList.length - 1) {
          const next_time = this.timeLinePointHourList[cur_index + 1];
          this.setTimeLineClickTime(next_time);
          Bus.$emit('tiggerTimeLineClick', this.currentDateTime + next_time);
        } else {
          // 如果是最后一个，则进行到下一个日期
          const nextDate = moment(this.currentDateTime).add(1, 'day').format('YYYYMMDD');
          if (this.timeSet.has(nextDate)) {
            this.setCurrentDateTime(nextDate);
            this.$refs.timeline.init();
          }
        }
      }
    },
    playToNext() {
      const cur_hour = moment(this.timeLineClickTime, 'YYYYMMDDHH').format('HH');
      const cur_index = this.timeLinePointHourList.findIndex((v) => v === cur_hour);
      if (cur_index > -1) {
        if (cur_index < this.timeLinePointHourList.length - 1) {
          const next_time = this.timeLinePointHourList[cur_index + 1];
          this.setTimeLineClickTime(next_time);
          Bus.$emit('tiggerTimeLineClick', this.currentDateTime + next_time);
        } else {
          // 如果是最后一个，则进行到下一个日期
          const nextDate = moment(this.currentDateTime).add(1, 'day').format('YYYYMMDD');
          if (this.timeSet.has(nextDate)) {
            this.setCurrentDateTime(nextDate);
            this.$refs.timeline.init();
          } else {
            // 如果不存在，则说明已经到最后一个时刻
            this.pause();
          }
        }
      }
    },

    // let currentPoint = this.currentPoint;
    // if (this.index === -1) {
    //   Bus.$emit('tiggerTimeLineClick', currentPoint);
    //   return;
    // }
    // const c_index = this.index + 1;
    // if (c_index === this.timeLineMaxLength ){
    //   return;
    // }
    // const nextDate = moment(this.timeLineDate,'YYYYMMDDHH').add(6,'hour')
    //   .format('YYYYMMDDHH');
    // this.setTimeLineDate(nextDate);
    // currentPoint = this.currentPoint;
    // Bus.$emit('tiggerTimeLineClick', currentPoint);
    // },
    goBorder(index) {
      this.pause();
      const sortTimeList = [...this.timeSet].sort();
      if (index === 0) {
        // 第一张
        const firstDate = sortTimeList[0];
        this.setCurrentDateTime(firstDate);
        this.$refs.timeline.init();
      } else {
        // 最后一张
        const lastDate = sortTimeList[sortTimeList.length - 1];
        this.setCurrentDateTime(lastDate);
        this.$refs.timeline.preInit();
      }
    },
    /**
     * @description 跳转到指定帧
     * @returns {void}
     * @author yujie
     */
    goToFrame(index, type) {
      if (type) {
        this.setRequestIndex(index);
      } else {
        this.setIndex(index);
      }
      this.$nextTick(() => {
        Bus.$emit('renderSatellite');
      });
    },
    /**
     * @description 禁用时间方法，timepicke提供
     * @param {Date} date 时间参数
     * @returns {Boolean} 当前日期是否禁用
     * @author yujie
     */
    disabledDate(value) {
      const str = moment(value).format('YYYYMMDD');
      return !this.timeSet.has(str);
    },

    setTime(position) {
      this.pause();
      const time = moment(this.currentTime);
      const str = time.add(position - 1 - time.hour(), 'h').utc().format('YYYY-MM-DDTHH:mm:ss.000+00:00');

      if (str in this.timeMap) {
        this.setLoading(true);
        this.goToFrame(this.timeMap[str]);
      } else {
        this.$message('您选择的时间超出了预测范围');
      }
    },
    changeCurrentTime(time) {
      this.setCurrentDateTime(time);
    },
  },
};
</script>

<style lang="scss" scoped>
.player_satellite {
  position: absolute;
  bottom: 0;
  height: 45px;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  background: rgba(66,66,66,0.55);
  backdrop-filter: blur(1px);
  left: 0;
  display: flex;
  z-index: 1000;
  align-items: center;

  .drag_wrap {
    display: flex;
    position: relative;
  }

  .player_wrap {
    margin-left: 10px;
    display: flex;
    align-items: center;
    height: 30px;
    border-radius: 21px;
    padding: 0 5px;

    .picker_wrap {
      display: flex;
      align-items: center;

      .label {
        font-size: 12px;
        color: #fff;
        margin-right: 5px;
        cursor: move;
        width: 30px;
      }

      ::v-deep {
        .el-input__inner,
        .el-input__icon {
          color: #ffffff;
          border-radius: 4px;
          border-color: #ffffff;
        }

        .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 120px;
        }
      }
    }
  }
}

.player_satellite.vertical_player {
  height: 84px;
  bottom: 0;
  background: #fff;

  .drag_wrap {
    display: block;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    .basetime_select {
      padding-left: 25px;
      //margin-bottom: 4px;

      .label {
        margin-right: 15px;
      }
    }

    .player_wrap {
      margin: 0;

      .icon_wrap {
        margin-left: 6px;
      }
    }
  }

  .vertical_time_wrap {
    width: 164px;
  }
}
</style>
