<template>
  <div>
    <div class="title_text flex-row">
      <span class="word3">地图底图</span>
    </div>
    <div class="layer-div flex-col">
      <van-grid :border="false" :column-num="3" :gutter="10">
        <van-grid-item v-for="(item, index) in map_type" :key="'map' + index">
          <div class="flex-col justify-between align-center">
            <div
              class="flex-col"
              :class="{ change_factor_item_class: getLayerType === item.name }"
              @click="changeMapClick(item.name)"
            >
              <img
                :src="require('@/assets/images/' + item.icon + '.png')"
                alt=""
                class="img_"
              />
            </div>
            <div class="flex-col">
              <span class="text_">{{ item.text }}</span>
            </div>
          </div>
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';

export default {
  components: {
  },
  props: {
    modelType: {
      type: String,
      required: true,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      map_type: [
        {
          text: '简洁',
          name: 'default',
          icon: 'default',
        },
        {
          text: '地形',
          name: 'graph',
          icon: 'terrain',
        },
        {
          text: '影像',
          name: 'shadow',
          icon: 'shadow',
        },
      ],
    };
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapState('map', ['t2Mode', 'layerType']),
    popupShow: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit('update:show', val);
      },
    },
    getLayerType() {
      return this.layerType;
    },
  },
  created() {
  },
  mounted() {

  },
  methods: {
    ...mapMutations('map', [
      'setLayerType',
    ]),
    ...mapMutations([
      'setT1LayerType',
    ]),
    changeMapClick(name) {
      this.setT1LayerType(name);
      this.setLayerType(name);
      this.$sensors.track('CurrentUnderlay', {
        under_layer_name: name,
        space_type: this.$route.name === 'earth' ? '三维' : '二维',
        mode:
          this.$route.name === 'weather_hd' || this.$route.name === 'earth'
            ? this.t2Mode
            : this.t1Mode,
        page_type:
          this.$route.name === 'weather_hd'
            ? '天机2'
            : this.$route.name === 'earth'
              ? '三维'
              : '天机1',
      });
      this.$emit('closePop');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/common.scss";

.title_text {
  width: 28px;
  height: 14px;
  margin: 20px 0 0 12px;
  .word3 {
    width: 28px;
    height: 14px;
    color: #fff;
    font-size: 14px;
    font-family: PingFangSC-Medium;
    text-align: left;
    white-space: nowrap;
    line-height: 14px;
  }
}

.img_ {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.layer-div {
  //background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  margin: 10px;
  //border: 1px solid #c8c9cc;
  background-color: rgba(66, 66, 66, 0.75);

  .text_ {
    color: #FFFFFF;
    margin-top: 10px;
    font-size: 14px;
  }

  .change_factor_item_class {
    border: 3px solid rgba(87, 127, 255, 1);
    border-radius: 50px;
  }

  justify-content: flex-center;
  .layer7 {
    width: 28px;
    height: 14px;
    margin: 15px 0 0 16px;
    .word3 {
      width: 28px;
      height: 14px;
      color: #fff;
      font-size: 14px;
      font-family: PingFangSC-Medium;
      text-align: left;
      white-space: nowrap;
      line-height: 14px;
    }
  }
}
</style>
