<template>
  <div>
    <div class="title_text flex-row">
      <span class="word3">{{ titleDays }}天气象模拟</span>
      <van-popover
        v-model="showPopover"
        :offset="[-150, 10]"
        trigger="click"
        theme="dark"
      >
        <van-grid
          :border="false"
          :column-num="3"
          :gutter="10"
          direction="horizontal"
          style="padding: 5px 5px;width: 350px"
        >
          <van-grid-item
            v-for="(item, index) in metrics"
            :key="'factor' + index"
            :class="item.id === factorSelected ? 'active' : ''"
            @click="changeFactor(item)"
          >
            <span class="text_">{{ item.text }}</span>
          </van-grid-item>
        </van-grid>
        <template #reference>
          <div class="qb-div">
            <div class="mode-text">
              <span>{{ getFactorText(factorSelected) }}</span>
            </div>
            <van-icon name="arrow-down" color="#FFFFFF" />
          </div>
        </template>
      </van-popover>
    </div>
    <div class="mod3">
      <div class="unit">{{ unitDescText }}</div>
      <div id="weatherChart" ref="chartDom" :style="{ width: '100%', height: '194px' }" />
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import moment from 'moment';
import { getCurrentPointFactorData } from '@/api/weather_hd';
import echarts from '@/plugins/echarts';
import { productionMap } from '@/config/MapConfig';
import { dust_factorMap, modeFactorMap, unit } from '@/config/MapConfigT4';
import { TIANJI1_DA, TIANJI1_ND, TIANJI2_DA, TIANJI2_ND } from '@/config';
// import { mobileLineOption, seriesConfig } from '@/config/lineOptions';

export default {
  components: {
  },
  props: {
    productType: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      input: '',
      lineData: [],
      chartObj: null,
      showPopover: false,
      actions: [],
      metrics: [],
      factorSelected: '',
      latlon: '',
      xAxisWidth: 0,
      unitDesc: '',
      titleDays: sessionStorage.getItem('current-mode').includes('天机1') ? 15 : 10,
    };
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapState('map', ['t2Mode', 'layerType', 'factor', 'popupVal', 'popupUnit']),
    ...mapGetters('map', ['currentLayerDetails', 'isT2Mode', 'currentTime']),
    popupShow: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit('update:show', val);
      },
    },
    unitDescText() {
      if (this.factorSelected === 'vis_cats') return '';
      
      return this.unitDesc || this.popupUnit;
    },
    factorKey() {
      return this.factor;
    },
    series() {
      const seriesData = [];
      this.lineData.forEach((item) => {
        const { forecastDetails, factorCode } = item;
        forecastDetails.map((it) => {
          if (it.forecastTimeString === moment(this.currentTime).format('YYYYMMDDHH') && factorCode === this.factor) {
            if (factorCode === 'wgrd10m' || factorCode === 'w100m') it.value[2] = this.popupVal;
            else it.value[0] = this.popupVal;
          }
        });
        const data = {};
        data.name = this.getFactorText(factorCode);
        data.data = forecastDetails.map((items) => ((factorCode === 'wgrd10m' || factorCode === 'w100m')
          ? Number(items.value[2]).toFixed(2) : (factorCode === 'vis_cats' ? this.changeVisCatsValue(items.value[0]) : Number(items.value).toFixed(2))));
        data.forecastDetails = forecastDetails;
        seriesData.push(data);
        // item.forecastDetails = forecastDetails;
        // return { ...item, ...seriesConfig };
      });
      return seriesData;
    },
    xAxis() {
      return this.series[0].forecastDetails.map((item) => moment(item.forecastTimeString, 'YYYYMMDDHH').format('MM.DD HH'));
    },
    legend() {
      return this.lineData.map((item) => {
        const { factorCode } = item;
        return this.getFactorText(factorCode);
      });
    },
    getMetrics() {
      return this.metrics;
    },

    dustFactorMode() {
      return this.t2Mode === TIANJI1_DA || this.t2Mode === TIANJI1_ND || this.t2Mode === TIANJI2_DA || this.t2Mode === TIANJI2_ND;
    },

  },
  created() {
  },
  mounted() {
    this.initActions();
  },
  methods: {
    changeVisCatsValue(val) {
      if (val === '好') {
        return Number(3).toFixed(2);
      } if (val === '一般') {
        return Number(2).toFixed(2);
      } if (val === '不佳') {
        return Number(1).toFixed(2);
      }
      return Number(val).toFixed(2);
    },
    getUrlEncode(obj) {
      let str = '';
      Object.keys(obj).forEach((key) => {
        str += `${key}=${obj[key]}&`;
      });
      return str.slice(0, -1);
    },
    initActions() {
      const data = [];
      this.metrics.forEach((v) => {
        data.push({
          text: v.text,
          icon: v.id === this.factor ? 'success' : '',
          id: v.id,
        });
      });
      this.actions = data;
    },
    getProductionFactorCode(obj) {
      // 判断是否为订正要素
      const isOld = ['early', 'late'].includes(obj.mode);
      let factorCode = obj.factorCode;
      const production = (this.dustFactorMode ? undefined : productionMap[obj.factorCode]) ?? (this.setFactorProduction(factorCode, isOld));
      if (['tmp2m', 't2mz'].includes(obj.factorCode)) {
        factorCode = !this.dustFactorMode ? 'tmp2m' : 't2mz';
      }
      return { production, factorCode };
    },

    setFactorProduction(item, isOld) {
      if (isOld) {
        return (dust_factorMap[item] || this.t2Mode.includes('天机1')) ? 't1' : 't2';
      }
      return 'nextgen';
    },

    async initChart(latlon, factorCode, metrics) {
      this.unitDesc = unit[factorCode];
      this.latlon = latlon;
      this.factorSelected = factorCode;
      const { lat, lng } = latlon;
      const { production, baseTimeString, mode } = this.currentLayerDetails;
      if (metrics) {
        this.buildFactorItems(metrics, mode);
      }
      const pFData = this.getProductionFactorCode({ mode, production, factorCode });
      const params = {
        lon: lng,
        lat,
        mode,
        baseTime: baseTimeString,
        production: pFData.production,
        region: 'global',
        factorCode: pFData.factorCode,
      };
      const res = await getCurrentPointFactorData(this.getUrlEncode(params));
      if (res && res.forecast) {
        const { forecast } = res;
        this.lineData = forecast;
        this.xAxisWidth = forecast[0]?.forecastDetails?.length * 40 || 240 * 40;
        this.xAxisWidth = this.xAxisWidth < 9600 ? this.xAxisWidth : 9600;
        document.getElementById('weatherChart').style.width = `${this.xAxisWidth}px`;
        this.renderChart();
      }
    },
    buildFactorItems(metricsList, mode) {
      // 展示当前模式下的所有要素
      // const modeName = '';
      const modeName = sessionStorage.getItem('current-mode');
      // const modeFirstName = ['early', 'late'].includes(mode) ? '天机2/' : '聚合';
      // if (mode === 'early') {
      //   modeName = `${modeFirstName}DA`;
      // } else if (mode === 'late') {
      //   modeName = `${modeFirstName}ND`;
      // } else {
      //   modeName = modeFirstName;
      // }
      const mode2Factor = modeFactorMap[modeName];
      const metricData = [];
      // 根据要素来展示模式选择
      metricsList.forEach((metric) => {
        if (metric.id !== 'satellite' && metric.id !== 'typhoon') {
          if (metric.children && metric.children.length > 0) {
            metric.children.forEach((v) => {
              if (mode2Factor.includes(v.id) || dust_factorMap[v.id]) {
                metricData.push(v);
              }
            });
          } else if (mode2Factor.includes(metric.id)) {
            metricData.push(metric);
          }
        }
      });

      console.log(metricData, 240);
      this.metrics = metricData;
    },
    changeFactor(item) {
      this.initChart(this.latlon, item.id);
      this.showPopover = false;
    },
    renderChart() {
      if (!this.chartObj) {
        const chartDom = document.getElementById('weatherChart');
        this.chartObj = echarts.init(chartDom);
      }
      const option = this.buildOption(this.xAxis, this.legend, this.series);
      if (this.factorSelected === 'vis_cats') {
        option.yAxis = {
          type: 'value',
          min: 1, // 设置最小值
          max: 3, // 设置最大值
          interval: 1, // 设置刻度间隔为1，使只显示 1, 2, 3
          splitLine: { show: false },
          axisLine: {
            lineStyle: {
              color: '#fff',
            },
          },
          axisLabel: {
            formatter (value) {
              const labels = {
                1: '不佳',
                2: '一般',
                3: '好',
              };
              return labels[value] || value; // 转换数值为汉字，默认显示原值
            },
          },
        };
      } else {
        option.yAxis = {
          type: 'value',
          axisLine: {
            lineStyle: {
              color: '#fff',
            },
          },
          splitLine: { show: false },
        };
      }
      this.$nextTick(() => {
        this.chartObj.setOption(option, true);
        window.onresize = () => {
          // 自适应大小
          this.chartObj.resize();
        };
      });
    },
    buildOption(timeData, legendData, data) {
      const seriesData = data && data.length > 0 ? data[0].data : [];
      // const name = data && data.length > 0 ? data[0].name : '';
      return {
        grid: {
          left: '0.45%',
          right: '0.3%',
          top: '12%',
          bottom: '12%',
        },
        xAxis: {
          type: 'category',
          data: timeData,
          axisLabel: {
            interval: 1,
            fontSize: 10,
            color: '#fff',
          },
          boundaryGap: true,
          axisTick: {
            alignWithLabel: true, // 让刻度线与刻度标签对齐
          },
          axisLine: {
            lineStyle: {
              color: 'rgba(245, 248, 253,0.7)',
            },
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(245, 248, 253,0.2)',
            },
            show: false,
          },
        },
        tooltip: {
          trigger: 'axis',
          formatter: (item) => {
            if (this.factorSelected === 'vis_cats') {
              if (item[0].data === '3.00') return `${item[0].axisValue}<br/><b>好<b>`;
              if (item[0].data === '2.00') return `${item[0].axisValue}<br/><b>一般<b>`;
              if (item[0].data === '1.00') return `${item[0].axisValue}<br/><b>不佳<b>`;
            } else {
              return `${item[0].axisValue}<br/>
              <b>${item[0].data}${this.unitDescText}<b>`;
            }
          },
        },
        yAxis: {
          type: 'value',
          axisLine: {
            interval: 1,
            lineStyle: {
              color: '#fff',
            },
          },
          splitLine: { show: false },
        },
        series: [
          {
            data: seriesData,
            type: 'line',
            smooth: true,
            itemStyle: {
              color: '#EE6666',
            },
            lineStyle: {
              color: '#EE6666',
            },
            label: {
              show: true,
              position: 'top',
              top: 10,
              interval: 1,
              color: '#fff', // 颜色
              fontStyle: 'normal', // 风格
              fontWeight: 'normal', // 粗细
              fontFamily: 'Microsoft yahei', // 字体
              fontSize: 12, // 大小
              align: 'center', // 水平对齐
              formatter: (item) => {
                if (this.factorSelected === 'vis_cats') {
                  if (item.value === '3.00') return '好';
                  if (item.value === '2.00') return '一般';
                  if (item.value === '1.00') return '不佳';
                } else if (this.factorSelected === 'dust_conc' || this.factorSelected === 'dust_ddep' || this.factorSelected === 'dust_emis') {
                  return item.dataIndex % 2 === 0 ? item.value : '';
                } else if (sessionStorage.getItem('current-mode').includes('天机1') && this.xAxisWidth === 9600) {
                  return item.dataIndex % 2 === 0 ? item.value : '';
                } else {
                  return item.value;
                }
              },
            },
          },
        ],
      };
    },
    getFactorText(id) {
      const res = this.metrics.filter((item) => item.id === id);
      return res[0]?.text || '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/common.scss";

.active {
  ::v-deep {
    .van-grid-item__content {
      background-color: rgba(87, 127, 255, 1);
      border-radius: 20px;
      padding: 6px 4px;
    }
  }
}

.title_text {
  width: 100%;
  height: 20px;
  margin: 20px 0 0 12px;
  justify-content: space-between;
  align-items: center;

  .word3 {
    width: 28px;
    height: 14px;
    color: #fff;
    font-size: 14px;
    font-family: PingFangSC-Medium;
    text-align: left;
    white-space: nowrap;
    line-height: 14px;
  }

  .qb-div {
    font-size: 14px;
    margin-right: 25px;
    padding: 12px 15px;
    color: #FFFFFF;
    border-radius: 50px;
    display: flex;
    z-index: 999;
    background: rgba(50, 50, 50, 0.5);
    backdrop-filter: blur(1px);
    box-shadow: 0 0 0 0;
    width: auto;
    height: 20px;
    align-items: center;
    justify-content: center;

    .mode-text {
      display: flex;
      align-items: flex-end;
      margin-right: 5px;
    }
  }
}

.img_ {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}
.mod3::-webkit-scrollbar {
  display: none;
}
.mod3 {
  //background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  margin: 10px;
  //border: 1px solid #c8c9cc;
  background-color: rgba(66, 66, 66, 0.75);
  overflow-x: scroll;
  .text_ {
    color: #FFFFFF;
    margin-top: 10px;
  }

  .icon {
    width: 30px;
    height: 30px;
  }

  .change_factor_item_class {
    border: 3px solid rgba(87, 127, 255, 1);
    border-radius: 50px;
  }
  .unit {
    margin-top: 10px;
    margin-left: 15px;
    font-size: 10px;
  }

  justify-content: flex-center;
}
::v-deep {
  .van-grid-item__content {
    background-color: rgba(0, 0, 0, 0);
    padding: 10px 4px;
  }
  .van-badge--fixed {
    top: 5px;
    right: 5px;
  }
  .van-badge {
    //border: 0;
    border: 1px solid #FFFFFF !important;
  }
}

::v-deep {
  .van-grid-item__content {
    padding: 6px 4px;
  }
}
</style>
