import { decodePixel, factor_log, reverseResult, reverse } from '@/config/Utils';
import store from '@/store/index';
import { factorMap, productionMap } from '@/config/MapConfig';
import fragmentShaderData from '@/plugins/glsl/fragshader.glsl';
import overSamplingFragmentShaderData from '@/plugins/glsl/overSamplingFragshader.glsl';
import vertexShaderText from '@/plugins/glsl/vertexShader.glsl';
import overSamplingVertexShaderText from '@/plugins/glsl/overSamplingVertexShader.glsl';
import colorArray from '@/config/ColorBarArray';
import route from '@/router';

const handlerTileCanvas = document.createElement('canvas');
const factor_type2 = ['rh2m', 't2mz', 'tmp2m', 'temp'];

export class ImageryTheme {
  constructor(options) {
    this.options = options;
    this._renderer = document.createElement('canvas');
    this.tileZoom = 2;
    this.gradientObj = {};
    this.min = -1;
    this.max = 0;
    this.config = 'map';
    this.gradient = [[]];
    this.step = 0;
    this.maxIndex = 0;
    this.colors = [];
    this.ident = 0;
    this.opaque = true;
    this.neutralGrayIndex = 0;
    this._renderer.width = 256;
    this._renderer.height = 256;
    this._gl = this._renderer.getContext('webgl2', {
      premultipliedAlpha: false,
      antialias: true,
    })
      || this._renderer.getContext('experimental-webgl', {
        premultipliedAlpha: false,
        antialias: true,
      });

    this._gl.viewport(0, 0, 256, 256);
    this._loadGLProgram();
  }

  _loadGLProgram() {
    // 加载webgl的Program
    const gl = this._gl;
    this.overSamplingShaderProgram = this.createShaderProgram(gl, overSamplingVertexShaderText, overSamplingFragmentShaderData);
    this.insertShaderProgram = this.createShaderProgram(gl, vertexShaderText, fragmentShaderData);
    this.screenBuffer = this.initBuffersForScreen(gl);
    this.offScreenBuffer = this.initBuffersForFramebuffer(gl);
    this.getColor();
  }

  // 构建webGL 上下文
  createShaderProgram(gl, vertexSource, fragmentSource) {
    const vertexShader = this.loadShader(gl, gl.VERTEX_SHADER, vertexSource);
    const fragmentShader = this.loadShader(
      gl,
      gl.FRAGMENT_SHADER,
      fragmentSource,
    );
    // 创建着色器对象
    const shaderProgram = gl.createProgram();
    // 添加着色器
    gl.attachShader(shaderProgram, vertexShader);
    gl.attachShader(shaderProgram, fragmentShader);
    // 多个着色器合并链接
    gl.linkProgram(shaderProgram);
    // 创建是否成功检查
    if (!gl.getProgramParameter(shaderProgram, gl.LINK_STATUS)) {
      console.log('无法初始化着色器程序:', gl.getProgramInfoLog(shaderProgram));
      return null;
    }
    const wrapper = { program: shaderProgram };

    const numAttributes = gl.getProgramParameter(
      shaderProgram,
      gl.ACTIVE_ATTRIBUTES,
    );
    for (let i = 0; i < numAttributes; i++) {
      const attribute = gl.getActiveAttrib(shaderProgram, i);
      wrapper[attribute.name] = gl.getAttribLocation(
        shaderProgram,
        attribute.name,
      );
    }
    const numUniforms = gl.getProgramParameter(
      shaderProgram,
      gl.ACTIVE_UNIFORMS,
    );
    for (let i = 0; i < numUniforms; i++) {
      const uniform = gl.getActiveUniform(shaderProgram, i);
      wrapper[uniform.name] = gl.getUniformLocation(
        shaderProgram,
        uniform.name,
      );
    }

    return wrapper;
  }

  // 创建缓冲对象
  createBuffer(gl, type, data) {
    const buffer = gl.createBuffer();
    gl.bindBuffer(type, buffer);
    gl.bufferData(type, data, gl.STATIC_DRAW);
    gl.bindBuffer(type, null);
    return buffer;
  }

  // 绑定着色器与webGL上下文
  loadShader(gl, type, source) {
    const shader = gl.createShader(type);
    gl.shaderSource(shader, source);
    gl.compileShader(shader);
    if (!gl.getShaderParameter(shader, gl.COMPILE_STATUS)) {
      console.log('着色器编译失败: ', gl.getShaderInfoLog(shader));
      gl.deleteShader(shader);
      return null;
    }
    return shader;
  }

  initBuffersForFramebuffer(gl) {
    const vertices = new Float32Array([
      1, 1,
      -1, 1,
      1, -1,
      -1, -1,
    ]); // 矩形
    const texCoords = new Float32Array([
      1.0, 0.0,
      0.0, 0.0,
      1.0, 1.0,
      0.0, 1.0,
    ]);

    const obj = {};
    obj.verticesBuffer = this.createBuffer(gl, gl.ARRAY_BUFFER, vertices);
    obj.texCoordsBuffer = this.createBuffer(gl, gl.ARRAY_BUFFER, texCoords);
    return obj;
  }

  initBuffersForScreen(gl) {
    const vertices = new Float32Array([
      1, 1,
      -1, 1,
      1, -1,
      -1, -1,
    ]); // 矩形
    const texCoords = new Float32Array([
      1.0, 0.0,
      0.0, 0.0,
      1.0, 1.0,
      0.0, 1.0,
    ]);

    const obj = {};
    obj.verticesBuffer = this.createBuffer(gl, gl.ARRAY_BUFFER, vertices);
    obj.texCoordsBuffer = this.createBuffer(gl, gl.ARRAY_BUFFER, texCoords);
    return obj;
  }

  getColor() {
    this.gradient = colorArray.color_arrays[factorMap[store.state[this.config].factor]].default;
    this.steps = colorArray.color_arrays[factorMap[store.state[this.config].factor]].steps;
    this.setMinMax();
    this.colors = this.createGradientArray(this.opaque);
    this.maxIndex = this.steps - 1 << 2;
    this.step = (this.max - this.min) / this.steps;
    this.gradientObj = this.createGradientObject();
  }

  createGradientObject() {
    try {
      const stepsInput = this.steps;
      const steps = stepsInput;
      let stepDivisionFactor = 1;

      // If the number of steps exceed 2048, limit them to 2048 and calculate the step division factor
      if (steps > 2048) {
        // stepDivisionFactor = stepsInput / (steps = 2048);
        stepDivisionFactor = stepsInput / steps;
      }

      let calculatedSteps = 1 << Math.round(Math.log2(steps));
      if (calculatedSteps < steps) {
        calculatedSteps += calculatedSteps;
      }

      const multiplier = steps / ((this.max - this.min) * calculatedSteps);
      const incrementValue = -multiplier * this.min;
      const fourfoldR = calculatedSteps << 2;

      let gradientArray = this.createGradientArray(false, false, stepDivisionFactor);

      if (gradientArray.byteLength < fourfoldR) {
        const newArray = new Uint8Array(fourfoldR);
        newArray.set(gradientArray);

        if (gradientArray.byteLength > 7) {
          const chunk = new Uint8Array(4);
          for (let i = 0; i < 4; i++) {
            chunk[i] = gradientArray[gradientArray.byteLength - 8 + i];
          }
          for (let i = gradientArray.byteLength - 4; i < fourfoldR; i += 4) {
            newArray.set(chunk, i);
          }
        }
        gradientArray = newArray;
      } else if (gradientArray.byteLength > fourfoldR) {
        gradientArray = new Uint8Array(gradientArray.buffer, 0, fourfoldR);
      }
      return {
        mul: multiplier,
        add: incrementValue,
        arr: gradientArray,
        width: calculatedSteps,
        height: 1,
      };
      // return {
      //   // texture: this.glo.createTexture2D(Ih.LINEAR, Ih.LINEAR, Ih.CLAMP_TO_EDGE, gradientArray, calculatedSteps, 1),
      // }
    } catch (error) {
      return null;
    }
  }

  /**
     * Method to create 2D texture with detailed parameters.
     * This method is to be used in a WebGL context to create detailed textures.
     * @param {Uint8Array} textureData - Texture data in Uint8Array type
     * @param {number} width - Texture width
     * @param {number} height - Texture height
     * @param {GLenum} format - Specifies the format of the texel data (optional)
     * @param {GLenum} type - Specifies the data type of the texel data(optional)
     * @returns {WebGLTexture | null} Created WebGLTexture object or null if failed.
     */
  createTexture2D(textureData, width, height, format, type) {
    const gl = this._gl;

    if (gl) {
      const texture = gl.createTexture();
      if (texture) {
        // texture._width = width;
        // texture._height = height;
        gl.bindTexture(gl.TEXTURE_2D, texture);
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
        // 纹理坐标垂直填充 t
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
        // 纹理放大处理
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
        // 纹理缩小处理
        gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
        // this.setBindedTexture2DParams(gl);
      }

      return this.resizeTexture2D(texture, textureData, width, height, format, type);
    }

    return null;
  }

  judgeFactorType(factor) {
    let factorType = 1;
    let minAlpha = 0.05;
    let maxAlpha = 0.5;
    if (factor === 'wind') {
      factorType = 3;
    } else if (factor_log.includes(factor)) {
      if (factor === 'dust_ddep' || factor === 'dust_emis') {
        minAlpha = 5;
        maxAlpha = 10;
      } else if (factor === 'ri_min') {
        factorType = 5;
        maxAlpha = 7.5;
      } else if (factor === 'max_reflectivity' || factor === 'base_reflectivity') {
        minAlpha = 5;
        maxAlpha = 10;
      } else if (factor === 'dust_conc') {
        minAlpha = 0;
        maxAlpha = 100;
      } else if (factor === 'snow' || factor === 'ice' || factor === 'graupel' || factor === 'rain') {
        minAlpha = 0.1;
        maxAlpha = 0.3;
      } else if (factor === 'pre_total' || factor === 'pres_all' || factor === 'prer') {
        minAlpha = 0.2;
        maxAlpha = 1;
      } else if (factor === 'dswrfsfc') {
        maxAlpha = 100;
        minAlpha = 50;
      }
      factorType = 4;
    } else if (factor_type2.includes(factor)) {
      factorType = 2;
    } else if (factor === 'ri_min') {
      factorType = 5;
      maxAlpha = 15;
      minAlpha = 7.5;
    } else if (factor === 'vis_cats') {
      factorType = 6;
      maxAlpha = 2.1;
      minAlpha = 1.1;
    } else {
      factorType = 1;
    }
    return {
      factorType,
      minAlpha,
      maxAlpha,
    };
  }

  /**
     * Method to resize a 2D texture.
     * This method is to be used in a WebGL context to resize a detailed texture.
     * @param {WebGLTexture} texture - Texture sampler related to WebGL
     * @param {Uint8Array} imageData - Texture image data which is a Uint8Array
     * @param {number} width - The new width of the texture
     * @param {number} height - The new height of the texture
     * @param {string} format - Specifies the format of the texel data (e.g gl.RGBA)
     * @param {boolean} generateMipmap - A flag determines if generate mipmap for the texture or not
     * @return {WebGLTexture} - returns the resized texture
     */
  resizeTexture2D(texture, imageData, width, height, format = 'gl.RGBA', generateMipmap) {
    // If the texture does not exist, return null
    if (!texture) return null;

    const gl = this._gl;

    if (gl) {
      // Assign width, height and format to the texture's properties
      texture._width = width;
      texture._height = height;
      texture._format = format;

      // Bind the texture
      gl.bindTexture(gl.TEXTURE_2D, texture);

      if (Array.isArray(imageData)) {
        let currWidth = width;
        let currHeight = height;
        gl.pixelStorei(gl.UNPACK_ALIGNMENT, currWidth > 4 ? 4 : 1);

        for (let level = 0; level < imageData.length; level++) {
          const textureLevelData = imageData[level];
          if (currWidth === 4) {
            gl.pixelStorei(gl.UNPACK_ALIGNMENT, 1);
          }
          if (textureLevelData === null || ArrayBuffer.isView(textureLevelData)) {
            // Here we load every mipmap level manually
            gl.texImage2D(gl.TEXTURE_2D, level, format, currWidth, currHeight, 0, format, gl.UNSIGNED_BYTE, textureLevelData);
          } else {
            gl.texImage2D(gl.TEXTURE_2D, level, format, format, gl.UNSIGNED_BYTE, textureLevelData);
          }
          currWidth = Math.max(currWidth >> 1, 1);
          currHeight = Math.max(currHeight >> 1, 1);
        }
        // We don't need to generate mipmaps manually since we did it above
        generateMipmap = false;
      } else if (imageData === null || ArrayBuffer.isView(imageData)) {
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, width, height, 0, gl.RGBA, gl.UNSIGNED_BYTE, imageData);
      } else {
        gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, imageData);
      }

      // Generate mipmaps if necessary
      if (generateMipmap) {
        gl.generateMipmap(gl.TEXTURE_2D);
      }

      // Unbind the texture
      gl.bindTexture(gl.TEXTURE_2D, null);
    }
    return texture;
  }

  setMinMax() {
    this.min = this.gradient[0][0];
    this.max = this.gradient[this.gradient.length - 1][0];
  }

  createGradientArray(useDefaultFlag = true, premultiplyAlpha = false, scaleFactor = 1) {
    const stepsCount = this.steps + 1;
    const resultArray = new Uint8Array(stepsCount * 4);
    const stepSize = (this.max - this.min) / this.steps;
    const gradientStops = this.gradient;
    let currentIndex = 0;
    const startIdx = 0;
    let endIdx = 1;
    let startStop = gradientStops[startIdx];
    let endStop = gradientStops[endIdx] !== null && void 0 !== gradientStops[endIdx] ? gradientStops[endIdx] : gradientStops[startIdx];
    for (let step = 0; step < this.steps; step++) {
      const stepValue = this.min + step * stepSize * scaleFactor;
      if (stepValue > endStop[0] && endIdx < gradientStops.length) {
        startStop = endStop;
        endStop = gradientStops[++endIdx];
      }
      const interpolationFactor = (stepValue - startStop[0]) / (endStop[0] - startStop[0]);
      const interpolatedColor = this.getGradientColorYUVA(startStop[1], endStop[1], interpolationFactor);
      if (premultiplyAlpha) {
        this.makePremultiplied(interpolatedColor);
      }
      resultArray[currentIndex++] = Math.round(interpolatedColor[0]);
      resultArray[currentIndex++] = Math.round(interpolatedColor[1]);
      resultArray[currentIndex++] = Math.round(interpolatedColor[2]);
      resultArray[currentIndex++] = useDefaultFlag ? 255 : Math.round(interpolatedColor[3]);
    }

    this.neutralGrayIndex = currentIndex;
    resultArray[currentIndex++] = resultArray[currentIndex++] = resultArray[currentIndex++] = 128;
    resultArray[currentIndex++] = 255;
    return resultArray;
  }

  makePremultiplied(color) {
    const t = color[3] / 255;
    for (let n = 0; n < 3; n++) {
      color[n] = Math.max(0, Math.min(t * color[n], 255));
    }
    return color;
  }

  getMulArray(e, t) {
    const n = [];
    for (let r = 0; r < e.length; r++) {
      n.push(e[r] * t);
    }
    return n;
  }

  rgba2yuva(color) {
    const r = color[0];
    const g = color[1];
    const b = color[2];
    const o = 0.299 * r + 0.587 * g + 0.114 * b;
    return [o, 0.565 * (b - o), 0.713 * (r - o), color[3]];
  }

  yuva2rgba(color) {
    const r = color[0];
    const g = color[1];
    const b = color[2];
    return [r + 1.403 * b, r - 0.344 * g - 0.714 * b, r + 1.77 * g, color[3]];
  }

  getGradientColorYUVA(start, end, interpolationFactor) {
    const i = 1 / 255;
    const r = this.getMulArray(start, i);
    const o = this.getMulArray(end, i);
    const a = this.rgba2yuva(r);
    const s = this.rgba2yuva(o);
    const l = this.gradYuva(a, s, interpolationFactor, !0);
    const c = this.yuva2rgba(l);
    for (let u = 0; u < c.length; u++) {
      c[u] = Math.min(256 * c[u], 255);
    }
    return c;
  }

  lerpArray(startArray, endArray, factor) {
    const interpolatedArray = [];
    const complementFactor = 1 - factor;
    for (let i = 0; i < startArray.length; i++) {
      // Linear interpolation formula
      interpolatedArray.push(startArray[i] * complementFactor + endArray[i] * factor);
    }
    return interpolatedArray;
  }

  vec2size(g, b) {
    return Math.sqrt(g * g + b * b);
  }

  gradYuva(startColor, endColor, interpolationFactor, scaleInterpolation) {
    const interpolatedColor = this.lerpArray(startColor, endColor, interpolationFactor);
    if (scaleInterpolation) {
      const startSize = this.vec2size(startColor[1], startColor[2]);
      const endSize = this.vec2size(endColor[1], endColor[2]);

      if (startSize > 0.05 && endSize > 0.05) {
        const interpolatedSize = this.vec2size(interpolatedColor[1], interpolatedColor[2]);

        if (interpolatedSize > 0.01) {
          const scaleFactor = (startSize * (1 - interpolationFactor) + endSize * interpolationFactor) / interpolatedSize;
          interpolatedColor[1] *= scaleFactor;
          interpolatedColor[2] *= scaleFactor;
        }
      }
    }
    return interpolatedColor;
  }

  createTexture(gl, source) {
    const texture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, texture);
    // 反转图片 Y 轴方向 在去顶点坐标和纹理坐标的时候，坐标顺序已经经过了反转
    // gl.pixelStorei(gl.UNPACK_FLIP_Y_WEBGL, true);
    // 纹理坐标水平填充 s
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE);
    // 纹理坐标垂直填充 t
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE);
    // 纹理放大处理
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR);
    // 纹理缩小处理
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR);
    // gl.generateMipmap(gl.TEXTURE_2D);
    // 图片数据赋给纹理对象
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      source,
    );

    gl.bindTexture(gl.TEXTURE_2D, null);
    return texture;
  }

  activeBindTexture(gl, texture, unit) {
    gl.activeTexture(gl.TEXTURE0 + unit);
    gl.bindTexture(gl.TEXTURE_2D, texture);
  }

  bindEnableBuffer(gl, buffer, attribute, numComponents) {
    gl.bindBuffer(gl.ARRAY_BUFFER, buffer);
    gl.enableVertexAttribArray(attribute);
    gl.vertexAttribPointer(attribute, numComponents, gl.FLOAT, false, 8, 0);
  }

  drawScreen(program, texture, max_min, colorTexture) {
    const gl = this._gl;
    const targetBuffer = this.screenBuffer;
    this.activeBindTexture(gl, texture, 1);
    this.activeBindTexture(gl, colorTexture, 2);
    this.bindEnableBuffer(
      gl,
      targetBuffer.texCoordsBuffer,
      program.aTextureCoords,
      2,
    );
    this.bindEnableBuffer(
      gl,
      targetBuffer.verticesBuffer,
      program.aVertexCoords,
      2,
    );

    gl.uniform1i(program.uTexture0, 1);
    gl.uniform1i(program.uGradientTexture, 2);
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
  }

  _render(coords, max_min, imageData, width, height) {
    const gl = this._gl;
    const framebuffer = gl.createFramebuffer();
    gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);

    const renderTexture = gl.createTexture();
    gl.bindTexture(gl.TEXTURE_2D, renderTexture);

    // 初始化纹理数据
    gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, 256, 256, 0, gl.RGBA, gl.UNSIGNED_BYTE, null);

    // 把纹理作为 framebuffer 的 color attachment
    gl.framebufferTexture2D(gl.FRAMEBUFFER, gl.COLOR_ATTACHMENT0, gl.TEXTURE_2D, renderTexture, 0);
    gl.bindFramebuffer(gl.FRAMEBUFFER, framebuffer);
    const imgTexture = this.createTexture(gl, imageData);
    // 构建colorBarTexture
    const colorTexture = this.createTexture2D(this.gradientObj.arr, this.gradientObj.width, this.gradientObj.height, 'gl.RGBA', undefined);
    const program = this.overSamplingShaderProgram;
    // 原始图像的上下文
    gl.useProgram(program.program);

    gl.uniform2fv(gl.getUniformLocation(program.program, 'split_size'), [width, height]);
    // 顶点数据映射
    gl.enable(gl.BLEND);
    gl.viewport(0, 0, 256, 256);
    gl.clearColor(0.0, 0.0, 0.0, 1.0);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    this.drawOffFrame(program, imgTexture, max_min);
    const data = new Uint8Array(256 * 256 * 4);
    gl.readPixels(0, 0, 256, 256, gl.RGBA, gl.UNSIGNED_BYTE, data);
    gl.disable(gl.BLEND);
    gl.bindFramebuffer(gl.FRAMEBUFFER, null);
    const img_256 = this.createTexture2D(data, 256, 256, 'gl.RGBA', undefined);
    const insertProgram = this.insertShaderProgram;
    gl.useProgram(insertProgram.program);
    gl.uniform2fv(gl.getUniformLocation(insertProgram.program, 'split_size'), [256.0, 256.0]);
    const factor = factorMap[store.state[this.config].factor];
    const { factorType, minAlpha, maxAlpha } = this.judgeFactorType(factor);
    const production = store.state[this.config].t2Mode;
    const uPars0 = [max_min[0], max_min[1], max_min[2], max_min[3]];
    const uPars1 = [this.gradientObj.mul, this.gradientObj.add, 0, 0];
    const uPars2 = [-0.001, 0, 128 / 255, 0];
    gl.uniform4fv(gl.getUniformLocation(insertProgram.program, 'uPars0'), uPars0);
    gl.uniform4fv(gl.getUniformLocation(insertProgram.program, 'uPars1'), uPars1);
    gl.uniform4fv(gl.getUniformLocation(insertProgram.program, 'uPars2'), uPars2);
    gl.uniform1i(gl.getUniformLocation(insertProgram.program, 'factorType'), factorType);
    gl.uniform1f(gl.getUniformLocation(insertProgram.program, 'minAlpha'), minAlpha);
    gl.uniform1f(gl.getUniformLocation(insertProgram.program, 'maxAlpha'), maxAlpha);
    gl.enable(gl.BLEND);
    gl.viewport(0, 0, gl.drawingBufferWidth, gl.drawingBufferHeight);
    gl.clearColor(0.0, 0.0, 0.0, 1.0);
    gl.clear(gl.COLOR_BUFFER_BIT | gl.DEPTH_BUFFER_BIT);
    this.drawScreen(insertProgram, img_256, max_min, colorTexture);
    const data1 = new Uint8Array(256 * 256 * 4);
    gl.readPixels(0, 0, 256, 256, gl.RGBA, gl.UNSIGNED_BYTE, data1);
    gl.disable(gl.BLEND);
  }

  drawOffFrame(program, texture, max_min) {
    const gl = this._gl;
    const targetBuffer = this.offScreenBuffer;
    this.activeBindTexture(gl, texture, 0);
    this.bindEnableBuffer(
      gl,
      targetBuffer.texCoordsBuffer,
      program.aTextureCoords,
      2,
    );
    this.bindEnableBuffer(
      gl,
      targetBuffer.verticesBuffer,
      program.aVertexCoords,
      2,
    );
    gl.uniform1i(program.uTexture0, 0);
    gl.drawArrays(gl.TRIANGLE_STRIP, 0, 4);
  }

  processImage(image1, url) {
    // 正常渲染的代码 用于还原使用
    const { imageData1, request_coors, real_coors } = this.dealNearTiles(image1);
    this.coords = request_coors;
    const { width, height } = imageData1;
    const cv = document.createElement('canvas');
    const ctx = cv.getContext('2d', { willReadFrequently: true });
    const tileCanvas = document.createElement('canvas');

    tileCanvas.width = 256;
    tileCanvas.height = 256;
    const tileCtx = tileCanvas.getContext('2d', { willReadFrequently: true });
    cv.width = width;
    cv.height = height;
    ctx.putImageData(imageData1, 0, 0);
    const imageData = ctx.getImageData(0, 0, 256, 8);
    const result = decodePixel(imageData.data, 256);
    const [max, min, vmax, vmin] = result;
    const currentZoom = request_coors.z_tile;
    // console.log(currentZoom,z_t,712);
    const {
      x, y, width: width_s, height: height_s,
    } = this.computeSubImageBounds(real_coors, currentZoom);
    //
    const startX = Math.max(0, x - 1);
    const startY = Math.max(0, y - 1);
    const endWidth = width_s + (x - startX);
    const endHeight = height_s + (y - startY);
    const cropData = ctx.getImageData(startX, 8 + startY, endWidth, endHeight);
    // const imageData1 = ctx.getImageData(0, 8, 256, 256);

    this._render(real_coors, [max, min, vmax, vmin], cropData, endWidth, endHeight);
    store.state.map.loading ? store.commit('map/setLoading', false) : '';
    // tileCtx.imageSmoothingEnabled = false;
    tileCtx.drawImage(this._renderer, 0, 0);
    return tileCanvas;
  }

  value2index(value) {
    return isNaN(value) ? this.neutralGrayIndex : Math.max(0, Math.min(this.maxIndex, (value - this.min) / this.step << 2));
  }

  computeSubImageBounds(coords, baseZ) {
    const factor = 2 ** (coords.z - baseZ);
    const size = 256 / factor;
    const offsetX = (coords.x % factor) * size;
    const offsetY = (coords.y % factor) * size;
    return {
      x: offsetX,
      y: offsetY,
      width: size,
      height: size,
    };
  }

  dealNearTiles(textureImages) {
    const factor = factorMap[store.state[this.config].factor];
    const size = { x: 256, y: 256 };
    handlerTileCanvas.width = size.x;
    handlerTileCanvas.height = size.y + 8;
    const ctx = handlerTileCanvas.getContext('2d', { willReadFrequently: true });
    const image1 = textureImages[0];
    const request_coors = image1.request_coors;
    const real_coors = image1.real_coors;
    const image2 = textureImages[1];
    ctx.drawImage(image1, 0, 0);
    const imageDataResult1 = ctx.getImageData(0, 0, size.x, 8);
    const [max1, min1, vmax1, vmin1] = decodePixel(imageDataResult1.data, 256);
    const imageData1 = ctx.getImageData(0, 0, image1.width, image1.height);
    const h1 = imageData1.height;
    const w1 = imageData1.width;
    ctx.drawImage(image2, 0, 0);
    // 获取image2的像素数据
    const imageDataResult2 = ctx.getImageData(0, 0, size.x, 8);
    const [max2, min2, vmax2, vmin2] = decodePixel(imageDataResult2.data, 256);
    if (image2.direction === 'right') {
      const imageData2 = ctx.getImageData(0, 0, image2.width, image2.height);
      const w2 = imageData2.width;
      this.mergeRightEdge(imageData1, imageData2, h1, w1, w2, max1, min1, vmax1, vmin1, max2, min2, vmax2, vmin2, factor);
    } else {
      const imageData2 = ctx.getImageData(0, 8, image2.width, 1);
      this.mergeBottomEdge(imageData1, imageData2, w1, h1, max1, min1, vmax1, vmin1, max2, min2, vmax2, vmin2, factor);
      return imageData1;
    }
    if (textureImages.length === 3) {
      const image3 = textureImages[2];
      // 获取第三张图片的像素数据
      ctx.drawImage(image3, 0, 0);
      const imageDataResult3 = ctx.getImageData(0, 0, size.x, 8);
      const [max3, min3, vmax3, vmin3] = decodePixel(imageDataResult3.data, 256);
      const imageData3 = ctx.getImageData(0, 8, image3.width, 1);
      this.mergeBottomEdge(imageData1, imageData3, w1, h1, max1, min1, vmax1, vmin1, max3, min3, vmax3, vmin3, factor);
    }
    return { imageData1, request_coors, real_coors };
  }

  mergeRightEdge(image1, image2, h1, w1, w2, max1, min1, vmax1, vmin1, max2, min2, vmax2, vmin2, factor) {
    const data2 = image2.data;
    const data1 = image1.data;
    for (let i = 8; i < h1; i++) {
      const index1 = (w1 - 1 + i * w1) * 4;
      const index2 = i * w2 * 4;
      // 像素值处理
      const resultData2 = reverse(data2[index2], data2[index2 + 1], max2, min2, vmax2, vmin2, factor, this.config);
      const reverseData = reverseResult(resultData2[0], max1, min1, vmax1, vmin1, factor, resultData2[1], this.config);
      data1[index1] = reverseData[0];
      data1[index1 + 1] = reverseData[1];
      data1[index1 + 2] = data2[index2 + 2];
      data1[index1 + 3] = data2[index2 + 3];
    }
  }

  mergeBottomEdge(image1, image2, w1, h1, max1, min1, vmax1, vmin1, max3, min3, vmax3, vmin3, factor) {
    const data1 = image1.data;
    const data3 = image2.data;
    const len = data1.length;
    for (let k = 0; k < h1 * 4; k += 4) {
      const resultData3 = reverse(data3[k], data3[k + 1], max3, min3, vmax3, vmin3, factor, this.config);
      const newData = reverseResult(resultData3[0], max1, min1, vmax1, vmin1, factor, resultData3[1], this.config);
      data1[len - w1 * 4 + k] = newData[0];
      data1[len - w1 * 4 + k + 1] = newData[1];
      data1[len - w1 * 4 + k + 2] = data3[k + 2];
      data1[len - w1 * 4 + k + 3] = data3[k + 3];
    }
  }

  _getNthThreeTile(coords, _tileUrl) {
    const urlArr = _tileUrl.split('/');
    const z = coords.z;
    const x = coords.x;
    const y = coords.y;
    this.tileZoom = this.confirmZoom(Number(z) - 1);
    const { x_tile, y_tile, z_tile } = this._getCoords(x, y, z, this.tileZoom);
    const { right_coords, under_coords } = this.getNearTile({ x_tile, y_tile, z_tile });
    let promises = [];
    if (Object.entries(under_coords).length > 0) {
      promises = [
        loadImage(this.getHandlerNewTileUrl(urlArr, x_tile, y_tile, z_tile), 'self', { x_tile, y_tile, z_tile }, { x, y, z }),
        loadImage(this.getHandlerNewTileUrl(urlArr, right_coords.x, right_coords.y, right_coords.z), 'right', { x_tile, y_tile, z_tile }, { x, y, z }),
        loadImage(this.getHandlerNewTileUrl(urlArr, under_coords.x, under_coords.y, under_coords.z), 'under', { x_tile, y_tile, z_tile }, { x, y, z }),
      ];
    } else {
      promises = [
        loadImage(this.getHandlerNewTileUrl(urlArr, x_tile, y_tile, z_tile), 'self', { x_tile, y_tile, z_tile }, { x, y, z }),
        loadImage(this.getHandlerNewTileUrl(urlArr, right_coords.x, right_coords.y, right_coords.z), 'right', { x_tile, y_tile, z_tile }, { x, y, z }),
      ];
    }

    return Promise.all(promises);
    function loadImage(src, direction, request_coors, real_coors) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = '';
        img.src = src;
        img.request_coors = request_coors;
        img.real_coors = real_coors;
        img.direction = direction;
        img.addEventListener('load', () => resolve(img));
        img.addEventListener('error', (error) => reject(error));
      });
    }
  }

  getHandlerNewTileUrl(urlArr, x, y, z) {
    const copiedUrlArr = [...urlArr];
    const last = `${y}.png`;
    copiedUrlArr.splice(-3, 3, z, x, last);
    return `${copiedUrlArr.slice(0, -1).join('/')}/${copiedUrlArr.slice(-1)}`;
  }

  confirmZoom(zoom) {
    const factor = store.state[this.config].factor;
    const tileProduction = productionMap[factor];
    const production = store.state[this.config].t2Mode;
    // return zoom>6?6:zoom;
    // const num = store.state[this.config].hd === 'none'?2:0;
    // const diffNum = store.state[this.config].hd === 'none'?0:(this.baseTimeString>2024060320?0:1);
    const { num, diffNum } = this.confirmImproveZoom();
    if (production === '聚合') {
      if (tileProduction === 'c2_5km') {
        const maxZoom = num === 0 ? 5 : 6;
        switch (zoom) {
          case 2:
          case 3:
            return 1 + num + diffNum;
          case 4:
            return 2 + num + diffNum;
          case 5:
            return 3 + num + diffNum;
          case 6:
            return 4 + num + diffNum;
          default:
            return maxZoom;
        }
      } else if (factor === 'tmp2m') {
        const maxZoom = num === 0 ? 6 : 7;
        switch (zoom) {
          case 2:
          case 3:
            return 1 + num + diffNum;
          case 4:
            return 2 + num + diffNum;
          case 5:
            return 3 + num + diffNum;
          case 6:
            return 4 + num + diffNum;
          case 7:
            return 5 + num + diffNum;
          default:
            return maxZoom;
        }
      } else {
        const maxZoom = num === 0 ? 3 : 4;
        switch (zoom) {
          case 2:
          case 3:
            return 1 + num + diffNum;
          default:
            return maxZoom;
        }
      }
    } else if (route.currentRoute.name === 'dust') {
      const maxZoom = num === 0 ? 3 : 4;
      switch (zoom) {
        case 2:
        case 3:
          return 1 + num + diffNum;
        default:
          return maxZoom;
      }
    } else {
      const maxZoom = num === 0 ? 5 : 6;
      switch (zoom) {
        case 2:
        case 3:
          return 1 + num + diffNum;
        case 4:
          return 2 + num + diffNum;
        case 5:
          return 3 + num + diffNum;
        case 6:
          return 4 + num + diffNum;
        default:
          return maxZoom;
      }
    }
  }

  confirmImproveZoom() {
    // console.log(store.state[this.config],"hd 996");
    if (store.state[this.config].hd === 'none') {
      return {
        num: 2,
        diffNum: 0,
      };
    }
    return {
      num: 0,
      diffNum: 0,
    };
  }

  _getCoords(x, y, z, tileZoom) {
    const x_tile = Math.floor(x / 2 ** (z - tileZoom));
    const y_tile_adjust = Math.floor(y / 2 ** (z - tileZoom));
    const y_tile = 2 ** (tileZoom - 1) - 1 - y_tile_adjust;
    const z_tile = tileZoom;
    return { x_tile, y_tile, z_tile };
  }

  // 判定瓦片是不是属于边界区域
  getNearTile(coords) {
    const { x_tile, y_tile, z_tile } = coords;
    const totalX = 2 ** z_tile;
    const totalY = 2 ** (z_tile - 1);
    let right_coords = {};
    let under_coords = {};
    // 判定瓦片所处于的位置区域 获取右边相邻瓦片以及下侧瓦片
    // 1. 瓦片处于非最下方的一行 需要考虑右侧下侧
    // 2. 瓦片处于最下方的一行 只需要考虑右侧
    if (y_tile === 0) {
      // 位于最后一行 判定x最右侧
      if (x_tile === totalX - 1) {
        right_coords = {
          x: 0,
          y: y_tile,
          z: z_tile,
        };
      } else {
        // 有相邻瓦片 坐标
        right_coords = {
          x: x_tile + 1,
          y: y_tile,
          z: z_tile,
        };
      }
    } else {
      // 既有下方右侧
      if (x_tile === totalX - 1) {
        right_coords = {
          x: 0,
          y: y_tile,
          z: z_tile,
        };
      } else {
        // 有相邻瓦片 坐标
        right_coords = {
          x: x_tile + 1,
          y: y_tile,
          z: z_tile,
        };
      }
      under_coords = {
        x: x_tile,
        y: y_tile - 1,
        z: z_tile,
      };
    }
    return { right_coords, under_coords };
  }
}
