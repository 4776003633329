<template>
  <div id="map" ref="map" />
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import moment from 'moment';
import L from 'leaflet';
import _ from 'lodash';
import { Message } from 'element-ui';
import Bus from '@/bus/index';
// import { getColorBar } from '@/utils';
// import "@/plugins/GrayScale/GrayScale";
import { TENCENT_KEY, TIANDITU_KEY } from '@/config';
import { factorMap, geoserverUrl, unitMap, unit } from '@/config/MapConfigT4';
// import GLOperations from '@/plugins/GLOperations';
import { getImage } from '@/api/image_hd';
import { getImage as getImageT3 } from '@/api/image_t3';
import ParseWind from '@/views/map/ParseWind';
import { getCurrentPointFactorData, getDataByFactor } from '@/api/weather_hd';
import 'leaflet.tilelayer.colorfilter';
import locationIcon from '@/assets/images/circle.png';
import '@/plugins/Leaflet.VectorGrid';
import { cityLayerFeature } from '@/plugins/CityLayer';
import { getJsonData } from '@/api/cityJson';
import { Isoline } from '@/plugins/canvas.isoline';
import { productionMap } from '@/config/MapConfig';

export default {
  props: {
    t3: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 地图实例
      map: null,
      // 弹出框实例
      popup: null,
      // 当前可视区域的范围
      bounds: null,
      // 辅助播放，记录当前时间
      time: Date.now(),
      // 延时器id
      timer: 0,
      // 当需要重新渲染风场时，是否更新的标志
      update: true,
      // 当需要重新渲染海平面气压图层时，是否更新的标志
      slpUpdate: true,

      show: true,
      loadMapLayerFactors: ['wgrd10m', 'w100m', 't2mz', 'rh2m', 'tmp2m'],
      // 当前位置的维度
      currentLatitude: 35,

      // 当前位置的经度
      currentLogitude: 118,

      // 要素值
      factorValue: 0,

      // 记录当前位置
      locationMarker: null,

      // 记录搜索位置的经纬度
      searchPosition: null,

      // 当前点预报的数据详情
      forecastDetails: null,
    };
  },
  computed: {
    ...mapState(['isMobile', 'layerType', 'mapCenter']),
    ...mapState('map', [
      'factor',
      'index',
      'status',
      'currentBaseTime',
      'windy',
      'city',
      'isobar',
      'region',
      'elevation',
      'worldLayer',
      'shadowLayer',
      'layerType',
      'hd',
      'timeType',
    ]),
    ...mapGetters('map', [
      'isT2Mode',
      'allDataIndex',
      'currentData',
      'current',
      'playCurrent',
      'timeList',
      'currentTime',
      'windMeta',
      'currentWindLayerDetails',
      'currentLayerDetails',
      'currentWindyId',
    ]),
    // 当前要素对应的key
    factorKey() {
      return factorMap[this.factor];
    },

    getCurrentMaxZoom() {
      const tileProduction = productionMap[this.factor];
      if (tileProduction === 'c10km' || tileProduction === 'c12km') {
        return 11;
      } if (tileProduction === 'c1km') {
        return 14;
      }
      return 13;
    },
    // SLP瓦片参数所需要的图层名称
    slpLayerName() {
      const majorFactorName = this.current?.storeName ?? '';
      if (majorFactorName !== '') {
        const layerNameSuffix = majorFactorName.substr(majorFactorName.indexOf('_20'));
        return `hpc:slp${layerNameSuffix}`;
      }
      return 'hpc:';
    },
    playLayerUrl() {
      const { production, baseTimeString, mode, region, factorCode } = this.currentLayerDetails;
      const index = (this.index + 1) % this.timeList.length;
      const { forecastTimeString } = this.playCurrent(index);
      let factor = factorCode;
      if (factorCode === 'pratesfc' && this.timeType.type > 1 && Number(forecastTimeString) !== Number(baseTimeString) + 1) {
        factor = `pratesfc_${this.timeType.type}hr`;
      }
      return `/main/tiles/${production}/${mode}/${region}/${baseTimeString}/${forecastTimeString}/${factor}/{z}/{x}/{-y}.png`;
    },
    // 当前要素加载的瓦片图层地址
    layerUrl() {
      const { production, baseTimeString, mode, region, factorCode } = this.currentLayerDetails;
      const { forecastTimeString } = this.current;
      let factor = factorCode;
      if (factorCode === 'pratesfc' && this.timeType.type > 1 && Number(forecastTimeString) !== Number(baseTimeString) + 1) {
        factor = `pratesfc_${this.timeType.type}hr`;
      }
      return `/main/tiles/${production}/${mode}/${region}/${baseTimeString}/${forecastTimeString}/${factor}/{z}/{x}/{-y}.png`;
    },
    nextLayerUrl() {
      const { production, baseTimeString, mode, region, factorCode } = this.currentLayerDetails;
      const { forecastTimeString } = this.current;
      const nextForecastTimeString = moment(forecastTimeString, 'YYYYMMDDHH')
        .add(1, 'hour')
        .format('YYYYMMDDHH');
      let factor = factorCode;
      if (factorCode === 'pratesfc' && this.timeType.type > 1 && Number(nextForecastTimeString) !== Number(baseTimeString) + 1) {
        factor = `pratesfc_${this.timeType.type}hr`;
      }
      return `/main/tiles/${production}/${mode}/${region}/${baseTimeString}/${nextForecastTimeString}/${factor}/{z}/{x}/{-y}.png`;
    },
    windyUrl() {
      const { production, baseTimeString, mode, region, factorCode } = this.currentLayerDetails;
      const { forecastTimeString } = this.current;
      const modify_production = (this.factor === 'tmp2m' || production === 'c2_5km') ? 'c10km' : production;
      const windFactor = factorCode === 'w100m' ? factorCode : 'wgrd10m';

      return `/gwind/${modify_production}/${mode}/${region}/${baseTimeString}/${windFactor}/${forecastTimeString}.webp`;
    },

    // 渲染 地面要素等压线
    slpUrl() {
      const { baseTimeString } = this.currentLayerDetails;
      const { forecastTimeString } = this.current;
      return `/arxiv/json/t2/early/global/${baseTimeString}/slp/${forecastTimeString}/${forecastTimeString}_d.json`;
    },
  },

  watch: {
    // 监听起报时间，起报时间变化，清除图层
    currentBaseTime() {
      this.clearLayer();
    },
    // 监听时间 或者 起报时间 或者 要素变化，重新渲染图层
    current() {
      this.render();
    },
    status() {
      if (this.status === 'play') {
        this.render();
      } else {
        // //暂停时更新时间轴，因为渲染条件不进入从这里更新
        // this.timer = setTimeout(() => {
        //   const index = (this.index + 1) % this.timeList.length;
        //   this.setIndex(index);
        // }, 1000);
      }
    },
    // 监听城市图层控制标识变化
    city(value) {
      if (value === 'none') {
        this.clearLayer('city');
      } else {
        // 判断字体
        this.cityLayer = this.setCityLayer(this.loadMapLayerFactors.includes(this.factor));
        this.map.addLayer(this.cityLayer);
      }
    },
    layerType(value) {
      // if (this.loadMapLayerFactors.indexOf(this.factor) == -1) {
      //   this.clearLayer('toggleMap');
      //   if (value === 'graph') {
      //     this.renderGeoLayer();
      //   } else {
      //     this.renderTianDiMapLayer();
      //   }
      // }
      this.clearLayer('toggleMap');
      if (value === 'graph') {
        this.renderGeoLayer();
        this.map.getPane('mapLayerPane').style.zIndex = 30;
      } else if (value === 'shadow') {
        this.renderTianDiMapLayer();
        this.map.getPane('mapLayerPane').style.zIndex = 30;
      } else {
        this.renderWindyMapLayer();

        this.map.getPane('mapLayerPane').style.zIndex = 51;
      }
      this.clearLayer('city');
      if (this.city !== 'none') {
        // 判断字体
        this.cityLayer = this.setCityLayer(this.loadMapLayerFactors.includes(this.factor));
        this.map.addLayer(this.cityLayer);
      }
    },
    worldLayer(value) {
      this.clearLayer('toggleMap');
      if (value === 'normal') {
        this.renderGeoLayer();
      } else if (this.shadowLayer === 'normal') {
        this.renderTianDiMapLayer();
      } else {
        this.renderWindyMapLayer();
      }
    },
    // 监听动态风控制变量变化
    windy(value) {
      if (value === 'none') {
        this.clearLayer('wind');
      } else if (this.image) {
        this.generateWindLayer(this.image);
      } else {
        this.renderWind();
      }
    },
    // 监听等压线控制变量变化
    isobar(value) {
      if (value === 'none') {
        this.clearLayer('isobar');
      } else {
        this.renderIsobar();
      }
    },
    // 监听高精度值是否发生变化
    hd() {
      this.clearLayer('map');
      this.removePopup();
      this.render();
    },
  },

  created() {
    Bus.$on('zoomIn', this.zoomIn);
    Bus.$on('zoomOut', this.zoomOut);
    Bus.$on('locate', this.manualLocation);
    Bus.$on('clearTimeout', this.clearTimeout);
    Bus.$on('clearLayer', this.clearLayer);
    Bus.$on('removePopByTimeTypeChange', this.removePopByTimeTypeChange);
    Bus.$on('removePopup', this.removePopup);
    Bus.$on('autoSetMapLayers', this.autoSetMapLayers);
    Bus.$on('render', this.render);
    Bus.$on('renderElevation', this.render);
    Bus.$on('setLocation', this.setLocation);
  },

  mounted() {
    if (!this.t3) {
      this.initMap();
      // 定位到用户当前位置
      this.map.locate();
    }
  },

  beforeDestroy() {
    Bus.$off('zoomIn', this.zoomIn);
    Bus.$off('zoomOut', this.zoomOut);
    Bus.$off('locate', this.manualLocation);
    Bus.$off('clearTimeout', this.clearTimeout);
    Bus.$off('clearLayer', this.clearLayer);
    Bus.$off('removePopByTimeTypeChange', this.removePopByTimeTypeChange);
    Bus.$off('removePopup', this.removePopup);
    Bus.$off('autoSetMapLayers', this.autoSetMapLayers);
    Bus.$off('render', this.render);
    Bus.$on('renderElevation', this.render);
    Bus.$off('setLocation', this.setLocation);
    this.$refs.map.removeEventListener('click', this.handlerOmap);
    this.removeEvent();
    this.setCenter(this.map.getCenter());
  },

  methods: {
    ...mapMutations('map', ['setBounds', 'setIndex', 'setWindData', 'setCenter', 'setPixelValue']),
    ...mapMutations('map', ['setWindy', 'setIsobar', 'setLoading']),
    ...mapMutations(['setCenter']),
    /**
     * @description 初始化地图
     * @returns {void}
     * @author yujie
     */
    initMap() {
      const reg = /cpu iphone os (.*?) like mac os/;
      const ios = !!navigator.userAgent.toLowerCase().match(reg);
      this.map = L.map('map', {
        crs: L.CRS.EPSG4326, // 坐标系--大地坐标系
        center: this.t3
          ? [this.region.latCenter, this.region.lonCenter]
          : this.mapCenter
            ? this.mapCenter
            : [35, 118], // 坐标中心
        // 最大倍数
        maxZoom: 13,
        minZoom: this.t3 ? 8 : 2, // 最小倍数
        zoom: this.t3 ? 7 : localStorage.getItem('current-zoom') || 2, // 初始缩放倍数
        maxBounds: this.t3
          ? [
            [this.region.latMin, this.region.lonMin],
            [this.region.latMax, this.region.lonMax],
          ]
          : [
            [-90, -Infinity],
            [90, Infinity],
          ], // 区域限制范围
        maxBoundsViscosity: 1,
        attributionControl: false, // 是否去除右下角标志
        zoomControl: false, // 是否显示地图缩放图例
        zoomAnimation: !(this.isMobile && ios),
      });

      // 创建不同的窗口(由于zIndex无效)
      this.map.createPane('borderLayerPane');
      // this.map.createPane('provinceLayerPane');
      this.map.createPane('isobarLayerPane');
      this.map.createPane('cityLayerPane');
      this.map.createPane('windLayerPane');
      this.map.createPane('heatmapLayerPane');
      this.map.createPane('mapLayerPane');

      // 设置窗口对应层级
      this.map.getPane('cityLayerPane').style.zIndex = 100;
      // this.map.getPane('provinceLayerPane').style.zIndex = 99;
      this.map.getPane('isobarLayerPane').style.zIndex = 99;
      this.map.getPane('borderLayerPane').style.zIndex = 98;
      this.map.getPane('windLayerPane').style.zIndex = 60;
      this.map.getPane('heatmapLayerPane').style.zIndex = 50;
      this.map.getPane('mapLayerPane').style.zIndex = 51;
      this.setCurrentBounds();
      this.addLayer();
      this.addEvent();
      this.convertMapByZoom();
    },
    convertMapByZoom() {
      this.map.on('zoomend', () => {
        const zoomlevel = this.map.getZoom();
        localStorage.setItem('current-zoom', zoomlevel);

        if (zoomlevel >= 9) {
          // 国界线图层 ，城市图层
          // this.cityLayer  this.borderLayer
          if (this.cityLayer) {
            this.map.removeLayer(this.cityLayer);
            this.cityLayer = null;
          }
          // 国界图层 borderLayerPane
          if (!this.borderLayer1) {
            this.borderLayer1 = L.tileLayer(
              `https://{s}.tianditu.gov.cn/DataServer?T=cta_c&X={x}&Y={y}&L={z}&tk=${TIANDITU_KEY}`,
              {
                zoomOffset: 1,
                subdomains: ['t1', 't2', 't3', 't4', 't5', 't6'],
                pane: 'borderLayerPane',
                opacity: 0.7,
              },
            );
            this.map.addLayer(this.borderLayer1);
          }
          if (this.borderLayer) {
            this.map.addLayer(this.borderLayer);
            this.borderLayer = null;
          }
        } else if (zoomlevel < 9) {
          if (this.layerType === 'default') {
            if (this.borderLayer) {
              this.map.removeLayer(this.borderLayer);
              // this.borderLayer1 = null
              this.borderLayer = null;
            }
          }

          if (this.borderLayer1) {
            this.map.removeLayer(this.borderLayer1);
            // this.borderLayer1 = null
            this.borderLayer1 = null;
          }

          if (!this.cityLayer) {
            this.addCityAndBorderLayer();
          }
        }
      });
    },

    setMarker(position) {
      this.removeMarker();
      this.locationMarker = L.marker(position, {
        icon: L.icon({
          iconUrl: locationIcon,
          className: 'locatedIcon',
          iconSize: [14, 14],
          iconAnchor: [7, 14],
        }),
        noWrap: true,
      });
      this.locationMarker.addTo(this.map);
      this.map.panTo(position);
    },
    setLocation(position) {
      const { lat, lon } = position;
      position = L.latLng([lat, lon]);
      const pixelValue = this.heatmapLayer.getPixelValue(position);
      // this.setMarker(position);
      this.map.panTo(position);
      if (!pixelValue) {
        this.searchPosition = this.searchPosition;
      }
      setTimeout(() => {
        this.setPopUp(position, pixelValue || 1);
      }, 500);
    },

    getUrlEncode(obj) {
      let str = '';
      Object.keys(obj).forEach((key) => {
        str += `${key}=${obj[key]}&`;
      });
      return str.slice(0, -1);
    },

    async setPopUp(position, pixelValue) {
      // 防止地图没有加载完点击
      if (!position.wrap) return;
      const { lat, lng } = position;
      let { production, baseTimeString, mode, region, pressure, factorCode } = this.currentLayerDetails;
      if (this.factor === 'pratesfc' && this.timeType.type > 1) {
        factorCode = `${factorCode}_${this.timeType.type}hr`;
      }
      const params = {
        lat,
        lon: lng,
        mode,
        baseTime: baseTimeString,
        production,
        pressure,
        region,
        factorCode,
      };

      const result = await getCurrentPointFactorData(this.getUrlEncode(params));
      this.forecastDetails = result?.forecast[0].forecastDetails;

      if (this.status === 'pause' && pixelValue !== undefined) {
        // console.log('33333333333333333333333', pixelValue)
        if (this.popup) {
          this.removePopup();
        }
        // 获取当前地图可视区域的范围
        const { min, max } = this.map.getPixelBounds();
        // 获取当前位置的坐标
        const { x, y } = this.map.project(position, this.map.getZoom()).floor();
        if (x > max.x - 120) {
          // const center = this.map.project(this.map.getCenter(), this.map.getZoom());
          // const latlng = L.CRS.pointToLatLng(L.point(center.x - 300, center.y), this.map.getZoom());
          // this.map.panTo(latlng);
          // return;
        }
        Bus.$emit('hiddenBottom');
        this.popup = L.popup({
          className: y - min.y < 140 ? 'custom-popup custom-popup-sepcial' : 'custom-popup',
          closeButton: false,
        });
        const val = this.calCurrentPointVal(this.forecastDetails, this.allDataIndex, pixelValue);

        const rightLng = ((lng - 180) % 360 + 360) % 360 - 180;
        const latS = parseFloat(lat) > 0.0 ? 'N' : 'S';
        const lngS = parseFloat(rightLng) > 0.0 ? 'E' : 'W';
        const unitDesc = unit[this.factorKey];

        this.popup
          .setLatLng(position)
          .setContent(
            `
          <div class="custom-popup-content" id="custom-popup-content">
            <div class="line"></div>
            <div class="close el-icon-circle-close custom-popup-close"></div>
            <div class="custom-popup-text">
              <div class="custom-popup-left">
                <div style="font-size: 11px;color:grey">${Math.abs(rightLng).toFixed(2)}${lngS},${Math.abs(lat).toFixed(2)}${latS} </div>
                <div style="display: flex;align-items: flex-end;justify-content: space-between">
                <div style="font-size: 18px;font-weight: 500">
                    <span id="factor-value">${val}</span>
                    <span style="font-size: 14px;margin-bottom: 2px">${unitDesc}</span>
                </div>
                </div>
               </div>
            </div>
          </div>
          `,
          )
          .openOn(this.map);
      }
    },

    /**
     * 单点数值计算
     * @param data
     */
    calCurrentPointVal(dataArr, index, pixelValue = undefined) {
      let val = '';
      if (this.factorKey === 'wind') {
        const data = dataArr[index]?.value ?? 0;
        if (Array.isArray(data)) {
          // 判断数据是否是数组
          const windData = [data[0], data[1]];
          let windSpeed = Math.sqrt(
            windData.reduce((sum, num) => sum + parseFloat(num) ** 2, 0),
          ).toFixed(2);
          windSpeed = this.calculateErrors(windSpeed, pixelValue);
          // val = unitMap[this.factorKey](windSpeed);
          val = windSpeed;
        }
      } else {
        let factorValue = dataArr[index]?.value[0] ?? 0;
        factorValue = this.calculateErrors(factorValue, pixelValue);
        // val = unitMap[this.factorKey](factorValue);
        val = factorValue;
      }
      return val;
    },
    /**
     * 计算误差
     * @param realValue
     * @param pixelValue
     */
    calculateErrors(realValue, pixelValue) {
      if (!pixelValue || pixelValue.maxValue <= realValue || pixelValue.minValue > realValue || pixelValue.isMaxValue || pixelValue === 1) {
        this.setPixelValue('');
        return realValue;
      }
      this.setPixelValue(pixelValue.pixelsValue);
      return pixelValue.pixelsValue;
    },

    /**
     * 国界图层
     * @returns {*}
     */
    setCountryBorder() {
      const url = `${geoserverUrl}/line-map/{z}/{x}/{y}.pbf`;
      const borderLayer = L.vectorGrid.protobuf(url, {
        rendererFactory: L.canvas.tile,
        pane: 'borderLayerPane',
        vectorTileLayerStyles: {
          interactive: true,
          world_line(properties) {
            if (properties.contry_sea === 1) {
              return {
                opacity: 0.9,
                fillColor: '#333',
                fillOpacity: 1,
                fill: true,
                color: '#333',
                weight: 1.2,
              };
            }

            return {
              opacity: 0.9,
              fillColor: '#333',
              fillOpacity: 1,
              fill: true,
              color: '#333',
              weight: 0.3,
            };
          },
          world_line_prov(properties) {
            if (properties.country_level === 1) {
              return {
                color: '#1d1d1d',
                weight: 1,
              };
            }
            return {
              color: '#333',
              dashArray: '2, 4',
              weight: 1,
            };
          },
          world_line_city(properties) {
            // 地形图配色
            if (properties.country_level === 3) {
              return {
                color: '#999',
                dashArray: '2, 6',
                weight: 1,
              };
            }
            if (properties.country_level === 4) {
              return {
                color: '#ccc',
                dashArray: '2, 8',
                weight: 1,
              };
            }
            if (properties.country_level === 2) {
              return {
                color: '#333',
                dashArray: '2, 4',
                weight: 1.2,
              };
            }
            return {
              color: '#1d1d1d',
              weight: 1,
            };
          },
        },
      });
      return borderLayer;
    },

    /**
     * 构建城市图层
     */
    setCityLayer(type) {
      type = this.layerType === 'shadow' ? true : type;
      const cityLayer = cityLayerFeature('/cities/{z}/{x}/{y}.json', {
        zoomOffset: 1,
        updateInterval: 5000,
        selectColor: true,
      });

      return cityLayer;
    },

    /**
     * @description 添加图层
     * @returns {void}
     * @author yujie
     */
    addLayer() {
      this.borderLayer = this.setCountryBorder();
      if (this.layerType === 'graph') {
        this.renderGeoLayer();
        this.map.addLayer(this.borderLayer);
        this.map.getPane('mapLayerPane').style.zIndex = 30;
      } else if (this.layerType === 'shadow') {
        this.renderTianDiMapLayer();
        this.map.addLayer(this.borderLayer);
        this.map.getPane('mapLayerPane').style.zIndex = 30;
      } else {
        this.renderWindyMapLayer();
      }
      if (this.city === 'normal') {
        this.cityLayer = this.setCityLayer(this.loadMapLayerFactors.includes(this.factor));
        this.map.addLayer(this.cityLayer);
      }
    },

    addCityAndBorderLayer() {
      if (this.city === 'normal') {
        this.cityLayer = this.setCityLayer(this.loadMapLayerFactors.includes(this.factor));
        this.map.addLayer(this.cityLayer);
      }
      if (this.layerType === 'graph' || this.layerType === 'shadow') {
        if (!this.borderLayer) {
          this.borderLayer = this.setCountryBorder();
          this.map.addLayer(this.borderLayer);
        }
      }
    },

    /**
     * @description 添加地图事件
     * @returns {void}
     * @author yujie
     */
    addEvent() {
      this.map.on('zoomend', () => {
        this.renderWind();
      });
      this.map.on('move', _.debounce(this.moveEvent.bind(this), 50));
      L.control
        .scale({
          imperial: false,
          maxWidth: 70,
          position: this.isMobile ? 'topright' : 'bottomleft',
        })
        .addTo(this.map);
      // 添加popup关闭的事件
      this.map.on('popupclose', () => {
        this.searchPosition = null;
      });
      if (!this.t3) {
        // 定位到用户当前位置
        // const dotIcon = L.icon({
        //   iconUrl: locateIcon,
        //   iconSize: [48, 48],
        // });

        const dotIcon = L.divIcon({
          className: 'blue-circle-icon',
          iconSize: [10, 10], // 设置图标大小
          html: '<div style="background-color: #156FCF; width: 10px; height: 10px; border-radius: 50%;border: 2px solid white;"></div>', // 使用HTML来定义圆形图标
        });

        // this.map.on('locationfound', (position) => {
        //   const locationMarker = L.marker([position.latitude, position.longitude], { icon: dotIcon });
        //   locationMarker.addTo(this.map);
        //   this.currentLatitude = position.latitude;
        //   this.currentLogitude = position.longitude;
        // });
        // this.map.on('locationerror', () => {
        //   console.log('触发1');
        // 使用腾讯地图定位
        this.tencentMapLocation(dotIcon);
        // });
      }

      this.map.on('click', ({ latlng, originalEvent }) => {
        if (this.heatmapLayer && !this.heatmapLayer.isLoading()) {
          const value = this.heatmapLayer.getPixelValue(latlng);
          this.setPopUp(latlng, value, originalEvent);
        }
      });

      // this.map.on("mousemove", (e) => {
      //   const {lat, lng} = e.latlng;
      //   const latS = parseFloat(lat) > 0.0 ? 'N' : 'S';
      //   const lngS = parseFloat(lng) > 0.0 ? 'E' : 'W';
      //   const lnglatText = Math.abs(lng).toFixed(2) + lngS + '\xa0\xa0'
      //     + Math.abs(lat).toFixed(2) + latS;
      //   this.$emit('mapMouseMove',lnglatText)
      // })

      this.$refs.map.addEventListener('click', this.handlerOmap);
    },
    // 处理地图popup的更多点击事件
    handlerOmap(e) {
      const { dataset, className } = e.target;
      const { lat, lng } = dataset;
      // e.stopPropagation();
      if (className.includes('more-factor-data')) {
        Bus.$emit('showBottom', { lat, lon: lng });
        const popUpDom = document.getElementById('custom-popup-content').parentElement.parentElement;
        popUpDom.style.display = 'none';

        const popUpTips = document.getElementsByClassName('leaflet-popup-tip');
        if (popUpTips && popUpTips.length > 0) {
          const popUpTip = popUpTips[0];
          const el = document.createElement('div');
          el.className = 'pulsating-icon repeat';
          popUpTip.append(el);
        }
      }
      if (className && className.indexOf('custom-popup-close') > -1) {
        this.removePopup();
        Bus.$emit('hiddenBottom');
      }
    },

    /**
     * @description 使用腾讯地图进行定位
     * @returns {void}
     * @author yunpengliu
     */
    tencentMapLocation(dotIcon) {
      this.$jsonp('https://apis.map.qq.com/ws/location/v1/ip', {
        key: TENCENT_KEY,
        output: 'jsonp',
      }).then((res) => {
        if (res.status === 0) {
          const locationMarker = L.marker([res.result.location.lat, res.result.location.lng], {
            icon: dotIcon,
          });
          locationMarker.addTo(this.map);
          this.currentLatitude = res.result.location.lat;
          this.currentLogitude = res.result.location.lng;
        }
      });
    },

    /**
     * @description 移除地图事件
     * @returns {void}
     * @author yujie
     */
    removeEvent() {
      this.map.off('move');
      this.map.off('mousemove');
    },
    /**
     * @description 地图移动事件 缩放 resize事件
     * @returns {void}
     * @author yujie
     */
    moveEvent() {
      this.setCurrentBounds();
      Bus.$emit('pause');
    },
    /**
     * @description 设置当前可视区域的范围
     * @returns {void}
     * @author yujie
     */
    setCurrentBounds() {
      this.bounds = this.map.getBounds();
      this.setBounds(this.timeList);
    },

    /**
     * @description 渲染地图图层
     * @returns {void}
     * @author yujie
     */
    render() {
      this.clearLayer();
      this.status !== 'play' && this.setLoading(true);
      this.time = Date.now();
      this.update = true;
      this.slpUpdate = true;
      if (this.heatmapLayer) {
        const url = this.status === 'play' ? this.playLayerUrl : this.layerUrl;
        // console.log("播放", url);
        if (this.status === 'play') {
          this.clearTimeout(this.timer);
          this.timer = setTimeout(() => {
            this.heatmapLayer.updateUrl(url, () => {
              const factorValueDom = document.getElementById('factor-value');
              const index = (this.index + 1) % this.timeList.length;
              const timeTypeIndex = (index + 1) * this.timeType.type - 1;
              this.setIndex(index);
              if (this.status === 'play') {
                // 限制播放时间为最快300ms一张
                const diff = Date.now() - this.time;
                if (factorValueDom) {
                  factorValueDom.innerHTML = this.calCurrentPointVal(this.forecastDetails, timeTypeIndex);
                }
                // console.log("下一帧");
              } else if (factorValueDom) {
                factorValueDom.innerHTML = this.calCurrentPointVal(this.forecastDetails, timeTypeIndex);
              }

              if (this.status === 'pause') {
                this.renderWind();
                this.renderIsobar();
              }
            });
            // this.setLoading(false)
          }, 100);
        } else {
          this.heatmapLayer.updateUrl(url, () => {
            const factorValueDom = document.getElementById('factor-value');
            console.log(this.index, '单点索引');
            if (factorValueDom) {
              const timeTypeIndex = (this.index + 1) * this.timeType.type - 1;
              const currentVal = this.calCurrentPointVal(this.forecastDetails, timeTypeIndex);
              factorValueDom.innerHTML = currentVal;
            }

            if (this.status === 'pause') {
              this.renderWind();
              this.renderIsobar();
            }
            // this.setLoading(false)
          });
        }
      } else {
        // 压缩
        const num = this.hd === 'none' ? 0 : 3;
        const baseTimeString = Number(this.currentLayerDetails.baseTimeString);
        this.heatmapLayer = new L.TileLayer.WebGL({
          url: this.layerUrl,
          zoomOffset: 1,
          pane: 'heatmapLayerPane',
          config: 'map',
          baseTimeString,
          maxNativeZoom: this.getCurrentMaxZoom - num,
        });
        this.heatmapLayer.on('load', () => {
          if (this.status === 'pause' && this.update) {
            this.renderIsobar();
            this.renderWind();
          }
          // FIXME 解决每次拖拽都重新请求风场数据的问题
          this.update = false;
          // this.setLoading(false)
        });
        this.map.addLayer(this.heatmapLayer);
      }
    },

    /**
     * @description 获取海平面气压数据，渲染等压线
     * @returns {void}
     * @author yunpengliu
     */
    renderIsobar() {
      // 为保证图层准确匹配，slp图层名称直接从this.layerName中替换而来
      if (this.isobar === 'normal' || localStorage.getItem('isoline-slp') === 'normal') {
        if (this.slpUrl) {
          getJsonData(this.slpUrl)
            .then((res) => {
              const { lineData: data, hlData } = this.handleSlpData(res.data.features);
              if (!this.isobarLayer) {
                this.isobarLayer = new Isoline({}, { data, hlData }).addTo(this.map);
              }
            })
            .catch(() => {
              this.setIsobar('none');
            });
        }
      }
    },

    handleSlpData(data = []) {
      const slpData = {
        lineData: [],
        hlData: [],
      };
      data.forEach((item) => {
        const temp = [];
        const value = item.properties.value;
        const arrLan = item.geometry.coordinates;
        const type = item.geometry.type;
        if (type === 'LineString') {
          arrLan.forEach((l) => {
            temp.push([l[1], l[0], value]);
          });
          slpData.lineData.push(temp);
        } else {
          slpData.hlData.push([arrLan[0][1], arrLan[0][0], item.properties.HL, value]);
        }
      });
      return slpData;
    },

    setGreyMapLayer() {
      const grayLayer = L.vectorGrid.protobuf(`${geoserverUrl}/grayscale/{z}/{x}/{y}.pbf`, {
        rendererFactory: L.canvas.tile,
        pane: 'mapLayerPane',
        vectorTileLayerStyles: {
          interactive: true,
          ne_10m_ocean() {
            return {
              fillColor: '#A1A1B1',
              fillOpacity: 1,
              fill: true,
              color: '#a6cee300',
            };
          },
        },
      });
      return grayLayer;
    },

    // https://www.tjweather.com/clear/{z}/{x}/{-y}.png
    setHillShadeLayer() {
      const hillshade = L.tileLayer(`${geoserverUrl}/hillshade/{z}/{x}/{y}.png`, {
        zoomOffset: 1,
        maxNativeZoom: 8,
        pane: 'mapLayerPane',
        className: 'hillshade-class-img-map',
      });
      return hillshade;
    },
    renderGeoLayer() {
      if (this.mapLayer) {
        this.map.removeLayer(this.mapLayer);
      }
      this.mapLayer = this.setHillShadeLayer();
      this.map.addLayer(this.mapLayer);
      const zoomlevel = this.map.getZoom();
      if (zoomlevel < 9) {
        if (!this.borderLayer) {
          this.borderLayer = this.setCountryBorder();
          this.map.addLayer(this.borderLayer);
        }
      }
      // 地形图
    },
    setSatelliteLayer() {
      const satellite = L.tileLayer(`${geoserverUrl}/satellite/{z}/{x}/{y}.png`, {
        // 链接要改对应的
        zoomOffset: 1,
        maxNativeZoom: 9,
        pane: 'mapLayerPane',
        className: 'satellite-class-img-map',
      });
      return satellite;
    },
    renderTianDiMapLayer() {
      // 影像图
      if (this.mapLayer) {
        this.map.removeLayer(this.mapLayer);
      }
      this.mapLayer = this.setSatelliteLayer();
      // this.borderLayer = this.setCountryBorder();
      this.map.addLayer(this.mapLayer);
      const zoomlevel = this.map.getZoom();
      if (zoomlevel < 9) {
        if (!this.borderLayer) {
          this.borderLayer = this.setCountryBorder();
          this.map.addLayer(this.borderLayer);
        }
      }
    },
    setWindyLayer() {
      return L.tileLayer('https://www.tjweather.com/clear/{z}/{x}/{-y}.png', {
        zoomOffset: 1,
        maxNativeZoom: 7,
        pane: 'mapLayerPane',
      });
    },
    renderWindyMapLayer() {
      if (this.borderLayer) {
        this.map.removeLayer(this.borderLayer);
        this.borderLayer = null;
      }

      if (this.mapLayer) {
        this.map.removeLayer(this.mapLayer);
      }
      this.mapLayer = this.setWindyLayer();
      this.map.addLayer(this.mapLayer);
    },
    /**
     * @description 获取风场数据，渲染风场
     * @returns {Promise}
     * @author yujie
     */
    async renderWind() {
      if (this.windy === 'normal' || localStorage.getItem('windy') === 'normal') {
        if (this.cancel && typeof this.cancel === 'function') this.cancel();
        const image = !this.t3
          ? await getImage(this.windyUrl, (cancel) => {
            this.cancel = cancel;
          })
          : await getImageT3(this.windyUrl, (cancel) => {
            this.cancel = cancel;
          });

        this.image = new Image();
        this.image.src = image;
        this.image.addEventListener('load', () => {
          this.generateWindLayer(this.image);
        });
      }
    },
    /**
     * @description 渲染风场
     * @param {Image} image 双通道图片
     * @returns {void}
     * @author yujie
     */
    async generateWindLayer(image) {
      if (!this.windMeta) {
        const factorTempKey = this.factor === 'w100m ' ? 'w100m' : 'wgrd10m';
        const initWindData = await getDataByFactor(factorTempKey, 'c10km');
        this.setWindData(initWindData);
      }
      if (!this.windMeta) {
        Message({
          type: 'error',
          message: '动态风的数据正在解析中，请稍候再试',
        });
        this.setWindy('none');
        return;
      }
      const surfaceData = this.windMeta || this.current;
      const region = this.region;
      const width = surfaceData.width || 1024;
      const height = surfaceData.height || 512;
      let solutionX;
      let solutionY;

      if (this.t3) {
        solutionX = (region?.lonMax - region?.lonMin) / width;
        solutionY = (region?.latMax - region?.latMin) / height;
      } else {
        solutionX = 360 / width;
        solutionY = 180 / height;
      }
      const data = {
        width,
        height,
        umin: surfaceData.umin,
        umax: surfaceData.umax,
        vmin: surfaceData.vmin,
        vmax: surfaceData.vmax,
        lonmax: region?.lonMax ?? 180,
        lonmin: region?.lonMin ?? -180,
        latmin: region?.latMax ?? 90,
        latmax: region?.latMin ?? -90,
        origionxv: 0,
        origionyu: 0,
        origionxu: 0,
        origionyv: 0,
        levelu: 0,
        levelv: 1,
        solutionX,
        solutionY,
        image,
      };

      const windOptions = {
        colorScale: [
          'rgba(255, 255, 255, 0.2)',
          'rgba(255, 255, 255, 0.3)',
          'rgba(255, 255, 255, 0.4)',
          'rgba(255, 255, 255, 0.5)',
          'rgba(255, 255, 255, 0.6)',
          'rgba(255, 255, 255, 0.7)',
        ],
      };
      const zoom = Number(localStorage.getItem('current-zoom')) || 2;
      const scaleFactor = zoom > 4 ? zoom : 1;

      const windData = new ParseWind(data, !this.t3).getData();
      if (this.windLayer) {
        this.windLayer.setData(windData);
        this.windLayer.setOptions({ particleMultiplier: 1 / (400 * scaleFactor) });
      } else {
        this.windLayer = L.velocityLayer(
          Object.assign(windOptions, {
            lineWidth: 2,
            data: windData,
            velocityScale: 0.01,
            maxVelocity: 10,
            minVelocity: 1,
            particleMultiplier: 1 / (400 * scaleFactor),
            frameRate: 20,
            paneName: 'windLayerPane',
          }),
        );

        this.map.addLayer(this.windLayer);
        // 由于以下四个事件需要windLayer定义以后才可以注册，所以进行延迟注册
        this.addEventDelay();
      }
    },

    /**
     * @description 延迟注册地图拖动及缩放事件，保证风场图层已加载完毕
     * @returns {void}
     * @author yunpengliu
     */
    addEventDelay() {
      this.map.on('zoomstart', this.clearWind);
      this.map.on('zoom', this.clearWind);
      this.map.on('zoomend', this.clearAndRestartWind);
      this.map.on('movestart', this.clearWind);
      this.map.on('move', this.clearWind);
      this.map.on('moveend', this.clearAndRestartWind);
    },
    /**
     * @description 清除风粒子
     * @returns {void}
     * @author yunpengliu
     */
    clearWind() {
      this.windLayer._clearWind();
    },
    /**
     * @description 清除风粒子并重新渲染
     * @returns {void}
     * @author yunpengliu
     */
    clearAndRestartWind() {
      this.windLayer._clearAndRestart();
    },
    /**
     * @description 移除popup
     * @returns {void}
     * @author yujie
     */
    removePopup() {
      this.popup?.remove();
      this.searchPosition = null;
    },
    // 移除地图标记
    removeMarker() {
      this.locationMarker?.remove();
    },

    /**
     * @description 地图缩放等级放大一级
     * @returns {void}
     * @author yujie
     */
    zoomIn() {
      this.map.zoomIn();
    },

    /**
     * @description 地图缩放等级缩小一级
     * @returns {void}
     * @author yujie
     */
    zoomOut() {
      this.map.zoomOut();
    },

    /**
     * @description 手动定位到用户当前所在位置
     * @returns {void}
     * @author yunpengliu
     */
    manualLocation() {
      this.map.flyTo([this.currentLatitude, this.currentLogitude], 7);
      const position = L.latLng([this.currentLatitude, this.currentLogitude]);
      const pixelValue = this.heatmapLayer.getPixelValue(position);
      this.setPopUp(position, pixelValue || 1);
    },

    /**
     * @description 暂停时移除定时器
     * @returns {void}
     * @author yujie
     */
    clearTimeout() {
      clearTimeout(this.timer);
    },
    /**
     * @description 移除地图图层
     * @returns {void}
     * @author yujie
     */
    clearLayer(type) {
      if (!type || type === 'map') {
        if (this.heatmapLayer) this.map.removeLayer(this.heatmapLayer);
        this.heatmapLayer = null;
      }

      if (!type || type === 'wind') {
        this.map.off('zoomstart', this.clearWind);
        this.map.off('zoom', this.clearWind);
        this.map.off('zoomend', this.clearAndRestartWind);
        this.map.off('movestart', this.clearWind);
        this.map.off('move', this.clearWind);
        this.map.off('moveend', this.clearAndRestartWind);

        if (this.windLayer) this.map.removeLayer(this.windLayer);
        this.windLayer = null;
        // FIX 修改有时不展示风场的问题
        this.image = null;
      }

      if (type === 'city') {
        if (this.cityLayer) this.map.removeLayer(this.cityLayer);
      }

      // change 地形图 还是天地图
      if (type === 'toggleMap') {
        if (this.borderLayer) this.map.removeLayer(this.borderLayer);
        this.borderLayer = null;
      }

      if (!type || type === 'isobar') {
        if (this.isobarLayer) this.map.removeLayer(this.isobarLayer);
        this.isobarLayer = null;
      }
    },
    removePopByTimeTypeChange() {
      this.removePopup();
    },

    autoSetMapLayers(factor) {
      if (this.loadMapLayerFactors.indexOf(factor) === -1) {
        if (this.cityLayer) this.map.removeLayer(this.cityLayer);
        this.cityLayer = null;
        if (this.city === 'normal') {
          this.cityLayer = this.setCityLayer(false);
          this.map.addLayer(this.cityLayer);
        }
      } else {
        // 移除图层
        if (this.cityLayer) this.map.removeLayer(this.cityLayer);
        this.cityLayer = null;
        if (this.city === 'normal') {
          this.cityLayer = this.setCityLayer(true);
          this.map.addLayer(this.cityLayer);
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#map {
  width: 100%;
  height: 100%;
}

#factor-tooltip {
  display: none;
  background: #c8c8c8;
  color: #333;
  opacity: 0.7;
  border-radius: 5px;
  font-family: "微软雅黑", sans-serif;
  font-size: 12px;
  text-align: center;
  padding: 4px;
  position: absolute;
  z-index: 1000;
  width: 50px;
  height: 20px;
}

::v-deep {
  .leaflet-left {
    bottom: 45px;

    .leaflet-control-scale {
      margin-left: 5px;
    }
  }

  .leaflet-right {
    .leaflet-control-scale {
      margin-right: 22px;
    }
  }

  .leaflet-popup-content-wrapper {
    position: absolute;
    //left: 60%;
    top: -90px;
    left: -78px;
    margin-left: -1px;
    //border-radius: 0 40px 40px 0;
    border-radius: 5px;
    background-color: rgba($text-operate, 0.92);
    color: $text-menu;

    .leaflet-popup-content {
      width: 148px;
      margin: 5px 10px;

      .line {
        border-left: 2px solid rgba($text-operate, 1);
        //height: 140px;
        height:52px;
        position: absolute;
        //left: 0;
        //top: 0;
        left: 80px;
        top: 50px;
      }

      .close {
        position: absolute;
        font-size: 20px;
        right: -20px;
        top: 0;
        color: $text-operate;
        cursor: pointer;
      }

      .custom-popup-content {
        .custom-popup-text {
          //display: flex;
          //align-items: center;
          //white-space: nowrap;
          //justify-content: space-between;

          .current-value {
            font-size: 15px;
            font-weight: 500
          }
          .current-factor {
            font-size: 12px;
          }

          .more-factor-data {
            width: 30px;
            height: 30px;
            //margin-left:10px;
            text-align: center;
            line-height: 30px;
            border-radius: 50%;
            background-color: $theme-color;
            color: #fff;
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
    }
  }

  .custom-popup-sepcial > .leaflet-popup-content-wrapper {
    top: 50px;

    .leaflet-popup-content {
      .line {
        top: -35px;
        height:35px;
      }
    }
  }

  .leaflet-popup-tip-container {
    top: -10px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -18px;

    .leaflet-popup-tip {
      border-radius: 50%;
      width: 12px;
      height: 12px;
      background: $theme-color;
      box-sizing: border-box;
      border: 2px solid $text-operate;
      margin: 0 auto;
    }

    .pulsating-icon {
      animation: pulsate .5s ease-out;
      -webkit-animation: pulsate .5s ease-out;
      animation-iteration-count: 3;
      -webkit-animation-iteration-count: 3;
      border: 3px solid #fff;
      pointer-events: none;
      height: 28px;
      width: 28px;
      margin-left: -14px;
      margin-top: -14px;
      border-radius: 30px;
      -webkit-border-radius: 30px;
      opacity: 0
    }

    .pulsating-icon.repeat {
      animation: pulsate 2s ease-out;
      -webkit-animation: pulsate 2s ease-out;
      /* animation-iteration-count:infinite; */
      -webkit-animation-iteration-count: infinite;
    }
  }

  .more-factor-data {
    color: #0078a8;
    cursor: pointer;
  }
}

.locatedIcon {
  margin-top: -10px !important;
}

@keyframes pulsate {
  0% {
    transform: scale(0.1, 0.1);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: scale(1.2,1.2);
    opacity: 0;
  }
}
</style>
