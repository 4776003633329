<template>
  <div class="apply">
    <div class="content-page">
      <div class="head">
        <span>API申领</span>
        <i class="el-icon-close" @click="$emit('close')" />
      </div>
      <div v-if="!isShowResult" class="content">
        <div class="solution" @click="jump()">
          <i class="el-icon-info" />
          <span>API说明文档</span>
        </div>
        <div class="info">
          <el-form
            ref="form"
            :model="form"
            :rules="rules"
            label-width="100px"
            hide-required-asterisk
            class="form"
          >
            <el-form-item prop="mobile">
              <div class="name-flag" style="display: flex">
                <span>手机号码：</span>
                <el-input
                  v-model="form.mobile"
                  class="must-content"
                  size="medium"
                  placeholder="请输入手机号码"
                  clearable
                />
              </div>
            </el-form-item>

            <el-form-item prop="email">
              <div class="name-flag" style="display: flex">
                <span>邮箱号码：</span>
                <el-input
                  v-model="form.email"
                  size="medium"
                  placeholder="请输入邮箱号码"
                  clearable
                />
              </div>
            </el-form-item>

            <el-form-item>
              <div class="name-flag" style="display: flex; margin-top: 19px">
                <span>用户姓名：</span>
                <el-input
                  v-model="form.userName"
                  size="medium"
                  placeholder="请输入姓名"
                  clearable
                />
              </div>
            </el-form-item>

            <el-form-item>
              <div class="name-flag" style="display: flex">
                <span>申领备注：</span>
                <el-input
                  v-model="form.description"
                  type="textarea"
                  resize="none"
                  placeholder="请输入备注"
                  maxlength="500"
                  show-word-limit
                />
              </div>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div v-if="isShowResult" class="result">
        <div class="status"><i class="el-icon-success" /></div>
        <div class="success">申领提交成功</div>
        <div class="descripe">
          您的API申请请求已经提交成功，您将收到申领成功短信，请保存好短信，后续中科天机会电话/邮件联系您沟通后续事宜!
          谢谢您的试用，欢迎您提出宝贵意见。
        </div>
      </div>

      <div class="footer">
        <div class="btn">
          <el-button v-if="!isShowResult" @click="$emit('close')"> 取消 </el-button>
          <el-button type="primary" @click="confirm()">
            {{ isShowResult ? "确定" : "提交" }}
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { apply } from '@/api/user.js';

export default {
  components: {},
  data() {
    return {
      form: {
        userName: '',
        mobile: '',
        email: '',
        description: '',
      },
      isShowResult: false,
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号格式错误', trigger: 'blur' },
        ],
        email: [
          {
            pattern: /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/,
            message: '邮箱格式错误',
            trigger: 'blur',
          },
        ],
      },
    };
  },
  created() {},
  methods: {
    confirm() {
      if (this.isShowResult) {
        this.$emit('close');
        return;
      }
      this.$refs.form.validate((valid) => {
        if (!valid) return;
        const param = {
          name: this.form.userName,
          mobile: this.form.mobile,
          email: this.form.email,
          applyRemark: this.form.description,
        };
        apply(param)
          .then((resp) => {
            if (resp.code == 200) {
              this.isShowResult = true;
              console.log('申请结果', resp);
            }
          })
          .catch((error) => {});
      });
    },
    jump() {
      window.open('https://www.tjweather.com/doc/', '_blank');
    },
  },
};
</script>
<style lang="scss" scoped>
.apply {
  height: 100%;
  width: 100%;
  display: flex;
  position: fixed;
  align-items: center;
  justify-content: center;
  z-index: 2001;
  background: rgba(255, 255, 255, 0);
  .must-content::before {
    content: "*";
    display: flex;
    color: #ff0000;
    margin-left: 4px;
    position: absolute;
    top: 2px;
    left: -95px;
  }

  .content-page {
    .content {
      height: calc(100% - 113px);
      box-sizing: border-box;
      padding: 10px 30px 15px 30px;
      ::v-deep {
        .el-form-item__error {
          left: 105px;
        }
        .el-form-item__content {
          margin-left: 0 !important;
        }
        .el-form-item {
          margin-bottom: 0;
        }
      }
      .info {
        width: 100%;
        height: calc(100% - 50px);
        box-sizing: border-box;
        padding: 0 30px 0 30px;
      }
      .name-flag {
        white-space: nowrap;
        align-items: center;
        position: relative;
        margin-top: 26px;
        display: flex;
        padding-right: 15px;
        box-sizing: border-box;
        span {
          position: absolute;
          right: 82%;
          font-size: 14px;
          color: #272727;
        }
        ::v-deep {
          .el-input {
            width: 80%;
            margin-left: 20%;
          }
          .el-input__inner {
            &:focus {
              border: 1px solid #027aff;
            }
            border-radius: 4px;
            border: 1px solid #dcdfe6;
          }
          textarea {
            width: 80%;
            height: 119px;
            margin-left: 20%;
          }
        }
        ::v-deep {
          .el-cascader {
            width: 100%;
          }
        }
      }
      .solution {
        i {
          margin-right: 10px;
          color: #5cb6ff;
        }
        display: flex;
        align-items: center;
        padding-left: 15px;
        color: #5cb6ff;
        height: 50px;
        width: 100%;
        cursor: pointer;
        background: #e7f7ff;
        border-radius: 4px;
      }
    }
    .result {
      height: calc(100% - 113px);
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 70px 50px 15px 50px;
      text-align: center;
      .success {
        font-size: 20px;
        margin-top: 15px;
        font-weight: 600;
      }
      .descripe {
        font-size: 15px;
        margin-top: 19px;
        line-height: 24px;
      }
      .status {
        font-size: 55px;
        color: #50c41b;
      }
    }
    .footer {
      height: 62px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: right;
      padding-right: 15px;
      box-sizing: border-box;
      border-top: 1px solid #ebebeb;
      ::v-deep {
        .el-button {
          height: 35px;
          width: 60px;
          padding: 10px 10px;
        }
      }
    }
    .head {
      i {
        cursor: pointer;
        position: absolute;
        right: 10px;
        &:hover {
          color: rgb(192, 0, 25);
        }
      }
      span {
        margin-left: 15px;
      }
      position: relative;
      height: 50px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #eaecf1;
    }
    width: 650px;
    height: 530px;
    border-radius: 4px;
    border: 1px solid #eaecf1;
    background: #ffffff;
  }
}
</style>
