<template>
  <div class="registerPage">
    <div class="head">
      <svg-icon
        class="input-svg"
        style="position: relative;height: 30px;width: 48px;top: 0px;left: -9px;"
        :icon-class="'titlesRed'"
      />
      <div class="title_box">
        <span>
          {{ '中科天机' }}
        </span>
      </div>
    </div>
    <p
      class="MsoNormal"
      align="center"
      style="mso-pagination:widow-orphan;mso-outline-level:3;
text-align: center;
    line-height: 22.5pt;
    background: rgb(248, 249, 252);
    margin-top: 61px;
    margin-bottom: 45px;"
    >
      <span
        style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(73,92,110);font-size:18.0000pt;mso-font-kerning:0.0000pt;"
      >
        <font face="微软雅黑">《中科天机服务平台用户协议》</font>
      </span><span
        style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(73,92,110);font-size:18.0000pt;mso-font-kerning:0.0000pt;"
      >
        <o:p />
      </span>
    </p>
    <div class="body">
      <div class="Section0" style="layout-grid:15.6000pt;">
        <p
          class="MsoNormal"
          style="text-align: left;
    background: rgb(246, 248, 248);
    color: #495c6e;
    background-color: #f6f8f8;
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    position: relative;
    left: -15px;
    width: 122%;
    top: -16px;
    border: 1px solid #dddddd;"
        >
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(73,92,110);font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">协议内容与条款</font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(73,92,110);font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p class="MsoNormal" style="mso-pagination:widow-orphan;text-align:left;line-height:200%;">
          <b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">欢迎访问中科天机服务平台并使用我们提供的产品和服务。</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p class="MsoNormal" style="mso-pagination:widow-orphan;text-align:left;">
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(113,128,143);font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          ><br></span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(113,128,143);font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p class="MsoNormal" style="mso-pagination:widow-orphan;text-align:left;line-height:200%;">
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">在完成注册程序或以任何方式使用中科天机服务平台及平台提供的服务前，请您务必仔细阅读并透彻理解本网站协议条款</font>
            <font face="微软雅黑">
              (以下或简称“协议条款”)，在确认充分理解后选择接受或不接受本协议条款；一旦您完成“同意并遵守”或开始以其他方式使用服务平台及平台提供的服务，即表明您已阅读并同意受本协议条款的约束。如您不同意本协议条款或其中任何条款约定，您应不再进行下一步或停止注册程序。
            </font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p class="MsoNormal" style="mso-pagination:widow-orphan;text-align:left;">
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(113,128,143);font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          ><br></span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(113,128,143);font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p class="MsoNormal" style="mso-pagination:widow-orphan;text-align:left;line-height:200%;">
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">天机再次提示您审慎阅读、充分理解各条款内容，特别是限制或免除责任的相应条款，限制或免除责任条款将以加粗或其他醒目形式提示您注意。</font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p class="MsoNormal" style="mso-pagination:widow-orphan;text-align:left;">
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(113,128,143);font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          ><br></span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(113,128,143);font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p class="MsoNormal" style="mso-pagination:widow-orphan;text-align:left;line-height:200%;">
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">如果您未满</font>
            <font face="微软雅黑">18周岁，请在法定监护人的陪同下阅读本协议条款。</font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p class="MsoNormal" style="mso-pagination:widow-orphan;text-align:left;">
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(113,128,143);font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p>&nbsp;</o:p>
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-bottom:3.7500pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">1签约主体以及协议范围</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-right:7.5000pt;margin-left:36.0000pt;text-indent:-18.0000pt;
mso-pagination:widow-orphan;text-align:left;line-height:200%;
mso-list:l5 level1 lfo1;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">·<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">1.1</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">《中科天机服务平台用户协议》是您与天机（</font>
            <font face="微软雅黑">“天机”或者“我们”）就使用天机服务平台及平台提供的服务（以下简称“平台”）而订立的有效合约。平台域名为:</font>
          </span><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >tjweather</span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">.com。</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-right:7.5000pt;margin-left:36.0000pt;text-indent:-18.0000pt;
mso-pagination:widow-orphan;text-align:left;line-height:200%;
mso-list:l5 level1 lfo1;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">·<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">1.2</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">请您审慎阅读、充分理解各条款内容，选择接受或不接受该协议条款。</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-bottom:3.7500pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">2平台用户的注册、使用与安全</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-right:7.5000pt;margin-left:36.0000pt;text-indent:-18.0000pt;
mso-pagination:widow-orphan;text-align:left;line-height:200%;
mso-list:l4 level1 lfo2;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">·<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">2.1</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">注册的资格</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:72.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;mso-list:l4 level1 lfo2;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(113,128,143);font-size:10.0000pt;mso-font-kerning:0.0000pt;"
          ><span
            style="mso-list:Ignore;"
          >·<span>&nbsp;</span></span></span><!--[endif]--><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(113,128,143);font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:72.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l4 level2 lfo2;"
        >
          <!--[if !supportLists]--><span
            style="font-family:'Courier New';mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-size:10.0000pt;mso-font-kerning:0.0000pt;"
          ><span
            style="mso-list:Ignore;"
          >o<span>&nbsp;</span></span></span><!--[endif]--><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">2.1.1 您确认，在您完成注册程序或以其他天机允许的方式实际使用本平台时，您应当是具备完全民事权利能力和完全民事行为能力的自然人、法人或其他组织（以下简称为“您”）。</font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:72.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l4 level2 lfo2;"
        >
          <!--[if !supportLists]--><span
            style="font-family:'Courier New';mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">o<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">2.1.2 若您是未成年人或限制民事行为能力人，则您不具备前述主体资格，您及您的监护人应承担因您的不当注册行为而导致的一切后果。</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:72.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l4 level2 lfo2;"
        >
          <!--[if !supportLists]--><span
            style="font-family:'Courier New';mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">o<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">2.1.3 您还需确保您不是任何国家、国际组织或者地域实施的贸易限制、制裁或其他法律、规则限制的对象，否则您可能无法正常注册和使用平台及服务。</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-right:7.5000pt;margin-left:36.0000pt;text-indent:-18.0000pt;
mso-pagination:widow-orphan;text-align:left;line-height:200%;
mso-list:l4 level1 lfo2;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">·<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">2.2</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">用户注册</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:72.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;mso-list:l4 level1 lfo2;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(113,128,143);font-size:10.0000pt;mso-font-kerning:0.0000pt;"
          ><span
            style="mso-list:Ignore;"
          >·<span>&nbsp;</span></span></span><!--[endif]--><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(113,128,143);font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:72.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l4 level2 lfo2;"
        >
          <!--[if !supportLists]--><span
            style="font-family:'Courier New';mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-size:10.0000pt;mso-font-kerning:0.0000pt;"
          ><span
            style="mso-list:Ignore;"
          >o<span>&nbsp;</span></span></span><!--[endif]--><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">2.2.1 当您按照注册页面提示填写信息、阅读并同意本协议条款且完成全部注册程序后，您将成为中科天机服务平台的用户。</font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:72.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l4 level2 lfo2;"
        >
          <!--[if !supportLists]--><span
            style="font-family:'Courier New';mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-size:10.0000pt;mso-font-kerning:0.0000pt;"
          ><span
            style="mso-list:Ignore;"
          >o<span>&nbsp;</span></span></span><!--[endif]--><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">2.2.2 您在注册时候设置或确认的用户名、邮箱、手机号及密码，将在注册成功后成为平台上您的标识。</font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:72.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l4 level2 lfo2;"
        >
          <!--[if !supportLists]--><span
            style="font-family:'Courier New';mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">o<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">2.2.3 您设置的用户名不得违反国家法律法规、平台的管理规范或容易引起您与平台识别身份时的混淆，否则您可能不能注册成功或天机有权经通知您后予以注销。</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:72.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l4 level2 lfo2;"
        >
          <!--[if !supportLists]--><span
            style="font-family:'Courier New';mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-size:10.0000pt;mso-font-kerning:0.0000pt;"
          ><span
            style="mso-list:Ignore;"
          >o<span>&nbsp;</span></span></span><!--[endif]--><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">2.2.4
              您应当按照法律法规要求或按相应页面的提示，准确提供并及时更新您的用户信息，以使之真实、及时、完整和准确。如您提供的资料错误、不实、过时或不完整，天机可以向您发出询问及/或要求改正的通知，您应按照天机的要求配合提供或者更新相关资料。
            </font>
          </span><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">因您填写的信息、资料不真实、不及时、不完整或不准确的，您应承担您不能使用平台功能或在使用过程中的后果和损失。</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:72.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l4 level2 lfo2;"
        >
          <!--[if !supportLists]--><span
            style="font-family:'Courier New';mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-size:10.0000pt;mso-font-kerning:0.0000pt;"
          ><span
            style="mso-list:Ignore;"
          >o<span>&nbsp;</span></span></span><!--[endif]--><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">2.2.5 平台可能会就某些产品或服务的开通，要求您提供更多的身份资料和信息，做进一步的身份认证或资格验证，您只有在通过这些认证和验证之后，方可获得使用相关产品或服务的资格。
            </font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:72.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l4 level2 lfo2;"
        >
          <!--[if !supportLists]--><span
            style="font-family:'Courier New';mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">o<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">2.2.6
              通常情况下，您的用户名是您在天机服务平台进行一切活动的唯一身份标识，除非另有约定，每一个注册用户都可以在本网站独立开展活动。但在下列情形下，平台有权根据自己的判断，对同一及/或关联法律主体拥有的多个用户进行统一处理，如根据不同用户在注册、登录、使用中的关联信息，平台判断其实际为同一用户。关联信息举例：同一证件、同一手机号、同一邮箱、同一设备、同一地址等。
            </font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-right:7.5000pt;margin-left:36.0000pt;text-indent:-18.0000pt;
mso-pagination:widow-orphan;text-align:left;line-height:200%;
mso-list:l4 level1 lfo2;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">·<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">2.3</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">用户的使用和安全</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:72.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;mso-list:l4 level1 lfo2;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(113,128,143);font-size:10.0000pt;mso-font-kerning:0.0000pt;"
          ><span
            style="mso-list:Ignore;"
          >·<span>&nbsp;</span></span></span><!--[endif]--><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(113,128,143);font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:72.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l4 level2 lfo2;"
        >
          <!--[if !supportLists]--><span
            style="font-family:'Courier New';mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-size:10.0000pt;mso-font-kerning:0.0000pt;"
          ><span
            style="mso-list:Ignore;"
          >o<span>&nbsp;</span></span></span><!--[endif]--><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">2.3.1 您有权使用您设定的用户名登录平台，进而使用平台提供的服务。</font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:72.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l4 level2 lfo2;"
        >
          <!--[if !supportLists]--><span
            style="font-family:'Courier New';mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-size:10.0000pt;mso-font-kerning:0.0000pt;"
          ><span
            style="mso-list:Ignore;"
          >o<span>&nbsp;</span></span></span><!--[endif]--><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">2.3.2 平台可能会以</font>
          </span><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">邮件、站内信、微信、短信或电话等方式</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">通知您服务进展情况以及提示您进行下一步的操作。在服务过程中您应当及时登录到平台查看和进行相关操作。</font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:72.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l4 level2 lfo2;"
        >
          <!--[if !supportLists]--><span
            style="font-family:'Courier New';mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">o<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">2.3.3 一个注册用户仅能对应唯一的法律主体。除非有法律明文规定、司法裁定或者经天机同意的情况下，您不得以任何方式转让、赠与或让他人继承您的注册用户信息或资格。</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:72.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l4 level2 lfo2;"
        >
          <!--[if !supportLists]--><span
            style="font-family:'Courier New';mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-size:10.0000pt;mso-font-kerning:0.0000pt;"
          ><span
            style="mso-list:Ignore;"
          >o<span>&nbsp;</span></span></span><!--[endif]--><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">2.3.4 您的用户名和密码由您自行设置并由您保管，您须对您的用户信息和密码保密。您需确保您在每个上网时段结束时，以正确步骤离开网站，即退出登录。</font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:72.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l4 level2 lfo2;"
        >
          <!--[if !supportLists]--><span
            style="font-family:'Courier New';mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">o<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">2.3.5
              如发现他人未经授权使用您的注册用户，您应立即通知天机；天机将协助您冻结注册用户、更改密码或进行其他安全设置；您理解天机对您的请求采取行动需要合理时间，天机对在采取行动前已经产生的以及由您引发的后果（包括但不限于您的任何损失）不承担任何责任。
            </font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:72.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l4 level2 lfo2;"
        >
          <!--[if !supportLists]--><span
            style="font-family:'Courier New';mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-size:10.0000pt;mso-font-kerning:0.0000pt;"
          ><span
            style="mso-list:Ignore;"
          >o<span>&nbsp;</span></span></span><!--[endif]--><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">2.3.6 天机亦会在网站服务端采取合理的技术措施保障用户信息的安全。</font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-bottom:3.7500pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">3网站服务及规范</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-right:7.5000pt;margin-left:36.0000pt;text-indent:-18.0000pt;
mso-pagination:widow-orphan;text-align:left;line-height:200%;
mso-list:l6 level1 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">·<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">3.1</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">您有权享受天机通过本网站提供的互联网技术服务和信息服务。您同时还应根据本协议条款以及您在购买具体服务时确认的相关条款和条件，履行及时付款、服务管理等责任。</font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-right:7.5000pt;margin-left:36.0000pt;text-indent:-18.0000pt;
mso-pagination:widow-orphan;text-align:left;line-height:200%;
mso-list:l6 level1 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">·<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">3.2</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">您使用平台和平台提供的服务时应保证：</font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:72.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;mso-list:l6 level1 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(113,128,143);font-size:10.0000pt;mso-font-kerning:0.0000pt;"
          ><span
            style="mso-list:Ignore;"
          >·<span>&nbsp;</span></span></span><!--[endif]--><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(113,128,143);font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:72.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l6 level2 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:'Courier New';mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">o<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">3.2.1
              您将遵从国家、地方法律法规、行业惯例和社会公共道德，不可以使用任何不被允许的第三方软件登录或使用中科天机所提供的产品和服务。不可以对中科天机服务器采取任何的黑客行为，或是尝试着使中科天机服务器过度负荷，不可以利用漏洞或其它软件或程序非法修改、截取、删除、系统信息及其它用户信息，不会利用平台提供的服务进行存储、发布、传播如下信息和内容：
            </font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:108.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;mso-list:l6 level2 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:'Courier New';mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(113,128,143);font-size:10.0000pt;mso-font-kerning:0.0000pt;"
          ><span
            style="mso-list:Ignore;"
          >o<span>&nbsp;</span></span></span><!--[endif]--><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(113,128,143);font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:108.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l6 level3 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Wingdings;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">§<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">(i) 违反国家法律法规政策的任何内容（信息）；</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:108.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l6 level3 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Wingdings;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">§<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">(ii) 违反国家规定的政治宣传和/或新闻信息；</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:108.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l6 level3 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Wingdings;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">§<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">(iii) 涉及国家秘密和/或安全的信息；</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:108.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l6 level3 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Wingdings;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">§<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">(iv) 封建迷信和/或淫秽、色情、下流的信息或教唆犯罪的信息；</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:108.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l6 level3 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Wingdings;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">§<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">(v) 博彩有奖、赌博游戏；违反国家民族和宗教政策的信息；</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:108.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l6 level3 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Wingdings;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">§<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">(vi) 妨碍互联网运行安全的信息；</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:108.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l6 level3 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Wingdings;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">§<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">(vii) 侵害他人合法权益的信息和/或其他有损于社会秩序、社会治安、公共道德的信息或内容；</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:108.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l6 level3 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Wingdings;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">§<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">(viii) 包含病毒、特洛依木马、密码爆破、挖矿软件、反向代理服务等可能对中科天机算平台系统造成伤害或影响其稳定性的内容；</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:108.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l6 level3 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Wingdings;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">§<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">(ix) 您同时承诺不得为他人发布上述不符合国家规定和/或本协议条款约定的信息内容提供任何便利，包括但不限于设置URL、BANNER链接等；</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:72.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l6 level2 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:'Courier New';mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">o<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">3.2.2 不应出现任何破坏或试图破坏网络安全等的行为，包括不会利用技术或其他手段破坏或扰乱本网站及其他客户的网站；</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:72.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l6 level2 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:'Courier New';mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">o<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">3.2.3 您使用平台和平台提供的服务应符合本协议条款，不得存在如下操作：</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:108.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;mso-list:l6 level2 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:'Courier New';mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(113,128,143);font-size:10.0000pt;mso-font-kerning:0.0000pt;"
          ><span
            style="mso-list:Ignore;"
          >o<span>&nbsp;</span></span></span><!--[endif]--><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(113,128,143);font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:108.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l6 level3 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Wingdings;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">§<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">(i) 违反本用户协议及平台规则的行为；</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:108.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l6 level3 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Wingdings;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">§<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">(ii) 提供虚假注册信息；</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:108.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l6 level3 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Wingdings;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">§<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">(iii) 通过不正当手段注册或使用中科天机服务；</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:108.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l6 level3 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Wingdings;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">§<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">(iv) 为任何非法目的而使用中科天机产品和服务；</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:108.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l6 level3 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Wingdings;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">§<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">(v) 有损害其他用户的行为；</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:108.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l6 level3 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Wingdings;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">§<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">(vi) 滥用所享有的用户权利；</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:108.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l6 level3 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Wingdings;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">§<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">(vii) 在公众场合发布有关中科天机及相关服务的负面宣传；</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:108.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l6 level3 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Wingdings;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">§<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">(viii) 在未经允许下利用中科天机所提供的产品和服务在现实中盈利；</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:108.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l6 level3 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Wingdings;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">§<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">(ix) 不得恶意占用服务器资源，破坏、干扰正常服务环境；</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:108.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l6 level3 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Wingdings;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">§<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">(x) 不得利用中科天机服务进行的其他不利于中科天机的行为；</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:72.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l6 level2 lfo3;"
        >
          <!--[if !supportLists]--><span
            style="font-family:'Courier New';mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">o<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">
              如您违反上述保证，天机除有权根据相关协议条款采取封存账号，删数据、中止服务、终止服务的措施，并有权冻结或注销您的平台部分或全部功能，并按《中华人民共和国网络安全法》及其相关条例保留索赔和追究法律责任的权利
            </font>
            <font face="微软雅黑">(包括刑事责任)。</font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-bottom:3.7500pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">4隐私及个人信息的保护</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l8 level1 lfo4;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-size:10.0000pt;mso-font-kerning:0.0000pt;"
          ><span
            style="mso-list:Ignore;"
          >·<span>&nbsp;</span></span></span><!--[endif]--><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">&nbsp;&nbsp;&nbsp;&nbsp;您的信任对我们非常重要，天机深知用户信息安全的重要性，我们将按照法律法规要求，采取安全保护措施，保护您的用户信息安全可控。</font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-bottom:3.7500pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">5知识产权</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-right:7.5000pt;margin-left:36.0000pt;text-indent:-18.0000pt;
mso-pagination:widow-orphan;text-align:left;line-height:200%;
mso-list:l1 level1 lfo5;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">·<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">5.1</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">我们尊重知识产权，除非获得了您的同意，平台不会擅自使用、修改、复制、公开传播、改变、散布、发行或公开发表您拥有知识产权的成果。</font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-right:7.5000pt;margin-left:36.0000pt;text-indent:-18.0000pt;
mso-pagination:widow-orphan;text-align:left;line-height:200%;
mso-list:l1 level1 lfo5;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">·<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">5.2</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">任何组织或个人认为平台的网页内容，如转载文章等可能侵犯其合法权益的，可向天机提出书面权利通知，天机将在收到知识产权权利人合格通知后依法尽快处理。</font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-right:7.5000pt;margin-left:36.0000pt;text-indent:-18.0000pt;
mso-pagination:widow-orphan;text-align:left;line-height:200%;
mso-list:l1 level1 lfo5;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">·<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">5.3</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">除非另行说明，中科天机服务平台</font>
            <font face="微软雅黑">Logo以及相关标识、徽记、技术文档等的知识产权为中科天机及其关联公司所有。</font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-right:7.5000pt;margin-left:36.0000pt;text-indent:-18.0000pt;
mso-pagination:widow-orphan;text-align:left;line-height:200%;
mso-list:l1 level1 lfo5;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">·<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">5.4</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">您应尊重天机和第三方的知识产权和其他合法权利</font>
            <font face="微软雅黑">
              /权益，并保证在发生侵犯前述权益的违法事件时，保护天机及其雇员、股东、合作伙伴等免于因该等事件受到影响或损失，天机保留在您侵犯天机及/或其他第三方的合法权利/权益时终止向您提供服务并不退还任何款项的权利。
            </font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-right:7.5000pt;margin-left:36.0000pt;text-indent:-18.0000pt;
mso-pagination:widow-orphan;text-align:left;line-height:200%;
mso-list:l1 level1 lfo5;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">·<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">5.5</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">
              除由天机服务根据合同约定而包含在其所提供的服务中的软件或应用程序外，您在使用服务过程中所安装、运行和使用的软件或应用程序均已取得与之用途相符的合法许可或授权，而不会侵犯他人的合法权益。并依法对所安装的软件承担全部责任。用户不可以使用任何不被允许的第三方软件登录或使用天机所提供的产品和服务，用户应确认并保证对所有上传、转载或其他方式的传播内容，已经取得了所有必要的权利或授权并自行承担全部法律责任。
            </font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-right:7.5000pt;margin-left:36.0000pt;text-indent:-18.0000pt;
mso-pagination:widow-orphan;text-align:left;line-height:200%;
mso-list:l1 level1 lfo5;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">·<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">&nbsp;</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >5.6</span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">如因您违反上述保证而导致天机被第三方索赔，您应根据合法许可或授权为天机提供抗辩，并承担任何不利终审判决或经批准的庭外和解产生的费用。</font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-bottom:3.7500pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">6责任范围和责任限制</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-right:7.5000pt;margin-left:36.0000pt;text-indent:-18.0000pt;
mso-pagination:widow-orphan;text-align:left;line-height:200%;
mso-list:l3 level1 lfo6;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">·<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">6.1</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">
              您了解并同意，您应承担因您使用本服务、违反本协议条款或在您的注册用户下采取的任何行动而导致的任何第三方索赔。如果由此引起天机及其关联公司、员工、客户和合作伙伴被第三方索赔的，您应负责处理，并赔偿天机及其关联公司由此遭受的全部损失和责任。
            </font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-right:7.5000pt;margin-left:36.0000pt;text-indent:-18.0000pt;
mso-pagination:widow-orphan;text-align:left;line-height:200%;
mso-list:l3 level1 lfo6;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">·<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">6.2</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">在适用法律许可的范围内，天机不对与本协议条款有关或由本协议条款引起的任何间接的、惩罚性的、特殊的、派生的损失承担赔偿责任。</font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-right:7.5000pt;margin-left:36.0000pt;text-indent:-18.0000pt;
mso-pagination:widow-orphan;text-align:left;line-height:200%;
mso-list:l3 level1 lfo6;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">·<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">6.3</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">
              天机在此提示，您在使用服务平台期间应当遵守中华人民共和国的法律，不得危害网络安全，不得利用服务平台从事侵犯他人名誉、隐私、知识产权和其他合法权益的活动。天机不对您使用服务平台及提供的服务违法或违约行为承担任何责任。
            </font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-bottom:3.7500pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">7不可抗力和意外事件</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;text-indent:-18.0000pt;mso-pagination:widow-orphan;
text-align:left;line-height:200%;mso-list:l2 level1 lfo7;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-size:10.0000pt;mso-font-kerning:0.0000pt;"
          ><span
            style="mso-list:Ignore;"
          >·<span>&nbsp;</span></span></span><!--[endif]--><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">平台可能因为下列不可抗力或意外事件无法正常运行的，天机不承担损害赔偿责任：</font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-right:7.5000pt;margin-left:36.0000pt;text-indent:-18.0000pt;
mso-pagination:widow-orphan;text-align:left;line-height:200%;
mso-list:l2 level1 lfo7;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">·<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">7.1</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">因自然灾害、罢工、暴乱、战争、政府行为、司法行政命令等不可抗力因素；</font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-right:7.5000pt;margin-left:36.0000pt;text-indent:-18.0000pt;
mso-pagination:widow-orphan;text-align:left;line-height:200%;
mso-list:l2 level1 lfo7;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">·<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">7.2</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">因电力供应故障、通讯网络故障等公共服务因素；</font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-right:7.5000pt;margin-left:36.0000pt;text-indent:-18.0000pt;
mso-pagination:widow-orphan;text-align:left;line-height:200%;
mso-list:l2 level1 lfo7;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">·<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">7.3</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">经提前公告或通知的，平台在短时间内的系统维护。</font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-bottom:3.7500pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">8不可抗力和意外事件</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-right:7.5000pt;margin-left:36.0000pt;text-indent:-18.0000pt;
mso-pagination:widow-orphan;text-align:left;line-height:200%;
mso-list:l7 level1 lfo8;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">·<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">8.1</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">
              您理解并同意，天机可通过网页公告、电子邮件、站内信、短信、电话、系统消息以及即时通信等以上一种或多种通知方式向您发送通知，且天机可以信赖您所提供的联系信息是完整、准确且当前有效的；上述通知在发送成功后视为已送达。
            </font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-right:7.5000pt;margin-left:36.0000pt;text-indent:-18.0000pt;
mso-pagination:widow-orphan;text-align:left;line-height:200%;
mso-list:l7 level1 lfo8;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">·<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">8.2</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">除非本协议条款另有约定或天机与您另行签订的协议明确规定了通知方式，您发送给平台的通知，应当通过天机对外正式公布的通信地址、传真号码、电子邮件地址等联系信息进行送达。</font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-bottom:3.7500pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">9条款的更新和终止</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:12.0000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-right:7.5000pt;margin-left:36.0000pt;text-indent:-18.0000pt;
mso-pagination:widow-orphan;text-align:left;line-height:200%;
mso-list:l0 level1 lfo9;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">·<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">9.1</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">
              天机有权对本协议条款及相应的服务规则内容进行变更，并将网页公告、电子邮件、站内信、短信等方式予以公告或通知；若您在本协议条款内容变更后继续使用本服务的，表示您已充分阅读、理解并接受修改后的内容，也将遵循修改后的条款内容。
            </font>
          </span></b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p
          class="MsoNormal"
          style="margin-right:7.5000pt;margin-left:36.0000pt;text-indent:-18.0000pt;
mso-pagination:widow-orphan;text-align:left;line-height:200%;
mso-list:l0 level1 lfo9;"
        >
          <!--[if !supportLists]--><span
            style="font-family:Symbol;mso-fareast-font-family:微软雅黑;mso-bidi-font-family:宋体;
color:rgb(102,102,102);font-weight:bold;font-size:10.0000pt;
mso-font-kerning:0.0000pt;"
          ><span style="mso-list:Ignore;">·<span>&nbsp;</span></span></span><!--[endif]--><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">9.2</font>
          </span></b><b><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-weight:bold;
font-size:10.5000pt;mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span></b>
        </p>
        <p
          class="MsoNormal"
          style="margin-left:36.0000pt;mso-pagination:widow-orphan;text-align:left;
line-height:200%;"
        >
          <span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <font face="微软雅黑">在您的注册用户注销或经双方协商一致终止网站服务的，本协议条款终止。</font>
          </span><span
            style="mso-spacerun:'yes';font-family:微软雅黑;mso-bidi-font-family:宋体;
line-height:200%;color:rgb(102,102,102);font-size:10.5000pt;
mso-font-kerning:0.0000pt;"
          >
            <o:p />
          </span>
        </p>
        <p class="MsoNormal">
          <span
            style="mso-spacerun:'yes';font-family:等线;mso-bidi-font-family:'Times New Roman';
font-size:10.5000pt;mso-font-kerning:1.0000pt;"
          >
            <o:p>&nbsp;</o:p>
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {
    };
  },
  created() { },
  methods: {},
};
</script>
<style lang="scss" scoped>
.registerPage {
  height: 100%;
  width: 100%;
  background: #F9FAFC;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.head {
  width: 100%;
  height: 65px;
  background: #FFFFFF;
  box-shadow: 0px 2px 6px 0px rgba(208, 212, 228, 0.6);
  display: flex;
  align-items: center;
  padding-left: 25px;

  .title_box {
    font-size: 25px;
    line-height: 42px;
    font-weight: 600;
    padding-right: 10px;
    color: #C00019;
  }
}

.body {
  padding: 0 15px 0 15px;
  width: calc(100% - 90px);
  height: calc(100% - 223px);
  overflow-y: auto;
  margin-top: 20px;
  margin-left: 20px;
  background: #FFFFFF;
  box-shadow: 0px 2px 6px 0px rgba(208, 212, 228, 0.6);
  border-radius: 4px;
  display: flex;
  justify-content: center;
}
</style>
