import { render, staticRenderFns } from "./ForcastTenPopup.vue?vue&type=template&id=736b84a1&scoped=true"
import script from "./ForcastTenPopup.vue?vue&type=script&lang=js"
export * from "./ForcastTenPopup.vue?vue&type=script&lang=js"
import style0 from "./ForcastTenPopup.vue?vue&type=style&index=0&id=736b84a1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "736b84a1",
  null
  
)

export default component.exports