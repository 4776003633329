<template>
  <van-action-sheet v-model:show="popupShow" title="模式列表" class="menu-popup">
    <div class="factor-div">
      <van-grid
        :border="false"
        :column-num="3"
        class="grid-div"
        :gutter="10"
      >
        <van-grid-item
          v-for="item in menu"
          :key="item"
          class="grid-item-div"
          :class="realModeName === item ? 'active' : ''"
          @click="changeModeType(item)"
        >
          <div class="flex-col justify-between align-center">
            <div class="flex-col">{{ modifyItem(item) }}</div>
            <div class="flex-col text">{{ ratioName(item) }}</div>
          </div>
        </van-grid-item>
      </van-grid>
    </div>
  </van-action-sheet>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import { TIANJI2_DA, TIANJI2_MERGE, TIANJI2_ND, TIANJI1_DA, TIANJI1_ND, CY } from '@/config';
import { factorMapT1, mapForCn, dust_factorMap } from '@/config/MapConfigT4';
import Bus from '@/bus/index';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    modelType: {
      type: String,
      required: true,
    },
    factor: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      input: '',
      activeIndex: 0,
      types: [TIANJI2_MERGE, TIANJI2_ND, TIANJI2_DA, TIANJI1_ND, TIANJI1_DA],
      modeItem: '',
      modeSelectShow: false,
      wrapText: '',
    };
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapState('map', ['t2Mode']),
    ...mapState(['t1Mode']),
    popupShow: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit('update:show', val);
      },
    },
    menu() {
      if (this.$route.name === 'earth') {
        this.types = [TIANJI2_MERGE, TIANJI2_ND, TIANJI2_DA];
      }
      if (['max_reflectivity', 'base_reflectivity', 'rh2m'].includes(this.factor)) {
        return [TIANJI2_MERGE, TIANJI2_ND, TIANJI2_DA];
      } if (['ri_min'].includes(this.factor)) {
        return [TIANJI2_ND, TIANJI2_DA, TIANJI1_ND, TIANJI1_DA];
      } if (dust_factorMap[this.factor]) {
        return [TIANJI2_MERGE, TIANJI1_ND, TIANJI1_DA];
      } if (['precp'].includes(this.factor)) {
        return [TIANJI2_MERGE];
      }
      return this.types;
    },
    realModeName() {
      // console.log(this.t1Mode);
      const routeName = this.$route.name;
      if (routeName === 'weather') {
        return this.t1Mode;
      }
      return dust_factorMap[this.factor] ? this.t2Mode.replace(2, 1) : this.t2Mode;
    },
  },
  created() {
    console.log('this.modelType==>', this.modelType, 'this.t2Mode==>', this.t2Mode, 'this.t1Mode=>', this.t1Mode);
    const modeName = this.modelType === 't2' ? this.t2Mode : this.t1Mode;
    const mode = this.menu.filter((v) => v === modeName);
    if (mode && mode.length > 0) {
      this.modeItem = modeName;
      this.$emit('changeModeName', mode[0]);
    } else {
      this.modeItem = this.menu[0];
      this.$emit('changeModeName', this.menu[0]);
    }
  },
  mounted() {

  },
  methods: {
    ...mapMutations('map', ['setMode', 'setFactor']),
    // ...mapMutations({t1setMode:"setMode",t1setFactor:"setFactor"}),
    changeModeItem(name) {
      this.modeItem = name;
    },

    modifyItem(item) {
      return dust_factorMap[this.factor] ? item.replace(2, 1) : item;
    },

    ratioName(item) {
      if (item === TIANJI2_MERGE) {
        if (['tmp2m', 't2mz'].includes(this.factor)) {
          return '全球1公里';
        } if (['dust_conc', 'dod', 'dust_ddep', 'dust_emis'].includes(this.factor)) {
          return '全球12公里';
        } if (['pratesfc', 'pregrt', 'prerrt', 'presrt', 'preirt', 'pre_total', 'pres_all', 'prer', 'max_reflectivity', 'base_reflectivity'].includes(this.factor)) {
          return '全球2.5公里';
        }
        return '全球10公里';
      }
      if (item === TIANJI1_DA || item === TIANJI1_ND) {
        return '全球12公里';
      }
      return '全球2.5公里';
    },
    changeModeType(item) {
      const routeName = this.$route.name;
      if (routeName === 'weather_hd') this.setT2ModeType(item);
      // if (routeName === 'weather') this.setT1ModeType(item);
      if (routeName === 'earth') this.set3DModeType(item);
      this.popupShow = false;
      Bus.$emit('closeDetailShow');
    },
    setT2ModeType(item) {
      this.modeItem = item;
      this.$emit('changeModeName', item);
      sessionStorage.setItem('current-mode', item);
      this.$sensors.track('currentMode', {
        info: mapForCn[this.factor],
        mode: this.t2Mode,
        space_type: this.$route.name === 'earth' ? '三维' : '二维',
        page_type: '天机2',
      });
      this.$sensors.track('Jump_volume', {
        from_factor: mapForCn[this.factor],
        from_mode: this.t2Mode,
        from_space_type: this.$route.name === 'earth' ? '三维' : '二维',
        to_mode: item,
        to_factor: mapForCn[this.factor],
        to_space_type: this.$route.name === 'earth' ? '三维' : '二维',
      });
      console.log(item, 'itempoqjpopo ');
      if (item === TIANJI1_DA || item === TIANJI1_ND) {
        sessionStorage.setItem('tmode', 't1');
        if (dust_factorMap[this.factor]) {
          this.setMode(item);
        } else {
          const factor = this.factor === 'tmp2m' ? 't2mz' : this.factor;
          this.setFactor(factor);
          this.setMode(item);
          // if (factorMapT1[factor]) {
          //   this.t1setFactor(factor);
          // }
          // this.$store.commit("setMode", item);
          // this.$router.push({ name: "weather" });
        }
      } else {
        sessionStorage.setItem('tmode', 't2');
        this.setMode(item);
      }
    },
    // setT1ModeType(item) {
    //   this.modeItem = item;
    //   this.$emit("changeModeName", item);
    //   if (item === CY) return;
    //   const from_mode = sessionStorage.getItem("tmode") ? this.t1Mode : this.t2Mode
    //   if (item !== TIANJI1_DA && item !== TIANJI1_ND) {
    //     sessionStorage.setItem("tmode", "t2");
    //     if (item === TIANJI2_MERGE && this.factor === "t2mz") {
    //       this.$sensors.track("currentMode", {
    //         info: mapForCn["tmp2m"],
    //         mode: item,
    //         space_type: this.$route.name === "earth" ? '三维' : '二维',
    //         page_type: "天机2",
    //       });
    //       this.$sensors.track("Jump_volume", {
    //         from_factor: mapForCn["tmp2m"],
    //         from_mode: from_mode,
    //         from_space_type: this.$route.name === "earth" ? '三维' : '二维',
    //         to_mode: item,
    //         to_factor: mapForCn["tmp2m"],
    //         to_space_type: this.$route.name === "earth" ? '三维' : '二维',
    //       });
    //       this.setFactor("tmp2m");
    //     } else {
    //       this.$sensors.track("currentMode", {
    //         info: mapForCn[this.factor],
    //         mode: item,
    //         space_type: this.$route.name === "earth" ? '三维' : '二维',
    //         page_type: "天机2",
    //       });
    //       this.$sensors.track("Jump_volume", {
    //         from_factor: mapForCn[this.factor],
    //         from_mode: from_mode,
    //         from_space_type: this.$route.name === "earth" ? '三维' : '二维',
    //         to_mode: item,
    //         to_factor: mapForCn[this.factor],
    //         to_space_type: this.$route.name === "earth" ? '三维' : '二维',
    //       });
    //       this.setFactor(this.factor);
    //     }
    //     this.$store.commit("map/setMode", item);
    //     this.$router.push({ name: "weather_hd" });
    //   } else {
    //     this.t1setMode(item);
    //     this.$sensors.track("currentMode", {
    //       info: mapForCn[this.factor],
    //       mode: this.t1Mode,
    //       space_type: this.$route.name === "earth" ? '三维' : '二维',
    //       page_type: "天机1",
    //     });
    //     this.$sensors.track("Jump_volume", {
    //       from_factor: mapForCn[this.factor],
    //       from_mode: from_mode,
    //       from_space_type: this.$route.name === "earth" ? '三维' : '二维',
    //       to_mode: this.t1Mode,
    //       to_factor: mapForCn[this.factor],
    //       to_space_type: this.$route.name === "earth" ? '三维' : '二维',
    //     });
    //   }
    // },
    set3DModeType(item) {
      this.modeItem = item;
      this.$emit('changeModeName', item);
      this.$sensors.track('currentMode', {
        info: mapForCn[this.factor],
        space_type: this.$route.name === 'earth' ? '三维' : '二维',
        mode: this.t2Mode,
        page_type: '天机2',
      });
      this.$sensors.track('Jump_volume', {
        from_factor: mapForCn[this.factor],
        from_mode: this.t2Mode,
        from_space_type: this.$route.name === 'earth' ? '三维' : '二维',
        to_mode: item,
        to_factor: mapForCn[this.factor],
        to_space_type: this.$route.name === 'earth' ? '三维' : '二维',
      });
      if (['dust_conc', 'dod', 'dust_ddep', 'dust_emis'].includes(this.factor)) {
        sessionStorage.setItem('tmode', 't2');
        item = item.replaceAll(1, 2);
        this.setMode(item);
      } else if (item === TIANJI1_DA || item === TIANJI1_ND) {
        sessionStorage.setItem('tmode', 't1');
        const factor = this.factor === 'tmp2m' ? 't2mz' : this.factor;
        if (factorMapT1[factor]) {
          this.t1setFactor(factor);
        }
        this.$store.commit('setMode', item);
        this.$router.push({ name: 'weather' });
      } else {
        sessionStorage.setItem('tmode', 't2');
        this.setMode(item);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mode-div {
  font-size: 14px;
  padding: 5px 15px;
  bottom: 150px;
  color: #FFFFFF;
  border-radius: 30px;
  display: flex;
  position: absolute;
  right: 8px;
  z-index: 999;
  background: rgba(50, 50, 50, 0.5);
  backdrop-filter: blur(1px);
  box-shadow: 0 0 0 0;
  width: auto;
  height: 20px;
  align-items: center;
  justify-content: center;

  .mode-text {
    display: flex;
    align-items: flex-end;
    margin-right: 5px;

    .text {
      font-size: 11px;
    }
  }
}
.menu-popup {
  background: $bg-popup;
  color: #FFFFFF;
  height: 30%;
  z-index: 99999;
}

.factor-div {
  border-radius: 8px;
  margin: 10px;
  background-color: rgba(66, 66, 66, 0.75);

  .grid-div {
    padding: 15px 5px;
  }

  .grid-item-div {
    ::v-deep {
      .van-grid-item__content {
        border-radius: 10px;
      }
    }

  }

  .text {
    font-size: 11px;
    padding-top: 10px;
  }

  .active {
    ::v-deep {
      .van-grid-item__content {
        background-color: rgba(87, 127, 255, 1);
      }
    }
  }
}

// .mode-div {
//   display: flex;
//   align-items: flex-end;
//   margin-right: 5px;
//   background: $bg-popup;
//   backdrop-filter: blur(1px);
//   width: auto;
//   height: 20px;
//   padding: 5px 15px;
//   border-radius: 30px;
// }

::v-deep {
  .van-grid-item__content {
    background-color: $bg-popup;
    padding: 10px 4px;
  }
  .van-grid-item {

  }
}
</style>
