<template>
  <div class="registerPage">
    <div class="head">
      <svg-icon
        class="input-svg"
        style="position: relative; height: 30px; width: 48px; top: 0px; left: -9px"
        :icon-class="'titlesRed'"
      />
      <div class="title_box">
        <span>
          {{ "中科天机" }}
        </span>
      </div>
    </div>
    <div class="body">
      <div v-if="isShowBody" class="info-form">
        <div class="title">用户注册</div>
        <el-form
          ref="form"
          :model="form"
          :rules="rules"
          label-width="100px"
          class="form"
        >
          <el-form-item label="用户名" prop="username">
            <el-input v-model="form.username" placeholder="请输入用户名" />
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <el-input
              v-model="form.password"
              type="password"
              show-password
              placeholder="请输入密码"
            />
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmPassword">
            <el-input
              v-model="form.confirmPassword"
              type="password"
              show-password
              placeholder="请确认密码"
            />
          </el-form-item>
          <el-form-item label="所属行业" prop="industry">
            <el-select v-model="form.industry" placeholder="请选择所属行业">
              <el-option
                v-for="(item, index) in list"
                :label="item.name"
                :value="item.professionId"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="手机号" class="phoneNumber" prop="phone">
            <div class="country">+86</div>
            <el-input v-model="form.phone" placeholder="请输入手机号" />
          </el-form-item>
          <el-form-item label="验证码" class="captchas-pid-phone" prop="phoneVerification">
            <el-input v-model="form.phoneVerification" placeholder="请输入验证码" />
            <div
              class="getCaptch"
              :class="{ disabled: countDown > 0 }"
              @click="sendVerificationCode"
            >
              {{ countDown > 0 ? `重新发送(${countDown}s)` : "获取验证码" }}
            </div>
          </el-form-item>
          <el-form-item class="agreeSome" prop="agreement">
            <el-checkbox v-model="form.agreement">我已阅读并接受</el-checkbox><span
              style="cursor: pointer; font-size: 14px; color: #4e7bf1"
              @click="jumpAgreement"
            >《中科天机服务平台用户协议》</span>
          </el-form-item>

          <el-form-item class="registerBtn" style="margin-top: 17px">
            <el-button type="primary" @click="submitForm">注册</el-button>
          </el-form-item>
          <div class="rule">
            <div>· 6~30位字符 <svg-icon :icon-class="'pathImg'" /></div>
            <div>
              · 只能包含大小写字母、数字以及标点符号（除空格） <svg-icon :icon-class="'pathImg'" />
            </div>
            <div style="color: red">· 大写字母、小写字母、数字和标点符号至少包含2种</div>
          </div>
        </el-form>
      </div>
      <div v-else class="info-form" style="position: absolute; top: 39%">
        <div style="display: flex; font-size: 31px; font-weight: 700">
          <svg-icon :icon-class="'pathImg'" style="position: relative; top: 3px; left: -13px" />
          <div>注册成功</div>
          <div
            style="
              border-bottom: 1px solid #4871ef;
              color: #4871ef;
              margin-left: 5px;
              cursor: pointer;
            "
            @click="back"
          >
            返回登录
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Message } from 'element-ui';
import { verifyUserName, regist, getList, getRegistCode } from '@/api/mannger';

export default {
  components: {},
  data() {
    return {
      form: {
        username: '',
        password: '',
        confirmPassword: '',
        industry: '',
        phone: '',
        phoneVerification: '',
        agreement: false,
      },
      list: [],
      isShowBody: true,
      rules: {
        username: [
          { required: true, message: '请输入用户名', trigger: 'blur' },
          {
            pattern: /^(?=.*[a-zA-Z])[a-zA-Z0-9]{6,20}$/,
            message: '输入格式不符合要求，必须包含字母且不允许输入特殊字符，长度在6~20',
            trigger: 'blur',
          },
          { validator: this.checkName, trigger: 'blur' },
        ],
        password: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          {
            pattern:
              /^(?:(?=.*[a-zA-Z])(?=.*[\d])|(?=.*[a-zA-Z])(?=.*[^\w\s])|(?=.*[\d])(?=.*[^\w\s]))[\w\W]{6,30}$/,
            message: '密码格式不符合要求',
            trigger: 'blur',
          },
        ],
        confirmPassword: [
          { required: true, message: '请再次输入密码', trigger: 'blur' },
          { validator: this.checkPassword, trigger: 'blur' },
        ],
        industry: [{ required: true, message: '请选择所属行业', trigger: 'change' }],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3456789]\d{9}$/, message: '手机号格式错误', trigger: 'blur' },
        ],
        phoneVerification: [{ required: true, message: '请输入手机号验证码', trigger: 'blur' }],
        agreement: [
          { type: 'boolean', required: true, message: '请勾选同意协议', trigger: 'change' },
        ],
      },
      countDown: 0,
    };
  },
  mounted() {
    getList().then((resp) => {
      this.list = resp;
      console.log('行业列表===>', this.list);
    });
  },
  created() {},
  methods: {
    back() {
      this.$router.push({ path: '/', query: { isLogin: true } });
    },
    checkName(rule, value, callback) {
      if (!value) return;
      verifyUserName({ username: value }).then((resp) => {
        if (resp) {
          callback(new Error('用户名已存在'));
        } else {
          callback();
        }
        return resp;
      });
    },
    checkPassword(rule, value, callback) {
      if (value !== this.form.password) {
        callback(new Error('两次输入密码不一致'));
      } else {
        callback();
      }
    },
    jumpAgreement() {
      const routeData = this.$router.resolve({ path: '/agreement' });
      window.open(routeData.href, '_blank');
    },
    submitForm() {
      this.$refs.form.validate((valid) => {});
      const phone = /^1[3456789]\d{9}$/;
      const userName = /^(?=.*[a-zA-Z])[a-zA-Z0-9]{6,20}$/;
      const passwordRegex = /^(?:(?=.*[a-zA-Z])(?=.*[\d])|(?=.*[a-zA-Z])(?=.*[^\w\s])|(?=.*[\d])(?=.*[^\w\s]))[\w\W]{6,30}$/;
      const verifyName = this.checkName('', this.form.username, () => {});

      const flag = passwordRegex.test(this.form.password)
        && this.form.password === this.form.confirmPassword
        && userName.test(this.form.username)
        && phone.test(this.form.phone)
        && !verifyName;
      if (
        !this.form.username
        || !this.form.password
        || !this.form.confirmPassword
        || !this.form.phone
        || !this.form.phoneVerification
        || !this.form.industry
        || !flag
      ) return;
      if (!this.form.agreement) {
        Message({
          type: 'error',
          message: '请勾选用户协议！',
        });
        return;
      }
      const param = {
        registType: 'password',
        user: {
          username: this.form.username,
          password: this.form.password,
          confirmPassword: this.form.confirmPassword,
          professionId: this.form.industry,
          mobile: this.form.phone,
          smsCode: this.form.phoneVerification,
        },
      };
      regist(param).then((resp) => {
        this.isShowBody = !this.isShowBody;
        console.log('注册返回===>', resp);
      });
    },
    sendVerificationCode() {
      const phone = /^1[3456789]\d{9}$/;
      if (!phone.test(this.form.phone)) return;
      if (this.countDown === 0) {
        this.countDown = 60;
        getRegistCode({ mobile: this.form.phone }).then((resp) => {
          console.log('获取验证码', resp);
        });

        const timer = setInterval(() => {
          if (this.countDown > 0) {
            this.countDown--;
          } else {
            clearInterval(timer);
          }
        }, 1000);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.registerPage {
  height: 100%;
  width: 100%;
  background: #f9fafc;

  .phoneNumber {
    .country {
      width: 80px;
      font-size: 14px;
      color: #3b4048;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      height: 43px;
      border-radius: 4px 0px 0px 4px;
      border-right: 1px solid #e7e7e7;
      z-index: 9;
    }

    ::v-deep {
      input {
        padding-left: 100px !important;
      }
    }
  }

  .agreeSome {
    position: relative;
    top: -16px;
    margin-bottom: 0;

    ::v-deep {
      .el-form-item__content {
        font-size: 12px;
      }
    }
  }

  .registerBtn {
    ::v-deep {
      button {
        width: 300px;
      }
    }
  }

  ::v-deep {
    .el-form-item__label {
      position: relative;
      left: 11px;
    }

    .el-input {
      input {
        width: 400px;
        height: 45px;
        padding-left: 12px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #e7e7e7;
      }
    }

    .el-form-item__label {
      text-align: left;
    }
  }
}

.head {
  width: 100%;
  height: 65px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(208, 212, 228, 0.6);
  display: flex;
  align-items: center;
  padding-left: 25px;

  .title_box {
    font-size: 25px;
    line-height: 42px;
    font-weight: 600;
    padding-right: 10px;
    color: #c00019;
  }
}

.body {
  width: calc(100% - 40px);
  height: calc(100% - 105px);
  overflow-y: auto;
  margin-top: 20px;
  margin-left: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(208, 212, 228, 0.6);
  border-radius: 4px;
  display: flex;
  justify-content: center;

  .rule {
    position: absolute;
    top: 181px;
    right: -325px;
    color: #a3a3a3;
    font-size: 12px;
  }

  .disabled {
    color: #cccccc !important;
  }

  .getCaptch {
    cursor: pointer;
    position: absolute;
    width: 89px;
    height: 20px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-left: 1px solid #e7e7e7;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    padding-left: 10px;
    white-space: nowrap; /* 强制一行显示 */
    overflow: hidden; /* 隐藏超出部分 */
    text-overflow: ellipsis; /* 超出显示省略号 */
  }

  .text {
    position: absolute;
    width: 168px;
    height: 12px;
    font-size: 12px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #3b4048;
    line-height: 12px;
    bottom: -32px;
  }

  .captchas-pid-phone {
    ::v-deep {
      .el-form-item__content {
        display: flex;
      }

      input {
        padding-right: 107px;
        padding-left: 20px;
      }
    }
  }

  .info-form {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 476px;
    padding-top: 45px;

    .title {
      width: 96px;
      height: 24px;
      margin-bottom: 45px;
      font-size: 24px;
      font-family: PingFangSC, PingFang SC;
      font-weight: 600;
      color: #3b4048;
      line-height: 24px;
    }
  }
}
</style>
