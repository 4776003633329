<template>
  <div>
    <div
      class="popover-wrap"
      :class="{
        mobile: isMobile,
        satelite: satelite,
        satellite: is3D,
      }"
    >
      <!-- <el-popover placement="left" width="100" trigger="click" popper-class="layer-popover">
        <div class="layer-box">
          <div v-for="item in menus" v-show="activeText !== item.text" :key="item.text" class="item" @click="menuClick(item)">
            <svg-icon :icon-class="item.icon" style="margin-right: 10px" />
            {{ item.text }}
          </div>
        </div> -->
      <!-- <div
          slot="reference"
          class="info-wrap setting"
          style="border-radius: 6px 6px 0 0"
          :style="$route.name !== 'satellite' && $route.name !== 'earth' ? '' : 'background: rgba(66, 66, 66, 0.55);box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.25);'"
          :class="{ earth: is3D, satellite: is3D }"
          @click="menuClick(activeText)"
        >
          <i>
            <svg-icon icon-class="2D" />
          </i>
          <template v-for="text in formatText(activeText)">
            <span :key="text">{{ '二维' }}</span>
          </template>
        </div> -->
      <!-- </el-popover> -->

      <!-- 影像 -->
      <el-popover
        placement="left"
        width="181"
        trigger="click"
        popper-class="layer-popover"
      >
        <div class="layer-box shadow">
          <div
            v-for="item in map_type"
            :key="item.name"
            class="item"
            :class="{
              active: getLayerType === item.name,
            }"
            @click="mapLayerChange(item.name)"
          >
            <img :src="require('@/assets/images/' + item.icon + '.png')" alt="">
            <div>{{ item.text }}</div>
          </div>
        </div>
        <div
          v-show="modelType !== 'satellite' && modelType !== 'earth'"
          slot="reference"
          class="info-wrap setting"
          :style="$route.name !== 'satellite' && $route.name !== 'earth' ? '' : 'background: rgba(66, 66, 66, 0.55);box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.25);'"
          style="height: 55px; border-radius: 6px 6px 0 0"
          :class="{ earth: is3D }"
        >
          <i>
            <svg-icon :icon-class="getLayerType === 'graph' ? 'control-shadow' : 'map'" />
          </i>
          <span>{{ activeMapText }}</span>
        </div>
      </el-popover>

      <!-- 图层 -->
      <el-popover
        placement="left"
        width="100"
        trigger="click"
        popper-class="layer-popover"
      >
        <div class="layer-box">
          <div v-for="item in getLayerData" :key="item.text" class="item">
            <el-checkbox
              v-model="item.checked"
              :style="{
                width: isMobile ? '30%' : '25%',
              }"
              :label="item.text"
              @change="(e) => layerChange(e, item.name)"
            >
              {{ item.text }}
            </el-checkbox>
          </div>
        </div>
        <div
          v-show="modelType !== 'satellite' && modelType !== 'earth'"
          slot="reference"
          class="info-wrap setting"
          :style="$route.name !== 'satellite' && $route.name !== 'earth' ? '' : 'background: rgba(66, 66, 66, 0.55);box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.25);'"
          style="border-radius: 0 0 6px 6px; height: 55px"
          :class="{ earth: is3D }"
        >
          <i>
            <svg-icon icon-class="layer1" />
          </i>
          <span>图层</span>
        </div>
      </el-popover>

      <!-- 更多 -->
      <!-- <el-popover placement="left" width="100" trigger="click" popper-class="layer-popover"> -->
      <!-- <div class="layer-box">
          <div v-for="item in moreOpts" :key="item.text" class="item" @click="clickMore(item.name)">
            <svg-icon :icon-class="item.icon" style="margin-right: 10px" />
            {{ item.text }}
          </div>
        </div> -->
      <div
        slot="reference"
        :style="$route.name !== 'satellite' && $route.name !== 'earth' ? '' : 'background: rgba(66, 66, 66, 0.55);box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.25);'"
        class="info-wrap model"
        :class="{ earth: is3D }"
        @click="feedBack()"
      >
        <i>
          <svg-icon icon-class="consult" />
        </i>
        <span>反馈</span>
      </div>
      <!-- </el-popover> -->
    </div>

    <feed-card v-if="showCard" @close="showCard = false" />
    <v-down-load-select ref="downselect" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { getDataByFactor } from '@/api/weather_hd';
import { TIANJI2_MERGE, TIANJI2_DA, TIANJI2_ND } from '@/config';
import FeedCard from '@/components/Home/FeedCard.vue';
import VDownLoadSelect from '@/components/Map/DownLoadSelect.vue';

export default {
  components: {
    VDownLoadSelect,
    FeedCard,
  },
  props: {
    satelite: {
      type: Boolean,
      default: false,
    },
    modelType: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      showCard: false,
      menu: [
        {
          text: '二维',
          name: 'weather',
          icon: '2DItem',
        },
        // {
        //   text: '天机2',
        //   name: 'weather_hd',
        //   icon: 't2',
        // },
        // {
        //   text: '明日卫星',
        //   name: 'satellite',
        //   icon: 'satellite',
        // },
        {
          text: '三维',
          name: 'earth',
          icon: '3d-earth',
        },
        // {
        //   text: "沙尘",
        //   name: "dust",
        //   icon: "dust",
        // },
        // {
        //   text: '台风',
        //   name: 'typhoon',
        //   icon: 'typhoon',
        // },
      ],
      map_type: [

        // {
        //   text: '道路',
        //   name: 'shape',
        //   icon: 'shape'
        // },
        {
          text: '地形',
          name: 'graph',
          icon: 'terrain',
        },
        {
          text: '影像',
          name: 'shadow',
          icon: 'shadow',
        },
        {
          text: '简洁',
          name: 'default',
          icon: 'default',
        },
      ],
      layer_type: [
        // {
        //   text: "粒子动画",
        //   name: "windy",
        //   checked: localStorage.getItem("windy") === "normal",
        // },
        // {
        //   text: '等压线',
        //   name: 'shape',
        //   icon: 'shape'
        // },
        {
          text: '城市',
          name: 'city',
          checked: localStorage.getItem('city-layer') === 'normal',
        },

        // {
        //   text: "等压线",
        //   name: "isoline",
        //   checked: localStorage.getItem("isoline-slp") === "normal",
        // },

        // {
        //   text: "压缩",
        //   name: "compress",
        //   checked: localStorage.getItem("compress-layer") === "normal",
        // },
      ],
      moreOpts: [
        {
          text: '了解天机',
          name: 'know',
          icon: 'know',
        },
        {
          text: '客户端',
          name: 'pc',
          icon: 'pc',
        },
        // {
        //   text: 'APP',
        //   name: 'app',
        //   icon: 'app',
        // },
      ],
    };
  },
  computed: {
    ...mapState('map', ['t2Mode', 'layerType', 'index', 'aliveData', 'windData', 'factor']),
    ...mapState('typhoon', []),
    ...mapState(['historyPath', 't1LayerType']),
    ...mapState('map', ['windy', 'city', 'isobar', 'hd']),
    activeText() {
      return this.$route.name === 'earth' ? '二维' : '三维';
    },
    activeIcon() {
      return this.$route.name === 'earth' ? '2D' : '3d-earth';
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    menus() {
      console.log('this.$route.name, this.factor', this.$route.name, this.factor);
      return this.menu.filter((item) => item.name !== this.$route.name);
    },
    read() {
      return localStorage.getItem(this.keywords) === 'true';
    },
    activeMapText() {
      if (this.modelType === 't1') {
        return this.map_type.find((item) => item.name === this.t1LayerType)?.text;
      }
      return this.map_type.find((item) => item.name === this.layerType)?.text;
    },
    is3D() {
      if (this.modelType === 'satellite' || this.modelType === 'earth') {
        return true;
      }
      return false;
    },
    getLayerData() {
      if (this.modelType === 't1') {
        return this.layer_type.slice(0, 2);
      }
      return this.layer_type;
    },
    getLayerType() {
      if (this.modelType === 't1') {
        return this.t1LayerType;
      }
      return this.layerType;
    },
  },

  watch: {
    windy(value) {
      this.layer_type[0].checked = value === 'normal';
      localStorage.setItem('windy', value);
    },
    // 监听等压线控制变量变化
    isobar(value) {
      this.layer_type[2].checked = value === 'normal';
      localStorage.setItem('isoline-slp', value);
    },
  },

  beforeCreate() {
    localStorage.setItem('windy', localStorage.getItem('windy') || 'none');
    localStorage.setItem('city-layer', localStorage.getItem('city-layer') || 'normal');
    localStorage.setItem('compress-layer', localStorage.getItem('compress-layer') || 'normal');
    localStorage.setItem('isoline-slp', localStorage.getItem('isoline-slp') || 'none');
  },

  mounted() {
    setTimeout(() => {
      this.visible = true;
    }, 1000);
  },
  methods: {
    ...mapMutations('map', ['setMode',
      'setAlive',
      'setFactor', 'setLayerType', 'setWindy', 'setCity', 'setHD', 'setWindData', 'setIsobar']),
    ...mapMutations('typhoon', []),
    ...mapMutations(['setHistory', 'setStatus', 'setCurrentData', 'setT1Windy', 'setT1LayerType', 'setT1City']),
    formatText(text) {
      if (text.length > 2) {
        if (text === '明日卫星') {
          return [text.substring(2, text.length)];
        }
        return [text];
      }
      return [text];
    },
    mapLayerChange(name) {
      // if (this.modelType === 't1') {
      this.setLayerType(name);
      this.$sensors.track('CurrentUnderlay', {
        under_layer_name: name,
        space_type: this.$route.name === 'earth' ? '三维' : '二维',
        page_type: '台风',
      });
      // } else {
      //   this.setLayerType(name);
      // }
    },
    async layerChange(e, name) {
      this.$sensors.track('CurrentLayer', {
        layer_name: name,
        space_type: this.$route.name === 'earth' ? '三维' : '二维',
        page_type: '台风',
      });
      if (name === 'windy') {
        if (this.modelType === 't1') {
          this.setT1Windy(e ? 'normal' : 'none');
        } else {
          if (e) {
            const factorTempKey = this.factor === 'w100m ' ? 'w100m' : 'wgrd10m';
            try {
              // 数据不能只差c10km
              const data = await getDataByFactor(factorTempKey, this.currentMode ?? 't2');
              this.setWindData(data);
            } catch (err) {
              console.error(err);
            }
          }
          this.setWindy(e ? 'normal' : 'none');
          localStorage.setItem('windy', e ? 'normal' : 'none');
        }
      } else if (name === 'city') {
        if (this.modelType === 't1') {
          this.setT1City(e ? 'normal' : 'none');
        } else {
          this.setCity(e ? 'normal' : 'none');
        }
        localStorage.setItem('city-layer', e ? 'normal' : 'none');
      } else if (name === 'compress') {
        // localStorage.setItem("compress-layer", e ? "normal" : "none");
        this.setHD(e ? 'normal' : 'none');
      } else if (name === 'isoline') {
        // 显示等压线
        this.setIsobar(e ? 'normal' : 'none');
        localStorage.setItem('isoline-slp', e ? 'normal' : 'none');
      }
    },
    menuClick(item) {
      return;
      if (item === '二维') {
        if (!this.historyPath) {
          // 如果是首次进入页面，进行路由跳转
          this.$router.push({ name: 'weather_hd' });
        } else {
          this.$router.push({ name: this.historyPath });
        }
      } else {
        this.setHistory(this.$route.name);
        this.$sensors.track('CurrentPage', {
          page_type: item,
        });
        this.$router.push({ name: 'earth' });
      }
    },
    feedBack() {
      this.showCard = true;
      this.$sensors.track('tj_apply', {
        btn_info: '反馈',
      });
    },
    clickMore(name) {
      if (name === 'know') {
        window.open('https://info.tjweather.com', '_blank');
      } else if (name === 'pc') {
        this.$refs.downselect.changeDvisible(true);
      } else if (name === 'app') {
        this.$refs.downselect.changeAppVisible(true);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.popover-wrap {
  position: fixed;
  z-index: 999;
  right: 22px;
  top: 215px;
  cursor: pointer;

  &.earth {
    background: rgba(66, 66, 66, 0.55);
    backdrop-filter: blur(1px);
    box-shadow: 0 0 0 0;
  }

  &.satelite {
    bottom: 44px;
  }

  &.mobile {
    bottom: 90px;
    left: 10px;
    height: 231px;
    width: 45px;

    &.earths {
      bottom: 160px;
      left: -20px;
    }

    &.satelite {
      bottom: 88px;
    }
  }

  .info-wrap {
    width: 45px;
    height: 50px;
    box-sizing: border-box;
    backdrop-filter: blur(1px);
    margin-bottom: 1px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgba(0, 0, 0, 0.45);
    box-shadow: 0 1px 0 0 rgba(255, 255, 255, 0.25);
    align-items: center;
    color: #fff;
    padding: 10px 0 2px;

    i {
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.model {
      margin-top: 15px;
      border-radius: 6px 6px 6px 6px;
    }

    &.layer {
      margin-bottom: 10px;
      border-radius: 0 0 6px 6px;
    }

    &.earth {
      background: rgba(66, 66, 66, 0.55);
      backdrop-filter: blur(1px);
      box-shadow: 0 0 0 0;
    }

    &.moreText {
      height: 65px;
      padding: 5px;
    }

    &.satellite {
      border-radius: 6px !important;
    }

    span {
      font-size: 12px;
      line-height: 24px;
    }
  }
}

.satellite {
  top: 85px;
}

.content {
  font-size: 10px;
}

.icon {
  cursor: pointer;
  position: absolute;
  top: 6px;
  right: 6px;
}

.layer-box {
  .item {
    width: 100%;
    box-sizing: border-box;
    height: 36px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    color: #464646;
    cursor: pointer;

    &.active {
      color: $theme-color;
    }

    &:hover {
      background-color: #f5f5f5;
      color: $theme-color;
    }
  }

  &.shadow {
    display: flex;

    .item {
      flex-direction: column;
      width: 60px;
      height: auto;
      padding: 0 10px;
      box-sizing: border-box;
      cursor: pointer;

      &.active {
        img {
          border: 2px solid $theme-color;
        }
      }

      img {
        width: 40px;
        height: 40px;
        border-radius: 8px;
        border: 2px solid #fff;
        box-sizing: border-box;
      }

      > div {
        line-height: 26px;
        height: 20px;
      }

      &:hover {
        background-color: #fff;
        color: $theme-color;

        img {
          border: 2px solid $theme-color;
        }
      }
    }
  }
}

::v-deep {
  .el-popover {
    padding: 0;
  }
}
</style>
