<template>
  <div id="app" :class="{ mobile: isMobile }">
    <div
      v-if="showBtn && !isMobile"
      class="applyBtn local"
      :style="isMobile ? 'right: 277px;top: 54px;' : ''"
      @click.stop="openApplyPage()"
    >
      <svg-icon style="margin-right: 3px" icon-class="apply" />
      数据申请
    </div>
    <div
      v-if="showBtn && !isMobile"
      class="applyBtn"
      :style="isMobile ? 'top: 54px;right: 165px;' : ''"
      @click.stop="openNewPage"
    >
      <svg-icon icon-class="home" style="margin-right: 3px" />
      天机官网
    </div>
    <!-- <div
      class="login-btn"
      v-if="showBtn"
      :style="isMobile ? 'top: 54px;right: 84px;' : ''"
      @click="login"
    >
      <span v-if="!haveToken"> <svg-icon style="position: relative;top: 1.5px;" icon-class="loginflag" /> 登录</span>
      <el-dropdown v-else>
        <div class="user">
          <svg-icon icon-class="user" />
          <span :title="username">{{ username }}</span>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item @click.native="logoutUser"> 退出登录 </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div> -->
    <apply-card v-if="isShowApply" @close="isShowApply = false" />
    <Login v-if="isShowLogin" class="login" @controlShow="showLogin" />
    <router-view />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import Login from './views/login_page/index';
import { getUserInfo, logout, getCheckLogin } from '@/api/mannger';
import ApplyCard from './components/Home/ApplyCard.vue';

export default {
  components: {
    ApplyCard,
    Login,
  },
  data() {
    return {
      isShowApply: false,
    };
  },
  created() {
    const pathname = window.location.pathname;
    if (pathname.includes('/internal')) {
      sessionStorage.setItem('userType', '内部');
    } else {
      sessionStorage.setItem('userType', '外部');
      if (this.$route.path === '/dust') {
        localStorage.setItem('factor', 'dust_conc');
        this.$router.replace('/');
        window.location.reload();
      }
    }
    // const isAutoLogin = localStorage.getItem("isAutoLogin") === "true";
    // if (isAutoLogin && localStorage.getItem("myToken")) {
    //   getCheckLogin()
    //     .then((resp) => {
    //       this.setToken(localStorage.getItem("myToken"));
    //       getUserInfo().then((resp2) => {
    //         this.setUserInfo(resp2);
    //       });
    //     })
    //     .catch((resp) => {
    //       // if (resp.code && resp.code == '10004') {
    //       localStorage.setItem("isAutoLogin", false);
    //       localStorage.setItem("myToken", "");
    //       this.setToken("");
    //       this.setLogin(false);
    //       // }
    //     });
    // } else if (sessionStorage.getItem("myToken")) {
    //   getCheckLogin()
    //     .then((resp) => {
    //       this.setToken(sessionStorage.getItem("myToken"));
    //       getUserInfo().then((resp2) => {
    //         this.setUserInfo(resp2);
    //       });
    //     })
    //     .catch((resp) => {
    //       // if (resp.code && resp.code == '10004') {
    //       sessionStorage.setItem("myToken", "");
    //       this.setToken("");
    //       this.setLogin(false);
    //       // }
    //     });
    // }
  },
  mounted() {
    setTimeout(() => {
      this.setShowInfo(true);
    }, 1000);
  },
  computed: {
    ...mapState(['userInfo', 'isLogin', 'myToken', 'isMobile']),
    isShowLogin() {
      return this.isLogin;
    },
    showBtn() {
      const name = this.$route.name;
      return (
        name !== 'register'
        && name !== 'findPassword'
        && name !== 'agreement'
        && name !== 'userInfoTianji'
        && name !== 'banner'
      );
    },
    haveToken() {
      return this.myToken;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },
    routerName() {
      return this.$route.name;
    },
    username() {
      console.log('this.userInfo', this.userInfo);
      return this.userInfo ? this.userInfo.username : '';
    },
  },
  methods: {
    ...mapMutations(['setUserInfo', 'setShowInfo', 'setToken', 'setLogin']),
    showLogin(flag) {
      this.setLogin(flag);
    },
    login() {
      this.$sensors.track('tj_apply', {
        btn_info: '登录',
      });
      if (!this.myToken) {
        this.setLogin(true);
      }
      // else {
      //   let routeData = this.$router.resolve({ path: '/userInfoTianji'});
      //   window.open(routeData.href, '_blank');
      // }
    },
    logoutUser() {
      logout().then((resp) => {
        console.log('111111111111111111111111');
        localStorage.setItem('isAutoLogin', false);
        localStorage.setItem('myToken', '');
        sessionStorage.setItem('myToken', '');
        this.setToken('');
        this.setLogin(false);
      });
    },
    applyBtn() {
      this.isShowApply = !this.isShowApply;
      this.$sensors.track('tj_apply', {
        btn_info: '申请API',
      });
    },

    openApplyPage() {
      window.open('https://www.tjweather.com/info/Apply', '_blank');
      this.$sensors.track('tj_apply', {
        btn_info: '申请API',
      });
    },

    openNewPage() {
      window.open('https://www.tjweather.com/info', '_blank');
      this.$sensors.track('tj_apply', {
        btn_info: '访问官网',
      });
    },
  },
};
</script>

<style lang="scss">
html,
body,
#app {
  width: 100%;
  height: 100%;
  user-select: none;
  overflow: hidden;
  .other_style {
    background-color: rgba(66, 66, 66, 0.55) !important;
  }
  .login-btn {
    .user {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      svg {
        margin-left: 5px;
        font-size: 14px;
      }
      span {
        font-size: 11px;
        max-width: 43px;
        margin-left: 5px;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #ffffff;
        overflow: hidden;
      }
    }
    cursor: pointer;
    right: 99px;
    top: 18px;
    width: 75px;
    height: 32px;
    position: fixed;
    z-index: 999;
    font-weight: 700;
    font-size: 14px;
    text-align: center;
    line-height: 32px;
    background: #027aff;
    color: #ffffff;
    border-radius: 4px;
  }
  .applyBtn {
    position: fixed;
    right: 97px;
    top: 18px;
    z-index: 999;
    width: 108px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: #027aff;
    color: #ffffff;
    font-weight: 500;
    font-size: 14px;
    border-radius: 4px;
  }
  .local {
    right: 235px;
  }
}
</style>
