<template>
  <div>
    <div class="title_text flex-row">
      <span class="word3">气象产品</span>
    </div>
    <div class="mod3 flex-col">
      <van-grid :border="false" :column-num="2" :gutter="10">
        <van-grid-item v-for="(item, index) in getProductType" :key="'product' + index">
          <div class="flex-col justify-between align-center">
            <div
              class="flex-col"
              :class="{ change_factor_item_class: modelType === item.id }"
              @click="changeProductClick(item)"
            >
              <img
                :src="require('@/assets/images/app_icon/' + item.appIcon + '.png')"
                alt=""
                class="img_"
              />
            </div>
            <div class="flex-col">
              <span class="text_">{{ item.text }}</span>
            </div>
          </div>
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations } from 'vuex';
import { TIANJI2_DA, TIANJI2_MERGE, TIANJI2_ND } from '@/config';
import { modeFactorMap, factorMap, mapForCn, appFactorMap } from '@/config/MapConfigT4';
import MenuLayerSelect from './MenuLayerSelect.vue';
import MenuFactorPopup from './MenuFactorPopup.vue';

export default {
  components: {
    MenuLayerSelect,
    MenuFactorPopup,
  },
  props: {
    productType: {
      type: Array,
      default: () => [],
    },
    modelType: {
      type: String,
      required: true,
    },
    isTyp: {
      type: Boolean,
      default: false,
    },
    isSatel: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 't2',
    },
  },
  data() {
    return {
      input: '',
      product_type: [],
    };
  },
  computed: {
    ...mapState(['isMobile']),
    ...mapState('map', ['t2Mode', 'layerType']),
    popupShow: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit('update:show', val);
      },
    },
    getProductType() {
      return this.productType;
    },
  },
  created() {
  },
  mounted() {

  },
  methods: {
    initProductType(data) {
      this.product_type = data;
    },
    changeProductClick(item) {
      this.$emit('changeProductClick', item);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/common.scss";

.title_text {
  width: 28px;
  height: 14px;
  margin: 20px 0 0 12px;
  .word3 {
    width: 28px;
    height: 14px;
    color: #fff;
    font-size: 14px;
    font-family: PingFangSC-Medium;
    text-align: left;
    white-space: nowrap;
    line-height: 14px;
  }
}

.img_ {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.mod3 {
  //background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  margin: 10px;
  //border: 1px solid #c8c9cc;
  background-color: rgba(66, 66, 66, 0.75);

  .text_ {
    color: #FFFFFF;
    margin-top: 10px;
  }

  .icon {
    width: 30px;
    height: 30px;
  }

  .change_factor_item_class {
    border: 3px solid rgba(87, 127, 255, 1);
    border-radius: 50px;
  }

  justify-content: flex-center;
}
</style>
