<template>
  <van-action-sheet v-model:show="popupShow" title="模式列表" class="menu-popup">
    <div class="factor-div">
      <van-grid
        :border="false"
        :column-num="2"
        class="grid-div"
        :gutter="10"
      >
        <van-grid-item
          v-for="(item, key) in menu"
          :key="key"
          class="grid-item-div"
          :class="[item.checked ? 'grid-item-click' : '', item.disabled ? 'grid-item-disable' : '']"
          @click="item.disabled ? null : changeModeItem(key, item)"
        >
          <div class="flex-row justify-between align-center" style="width: 100%;padding: 10px;">
            <div class="flex-row align-center">
              <div
                :style="{
                  width: '15px', height: '15px', borderRadius: '50px', marginRight: '8px', background: `${item.color}`,
                }"
              />
              <div class="flex-row">{{ item.name }}</div>
            </div>
            <div @click.stop>
              <van-checkbox v-model="item.windChecked" :disabled="item.disabled" @change="(value) => switchWindChange(value, key)">风圈</van-checkbox>
            </div>
          </div>
        </van-grid-item>
      </van-grid>
    </div>
  </van-action-sheet>
</template>
<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import moment from 'moment';
import { TIANJI2_DA, TIANJI2_MERGE, TIANJI2_ND, TIANJI1_DA, TIANJI1_ND } from '@/config';
import { factorMapT1, mapForCn, dust_factorMap } from '@/config/MapConfigT4';
import { drag } from '@/utils/drag';
import Bus from '@/bus/index';

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      input: '',
      activeIndex: 0,
      types: [TIANJI2_MERGE, TIANJI2_ND, TIANJI2_DA, TIANJI1_ND, TIANJI1_DA],
      modeItem: '',
      modeSelectShow: false,
      wrapText: '',
    };
  },
  computed: {
    ...mapState('typhoon', ['modelLegend', 'typhoonObj', 'tableListData', 'ObserveTyphoonList',
      'observeTyphoonObj', 'typhoonLineObj', 'timeLineIndex', 'timeLineData', 'timeLineLengthObj',
      'pointLengthObj', 'timeLineLength', 'renderTyphoonObj', 'timeSetObj', 'timeSet',
      'timeLineClickTime', 'timeRangeData', 'initMove']),
    ...mapGetters('typhoon', ['timeLinePointHourList']),
    popupShow: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit('update:show', val);
      },
    },
    menu() {
      return this.modelLegend;
    },
  },
  created() {

  },
  mounted() {
  },
  methods: {
    ...mapMutations('map', ['setMode']),
    ...mapMutations('typhoon', ['setModelLegend', 'setModelLegendWind', 'setLinePointMaxLength',
      'setTimeLineLength', 'setTimeSet', 'setCurrentDateTime', 'setInitMove', 'setTimeLineClickTime']),
    changeModeItem(key, item) {
      const value = !item.checked;
      this.setModelLegend({ key, checked: value });
      this.setModelLegendWind({ key, checked: value });
      // this.resetTimeLength();
      const unionTimeSet = this.computeRangeTimeSet(key, value);
      let playObj = {};
      if (key === 'tcvital') {
        playObj = this.observeTyphoonObj;
      } else {
        playObj = this.typhoonLineObj;
      }
      if (!value) {
        // 隐藏所有的key的图
        if (playObj && Object.keys(playObj).length > 0) {
          Object.keys(playObj).forEach((v) => {
            if (v.indexOf(key) > -1) {
              playObj[v].deleteTyphoon();
            }
          });
        }
        this.computeRenderLine(unionTimeSet, key, value);
      } else {
        // 重新展示
        // eslint-disable-next-line no-lonely-if
        if (playObj && Object.keys(playObj).length > 0) {
          let findLine = false;
          Object.keys(playObj).forEach((v) => {
            if (v.indexOf(key) > -1) {
              // 如果已经绘制过，则重新绘制
              playObj[v].reRenderLine();
              findLine = true;
            }
          });

          if (!findLine) {
            // 如果没有绘制过，则重新绘制
            if (this.initMove) {
              this.setInitMove(false);
            }
            this.renderTyphoonObj[key].forEach((params) => {
              Bus.$emit('renderTyphoon', params);
            });
            setTimeout(() => {
              this.computeRenderLine(unionTimeSet, key, value);
            }, 1000);
          } else {
            this.computeRenderLine(unionTimeSet, key, value);
          }
        }
      }
    },
    computeRangeTimeSet(key, isShow) {
      // 计算当前选择线路的可选日期区间
      const timeSet = this.timeSet;
      const keyTimeSet = this.timeSetObj[key];
      let unionTimeSet = new Set();
      if (isShow) {
        // 展示，合并
        unionTimeSet = new Set([...timeSet, ...keyTimeSet]);
        this.setTimeSet(unionTimeSet);
      } else {
        // 隐藏，去掉timeSet中的数据
        Object.keys(this.timeSetObj).forEach((k) => {
          if (this.modelLegend[k].checked) {
            unionTimeSet = new Set([...unionTimeSet, ...this.timeSetObj[k]]);
          }
        });
        this.setTimeSet(unionTimeSet);
      }
      return unionTimeSet;
    },
    computeRenderLine(unionTimeSet, key, isShow) {
      // 判断当前时刻是否在事件区间内，如果不在，则切换到第一天的第一个时刻点击状态，如果在，则不处理
      const date = moment(this.timeLineClickTime, 'YYYYMMDDHH').format('YYYYMMDD');
      if (!unionTimeSet.has(date)) {
        // 如果不存在
        const [first] = [...unionTimeSet].sort();
        this.setCurrentDateTime(first);
        const currentTime = first + this.timeLinePointHourList[0];
        Bus.$emit('tiggerTimeLineClick', currentTime);
      } else {
        // 如果存在，则判断当前时间点节点小时是否在这个时刻里，如果不在，则获取第一，如果在则不处理
        const hour = moment(this.timeLineClickTime, 'YYYYMMDDHH').format('HH');
        const index = this.timeLinePointHourList.findIndex((v) => v === hour);
        if (index === -1) {
          const [first] = [...unionTimeSet].sort();
          this.setCurrentDateTime(first);
          const currentTime = first + this.timeLinePointHourList[0];
          Bus.$emit('tiggerTimeLineClick', currentTime);
        } else if (isShow && this.modelLegend[key].windChecked) {
          // 如果存在，如果风圈是展示的，则需要展示当前位置风圈
          let lineObj = {};
          if (key === 'tcvital') {
            // 实况
            lineObj = this.observeTyphoonObj;
          } else {
            lineObj = this.typhoonLineObj;
          }
          if (lineObj && Object.keys(lineObj).length > 0) {
            Object.keys(lineObj).forEach((t) => {
              if (t.indexOf(key) > -1) {
                lineObj[t].showWindCircle(this.timeLineClickTime);
              }
            });
          }
        }
      }
    },

    // 展示风圈按钮
    switchWindChange(value, key) {
      this.setModelLegendWind({ key, checked: value });
      if (!this.modelLegend[key].checked) {
        return;
      }
      let lineObj = {};
      if (key === 'tcvital') {
        // 实况
        lineObj = this.observeTyphoonObj;
      } else {
        lineObj = this.typhoonLineObj;
      }
      if (!value) {
        // 不展示风圈
        if (lineObj && Object.keys(lineObj).length > 0) {
          Object.keys(lineObj).forEach((t) => {
            if (t.indexOf(key) > -1) {
              lineObj[t].deleteWindCircle();
            }
          });
        }
      } else {
        // 显示风圈，如果当前线路不显示，仍然不展示风圈
        // 如果是观测的，点位不在时间轴区间上，
        // eslint-disable-next-line no-lonely-if
        if (lineObj && Object.keys(lineObj).length > 0) {
          Object.keys(lineObj).forEach((t) => {
            if (t.indexOf(key) > -1) {
              lineObj[t].showWindCircle(this.timeLineClickTime);
            }
          });
        }
      }
    },

  },
};
</script>

<style lang="scss" scoped>
@import "@/style/common.scss";
.mode-div {
  font-size: 14px;
  padding: 5px 15px;
  bottom: 150px;
  color: #FFFFFF;
  border-radius: 30px;
  display: flex;
  position: absolute;
  right: 8px;
  z-index: 999;
  background: rgba(50, 50, 50, 0.5);
  backdrop-filter: blur(1px);
  box-shadow: 0 0 0 0;
  width: auto;
  height: 20px;
  align-items: center;
  justify-content: center;

  .mode-text {
    display: flex;
    align-items: flex-end;
    margin-right: 5px;

    .text {
      font-size: 11px;
    }
  }
}
.menu-popup {
  background: $bg-popup;
  color: #FFFFFF;
  height: 35%;
  z-index: 99999;
}

.factor-div {
  border-radius: 8px;
  margin: 10px;
  background-color: rgba(66, 66, 66, 0.75);

  .grid-div {
    padding: 15px 5px;
  }

  .grid-item-div {
    ::v-deep {
      .van-grid-item__content {
        border-radius: 10px;
      }
    }
  }

  .grid-item-click {
    ::v-deep {
      .van-grid-item__content {
        border-radius: 10px;
        border: 3px solid rgba(87, 127, 255, 1);
      }
    }
  }

  .grid-item-disable {
    ::v-deep {
      .van-grid-item__content {
        background-color: #808080;
        border-color: #c8c9cc;
        cursor: not-allowed;
      }
    }
  }

  .text {
    font-size: 11px;
    padding-top: 10px;
  }

  .active {
    ::v-deep {
      .van-grid-item__content {
        background-color: rgba(87, 127, 255, 1);
      }
    }
  }
}

// .mode-div {
//   display: flex;
//   align-items: flex-end;
//   margin-right: 5px;
//   background: $bg-popup;
//   backdrop-filter: blur(1px);
//   width: auto;
//   height: 20px;
//   padding: 5px 15px;
//   border-radius: 30px;
// }

::v-deep {
  .van-grid-item__content {
    background-color: $bg-popup;
    padding: 10px 4px;
  }
  .van-checkbox__label {
    color: #FFFFFF;
  }
}
</style>
