export const allMetrics = [
  {
    id: 'tmp2m',
    icon: 'f-temp',
    text: '温度',
  },
  {
    id: 'pratesfc',
    text: '降水率',
    icon: 'f-prerrt',
    
  },
  {
    id: 'wgrd10m',
    icon: 'f-windy',
    text: '10米风场',
  },
  {
    id: 'rh2m',
    icon: 'humidity',
    text: '相对湿度',
  },
  {
    id: 'max_reflectivity',
    icon: 'f-radar',
    text: '明日雷达',
  },
  {
    id: 'dswrfsfc',
    icon: 'dswrfsfc',
    text: '辐照度',
  },
  {
    id: 'dust_conc',
    icon: 'dust',
    text: '地表沙尘浓度',
  },
];
