<template>
  <div style="height: 30px; width: 100%; margin-left: 20px; margin-top: 22px">
    <div class="timeline">
      <div
        class="target"
        :style="{
          left: `${indexFlag * (100 / (timeType.timeLength - 1)) + 0.2}%`,
        }"
      >
        <div class="box" />

        <div
          v-if="date"
          class="datetime"
          :class="{ last_tip: isLast || indexFlag === timeType.timeLength - 1 }"
        >
          {{ date }}
        </div>
      </div>
      <div class="time_wrap">
        <div
          v-for="i in timeType.timeLength"
          :key="i"
          class="time_item"
          :class="{
            active: i === indexFlag + 1,
            animation: i <= indexFlag,
          }"
          :style="canClick(i, timeType.type)"
        >
          <div class="clickDiv" :style="canClick(i, timeType.type)" @click="timeClick(i)" />
          <div
            class="circle"
            :class="{
              animation: i <= indexFlag,
            }"
            :style="canClick(i, timeType.type)"
          />
          <div v-if="i % 2 || timeType.type > 1" class="word satellite" :style="canClick(i, timeType.type, 'word')">
            {{ setTimeLineTime(i - 1) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';

export default {
  filters: {
    getTime(value, type) {
      if (type === 3) {
        return `${2 + value * type < 10 ? `0${2 + value * type}` : 2 + value * type}:00`;
      } if (type === 6) {
        return `${2 + value * type < 10 ? `0${2 + value * type}` : 2 + value * type}:00`;
      }
      return `${value * type < 10 ? `0${value * type}` : value * type}:00`;
    },
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    color: {
      type: Boolean,
      default: false,
    },
    modelType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isClick: false,
      isLast: false,
    };
  },
  computed: {
    ...mapState(['currentData']),
    ...mapState('map', ['status', 'currentBaseTime']),
    ...mapGetters('map', ['currentTime', 'timeList']),
    ...mapGetters('satellite', ['currentSatelliteTime', 'currentSatelliteBaseTime']),
    indexFlag() {
      return this.index;
    },
    timeType() {
      return {
        type: 1,
        timeLength: 12,
      };
    },
    date() {
      if (this.modelType === 't2' && this.currentTime) {
        return moment(this.currentTime).format('YYYY-MM-DD HH:mm');
      }
      return '';
    },
    currentStartTime() {
      let time = '';
      time = (this.currentBaseTime ? this.currentBaseTime.split(' ')[0] : '') || sessionStorage.getItem('baseTime');
      return moment(time).format('YYYY-MM-DD HH:mm');
    },
    lastTime() {
      // const baseTime = this.timeList[this.timeList.length-1].forecastTime
      const baseTime = (this.timeList.length > 0 ? this.timeList[this.timeList.length - 1].forecastTime : '') || sessionStorage.getItem('baseTime');
      return moment(baseTime).format('YYYY-MM-DD HH:mm');
    },
  },
  methods: {

    setTimeLineTime(value) {
      const date = new Date(this.currentTime);

      // 获取本地时间的小时部分
      const hour = date.getHours();
      // const hour = Number(time.slice(8, 10))
      const setHour = hour < 10 ? `0${hour}` : hour;
      return `${value * 5 < 10 ? `${setHour}:0${value * 5}` : `${setHour}:${value * 5}`}`;
    },
    canClick(i, type, typ) {
      const inx = i - 1;
      if (this.currentStartTime.split(' ')[0] === this.date.split(' ')[0]) {
        const baseHourTime = this.currentStartTime.split(' ')[1];
        const commonHour = this.setTimeLineTime(inx);
        // const hour = commonHour.split(":")[0]
        const cHour = Number(this.currentStartTime.split(' ')[1].split(':')[0]);
        const minutes = this.date.split(' ')[1];
        const dHour = Number(minutes.split(':')[0]);
        if (dHour === cHour) {
          if (commonHour < baseHourTime) {
            if (typ === 'word') {
              return 'cursor: not-allowed; color: grey;';
            }
            return 'cursor: not-allowed;';
          }
        } else {
          // 小时数不一样
          const lMinute = this.lastTime.split(' ')[1];
          const lateTimeHour = lMinute.substring(0, 5);
          if (commonHour > lateTimeHour) {
            return 'cursor: not-allowed; color: grey;';
          }
        }
      } else if (this.date.split(' ')[0] === this.lastTime.split(' ')[0]) {
        const lastHour = this.lastTime.split(' ')[1];
        const hour = this.setTimeLineTime(inx);
        if (hour > lastHour) {
          return 'cursor: not-allowed; color: grey;';
        }
      }
    },
    timeClick(i) {
      this.isClick = true;
      if (i === this.timeType.timeLength) {
        this.isLast = true;
      } else {
        this.isLast = false;
      }
      this.$emit('change', i);
    },
  },
};
</script>

<style lang="scss" scoped>
.timeline {
  width: 100%;
  height: 1px;
  background: #6b738b;
  box-sizing: border-box;
  display: flex;
  color: rgba($color: #fff, $alpha: 1);
  font-size: 12px;
  position: relative;

  &.satellite {
    background: #fff;
  }

  .slider {
    position: absolute;
    top: -14px;
    transform: translateX(-50%);
    color: rgba($color: #f00, $alpha: 0.5);
    font-size: 20px;
  }

  .target {
    position: absolute;
    top: -7px;
    padding: 4px;
    z-index: 2;
    transform: translateX(-65%);
    border-radius: 50%;
    background: $text-operate;

    .box {
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background-color: $theme-color;
    }

    .datetime {
      position: absolute;
      top: -28px;
      left: 50%;
      transform: translateX(-50%);
      height: 24px;
      background: #fff;
      color: $theme-color;
      width: 118px;
      line-height: 24px;
      border-radius: 4px;
      text-align: center;

      &::before {
        position: absolute;
        bottom: -6px;
        left: 50%;
        transform: translateX(-50%);
        content: "";
        display: block;
        width: 6px;
        height: 6px;
        box-sizing: border-box;
        border-top: 3px solid #fff;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
        border-bottom: 3px solid transparent;
      }
    }

    .last_tip {
      left: -210%;

      &::before {
        left: 76%;
      }
    }
  }

  .time_wrap {
    width: 100%;
    display: flex;

    .time_item {
      flex: 1;
      cursor: pointer;
      position: relative;

      &:last-child {
        cursor: pointer;
        width: 2px;
        flex: 0;

        .word {
          transform: translateX(-50%);
        }
      }

      &.animation {
        background: #bac3db;
      }

      .clickDiv {
        position: absolute;
        top: -10px;
        left: -4px;
        cursor: pointer;
        width: 10px;
        height: 35px;
        z-index: 9999;
      }

      .circle {
        width: 1px;
        height: 7px;
        background-color: #6b738b;
        position: absolute;
        top: -6px;
        cursor: pointer;

        &.satellite,
        &.animation {
          background: #fff;
        }
      }

      .word {
        position: absolute;
        top: 4px;
        transform: translateX(-48%);
        text-align: center;
      }
    }
  }
}
</style>

<style>
.current_a {
  background-color: #ffffff;
}
</style>
