<template>
  <div class="home">
    <v-map ref="map" @mapMouseMove="mapMouseMove" />
    <v-loading :loading="loading" />
    <v-title title="中科天机" border @change="$router.push('/')">
      <v-search
        v-if="!isMobile"
        slot="search"
        @locate="setLocation"
        @cleanIcon="cleanLocationIcon"
      />
    </v-title>
    <v-map-title />
    <!--    <v-legend />-->
    <v-model-legend v-if="!isMobile" />
    <!--    <v-map-tile model-type="t2" />-->
    <v-control show @setting="showSetting = !showSetting" />
    <v-player v-if="!isMobile" model-type="t1" />
    <!--    <v-elevation v-if="!isMobile && elevationList && elevationList.length" />-->
    <template v-if="!isMobile">
      <v-metric-select
        :factor="factor"
        :metrics="metricT2"
        :type="modeType"
        :is-typ="true"
        @change="factorChange"
      />
    </template>

    <!--    <template v-else>-->
    <!--      <v-mobile-operate-menu-->
    <!--        :factor="factor"-->
    <!--        :windy="windy"-->
    <!--        :type="modeType"-->
    <!--        :isTyp="true"-->
    <!--        :metrics="metricT2"-->
    <!--        :init-base-time="currentBaseTime"-->
    <!--        :base-time="baseTime"-->
    <!--        @handleBaseTimeChange="(val) => setCurrentBaseTime(val)"-->
    <!--        @change="factorChange"-->
    <!--        @setWindy="setWindy"-->
    <!--      />-->
    <!--    </template>-->
    <!--    <v-info keywords="tianji2" />-->
    <v-layer-select v-if="!isMobile" model-type="t2" />
    <mobile-right-btn
      v-if="isMobile"
      model-type="typhoon"
      factor="'typ'"
      :is-typ="true"
      :metrics="metricT2"
      @change="factorChange"
      @locate="setLocation"
      @cleanIcon="cleanLocationIcon"
    />
    <time-line v-if="isMobile" />
    <div v-if="!isMobile" class="lnglattext">
      <span>{{ lnglatText }}</span>
    </div>
    <!--    <v-table />-->
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import { allMetrics } from '@/config/MapConfigT4';
import Bus from '@/bus/index';
import VTitle from '@/components/Map/Title.vue';
import VMapTitle from './components/MapTitle.vue';
import VSearch from './components/Search.vue';
import VLayerSelect from './components/LayerSelect.vue';
import VMetricSelect from '@/components/Map/MetricSelect.vue';
import VMobileOperateMenu from '@/components/Map/MobileOperateMenu.vue';
import VControl from '@/components/Map/Control.vue';
import VPlayer from './components/Player.vue';
import { TIANJI2_DA, TIANJI2_MERGE, TIANJI2_ND } from '@/config';
import VMap from './Map.vue';
import VModelLegend from './components/ModelLegend.vue';
import VLoading from '@/components/Map/Loading.vue';
import MobileRightBtn from '@/components/Map/mobile/MobileRightBtn.vue';
import TimeLine from '@/components/Map/mobile/typhoon/TyphoonTimeLine.vue';

export default {
  name: 'SatelliteCloud',
  components: {
    VLayerSelect,
    VTitle,
    VPlayer,
    // VTime,
    VSearch,
    VControl,
    VMap,
    VMapTitle,
    VMetricSelect,
    VMobileOperateMenu,
    VModelLegend,
    VLoading,
    MobileRightBtn,
    TimeLine,
  },
  data() {
    return {
      allMetrics,
      startTime: '',
      lnglatText: '',
    };
  },
  computed: {
    ...mapState('map', ['factor', 'windy', 'isobar', 'currentBaseTime', 't2Mode', 'loading']),
    ...mapState('satellite', ['screenSize']),
    ...mapState(['isMobile']),
    ...mapState('typhoon', [
      'qbTime',
      'typhoonObj',
      'tableListData',
      'beginDateTime',
      'timeLineData',
    ]),
    ...mapGetters('satellite', ['currentTime']),
    metricT2() {
      const temp = this.allMetrics.filter((item) => !item.private || item.private === 't2');
      if (sessionStorage.getItem('tmode') === 't2') {
        if (this.t2Mode !== TIANJI2_MERGE) {
          return temp.filter((item) => item.type !== 'merge');
        }
        if (this.t2Mode === TIANJI2_MERGE) {
          return temp.filter((item) => item.type !== 'default');
        }
        return temp;
      }
      return temp.filter((item) => item.type !== 'merge' && item.id !== 'tmp2m');
    },
    modeType() {
      return sessionStorage.getItem('tmode');
    },
    isVertical() {
      return this.screenSize.width < 1060;
    },
  },
  created() {
    this.startTime = Date.now();
    this.init();
  },
  mounted() {
    // this.setScreenSize({
    //   width: document.documentElement.clientWidth,
    //   height: document.documentElement.clientHeight,
    // });
    // window.onresize = throttle(() => {
    //   this.setScreenSize({
    //     width: document.documentElement.clientWidth,
    //     height: document.documentElement.clientHeight,
    //   });
    // }, 200);
  },
  methods: {
    ...mapMutations('map', ['setCurrentBaseTime', 'setWindy', 'setLoading']),
    ...mapMutations('typhoon', [
      'setTyphoonListData',
      'setQbTime',
      'setTyphoonObj',
      'setCurrentDateTime',
      'setStatus',
      'setIsPause',
      'setIndex',
      'setIsMorePause',
      'setIsMorePlay',
      'setTimeLineDataInfo',
      'setObserveTyphoonList',
      'setIfsTyphoonList',
      'setEpsTyphoonList',
      'setGfsTyphoonList',
      'setForecastTyphoonListData',
      'setInitForecastTyphoonObj',
    ]),
    ...mapMutations('map', ['setLayerType']),
    async init() {
      this.setLoading(true);
      // this.setLayerType('shadow');
    },
    upPageTime(time1, time2) {
      const time = Math.ceil((time2 - time1) / (1000 * 60));
      this.$sensors.track('tj_pageTime', {
        tj_stayTime: time,
        page_type: '台风',
        space_type: '二维',
        info: '台风',
        mode: '台风',
      });
      this.startTime = time2;
      // 计算相差的分钟数
    },
    factorChange() {},
    setLocation(position) {
      Bus.$emit('setLocation', position);
    },
    mapMouseMove(e) {
      this.lnglatText = e;
    },
    cleanLocationIcon() {
      this.$refs.map.removeMarker();
    },
  },
  beforeDestroy() {
    this.upPageTime(this.startTime, Date.now());
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  width: 100%;
  position: relative;
}

.lnglattext {
  position: fixed;
  z-index: 999;
  left: 80px;
  bottom: 50px;
  color: lightgrey;
  font-size: 11px;
  //font-weight: 500;
  //text-shadow: 0 0 1px rgba(0, 0, 0, 0.5), 1px 0 1px rgba(0, 0, 0, 0.5),
  //  -1px 0 1px rgba(0, 0, 0, 0.5), 0 1px 1px rgba(0, 0, 0, 0.5), 0 -1px 1px rgba(0, 0, 0, 0.5);
}
</style>
