import axios from 'axios';
import { Message } from 'element-ui';

const http = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL_APPLY,
  timeout: 60000,
  headers: {
    'Content-Type': 'application/json',
  },
});

const success = (response) => {
  const { data } = response;
  if (response.headers.authorization) {
    store.commit('setToken', response.headers.authorization);
  }
  // TODO 登录校验
  if (data.code == 200) {
    return data;
  }

  if (data.status === '0' || JSON.stringify(data) === '{}' || data.status === '404') {
    return data;
  }

  Message({
    type: 'error',
    message: data.message,
  });

  return Promise.reject(data);
};

/**
 * @description 接口调用失败的拦截器
 * @param {Object} error 错误信息
 * @returns {Object}
 */
const failed = (error) => {
  if (error.message.includes('timeout')) {
    return error;
  }

  Message({
    type: 'error',
    message: error.response?.data?.message || '服务器出错，请联系管理员',
  });

  return Promise.reject();

  // TODO 不同错误码的验证
  // return error;
};

http.interceptors.response.use(success, failed);

export const apply = (param) => http.post('/gateway/admin/api/apply/out', param);

export const consult = (param) => http.post('/gateway/admin/api/consult/out', param);
