import Vue from 'vue';
import VueRouter from 'vue-router';
import Weather from '../views/map/index.vue';
import Register from '../views/login_page/register.vue';
import findPassword from '../views/login_page/findPassword.vue';
import agreement from '../views/login_page/agreement.vue';
import WeatherHD from '../views/map_hd/index.vue';
import Earth from '../views/cesium_hd/index.vue';
import Satellite from '../views/satellite/index.vue';
import Typhoon from '../views/typhoon/index.vue';
import banner from '../views/website_page/banner.vue';

Vue.use(VueRouter);
function goToPrevRouter(router) {
  const name = localStorage.getItem('router-url');
  // 解决有些用户localstorage存了banner的bug
  if (name === 'banner') {
    router.replace({ name: 'weather_hd' });
    return;
  }
  const factor = localStorage.getItem('factor');
  console.log('name==>', name, 'factor==>', factor);
  // sessionStorage.setItem('tmode', (name !== 'weather'&&dust_factorMap[factor]) ? 't2' : 't1');
  if (name && name !== 'register' && name !== 'findPassword' && name !== 'agreement' && name !== 'userInfoTianji' && router.history._startLocation == '/') {
    console.log(name);
    router.replace({ name });
  }
}

const routes = [
  {
    path: '/earth',
    name: 'earth',
    component: Earth,
  },
  {
    path: '/weather',
    name: 'weather',
    component: Weather,
  },
  {
    path: '/',
    name: 'weather_hd',
    component: WeatherHD,
  },
  {
    path: '/dust',
    name: 'weather_hd',
    component: WeatherHD,
  },
  {
    path: '/satellite',
    name: 'satellite',
    component: Satellite,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  },
  {
    path: '/agreement',
    name: 'agreement',
    component: agreement,
  },
  {
    path: '/findPassword',
    name: 'findPassword',
    component: findPassword,
    props: true,
  },
  {
    path: '/typhoon',
    name: 'typhoon',
    component: Typhoon,
  },
  {
    path: '/banner',
    name: 'banner',
    component: banner,
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  base: process.env.VUE_APP_BASE_PUBLIC_PATH,
});

router.afterEach((to) => {
  // const factor = localStorage.getItem('factor');
  // sessionStorage.setItem('tmode', (to.name !== 'weather'&&dust_factorMap[factor]) ? 't2' : 't1');
  if (to.name !== 'banner') {
    localStorage.setItem('router-url', to.name);
  }
});

goToPrevRouter(router);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

export default router;
