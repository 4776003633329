<template>
  <van-action-sheet v-model="popupShow" :title="latlngText" class="menu-popup">
    <div class="menu-wrap">
      <!-- <div class="mod3 flex-col">
      <div class="cordText">
        {{ latlngText }}
      </div>
      <div class="tempText">
        {{ popupVal }}{{ popupUnit }}
      </div>
    </div> -->
      <ForcastPrecpPopup ref="tenPopup" model-type="" />
    </div>
  </van-action-sheet>
</template>
<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
// import { getSinglePoint } from "@/api/weather_hd";
import moment from 'moment';
import ForcastPrecpPopup from './ForcastPrecpPopup.vue';
import Bus from '@/bus/index';

export default {
  components: {
    ForcastPrecpPopup,
  },
  props: {
    metrics: {
      type: Array,
      default: () => [],
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      latlng: '',
      detailData: {
        temp: '',
        windSpeed: '',
        precip: '',
        tempMax: '',
        tempMin: '',
      },
    };
  },
  computed: {
    ...mapState(['isMobile', 'currentData']),
    ...mapState('map', ['factor', 'popupVal', 'popupUnit']),
    ...mapGetters('map', ['currentLayerDetails', 'isT2Mode', 'currentTime']),
    popupShow: {
      get() {
        return this.show;
      },
      set(val) {
        if (!val) {
          Bus.$emit('removePopup');
        }
        this.$emit('update:show', val);
      },
    },
    getLayerType() {
      return this.layerType;
    },
    latlngText() {
      if (!this.latlng) return '';
      const { lat, lng } = this.latlng;
      const rightLng = ((lng - 180) % 360 + 360) % 360 - 180;
      const latS = parseFloat(lat) > 0.0 ? 'N' : 'S';
      const lngS = parseFloat(rightLng) > 0.0 ? 'E' : 'W';
      return `${Math.abs(rightLng).toFixed(2)}${lngS},${Math.abs(lat).toFixed(2)}${latS}`;
    },
    getCurrentTime() {
      // return '时刻：' + moment(this.currentData.timestamp).format('YYYY-MM-DD HH:mm');
      return '时刻：';
    },
  },
  created() {
    console.log();
  },
  mounted() {

  },
  methods: {
    ...mapMutations([
      'setHistory',
      'setStatus',
      'setCurrentData',
      'setT1Windy',
      'setT1LayerType',
      'setT1City',
    ]),
    init(latlng, metrics, precpData) {
      this.latlng = latlng;
      this.$nextTick(() => {
        // this.getSinglePointInfo();
        this.$refs.tenPopup.initChart(latlng, this.factor, metrics, precpData);
      });
    },
    async getSinglePointInfo() {
      const { production, baseTimeString, mode, region, factorCode } = this.currentLayerDetails;
      const { lat, lng } = this.latlng;
      const params = {
        lonlat: `${lng};${lat}`,
        mode,
        fxTime: moment(this.currentTime).format('YYYYMMDDHH'),
        baseTime: baseTimeString,
        production,
      };
      const detailData = await getSinglePoint(params);
      if (detailData && detailData.length > 0) {
        this.detailData = detailData[0];
        console.log('this.detailData', this.detailData);
      }
      this.$refs.tempPopup.getSinglePointMaxMinTemp(this.latlng);
    },
    getTempValue(value) {
      return value ? parseInt(value) : '';
    },
    checkPrecip(value) {
      if (!value || value === '0.0') {
        return false;
      }
      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/style/common.scss";

.menu-popup {
  background: $bg-popup;
  color: #FFFFFF;
  max-height: 50%;
  .menu-wrap {
    height: calc(50vh - 20px);
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 20px;
  }
}

.title_text {
  width: 28px;
  height: 14px;
  margin: 20px 0 0 12px;
  .word3 {
    width: 28px;
    height: 14px;
    color: #fff;
    font-size: 14px;
    font-family: PingFangSC-Medium;
    text-align: left;
    white-space: nowrap;
    line-height: 14px;
  }
}

.img_ {
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.mod3 {
  align-items: center;

  .latlngText {
    font-size: 20px;
    padding: 5px 0 10px 0;
  }

  .cordText {
    font-size: 20px;
    padding: 5px 0 5px 0;
  }

  .tempText {
    font-size: 40px;
    padding: 5px 0 10px 0;
  }

  .otherText {
    font-size: 20px;
    padding: 5px 0 10px 0;
  }

  .text_ {
    color: #FFFFFF;
    margin-top: 10px;
  }

  .icon {
    width: 30px;
    height: 30px;
  }

  .change_factor_item_class {
    border: 3px solid rgba(87, 127, 255, 1);
    border-radius: 50px;
  }

  justify-content: flex-center;
}

.factor-div {
  //background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  margin: 10px;
  //border: 1px solid #c8c9cc;
  background-color: rgba(66, 66, 66, 0.75);

  .text_ {
    color: #FFFFFF;
    margin-top: 10px;
    font-size: 14px;
  }

  .checked_class {
    display: block;
    font-size: 14px;
    line-height: 20px;
  }

  .change_factor_item_class {
    border: 3px solid rgba(87, 127, 255, 1);
    border-radius: 18px;
  }

  justify-content: flex-center;
  .layer7 {
    width: 28px;
    height: 14px;
    margin: 15px 0 0 16px;
    .word3 {
      width: 28px;
      height: 14px;
      color: #fff;
      font-size: 14px;
      font-family: PingFangSC-Medium;
      text-align: left;
      white-space: nowrap;
      line-height: 14px;
    }
  }
}

.layer-div {
  //background-color: rgba(255, 255, 255, 1);
  border-radius: 8px;
  margin: 10px;
  //border: 1px solid #c8c9cc;
  background-color: rgba(66, 66, 66, 0.75);

  .text_ {
    color: #FFFFFF;
    margin-top: 10px;
    font-size: 14px;
  }

  .change_factor_item_class {
    border: 3px solid rgba(87, 127, 255, 1);
    border-radius: 50px;
  }

  justify-content: flex-center;
  .layer7 {
    width: 28px;
    height: 14px;
    margin: 15px 0 0 16px;
    .word3 {
      width: 28px;
      height: 14px;
      color: #fff;
      font-size: 14px;
      font-family: PingFangSC-Medium;
      text-align: left;
      white-space: nowrap;
      line-height: 14px;
    }
  }
}

::v-deep {
  .van-grid-item__content {
    background-color: rgba(0, 0, 0, 0);
    padding: 10px 4px;
  }
  .van-badge--fixed {
    top: 5px;
    right: 5px;
  }
  .van-badge {
    border: 0;
  }
}
::-webkit-scrollbar {
  display: none;/*隐藏滚轮*/
}
</style>
