<template>
  <div class="satellite_cloud_wrap">
    <v-title title="明日卫星" @change="$router.push('/')" />
    <v-picture ref="picture" :is-vertical="isVertical" />
    <v-map-tile model-type="satellite" />
    <v-player v-if="!isMobile" :is-vertical="isVertical" model-type="t1" />

    <template v-if="!isMobile">
      <v-metric-select
        :factor="'isSatel'"
        :metrics="metricT2"
        :type="modeType"
        :is-satel="true"
        @change="factorChange"
      />
    </template>

    <!--    <template v-else>-->
    <!--      <v-mobile-operate-menu-->
    <!--        :factor="'isSatel'"-->
    <!--        :windy="windy"-->
    <!--        :type="modeType"-->
    <!--        :isSatel="true"-->
    <!--        :metrics="metricT2"-->
    <!--        :base-time="baseTime"-->
    <!--        @handleBaseTimeChange="(val) => setCurrentBaseTime(val)"-->
    <!--        @change="factorChange"-->
    <!--        @setWindy="setWindy"-->
    <!--      />-->
    <!--    </template>-->

    <v-info v-if="!isMobile" keywords="satelite" satelite />
    <v-control />
    <mobile-right-btn
      v-if="isMobile"
      model-type="satellite"
      :factor="'isSatel'"
      :is-satel="true"
      :metrics="metricT2"
      @change="factorChange"
    />
    <v-layer-select v-if="!isMobile" model-type="satellite" />
    <time-line v-if="isMobile" />
  </div>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex';
import moment from 'moment';
import { allMetrics } from '@/config/MapConfigT4';
import { getSatelliteData } from '@/api/satellite';
import { throttle } from '@/utils';
import VTitle from '@/components/Map/Title.vue';
import VMetricSelect from '@/components/Map/MetricSelect.vue';
import VMobileOperateMenu from '@/components/Map/MobileOperateMenu.vue';
import VInfo from '@/components/Map/Info.vue';
import VLayerSelect from '@/components/Map/LayerSelect.vue';
// import VTime from '@/components/Map/Time.vue';
import VControl from '@/components/Map/Control.vue';
import VMapTile from '@/components/Map/MapTitle.vue';
import { TIANJI2_DA, TIANJI2_MERGE, TIANJI2_ND } from '@/config';
import VPicture from './SatellitePicture.vue';
import VPlayer from './components/PlayerNew.vue';
import MobileRightBtn from '@/components/Map/mobile/MobileRightBtn.vue';
import TimeLine from '@/components/Map/mobile/satellite/TimeLine.vue';

export default {
  name: 'SatelliteCloud',
  components: {
    VLayerSelect,
    VTitle,
    VMobileOperateMenu,
    VPicture,
    VPlayer,
    // VTime,
    VInfo,
    VControl,
    VMetricSelect,
    VMapTile,
    MobileRightBtn,
    TimeLine,
  },
  data() {
    return {
      startTime: '',
      allMetrics,
    };
  },
  computed: {
    ...mapState('map', ['t2Mode']),
    ...mapState('satellite', ['screenSize']),
    ...mapState(['isMobile']),
    ...mapGetters('satellite', ['currentTime', 'baseTime', 'timeList']),
    metricT2() {
      const temp = this.allMetrics.filter((item) => !item.private || item.private === 't2');
      if (sessionStorage.getItem('tmode') === 't2') {
        if (this.t2Mode !== TIANJI2_MERGE) {
          return temp.filter((item) => item.type !== 'merge');
        }
        if (this.t2Mode === TIANJI2_MERGE) {
          return temp.filter((item) => item.type !== 'default');
        }
        return temp;
      }
      return temp.filter((item) => item.type !== 'merge' && item.id !== 'tmp2m');
    },
    modeType() {
      return sessionStorage.getItem('tmode');
    },
    isVertical() {
      return this.screenSize.width < 1060;
    },
  },
  created() {
    this.startTime = Date.now();
    this.init();
  },
  mounted() {
    this.setScreenSize({
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    });
    window.onresize = throttle(() => {
      this.setScreenSize({
        width: document.documentElement.clientWidth,
        height: document.documentElement.clientHeight,
      });
    }, 200);
  },
  methods: {
    ...mapMutations('map', ['setMode']),
    ...mapMutations('satellite', [
      'setSatelliteData',
      'setScreenSize',
      'setCurrentBaseTime',
      'setIndex',
    ]),
    async init() {
      const type = '';
      const mode = '';
      const satelliteData = await getSatelliteData(type, mode);
      if (!satelliteData || !satelliteData.length) {
        this.$message('暂无数据');
        return;
      }
      this.setSatelliteData(satelliteData);

      const flag = this.initTimeLine();
      if (!flag) {
        this.setCurrentBaseTime(this.baseTime[0]);
        const now = this.getNowHasBaseTime();
        const flagIndex = this.timeList.findIndex(
          (item) => moment(item.forecastTime).format('YYYY-MM-DD-HH') === now,
        );
        setTimeout(() => {
          flagIndex ? this.setIndex(flagIndex) : this.setIndex(0);
        }, 1000);
      }

      this.$nextTick(() => {
        this.$refs.picture.renderPicture();
      });
    },

    getNowHasBaseTime() {
      const now = new Date();

      // 将时间戳转换为Moment对象
      const momentObj = moment(now);

      // 将小时数加1
      momentObj.add(1, 'hours');

      // 格式化日期时间为指定的格式
      const formattedTime = momentObj.format('YYYY-MM-DD-HH');

      return formattedTime;
    },
    factorChange() {

    },
    upPageTime(time1, time2) {
      const time = Math.ceil((time2 - time1) / (1000 * 60));
      this.$sensors.track('tj_pageTime', {
        tj_stayTime: time,
        page_type: '明日卫星',
        space_type: '二维',
        info: '明日卫星',
        mode: '明日卫星',
      });
      this.startTime = time2;
      // 计算相差的分钟数
    },
    initTimeLine() {
      if (!sessionStorage.getItem('nowTime') || !sessionStorage.getItem('baseTime')) return false;
      const baseIndex = this.baseTime.findIndex(
        (item) => Date.parse(item) === Date.parse(sessionStorage.getItem('baseTime')),
      );

      if (baseIndex !== -1) {
        this.setCurrentBaseTime(this.baseTime[baseIndex]);
        const flagIndex = this.timeList.findIndex(
          (item) => moment(item.forecastTime).format('YYYY-MM-DD-HH')
            === moment(sessionStorage.getItem('nowTime')).format('YYYY-MM-DD-HH'),
        );
        if (flagIndex !== -1) {
          this.setIndex(flagIndex);
          return true;
        }
        return false;
      }
      return false;
    },
  },
  beforeDestroy() {
    this.upPageTime(this.startTime, Date.now());
  },
};
</script>

<style lang="scss" scoped>
.satellite_cloud_wrap {
  height: 100%;
  width: 100%;
  background: #000;
}
</style>
