import colorArray, { extractColorsAndValues } from '@/config/ColorBarArray';

export const factor_log = ['ri_min', 'dust_conc', 'dust_emis', 'dust_ddep', 'dod', 'pratesfcaggr', 'pratesfc', 'prerrt',
  'pres', 'preg', 'pre_total', 'prei', 'pres_alls', 'prer', 'presrt', 'pregrt', 'preirt',
  'base_reflectivity', 'max_reflectivity', 'pratesfcaggr', 'preraggr', 'presaggr', 'preiaggr',
  'pregaggr', 'ice', 'snow', 'rain', 'graupel', 'pres_all', 'dswrfsfc', 'pratesfc_3hr', 'pratesfc_6hr', 'pratesfc_24hr'];
export function calculateHoursDifference(time1, time2) {
  const date1 = new Date(time1);
  const date2 = new Date(time2);
  return Math.abs(date2 - date1) / (1000 * 60 * 60);
}
export function filterTimeList(times, diff) {
  // 保留的时间列表
  const filtered = [];
  // 遍历时间列表
  for (let i = 1; i < times.length; i++) {
    // 计算当前时间点与第一个时间点的差
    const hoursDiff = calculateHoursDifference(times[0].forecastTime, times[i].forecastTime);
    // 对于第二个时间点，检查与第一个时间点的差是否为2小时
    if (filtered.length === 0 && hoursDiff === diff - 1) {
      filtered.push(times[i]);
    } else if (filtered.length > 0) {
      // 对于其他时间点，检查与前一个保留时间点的差是否为3小时
      const prevKeptTime = filtered[filtered.length - 1].forecastTime;
      const nextHoursDiff = calculateHoursDifference(prevKeptTime, times[i].forecastTime);
      if (nextHoursDiff === diff) {
        filtered.push(times[i]);
      }
    }
  }
  return filtered;
}

export function decodePixel(image_data, tile_width) {
  let r; let g; let b;
  const buffer = new ArrayBuffer(24);
  const src = new Uint8Array(buffer);
  const result = new Float32Array(buffer);
  let byte_offset = 4 * tile_width * 4 + 8;
  for (let index = 0; index < 24; index++) {
    r = image_data[byte_offset];
    g = image_data[byte_offset + 1];
    b = image_data[byte_offset + 2];
    r = Math.round(r / 64);
    g = Math.round(g / 16);
    b = Math.round(b / 64);
    src[index] = (r << 6) + (g << 2) + b;
    byte_offset += 16;
  }
  return result;
}

export function reverse(data, data1, max, min, vmax, vmin, factor, baseTime) {
  let result = 0; let windDir = 0;
  if (factor === 'wind') {
    const v1 = (min + data * (max - min) / 255) * 16;
    const v2 = (vmin + data1 * (vmax - vmin) / 255) * 16;
    result = Math.sqrt(v1 ** 2 + v2 ** 2);
    // const r2d = 180 / Math.PI;
    windDir = Math.atan2(v2, v1); // the direction from which wind is blowing
  } else if (factor_log.includes(factor)) {
    const value = min + data * (max - min) / 255;
    result = 10 ** value - 1;
  } else {
    result = (min + data * (max - min) / 255) * 16;
  }

  return [result, windDir];
}

/**
 * 数值进行还原
 * @param result
 * @param max
 * @param min
 * @param vmax
 * @param vmin
 * @param factor
 * @param angle
 * @param baseTime
 * @returns {(number|number)[]}
 */
export function reverseResult(result, max, min, vmax, vmin, factor, angle, baseTime) {
  let data = 0;
  let data1 = 0;
  if (factor === 'wind') {
    // 如果 factor 为 'wind'
    // 根据夹角计算 v1 和 v2
    const v1 = result * Math.cos(angle) / 16;
    const v2 = result * Math.sin(angle) / 16;
    // 反向计算 data 和 data1，并使用 Math.round 进行取整
    data = Math.round((v1 - min) * (255 / (max - min)));
    data1 = Math.round((v2 - vmin) * (255 / (vmax - vmin)));
  } else if (factor_log.includes(factor)) {
    data = Math.round((Math.log10(result + 1) - min) * (255 / (max - min)));
  } else {
    // 其他情况
    data = Math.round((result / 16 - min) * (255 / (max - min)));
  }
  return [data, data1];
}

export function gradienta(sColor, eColor, sValue, eValue, value) {
  const step = ((eValue - sValue) / (value - sValue));
  const rStep = (eColor[0] - sColor[0]) / step;
  const gStep = (eColor[1] - sColor[1]) / step;
  const bStep = (eColor[2] - sColor[2]) / step;
  const aStep = (eColor[3] - sColor[3]) / step;
  return [Math.round(rStep + sColor[0]), Math.round(gStep + sColor[1]), Math.round(bStep + sColor[2]), Math.round(aStep + sColor[3])];
}

export function getGradientColor(name, value) {
  const { colors, values } = extractColorsAndValues(colorArray.color_arrays[name]);
  const colorLength = colors.length;
  if (value <= values[0]) {
    return colors[0];
  }
  if (value >= values[values.length - 1]) {
    return colors[colorLength - 1];
  }
  const ops = getIndex(value, values);

  const sValue = values[ops - 1];
  const eValue = values[ops];
  const sColor = colors[ops - 1];
  const eColor = colors[ops];
  return gradienta(sColor, eColor, sValue, eValue, value);
}

export function getIndex(value, allValue) {
  let low = 0;
  let high = allValue.length - 1;
  let mid;
  while (low <= high) {
    mid = (low + high) >> 1;
    if (allValue[mid] < value) {
      low = mid + 1;
    } else {
      high = mid - 1;
    }
  }
  return low;
}

export function formatDateString(dateString) {
  if (dateString.length !== 12) {
    throw new Error('日期字符串长度必须为12位');
  }

  const year = dateString.slice(0, 4);
  const month = dateString.slice(4, 6);
  const day = dateString.slice(6, 8);
  const hour = dateString.slice(8, 10);
  const minute = dateString.slice(10, 12);
  const second = '00'; // 默认的秒数

  return `${year}-${month}-${day} ${hour}:${minute}:${second}`;
}

export function convertToISO8601WithOffset(timestamp) {
  const year = timestamp.substring(0, 4);
  const month = timestamp.substring(4, 6);
  const day = timestamp.substring(6, 8);
  const hour = timestamp.substring(8, 10);
  const minute = timestamp.substring(10, 12);
  // 使用 Date 对象并设置时区偏移
  const date = new Date(Date.UTC(year, month - 1, day, hour, minute));

  // 调整时间到 UTC+8
  date.setUTCHours(date.getUTCHours() - 8);

  // 转换为 ISO 8601 字符串并替换 'Z' 为 '+00:00'
  let isoString = date.toISOString().replace('Z', '+00:00');

  // 修正小时部分 (此处需要注意的是原始时间的小时部分已经调整过到 UTC+8)
  let fixedHour = hour - 8;
  if (fixedHour < 0) {
    fixedHour = 24 + fixedHour;
    const prevDay = new Date(date);
    prevDay.setUTCDate(prevDay.getUTCDate() - 1);
    isoString = prevDay.toISOString().replace('Z', '+00:00').slice(0, 11)
      + (fixedHour < 10 ? `0${fixedHour}` : fixedHour)
      + isoString.slice(13);
  } else {
    isoString = isoString.slice(0, 11)
      + (fixedHour < 10 ? `0${fixedHour}` : fixedHour)
      + isoString.slice(13);
  }

  return isoString;
}

function formatTimestamp(timestamp) {
  const date = new Date(timestamp * 1000);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  return `${year}${month}${day}${hours}${minutes}`;
}

function generateTimes(initialTimestamp, count, intervalMinutes, location, realData = [], flag) {
  const result = {
    lat: location[0],
    lon: location[1],
    values: [],
  };

  let currentTimestamp = initialTimestamp;

  for (let i = 0; i < count; i++) {
    const value = realData[i] !== undefined ? [realData[i]] : ['0'];
    result.values.push({
      time: formatTimestamp(currentTimestamp),
      value,
    });
    currentTimestamp += intervalMinutes * 60; // 增加 intervalMinutes 分钟的秒数
  }
  if (flag) {
    result.values.shift();
  }
  return result;
}

// 1是危险，2是一般，3是优秀。
export function getPointDataLevel(value) {
  console.log(value, 166);
  if (value < 2) {
    return '不佳';
  } if (value >= 2 && value <= 2.11) {
    return '一般';
  }
  return '好';
}

export const determineFactor = () => {
  const factor = localStorage.getItem('factor');
  if (factor === 'precp') {
    localStorage.setItem('factor', 'tmp2m');
    return 'tmp2m';
  }
  return factor || 'tmp2m';
};

function mergeData(data1, data2) {
  const mergedValues = data1.values.map((item1) => {
    const matchingItem = data2.values.find((item2) => item2.time === item1.time);
    return matchingItem ? { ...item1, value: matchingItem.value } : item1;
  });

  return { ...data1, values: mergedValues };
}

export function handlerPrecpData(data, baseTimestamp) {
  // 起报时间
  const initialTimestamp = data.server_time;
  const precpData = data.result.minutely.precipitation_2h;

  const location = data.location;

  // const precp_25 = generateTimes(baseTimestamp, 25, 5, location,[],true);
  const precp_120 = generateTimes(initialTimestamp, 120, 1, location, precpData, false);
  // 处理 values
  const processedValues = [precp_120.values[0]]; // 保留第一个元素
  // 过滤后续元素
  const filteredValues = precp_120.values.slice(1).filter((item) => {
    const timeNum = parseInt(item.time);
    return timeNum % 5 === 0; // 检查是否能被5整除
  });

  // 合并结果
  processedValues.push(...filteredValues);

  // 更新原对象
  precp_120.values = processedValues;

  return precp_120;
}

export function transTimestamp(timeStr, mode) {
  // 检查时间字符串格式
  let date;
  // 若字符串包含 "T"，则认为是 UTC 时间，需进行转换
  if (timeStr.includes('T') && mode === 't1' && !timeStr.includes('+08')) {
    // 创建 Date 对象，认为它是 UTC 时间
    date = new Date(timeStr);
    // 转换为东八区时间
    date = new Date(date.getTime() + 8 * 60 * 60 * 1000);
  } else {
    // 否则，直接使用这个时间字符串
    // 直接创建 Date 对象
    date = new Date(timeStr);
  }
  // 获取时间戳
  return Date.parse(date);
}

export function debounce(func, delay) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}
