<template>
  <div class="home">
    <v-map ref="map" />
    <v-loading :loading="loading" />
    <v-title
      title="中科天机"
      border
      @change="$router.push('/')"
    >
      <v-search
        v-if="!isMobile"
        slot="search"
        @locate="setLocation"
      />
    </v-title>

    <v-map-tile model-type="t2" />
    <v-legend
      :factor="factor"
      :mobile="isMobile"
    />
    <v-control
      show
      @setting="showSetting = !showSetting;"
    />
    <v-player v-show="isShowTimeLine && !isMobile" model-type="t1" />
    <v-elevation v-if="!isMobile && elevationList && elevationList.length" />
    <v-bottom-more :metrics="metricT2" @controlTimeLine="controlTimeLine" />
    <template v-if="!isMobile">
      <!-- <v-bottom-more :metrics="metricT2" @controlTimeLine="controlTimeLine" /> -->
      <!-- <v-bottom-moredust :metrics="dustMetrics" :config="'map'" @controlTimeLine="controlTimeLine" /> -->
      <v-metric-select
        :factor="factor"
        :metrics="metricT2"
        @change="factorChange"
      />
    </template>

    <!-- <template v-else>
      <v-mobile-bottom-more :metrics="metricT2" />
      <v-mobile-bottom-more  :config="'map'" :metrics="dustMetrics" />
      <v-mobile-operate-menu
        :factor="factor"
        :windy="windy"
        :metrics="metricT2"
        :init-base-time="currentBaseTime"
        :base-time="baseTime"
        @handleBaseTimeChange="val => setCurrentBaseTime(val)"
        @change="factorChange"
        @setWindy="setWindy"
      />
    </template> -->
    <v-info v-if="!isMobile" keywords="tianji2" />
    <v-mode v-if="!isMobile" />
    <v-layer-select v-if="!isMobile" model-type="3D" />
    <mobile-right-btn
      v-if="isMobile"
      model-type="3D"
      :factor="factor"
      :metrics="metricT2"
      @change="factorChange"
      @locate="setLocation"
    />
    <time-line v-if="isMobile" />
    <popup v-if="isMobile" />
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import { getDataByFactor } from '@/api/weather_hd';
import { factorMap, mapForCn, allMetrics, dustMetrics, dust_factorMap } from '@/config/MapConfigT4';
import Bus from '@/bus/index';
import VLegend from '@/components/Map/Legend.vue';
import VControl from '../../components/CesiumMap/ControlCesium.vue';
import VTitle from '@/components/Map/Title.vue';
import VSearch from '@/components/Map/Search.vue';
import VMetricSelect from '@/components/Map/MetricSelect.vue';
import VMobileOperateMenu from '@/components/Map/MobileOperateMenu.vue';
import VBottomMore from '@/components/Map/FactorDataMore.vue';

import VMobileBottomMore from '@/components/Map/MobileFactorDataMore.vue';

import VMobileBottomMoredust from '@/components/Map/MobileFactorDataMore.vue';

import VElevation from '@/components/Map/Elevation.vue';
import VInfo from '@/components/Map/Info.vue';
import VLayerSelect from '@/components/Map/LayerSelect.vue';
import VMode from './components/Mode.vue';
import VMapTile from '@/components/Map/MapTitle.vue';
import VPlayer from './components/PlayerNew.vue';
import { TIANJI1_DA, TIANJI1_ND, TIANJI2_DA, TIANJI2_MERGE, TIANJI2_ND } from '@/config';
import VMap from './cesiumMap.vue';
import VLoading from '@/components/Map/Loading.vue';
import MobileRightBtn from '@/components/Map/mobile/MobileRightBtn.vue';
import TimeLine from '@/components/Map/mobile/TimeLine.vue';
import Popup from '@/components/Map/mobile/Popup.vue';

export default {
  name: 'Home',
  components: {
    VLayerSelect,
    VElevation,
    VMap,
    VPlayer,
    VMode,
    VLegend,
    VTitle,
    VMetricSelect,
    VControl,
    VMobileOperateMenu,
    VMobileBottomMoredust,
    VSearch,
    VBottomMore,
    VMobileBottomMore,
    VInfo,
    VMapTile,
    VLoading,
    MobileRightBtn,
    TimeLine,
    Popup,
  },
  data() {
    return {
      showSetting: false,
      allMetrics,
      startTime: '',
      dustMetrics,
      isShowTimeLine: true,
      dustFactors: ['dust_conc', 'dod', 'dust_ddep', 'dust_emis'],
    };
  },
  computed: {
    ...mapState('map', ['t2Mode', 'timeType', 'factor', 'windy', 'isobar', 'currentBaseTime', 'loading']),
    ...mapState(['isMobile']),
    ...mapGetters('map', ['baseTime', 'currentTime', 'elevationList', 'currentMode', 'timeList', 'current', 'index']),
    metricT2() {
      const dusts = ['dust_conc', 'dod', 'dust_ddep', 'dust_emis'];
      const temp = this.allMetrics.filter((item) => !item.private || item.private === 't2');
      if (this.t2Mode !== TIANJI2_MERGE) {
        return temp.filter((item) => item.type !== 'merge' && (!item.isPage || dusts.includes(item.id)));
      }
      if (this.t2Mode === TIANJI2_MERGE) {
        return temp.filter((item) => item.type !== 'default' && (!item.isPage || dusts.includes(item.id)));
      }
      return temp;
    },
    // 当前要素对应的key
    factorKey() {
      return factorMap[this.factor];
    },
    // 判断当前时间是否在预报的数据列表里面
    hasBaseTime() {
      return this.baseTime.includes(this.currentBaseTime);
    },
  },
  watch: {
    t2Mode() {
      this.upPageTime(this.startTime, Date.now());
      this.clearLayer();
      this.init();
    },
  },
  created() {
    // const flag = ['dust_conc', 'dod', 'dust_emis', 't2mz', 'tmp2m', 'wgrd10m', 'w100m'].includes(this.factor);
    // const timeType = localStorage.getItem('timeType') && flag ? JSON.parse(localStorage.getItem('timeType')) : {
    //   type: 1,
    //   timeLength: 24,
    // };
    // this.$store.commit("map/setTimeType", timeType);
    this.startTime = Date.now();
    this.init();
  },
  methods: {
    ...mapMutations('map', ['setFactorData', 'setCurrentBaseTime', 'setStatus', 'setFactor', 'setWindData', 'setIndex', 'setWindy', 'setIsobar', 'setLoading']),
    /**
     * @description 根据要素获取预测数据
     * @returns {Promise}
     * @author yujie
     */
    async init() {
      this.setLoading(true);
      if (['tmp2m', 't2mz'].includes(this.factor)) {
        this.setFactor(this.t2Mode === TIANJI2_MERGE ? 'tmp2m' : 't2mz');
      }
      console.log(this.t2Mode, 183);

      let mode = this.currentMode ?? 't2';
      if (dust_factorMap[this.factor]) {
        if (this.t2Mode === TIANJI2_DA || this.t2Mode === TIANJI2_ND || this.t2Mode === TIANJI1_ND || this.t2Mode === TIANJI1_ND) {
          mode = 't1';
        } else {
          mode = this.currentMode;
        }
      }

      const data = (await getDataByFactor(this.factor, mode)).filter((item) => {
        if (this.t2Mode === TIANJI2_ND || this.t2Mode === TIANJI1_ND) {
          return item.mode === 'late';
        }
        if (this.t2Mode === TIANJI2_DA || this.t2Mode === TIANJI1_DA) {
          return item.mode === 'early';
        }
        return item;
      });
      if (!data || !data.length) {
        this.setLoading(false);
        this.$message('当前要素无预测数据');
        return;
      }

      // const flag = ["dust_conc", "dod", "dust_emis", "t2mz", "tmp2m", "wgrd10m", "w100m"].includes(
      //   this.factor
      // );

      // !flag && this.timeType.timeLength !== 24
      //   ? this.$store.commit("map/setTimeType", {
      //       type: 1,
      //       timeLength: 24,
      //     }) : '';

      this.setFactorData(data);
      if (this.factor === 'w100m' || this.factor === 'wgrd10m') {
        this.setWindData(data);
      }
      if (!this.hasBaseTime) {
        // 切换天机1 天机2，已经有起报时间了, 更新起报时间
        const flag = this.initTimeLine();
        if (flag) return;

        this.setCurrentBaseTime(data[0].baseTimeModel);
        const now = this.getNowHasBaseTime();
        const flagIndex = this.timeList.findIndex((item) => Date.parse(item.forecastTime) === Date.parse(now));
        flagIndex ? this.setIndex(flagIndex) : this.setIndex(0);
      } else {
        // eslint-disable-next-line no-lonely-if
        if (this.currentBaseTime) {
          const times = this.currentBaseTime.split(' ');
          times[1] = data[0].baseTimeModel.split(' ')[1];
          this.setCurrentBaseTime(times.join(' '));
        }
        // let currentDate = this.currentBaseTime;
        // let flag = this.initTimeLine();
        // if(!flag) {
        //   // console.log('索引', this.index);
        //   this.setCurrentBaseTime(currentDate);
        //   console.log('this.current.forecastTime', this.current);
        //   sessionStorage.setItem('nowTime', this.current.forecastTime);
        //   // this.setIndex(this.index);
        // }
      }
    },
    upPageTime(time1, time2) {
      const time = Math.ceil((time2 - time1) / (1000 * 60));
      this.$sensors.track('tj_pageTime', {
        tj_stayTime: time,
        page_type: '天机2',
        space_type: '三维',
        info: mapForCn[this.factor],
        mode: this.t2Mode,
      });
      this.startTime = time2;
      // 计算相差的分钟数
    },
    initTimeLine() {
      if (!sessionStorage.getItem('nowTime') || !sessionStorage.getItem('baseTime')) return false;

      const baseIndex = this.baseTime.findIndex((item) => {
        item = item.split(' ')[0];
        return Date.parse(item) === Date.parse(sessionStorage.getItem('baseTime'));
      });

      if (baseIndex !== -1) {
        this.setCurrentBaseTime(this.baseTime[baseIndex]);
        const flagIndex = this.timeList.findIndex((item) => Date.parse(item.forecastTime) === Date.parse(sessionStorage.getItem('nowTime')));
        if (flagIndex !== -1) {
          this.setIndex(flagIndex);
          return true;
        }
        return false;
      }
      return false;
    },
    setLocation(position) {
      Bus.$emit('setLocation', position);
    },
    getNowHasBaseTime() {
      const base = this.timeList[0].forecastTime;
      const date = base.split('T')[0].split('-');
      const time = base.split('T')[1].split(':');

      const now = new Date();

      date[1] = `0${now.getMonth() + 1}`.slice(-2);
      date[2] = `0${now.getDate()}`.slice(-2);

      time[0] = `0${Number(now.getHours()) + 1}`.slice(-2);

      return `${date.join('-')}T${time.join(':')}`;
    },
    /**
     * @description 要素改变事件
     * @returns {void}
     * @author yujie
     * @param metric
     */
    factorChange(metric) {
      console.log(metric.id, 296);
      this.upPageTime(this.startTime, Date.now());
      this.clearLayer();
      Bus.$emit('clearHeatLayer', metric.id);
      this.setStatus('pause');
      this.setFactor(metric.id);
      localStorage.setItem('factor', metric.id);
      this.init();
    },
    controlTimeLine(flag) {
      this.isShowTimeLine = flag;
    },
    clearLayer() {
      Bus.$emit('removePopup');
      Bus.$emit('hiddenBottom');
      Bus.$emit('clearLayer');
    },
  },
  beforeDestroy() {
    this.upPageTime(this.startTime, Date.now());
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  width: 100%;
  position: relative;
}
</style>
