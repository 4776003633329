<template>
  <div
    v-show="modelType === 't1' || modelType === 't2' || modelType === 'satellite'"
    class="tile"
  >
    {{ modelInfo }}&nbsp;&nbsp;{{ date }}&nbsp;&nbsp;{{ hours }}
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';
import { dust_factorMap } from '@/config/MapConfigT4';

export default {
  name: 'MapTitle',
  props: {
    modelType: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('map', ['currentBaseTime', 'factor', 'region', 'model', 't2Mode']),
    ...mapGetters('map', ['baseTime', 'currentTime', 'isT2Mode']),
    ...mapState(['currentData', 'currentBaseTime', 't1Mode', 'factor']),
    ...mapGetters('satellite', ['currentSatelliteTime']),
    baseTime: {
      get() {
        const [time, model] = this.currentBaseTime.split(' ');
        return `${moment(time).format('YYYY.MM.DD HH时')} ${model}`;
      },
    },
    date: {
      get() {
        if (this.modelType === 't2') {
          return moment(this.currentTime).format('YYYY-MM-DD');
        } if (this.modelType === 't1') {
          return moment(this.currentData.timestamp).format('YYYY-MM-DD');
        } if (this.modelType === 'satellite') {
          return moment(this.currentSatelliteTime).format('YYYY-MM-DD');
        }
        return '';
      },
    },
    hours: {
      get() {
        if (this.modelType === 't2' && this.currentTime) {
          return `${moment(this.currentTime).format('HH')}:00${this.getDesc(this.currentTime)}`;
        } if (this.factor === 'precp' && this.currentData && this.currentData.timestamp) {
          return `${moment(this.currentData.timestamp).format('HH:mm')}${this.getDesc(this.currentData.timestamp)}`;
        }
        if (this.modelType === 't1' && this.currentData && this.currentData.timestamp) {
          return `${moment(this.currentData.timestamp).format('HH')}:00${this.getDesc(this.currentData.timestamp)}`;
        } if (this.modelType === 'satellite' && this.currentSatelliteTime) {
          console.log(this.currentSatelliteTime);
          return `${moment(this.currentSatelliteTime).format('HH')}:00${this.getDesc(this.currentSatelliteTime)}`;
        }
        return '';
      },
    },

    modelInfo: {
      get() {
        if (this.modelType === 't2') {
          if (dust_factorMap[localStorage.getItem('factor')]) {
            return this.t2Mode.replace(2, 1);
          }
          return this.t2Mode;
        } if (this.modelType === 't1') {
          if (this.factor === 'precp') {
            return '彩云/短临';
          }
          return this.t1Mode;
        } if (this.modelType === 'satellite') {
          return '明日云图';
        }
        return '';
      },
    },
  },

  methods: {
    getDesc(times) {
      if (times) {
        const time = moment(times).tz('Asia/Shanghai').format('YYYY-MM-DD HH:mm:ss');
        const click_time = moment(time, 'YYYYMMDDHHmm');
        const duration = moment.duration(click_time.diff(moment.now()));
        const { _data } = duration;
        const { days, hours, minutes } = _data;
        let text = '';
        let desc = '，距现在';
        if (days < 0 || hours < 0 || minutes < 0) {
          text = '以前';
        } else if (days === 0 && hours === 0 && minutes === 0) {
          return '，现在';
        } else {
          text = '以后';
        }
        if (days !== 0) {
          desc += `${Math.abs(days)}天`;
        }
        if (hours !== 0) {
          desc += `${Math.abs(hours)}时`;
        }
        if (minutes !== 0) {
          desc += `${Math.abs(minutes)}分`;
        }
        return desc + text;
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
  .tile {
    position: absolute;
    z-index: 100000;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    top: 0;
    left: 50%;
    height: 25px;
    line-height: 25px;
    padding: 0 15px;
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.55);
    backdrop-filter: blur(1px);
    border-radius: 0 0 6px 6px;
    font-size: 12px;
    color: #333;
  }
</style>
