import { TIANDITU_KEY } from '@/config';
import { http } from './http';
/**
 * @description 根据要素和预报时间获取要素预报
 * @param baseTime
 * @param {String} factorCode 要素id
 * @param mode
 * @returns {Promise}
 * @author yujie
 */
export const getFactorcast = (baseTime, factorCode, mode) => http.get('/factor/forecast', { params: { baseTime, factorCode, mode } });

/**
 * @description 获取预测时间列表
 * @param {String} factorCode 要素id
 * @returns {Promise}
 * @author yujie
 */
export const getBaseTime = (factorCode) => http.get('/common/base-time/available', { params: { factorCode } });

/**
 * @description 获取可预测的要素
 * @returns {Promise}
 * @author yujie
 */
export const getAllFactor = () => http.get('/common/ddl/factor');

/**
 * @description 获取各个要素最新base time第一张图片
 * @returns {Promise}
 * @author yujie
 */
export const getFirst = () => http.get('/factor/base-latest');

/**
 * @description 获取风场距离当前时间最近的数据
 * @returns {Promise}
 * @author yujie
 */
export const getCurrentWind = () => http.get('/factor/3d/wind');

/**
 * @description 根据关键字获取天地图位置
 * @returns {Promise}
 * @author guoqb
 */
export const getLocation = (keyWord) => http.get('https://api.tianditu.gov.cn/geocoder', {
  params: {
    ds: { keyWord },
    tk: TIANDITU_KEY,
  },
});

export const getLocationNameList = (keyWord) => http.get('https://api.tianditu.gov.cn/v2/search', {
  params: {
    type: 'query',
    tk: TIANDITU_KEY,
    postStr: { keyWord, level: '18', mapBound: '-180,-90,180,90', queryType: '4', start: '0', count: '10' },
  },
});
