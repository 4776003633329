<template>
  <div class="metric-select">
    <div class="metric-wrap" @mouseover="showAllMenu = true" @mouseleave="handleMouseLeave">
      <ul>
        <li
          v-for="metric in currentData"
          :key="metric.id"
          :class="{
            active: isTyp
              ? metric.id === 'typhoon'
              : isSatel
                ? metric.id === 'satellite'
                : metric.id === factor || showParent(metric),
          }"
          :style="{
            width: metric.id === factor || showAllMenu || showParent(metric) ? '130px' : '42px',
          }"
          @click="handler(metric)"
        >
          <div
            class="text-wrap"
            :class="metric.show && metric.children ? 'expand-second' : ''"
            @click="toggleChange(metric)"
          >
            <div class="icon">
              <svg-icon :icon-class="metric.icon" />
            </div>
            <div
              v-show="metric.id === factor || showAllMenu || showParent(metric)"
              class="text"
              @mouseenter="activeID = metric.id"
              @mouseleave="activeID = ''"
            >
              <span>{{ metric.text }}</span>
              <i
                v-if="metric.children"
                :class="metric.show ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
              />
              <div
                v-show="
                  activeID === metric.id
                    && metric.id !== 'satellite'
                    && metric.id !== 'typhoon'
                    && !metric.children
                    && haveFactor(metric.id)
                "
                class="qrShow"
                style="height: 26px; line-height: 26px"
                v-html="getRatio(metric)"
              />
            </div>
          </div>
          <ul
            v-show="(metric.show && showAllMenu) || (metric.show && showParent(metric))"
            class="second transition-box"
          >
            <li
              v-for="sub in handleActiveMetric(metric.children)"
              :key="sub.id"
              @click="handler(sub, 'second', metric)"
            >
              <div
                class="text-wrap"
                :class="{
                  active: isTyp
                    ? metric.id === 'typhoon'
                    : isSatel
                      ? metric.id === 'satellite'
                      : sub.id === factor,
                }"
                @mouseenter="activeID = sub.id"
                @mouseleave="activeID = ''"
              >
                <div class="text" style="position: relative">
                  {{ sub.text }}
                  <div
                    v-show="
                      activeID === sub.id
                        && sub.id !== 'satellite'
                        && sub.id !== 'typhoon'
                        && haveFactor(sub.id)
                    "
                    class="qrShow"
                    :style="
                      ['pre_total', 'pres_all', 'prer', 'pres', 'preg', 'prei'].includes(sub.id)
                        ? 'top: -7px;line-height: 21px;'
                        : 'top: 0'
                    "
                    v-html="getRatio(sub)"
                  />
                </div>
              </div>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import { drag } from '@/utils/drag';
import { TIANJI2_DA, TIANJI2_MERGE, TIANJI2_ND } from '@/config';
import { modeFactorMap, factorMap, mapForCn } from '@/config/MapConfigT4';

export default {
  name: 'MetricSelect',
  props: {
    factor: {
      type: String,
      required: true,
    },
    metrics: {
      type: Array,
      default: () => [],
    },
    isTyp: {
      type: Boolean,
      default: false,
    },
    isSatel: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 't2',
    },
  },
  data() {
    return {
      currentData: [],
      showAllMenu: true,
      activeID: '',
      isMove: false,
    };
  },
  computed: {
    ...mapState('map', ['t2Mode']),
    ...mapState(['t1Mode']),
  },
  watch: {
    metrics() {
      this.initData();
    },
  },
  mounted() {
    drag(this.$el);
    this.$el.addEventListener('mousedown', (event) => {
      this.handleMouseDown();
    });
    this.$el.addEventListener('mouseup', (event) => {
      this.handleMouseUp();
      setTimeout(() => {
        this.isMove = false;
      }, 500);
    });
  },
  created() {
    this.initData();
  },
  methods: {
    ...mapMutations('dust', ['setFactor']),
    // 组件中的方法
    handleMouseDown() {
      this.$el.addEventListener('mousemove', this.handleMouseMove);
    },
    handleMouseMove(event) {
      // 监听鼠标移动事件时的逻辑
      this.isMove = true;
    },
    handleMouseUp() {
      this.$el.removeEventListener('mousemove', this.handleMouseMove);
    },
    handleActiveMetric(metric = []) {
      return this.showAllMenu ? metric : metric.filter((item) => item.id === this.factor);
    },
    getRatio(metric) {
      return this.t2Ratio(metric);
      // if (this.isTyp || this.isSatel) {
      //   //如果列表是t2此时取t2的模式，否则取t1的模式
      //   if (sessionStorage.getItem("tmode") === "t2") {
      //     return this.t2Ratio(metric);
      //     //天机1时
      //   } else {
      //     return ["pre_total", "pres_all", "prer", "pres", "preg", "prei"].includes(metric.id)
      //       ? "12km<br/>自起报时累计"
      //       : "12km";
      //   }
      // } else {
      //   //以天机2为准
      //   if (this.$route.name === "weather_hd" || this.$route.name === "earth") {
      //     return this.t2Ratio(metric);
      //   } else {
      //     return ["pre_total", "pres_all", "prer", "pres", "preg", "prei"].includes(metric.id)
      //       ? "12km<br/>自起报时累计"
      //       : "12km";
      //   }
      // }
    },
    t2Ratio(metric) {
      // if (this.t2Mode === TIANJI2_MERGE) {
      if (['tmp2m', 't2mz'].includes(metric.id)) {
        return '1km';
      } if (['dust_conc', 'dod', 'dust_ddep', 'dust_emis'].includes(metric.id)) {
        return '12km';
      } if (['pratesfc', 'prerrt', 'pregrt', 'preirt', 'presrt', 'max_reflectivity', 'base_reflectivity'].includes(metric.id)) {
        return '2.5km';
      } if (['prer', 'pres', 'preg', 'prei', 'pre_total', 'pres_all'].includes(metric.id)) {
        return '2.5km<br/>自起报时累计';
      }
      return '10km';
        
      // } else {
      //   return ["pre_total", "pres_all", "prer", "pres", "preg", "prei"].includes(metric.id)
      //     ? "2.5km<br/>自起报时累计"
      //     : "2.5km";
      // }
    },
    showParent(metric) {
      return metric.subId && !this.isTyp && !this.isSatel
        ? metric.subId.includes(this.factor)
        : false;
    },
    handleMouseLeave() {
      this.currentData = this.metrics.map((metric) => {
        metric.show = metric.id === this.factor || this.showParent(metric);
        return metric;
      });
      // this.showAllMenu = false;
    },
    initData() {
      this.currentData = this.metrics.map((metric) => {
        metric.show = metric.id === this.factor || this.showParent(metric);
        return metric;
      });
    },
    toggleChange(metric) {
      if (metric.children && metric.children.length) {
        this.currentData = this.currentData.map((item) => {
          if (item.id === metric.id) {
            item.show = !item.show;
          } else {
            item.show = false;
          }
          return item;
        });
      }
    },

    haveFactor(id) {
      const mapType = sessionStorage.getItem('tmode') === 't1'
        ? modeFactorMap[this.$store.state.t1Mode]
        : modeFactorMap[this.$store.state.map.t2Mode];
      return mapType.includes(id);
    },

    handler(metric) {
      if (this.isMove) return;
      const factor = sessionStorage.getItem('tmode') === 't1'
        ? this.$store.state.factor
        : this.$store.state.map.factor;
      if (metric.children && metric.children.length) {
        console.log('factor, metric', factor, metric);
        if (!metric.subId.includes(factor) || this.isTyp || this.isSatel) {
          metric = metric.children[0];
        } else {
          return;
        }
      }
      this.$sensors.track('Jump_volume', {
        from_factor: mapForCn[factor],
        from_mode: sessionStorage.getItem('tmode') === 't1' ? this.t1Mode : this.t2Mode,
        from_space_type: this.$route.name === 'earth' ? '三维' : '二维',
        to_mode: sessionStorage.getItem('tmode') === 't1' ? this.t1Mode : this.t2Mode,
        to_factor: metric.text,
        to_space_type: this.$route.name === 'earth' ? '三维' : '二维',
      });
      // 如果是页面则跳转
      if (metric.isPage) {
        this.$sensors.track('CurrentPage', {
          page_type: metric.routerName,
          space_type: this.$route.name === 'earth' ? '三维' : '二维',
        });
        // 如果是沙城要素记录
        if (metric.routerName === 'dust') {
          this.$sensors.track('CurrentFactor', {
            info: `${metric.text}`,
            mode: '聚合',
            page_type: '沙尘',
            space_type: this.$route.name === 'earth' ? '三维' : '二维',
          });

          if (!modeFactorMap[this.$store.state.map.t2Mode].includes(metric.id)) {
            this.$store.commit('map/setMode', TIANJI2_MERGE);
          }
          this.setFactor(metric.id);
        } else {
          this.$sensors.track('CurrentPage', {
            page_type: metric.text,
            space_type: this.$route.name === 'earth' ? '三维' : '二维',
          });
        }
        if (this.$route.name === 'earth' && metric.routerName === 'dust') {
          this.$emit('change', metric);
          return;
        }
        this.$router.push({ name: metric.routerName });
        return;
      }
      console.log('window.history.length', window.history);
      // 如果是台风或明日卫星切回来则做对应记录
      if (this.isTyp || this.isSatel) {
        if (sessionStorage.getItem('tmode') === 't1') {
          const mapType = modeFactorMap[this.$store.state.t1Mode];
          if (!mapType.includes(metric.id)) {
            let str = '';
            for (const key in modeFactorMap) {
              const item = modeFactorMap[key];
              if (item.includes(metric.id) && !str) {
                str = key;
              }
            }

            if (str.includes('天机1')) {
              this.$store.commit('setMode', str);
              this.$store.commit('setFactor', metric.id);
              this.$router.push({ name: 'weather' });
            } else {
              this.$store.commit('map/setMode', str);
              this.$store.commit('map/setFactor', metric.id);
              this.$router.push({ name: 'weather_hd' });
            }
          } else {
            this.$store.commit('setFactor', metric.id);
            this.$router.push({ name: 'weather' });
          }
        }
        // t2
        else {
          const mapType = modeFactorMap[this.$store.state.map.t2Mode];
          if (!mapType.includes(metric.id)) {
            let str = '';
            for (const key in modeFactorMap) {
              const item = modeFactorMap[key];
              if (item.includes(metric.id) && !str) {
                str = key;
              }
            }

            if (!str.includes('天机1')) {
              this.$store.commit('map/setMode', str);
              this.$store.commit('map/setFactor', metric.id);
              this.$router.push({ name: 'weather_hd' });
            } else {
              this.$store.commit('setMode', str);
              this.$store.commit('setFactor', metric.id);
              this.$router.push({ name: 'weather' });
            }
          } else {
            this.$store.commit('map/setFactor', metric.id);
            this.$router.push({ name: 'weather_hd' });
          }
        }
        this.$sensors.track('CurrentFactor', {
          info: `${metric.text}`,
          mode: sessionStorage.getItem('tmode') === 't1' ? this.t1Mode : this.t2Mode,
          space_type: this.$route.name === 'earth' ? '三维' : '二维',
          page_type: sessionStorage.getItem('tmode') === 't1' ? '天机1' : '天机2',
        });
        return;
      }

      const mapType = sessionStorage.getItem('tmode') === 't1'
        ? modeFactorMap[this.$store.state.t1Mode]
        : modeFactorMap[this.$store.state.map.t2Mode];
      // 如果当前模式不存在该要素则找最近得模式进行切换展示
      if (!mapType.includes(metric.id)) {
        let str = '';
        for (const key in modeFactorMap) {
          const item = modeFactorMap[key];
          if (item.includes(metric.id) && !str) {
            str = key;
          }
        }

        if (sessionStorage.getItem('tmode') === 't1') {
          if (str.includes('天机1')) {
            this.$store.commit('setMode', str);
            this.$store.commit('setFactor', metric.id);
          } else {
            this.$store.commit('map/setMode', str);
            this.$store.commit('map/setFactor', metric.id);
            this.$router.push({ name: 'weather_hd' });
          }
        } else if (!str.includes('天机1')) {
          this.$store.commit('map/setMode', str);
          this.$store.commit('map/setFactor', metric.id);
        } else {
          this.$store.commit('setMode', str);
          this.$store.commit('setFactor', metric.id);
          this.$router.push({ name: 'weather' });
        }
        return;
      }

      if (!factorMap[metric.id]) {
        this.$message('当前要素正在开发中，请耐心等待。');
        return;
      }

      // 其余情况正常切换要素
      this.$sensors.track('CurrentFactor', {
        info: `${metric.text}`,
        space_type: this.$route.name === 'earth' ? '三维' : '二维',
        mode:
          this.$route.name === 'weather_hd' || this.$route.name === 'earth'
            ? this.t2Mode
            : this.t1Mode,
        page_type:
          this.$route.name === 'weather_hd'
            ? '天机2'
            : this.$route.name === 'earth'
              ? '三维'
              : '天机1',
      });
      this.$emit('change', metric);
    },
  },
};
</script>

<style lang="scss" scoped>
$primary-color: #05f;
$grey-bg: rgba(50, 50, 50, 0.5);

.metric-select {
  position: absolute;
  left: 20px;
  top: 20px;
  color: $text-operate;
  z-index: 1000;
  .qrShow {
    position: absolute;
    left: 111%;
    z-index: 999;
    background-color: rgba(66, 66, 66, 0.55);
    width: 93px;
    height: auto;
    color: white;
    text-align: center;
    border-radius: 6px;
    font-family: sans-serif;
    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border: 8px solid rgba(0, 0, 0, 0);
      border-right-color: rgba(66, 66, 66, 0.55);
      right: 100%;
      top: 50%;
      margin-top: -7px;
    }
  }
}

.metric-wrap {
  > ul {
    position: relative;
    border-radius: 25px;

    > li {
      box-sizing: border-box;
      position: relative;
      cursor: pointer;
      margin-bottom: 2px;

      > .text-wrap {
        border-radius: 6px;
        display: flex;
        background: rgba(66, 66, 66, 0.55);
        backdrop-filter: blur(1px);
        align-items: center;
        padding: 2px 0;

        &.expand-second {
          border-radius: 6px 6px 0 0;
          background-color: rgba(66, 66, 66, 0.55) !important;
        }

        .text {
          font-size: 12px;
          line-height: 32px;
          position: relative;
          display: flex;
          flex: 1;
          justify-content: space-between;
          align-items: center;
          text-shadow: 0px 0px 4px black;

          i {
            margin-right: 10px;
          }
        }
      }

      .second {
        .text-wrap {
          height: 26px;
          background: rgba(66, 66, 66, 0.55);
        }

        .text {
          line-height: 26px;
          margin-left: 32px;
          font-size: 12px;
          text-shadow: 0px 0px 4px black;
        }
      }

      .icon {
        border-radius: 6px;
        height: 32px;
        width: 32px;
        line-height: 32px;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .active {
        &.text-wrap {
          background: #027aff;
        }
      }

      .text-wrap:hover,
      &.active > .text-wrap {
        background-color: #027aff;

        > .text {
          color: $text-operate;
        }
      }
    }
  }
  .text-wrap {
    svg {
      path {
        fill: #ffffff !important;
      }
    }
  }
  .second li {
    .text-wrap {
      &:hover,
      &.active {
        background: #027aff;
      }
    }

    &:last-child {
      .text-wrap {
        border-radius: 0 0 6px 6px;
      }
    }
  }
}
</style>
