<template>
  <div
    class="player"
    :class="{
      mobile_player_t2: isMobile,
    }"
  >
    <div class="drag_wrap">
      <div class="drag_container">
        <div class="operator-wrap">
          <v-base-time-select v-if="!isMobile" ref="timeSelect" />
          <div class="player_wrap">
            <div v-if="!isMobile" class="picker_wrap">
              <div class="label">日期</div>
              <el-date-picker
                v-model="date"
                type="date"
                placeholder="选择日期"
                format="yyyy.MM.dd"
                :picker-options="{ disabledDate }"
                :clearable="false"
              />
            </div>
            <div v-if="!isMobile && timeShow" class="timeLong">
              <div class="label">频率</div>
              <div
                v-click-outside="
                  () => {
                    showList = false;
                  }
                "
                class="timeItem"
                @click="showList = !showList"
              >
                {{ checkTimeName }}
                <div v-show="showList" class="timeCheck">
                  <div
                    v-for="(item, index) in timeCheckList"
                    :key="index"
                    class="item"
                    :class="{ chcek: checkType === item.type }"
                    @click.stop="checkTime(item)"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="now" @click="toNow">现在</div>
            <template v-if="isMobile">
              <v-icon-word
                v-if="status === 'pause'"
                icon="play"
                desc="播放"
                spec
                @click.native="play"
              />
              <v-icon-word
                v-else
                icon="pause"
                desc="暂停"
                spec
                @click.native="pause"
              />
            </template>
            <v-icon-word
              icon="p-first"
              desc="第一张"
              spec
              c-icon
              @click.native="goToFrame(0)"
            />
            <v-icon-word
              icon="p-prev"
              desc="上一张"
              spec
              c-icon
              @click.native="prev"
            />
            <template v-if="!isMobile">
              <v-icon-word
                v-if="status === 'pause'"
                icon="play"
                desc="播放"
                spec
                center
                @click.native="play"
              />
              <v-icon-word
                v-else
                icon="pause"
                desc="暂停"
                spec
                @click.native="pause"
              />
            </template>
            <el-select v-else v-model="time">
              <el-option
                v-for="item in timeList"
                :key="item.forecastTime"
                :label="item.forecastTime | formatTimestamp"
                :value="item.forecastTime"
              />
            </el-select>
            <v-icon-word
              icon="p-next"
              desc="下一张"
              spec
              c-icon
              @click.native="next"
            />
            <v-icon-word
              icon="p-last"
              desc="最后一张"
              spec
              c-icon
              @click.native="goToFrame(timeList.length - 1)"
            />
          </div>
        </div>
      </div>
    </div>
    <v-timeline
      v-if="!isMobile && checkType !== 24"
      :index="hourIndex"
      model-type="t2"
      @change="setTime"
    />
    <v-day-timeline v-else-if="!isMobile && checkType === 24" :index="index" />
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import moment from 'moment';
import vClickOutside from '@/plugins/v-click-outside/v-click-outside';
import Bus from '@/bus/index';
import { drag } from '@/utils/drag';
import VIconWord from '@/components/Map/IconWord.vue';
import { mapForCn } from '@/config/MapConfigT4';
import { factorMap } from '@/config/MapConfig';
// import VAreaSelect from '@/components/Map/AreaSelect.vue';
// import VElevation from '@/components/Map/Elevation.vue';
import VTimeline from '@/components/Map/Timeline.vue';
import VDayTimeline from '../../map_hd/components/DayTimeLine.vue';
import VBaseTimeSelect from './BaseTImeSelectNew.vue';

export default {
  directives: {
    clickOutside: vClickOutside,
  },
  components: {
    VIconWord,
    VDayTimeline,
    VTimeline,
    VBaseTimeSelect,
    // VElevation,
    // VAreaSelect,
  },

  filters: {
    formatTimestamp(val) {
      return moment(val).format('YYYY-MM-DD HH:mm:ss');
    },
  },
  props: {
    t3: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      position: 1,
      hourIndex: 1,
      showList: false,
      timeCheckList: [
        { type: 1, name: '1小时' },
        { type: 3, name: '3小时' },
        { type: 6, name: '6小时' },
        { type: 24, name: '24小时' },
      ],
    };
  },

  computed: {
    ...mapState(['isMobile']),
    ...mapState('map', [
      't2Mode',
      'status',
      'factor',
      'timeType',
      'index',
      'windy',
      'city',
      'isobar',
    ]),
    ...mapGetters('map', ['currentTime', 'timeList', 'elevationList']),
    timeShow() {
      return false;
      // return ['dust_conc', 'dod', 'dust_emis', 't2mz', 'tmp2m', 'wgrd10m', 'w100m'].includes(this.factor);
    },
    // 时间选择器绑定的日期
    date: {
      get() {
        return moment(this.currentTime).format('YYYY/MM/DD');
      },
      set(value) {
        const time = moment(value);
        const str = time.format('YYYYMMDD');
        const index = this.dayMap[str][0];
        this.goToFrame(index);
      },
    },
    checkType() {
      return this.timeType.type;
    },
    checkTimeName() {
      return this.timeCheckList.find((item, index) => item.type == this.timeType.type).name;
    },
    time: {
      get() {
        return this.currentTime;
      },
      set(value) {
        if (value in this.timeMap) {
          this.goToFrame(this.timeMap[value]);
          // this.setIndex(this.timeMap[value]);
        } else {
          this.$message('您选择的时间超出了预测范围');
        }
      },
    },
    // 根据返回的时间生成map，为禁用时间提供hash表，降低查询的时间复杂度
    timeCollection() {
      return this.timeList.reduce((target, item) => {
        const str = moment(item.forecastTime).format('YYYYMMDD');
        target.add(str);
        return target;
      }, new Set());
    },
    // 根据forecastTime生成hash表，方便计算索引
    dayMap() {
      return this.timeList.reduce((target, item, index) => {
        const str = moment(item.forecastTime).format('YYYYMMDD');
        if (target[str]) {
          target[str].push(index);
        } else {
          target[str] = [index];
        }
        return target;
      }, {});
    },
    // 根据forecastTime生成hash表，方便计算索引
    timeMap() {
      return this.timeList.reduce((target, item, index) => {
        target[item.forecastTime] = index;
        return target;
      }, {});
    },
    // 当前要素对应要素
    currentFactor() {
      return factorMap[this.factor];
    },
  },
  watch: {
    currentTime: {
      handler(value) {
        this.updateTimeIndex(); // 调用方法更新时间索引
      },
      immediate: true,
    },
    timeType: {
      handler() {
        this.updateTimeIndex(); // 调用方法更新时间索引
      },
      immediate: true,
    },
  },
  created() {
    Bus.$on('pause', this.pause);
  },

  mounted() {
    drag(this.$el.querySelector('.drag_wrap'));
  },

  beforeDestroy() {
    Bus.$off('pause', this.pause);
  },

  methods: {
    ...mapMutations('map', [
      'setStatus',
      'setIndex',
      'setWindy',
      'setCurrentTime',
      'setCity',
      'setTimeType',
      'setIsobar',
    ]),
    updateTimeIndex() {
      const hour = moment(this.currentTime).hour();
      this.position = hour * 2 + 1;
      this.hourIndex = hour / this.timeType.type;
    },
    toNow() {
      // this.toNowIndex();
      const now = this.getNowHasBaseTime();
      const nowTimestamp = Date.parse(now);

      const flagIndex = this.timeList.findIndex(
        (item) => Date.parse(item.forecastTime) === Date.parse(now),
      );
      if (flagIndex === -1) {
        let nearestIndex = -1;
        if (this.timeType.type !== 24) {
          let minDiff = Infinity;

          this.timeList.forEach((item, index) => {
            const itemTimestamp = Date.parse(item.forecastTime);
            const diff = Math.abs(nowTimestamp - itemTimestamp);
            if (diff < minDiff) {
              minDiff = diff;
              nearestIndex = index;
            }
          });
        } else {
          const sameDateMidnightIndex = this.timeList.findIndex((timeObj) => {
            const time = new Date(timeObj.forecastTime);
            const nowTime = new Date(nowTimestamp);
            return time.getDate() === nowTime.getDate();
          });

          if (sameDateMidnightIndex !== -1) {
            nearestIndex = sameDateMidnightIndex; // Set closest index to the found midnight time index
          } else {
            nearestIndex = 0; // If no midnight time found, set index to 0
          }
        }

        this.setIndex(nearestIndex);
        return;
      }
      this.setIndex(flagIndex);
    },
    getNowHasBaseTime() {
      const base = this.timeList[0].forecastTime;
      const date = base.split('T')[0].split('-');
      const time = base.split('T')[1].split(':');
      const now = new Date();

      date[1] = `0${now.getMonth() + 1}`.slice(-2);
      date[2] = `0${now.getDate()}`.slice(-2);

      time[0] = `0${Number(now.getHours()) + 1}`.slice(-2);

      return `${date.join('-')}T${time.join(':')}`;
    },
    /**
     * @description 播放
     * @returns {void}
     * @author yujie
     */
    play() {
      this.$sensors.track('timePlayer', {
        info: mapForCn[this.factor],
        mode: this.t2Mode,
        space_type: this.$route.name === 'earth' ? '三维' : '二维',
        page_type: '天机2',
      });
      this.setStatus('play');
      console.log('开始播放');
      // this.next();
    },
    checkTime(item) {
      this.setTimeType({ type: item.type, timeLength: 24 / item.type });
    },
    /**
     * @description 暂停
     * @returns {void}
     * @author yujie
     */
    pause() {
      this.setStatus('pause');
      Bus.$emit('clearTimeout');
    },
    /**
     * @description 播放上一张
     * @returns {void}
     * @author yujie
     */
    prev() {
      const index = (this.index - 1 + this.timeList.length) % this.timeList.length;
      this.goToFrame(index);
    },
    /**
     * @description 播放下一张
     * @returns {void}
     * @author yujie
     */
    next() {
      const index = (this.index + 1) % this.timeList.length;
      this.goToFrame(index);
    },
    /**
     * @description 跳转到指定帧
     * @returns {void}
     * @author yujie
     */
    goToFrame(index) {
      Bus.$emit('clearLayer', 'wind');
      Bus.$emit('clearLayer', 'isobar');
      // Bus.$emit('removePopup');
      this.setIndex(index);
    },
    /**
     * @description 禁用时间方法，timepicke提供
     * @returns {Boolean} 当前日期是否禁用
     * @author yujie
     * @param value
     */
    disabledDate(value) {
      const str = moment(value).format('YYYYMMDD');
      return !this.timeCollection.has(str);
    },

    setTime(position) {
      const time = moment(this.currentTime);
      const str = time
        .add((position - 1) * this.timeType.type - time.hour(), 'h')
        .format('YYYY-MM-DDTHH:mm:ss+08:00');
      console.log('111111111111111111', str, this.timeMap[str]);
      if (str in this.timeMap) {
        this.$sendPoint({
          category: 'button',
          action: '时间轴-三维',
          opt_label: `${str}`,
        });
        this.goToFrame(this.timeMap[str]);
      }
      // else {
      //   this.$message("您选择的时间超出了预测范围");
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.player {
  position: absolute;
  bottom: 0;
  height: 45px;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.55);
  backdrop-filter: blur(1px);
  left: 0;
  display: flex;
  z-index: 1000;
  align-items: center;

  .drag_wrap {
    position: relative;

    .drag_container {
      display: flex;
      width: 100%;
      justify-content: center;

      .operator-wrap {
        display: flex;
        justify-content: center;
      }
    }
  }

  .player_wrap {
    margin-left: 10px;
    display: flex;
    align-items: center;
    height: 30px;
    border-radius: 21px;
    padding: 0 5px;
    .now {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 50px;
      font-size: 12px;
      color: #fff;
      margin-left: 10px;
      height: 24px;
      width: 50px;
      background: #027aff;
    }
    .timeLong {
      display: flex;
      align-items: center;
      margin-left: 10px;
      .label {
        font-size: 12px;
        color: #fff;
        margin-right: 5px;
        cursor: move;
        width: 30px;
      }
      .timeItem {
        font-size: 12px;
        text-align: center;
        line-height: 30px;
        .timeCheck {
          position: absolute;
          height: 30px;
          z-index: 99999;
          display: flex;
          border-radius: 5px;
          padding: 3px 0 3px 5px;
          left: 50%;
          transform: translateX(-50%);
          top: -45px;
          background: #1a1a1a;
          .chcek {
            background: #027aff;
          }
          .item {
            width: 50px;
            height: 30px;
            margin-right: 5px;
            text-align: center;
            line-height: 30px;
            border-radius: 10px;
            color: #ffffff;
          }
        }
        width: 55px;
        height: 30px;
        position: relative;
        border-radius: 4px;
        cursor: pointer;
        border: none;
        background: #1a1a1a;
        color: #ffffff;
      }
    }

    .picker_wrap {
      display: flex;
      align-items: center;

      .label {
        font-size: 12px;
        color: #fff;
        margin-right: 5px;
        cursor: move;
        width: 30px;
      }

      ::v-deep {
        .el-input__inner,
        .el-input__icon {
          color: #ffffff;
          border-radius: 4px;
          border: none;
          background: #1a1a1a;
        }

        .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 120px;
        }
      }
    }
  }
}

.player.mobile_player_t2 {
  height: 62px;
  padding: 0;
  bottom: 6px;

  .drag_wrap {
    width: 100%;
    left: 0;
    background: #fff;

    .player_wrap {
      margin: 0;
      padding: 0 10px;
      border-radius: 0;
      width: 100%;
      justify-content: space-between;

      .icon_wrap {
        margin-left: 10px;
      }
    }
  }
}
</style>
