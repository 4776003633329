<template>
  <div class="home">
    <v-map ref="map" @mapMouseMove="mapMouseMove" />
    <v-loading :loading="loading" />
    <v-search v-if="!isMobile" @locate="setLocation" />
    <v-title title="中科天机" border @change="$router.push('/')">
      <v-search v-if="!isMobile" slot="search" @locate="setLocation" />
    </v-title>
    <v-map-tile model-type="t1" />
    <v-legend :factor="factor" :mobile="isMobile" />
    <v-control />
    <v-player v-show="isShowTimeLine && !isMobile" model-type="t1" />
    <template v-if="!isMobile">
      <v-metric-select
        :factor="factor"
        :type="'t1'"
        :metrics="metricT1"
        @change="factorChange"
      />
    </template>
    <v-precp-data-more :metrics="precpData" @controlTimeLine="controlTimeLine" />
    <!--    <v-mobile-operate-menu-->
    <!--      v-else-->
    <!--      :metrics="metricT1"-->
    <!--      :factor="factor"-->
    <!--      :type="'t1'"-->
    <!--      :init-base-time="currentBaseTime.baseTimeModel || ''"-->
    <!--      :base-time="baseTime"-->
    <!--      :windy="windy"-->
    <!--      @handleBaseTimeChange="handleBaseTimeChange"-->
    <!--      @change="factorChange"-->
    <!--      @setWindy="setWindy"-->
    <!--    />-->
    <v-info v-if="!isMobile" keywords="tianji1" />
    <v-mode v-if="!isMobile" />
    <v-layer-select v-if="!isMobile" model-type="t1" />
    <mobile-right-btn
      v-if="isMobile"
      model-type="t1"
      :factor="factor"
      :t2-mode="t1Mode"
      :metrics="metricT1"
      @change="factorChange"
      @locate="setLocation"
    />
    <div v-if="!isMobile" class="lnglattext">
      <span>{{ lnglatText }}</span>
    </div>
    <t1-time-line v-if="isMobile && factor !== 'precp'" />
    <precp-time-line v-if="isMobile && factor === 'precp'" />
    <popup v-if="isMobile" />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { getFactorcast, getBaseTime, getFirst } from '@/api';
import { getPrecipData } from '@/api/weather_hd';
import { formatDateString, transTimestamp } from '@/config/Utils';
import VMode from './components/Mode.vue';
import Bus from '@/bus/index';
import VControl from '@/components/Map/Control.vue';
import VLegend from '@/components/Map/Legend.vue';
import VTitle from '@/components/Map/Title.vue';
import VSearch from '@/components/Map/Search.vue';
import { mapForCn, factorWind, allMetrics } from '@/config/MapConfigT4';
import VMetricSelect from '@/components/Map/MetricSelect.vue';
import VMobileOperateMenu from '@/components/Map/MobileOperateMenu.vue';
import VInfo from '@/components/Map/Info.vue';
import VLayerSelect from '@/components/Map/LayerSelect.vue';
import VMapTile from '@/components/Map/MapTitle.vue';
import VPlayer from './components/PlayerNew.vue';
import VMap from './Map.vue';
import { TIANJI1_DA, TIANJI1_ND } from '@/config';
import VLoading from '@/components/Map/Loading.vue';
import MobileRightBtn from '@/components/Map/mobile/MobileRightBtn.vue';
import T1TimeLine from '@/components/Map/mobile/T1TimeLine.vue';
import precpTimeLine from '@/components/Map/mobile/precpTimeLine.vue';
import Popup from '@/components/Map/mobile/Popup.vue';
import VPrecpDataMore from '@/components/Map/FactorDataPrecp.vue';

export default {
  name: 'Home',
  components: {
    VLayerSelect,
    VMap,
    VPlayer,
    VLegend,
    VTitle,
    VMetricSelect,
    VControl,
    VMobileOperateMenu,
    VSearch,
    VInfo,
    VMapTile,
    VMode,
    VLoading,
    MobileRightBtn,
    T1TimeLine,
    precpTimeLine,
    Popup,
    VPrecpDataMore,
  },
  data() {
    return {
      isShowTimeLine: true,
      allMetrics,
      startTime: '',
      lnglatText: '',
      precpData: [{
        id: 'precp',
        icon: 'rain',
        text: '短临降水率',
        isPage: 'true',
        routerName: 'weather',
        appIcon: 'rain',
      }],
    };
  },
  computed: {
    ...mapState([
      'factor',
      'baseTime',
      'timeType',
      'isMobile',
      'currentBaseTime',
      'currentData',
      'currentTimestamp',
      't1Mode',
    ]),
    precp() {
      return ['precp'];
    },
    ...mapState('map', ['windy', 'loading']),
    ...mapGetters(['firstDataMap', 'factorDataTimeMap']),
    metricT1() {
      const temp = this.allMetrics.filter((item) => !item.private || item.private === 't2');
      return temp.filter((item) => item.type !== 'merge' && item.id !== 'tmp2m');
    },
    baseTimeMap() {
      return this.baseTime.reduce((target, item) => {
        target[item.baseTimeModel] = item;
        return target;
      }, {});
    },
    time: {
      get() {
        return this.currentBaseTime.baseTimeModel;
      },

      set(value) {
        const time = this.baseTimeMap[value];
        this.setCurrentBaseTime(time);
        Bus.$emit('getFactorcast', time, true);
      },
    },
  },
  watch: {
    t1Mode() {
      this.upPageTime(this.startTime, Date.now());
      this.init();
    },
  },
  created() {
    // this.getFirstData();
    // const flag = ['dust_conc', 'dod', 'dust_emis', 't2mz', 'tmp2m', 'wgrd10m', 'w100m'].includes(this.factor);
    // const timeType = localStorage.getItem('timeType') && flag ? JSON.parse(localStorage.getItem('timeType')) : {
    //   type: 1,
    //   timeLength: 24,
    // };
    // this.$store.commit("setTimeType", timeType);
    this.init();
    this.startTime = Date.now();
    Bus.$on('getFactorcast', this.getFactorcast);
  },

  beforeDestroy() {
    Bus.$off('getFactorcast', this.getFactorcast);
  },

  methods: {
    ...mapMutations([
      'setFactor',
      'setStatus',
      'setCurrentFactorData',
      'setFactorFirstData',
      'setCurrentData',
      'setBaseTime',
      'setCurrentBaseTime',
      'setCurrentTimestamp',
    ]),
    ...mapMutations('map', ['setWindy', 'setLoading']),
    async init() {
      console.log(this.factor, 166);
      this.setLoading(true);
      let baseTime;
      if (this.factor === 'precp') {
        baseTime = [];
        const precipData = await getPrecipData();
        precipData.baseTime = formatDateString(precipData.baseTimeString);
        precipData.mode = 'CY';
        // precipData['forecast'].shift()
        baseTime.push(precipData);
      } else {
        baseTime = (await getBaseTime(this.factor)).filter((item) => {
          if (this.t1Mode === TIANJI1_ND) {
            return item.mode === 'late';
          }
          if (this.t1Mode === TIANJI1_DA) {
            return item.mode === 'early';
          }
          return item;
        });
      }

      if (!baseTime || !baseTime.length) {
        this.setLoading(false);
        this.$message('当前要素无预测数据');
        return;
      }
      this.setBaseTime(baseTime);
      this.initTimeLine();
      if (!sessionStorage.getItem('current-mode')) {
        sessionStorage.setItem('current-mode', '聚合');
      }
    },
    setLocation(position) {
      Bus.$emit('setLocation', position);
    },
    /**
     * @description 获取要素数据
     * @param {String} time basetime
     * @param {Boolean} refresh 是否刷新
     * @returns {Promise}
     * @author yujie
     */
    async getFactorcast(time, refresh = false, oldDate) {
      const mode = time.mode;
      if (mode !== 'CY') {
        const promise = [getFactorcast(time.baseTime, this.factor, mode)];
        promise.push(Promise.resolve([]));

        if (factorWind[this.factor]) {
          promise.push(getFactorcast(time.baseTime, 'wgrd10m', mode));
        } else {
          promise.push(Promise.resolve([]));
        }
        // 构建请求promise
        const [factorData, slpData = [], windData = []] = await Promise.all(promise);
        // 排序函数
        const compare = (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
        // 将要素数据和气压数据按时间顺序排序
        factorData.sort(compare);
        slpData.sort(compare);
        windData.sort(compare);
        const factor = Object.freeze(factorData.map((item, index) => ({ ...item, index })));
        const slp = Object.freeze(slpData.map((item, index) => ({ ...item, index })));
        const wind = Object.freeze(windData.map((item, index) => ({ ...item, index })));
        this.setCurrentFactorData({ factor, slp, wind });
        if (refresh) {
          console.log(sessionStorage.getItem('nowTime'), 'nowTime refresh');
          if (this.currentTimestamp === '' || !this.factorDataTimeMap[this.currentTimestamp]) {
            // const targetTime = transTimestamp(sessionStorage.getItem("nowTime"),sessionStorage.getItem("tmode"));
            const targetTime = Date.parse(sessionStorage.getItem('nowTime'));
            const flagIndex = Object.values(this.factorDataTimeMap).reduce((closest, item, index) => {
              const itemTime = Date.parse(item.timestamp); // 获取当前项的时间戳
              const currentDiff = Math.abs(itemTime - targetTime); // 计算当前时间与目标时间的差值
              // 如果是第一次比较或者当前差值小于之前找到的最小差值，则更新closest
              if (closest === -1 || currentDiff < Math.abs(Date.parse(Object.values(this.factorDataTimeMap)[closest].timestamp) - targetTime)) {
                return index; // 更新最接近的索引
              }
              return closest; // 返回当前最接近的索引
            }, -1); // 初始值为 -1，表示未找到
            if (flagIndex !== -1) {
              this.setCurrentData(Object.values(this.factorDataTimeMap)[flagIndex]);
              const keys = Object.keys(this.factorDataTimeMap);
              this.setCurrentTimestamp(keys[flagIndex]);
              return true;
            }
          } else if (this.factorDataTimeMap[this.currentTimestamp]) {
            this.setCurrentData(this.factorDataTimeMap[this.currentTimestamp]);
          } else {
            this.setCurrentData(factor[0]);
          }
          return;
        }

        if (oldDate) {
          console.log(sessionStorage.getItem('nowTime'), 'nowTime oldDate');
          this.setCurrentBaseTime(time);
          // const flagIndex = Object.values(this.factorDataTimeMap).findIndex(
          //   (item) => Date.parse(item.timestamp) === Date.parse(sessionStorage.getItem("nowTime"))
          // );
          // const targetTime = transTimestamp(sessionStorage.getItem("nowTime"),sessionStorage.getItem("tmode"));
          const targetTime = Date.parse(sessionStorage.getItem('nowTime'));

          const flagIndex = Object.values(this.factorDataTimeMap).reduce((closest, item, index) => {
            const itemTime = Date.parse(item.timestamp); // 获取当前项的时间戳
            const currentDiff = Math.abs(itemTime - targetTime); // 计算当前时间与目标时间的差值

            // 如果是第一次比较或者当前差值小于之前找到的最小差值，则更新closest
            if (closest === -1 || currentDiff < Math.abs(Date.parse(Object.values(this.factorDataTimeMap)[closest].timestamp) - targetTime)) {
              return index; // 更新最接近的索引
            }

            return closest; // 返回当前最接近的索引
          }, -1); // 初始值为 -1，表示未找到

          if (flagIndex !== -1) {
            this.setCurrentData(Object.values(this.factorDataTimeMap)[flagIndex]);
            return true;
          }
          this.getFactorcast(this.baseTime[0], true);
          return false;
        }

        console.log(sessionStorage.getItem('nowTime'), 'nowTime else');
        const now = this.getNowHasBaseTime();

        let flagIndex = Object.values(this.factorDataTimeMap).reduce((closest, item, index) => {
          const itemTime = Date.parse(item.timestamp); // 获取当前项的时间戳
          const currentDiff = Math.abs(itemTime - now); // 计算当前时间与目标时间的差值

          // 如果是第一次比较或者当前差值小于之前找到的最小差值，则更新closest
          if (closest === -1 || currentDiff < Math.abs(itemTime - now)) {
            return index; // 更新最接近的索引
          }
          // 返回当前最接近的索引
          return closest;
        }, -1); // 初始值为 -1，表示未找到

        // let flagIndex = Object.values(this.factorDataTimeMap).findIndex(
        //   (item) => Date.parse(item.timestamp) === now
        // );

        flagIndex = flagIndex === -1 ? 0 : flagIndex;
        this.setCurrentData(Object.values(this.factorDataTimeMap)[flagIndex]);
        sessionStorage.setItem('nowTime', this.currentData.timestamp);
      } else {
        console.log('彩云数据');

        const promise = [getPrecipData()];
        promise.push(Promise.resolve([]));
        promise.push(Promise.resolve([]));
        // 构建请求promise
        let [factorData, slpData = [], windData = []] = await Promise.all(promise);
        // 排序函数
        const compare = (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime();
        // 将要素数据和气压数据按时间顺序排序
        factorData = this.handlerPrecipData(factorData);
        factorData.sort(compare);
        slpData.sort(compare);
        windData.sort(compare);
        const factor = Object.freeze(factorData.map((item, index) => ({ ...item, index })));
        const slp = Object.freeze(slpData.map((item, index) => ({ ...item, index })));
        const wind = Object.freeze(windData.map((item, index) => ({ ...item, index })));
        this.setCurrentFactorData({ factor, slp, wind });
        if (refresh) {
          console.log(sessionStorage.getItem('nowTime'), 'nowTime refresh');
          if (this.currentTimestamp === '' || !this.factorDataTimeMap[this.currentTimestamp]) {
            // const targetTime = transTimestamp(sessionStorage.getItem("nowTime"),sessionStorage.getItem("tmode"));
            const targetTime = Date.parse(sessionStorage.getItem('nowTime'));
            const flagIndex = Object.values(this.factorDataTimeMap).reduce((closest, item, index) => {
              const itemTime = Date.parse(item.timestamp); // 获取当前项的时间戳
              const currentDiff = Math.abs(itemTime - targetTime); // 计算当前时间与目标时间的差值
              // 如果是第一次比较或者当前差值小于之前找到的最小差值，则更新closest
              if (closest === -1 || currentDiff < Math.abs(Date.parse(Object.values(this.factorDataTimeMap)[closest].timestamp) - targetTime)) {
                return index; // 更新最接近的索引
              }
              return closest; // 返回当前最接近的索引
            }, -1); // 初始值为 -1，表示未找到
            if (flagIndex !== -1) {
              this.setCurrentData(Object.values(this.factorDataTimeMap)[flagIndex]);
              const keys = Object.keys(this.factorDataTimeMap);
              this.setCurrentTimestamp(keys[flagIndex]);
              return true;
            }
          } else if (this.factorDataTimeMap[this.currentTimestamp]) {
            this.setCurrentData(this.factorDataTimeMap[this.currentTimestamp]);
          } else {
            this.setCurrentData(factor[0]);
          }
          return;
        }
        if (oldDate) {
          console.log(sessionStorage.getItem('nowTime'), 'nowTime oldDate');
          this.setCurrentBaseTime(time);
          const targetTime = Date.parse(sessionStorage.getItem('nowTime'));
          // const targetTime = transTimestamp(sessionStorage.getItem("nowTime"),sessionStorage.getItem("tmode"));
          const flagIndex = Object.values(this.factorDataTimeMap).reduce((closest, item, index) => {
            const itemTime = Date.parse(item.timestamp); // 获取当前项的时间戳
            const currentDiff = Math.abs(itemTime - targetTime); // 计算当前时间与目标时间的差值
            // 如果是第一次比较或者当前差值小于之前找到的最小差值，则更新closest
            if (closest === -1 || currentDiff < Math.abs(Date.parse(Object.values(this.factorDataTimeMap)[closest].timestamp) - targetTime)) {
              return index; // 更新最接近的索引
            }

            return closest; // 返回当前最接近的索引
          }, -1); // 初始值为 -1，表示未找到

          if (flagIndex !== -1) {
            this.setCurrentData(Object.values(this.factorDataTimeMap)[flagIndex]);
            return true;
          }
          this.getFactorcast(this.baseTime[0], true);
          return false;
        }

        console.log(sessionStorage.getItem('nowTime'), 'nowTime else');

        const now = this.getNowHasBaseTime();
        let flagIndex = Object.values(this.factorDataTimeMap).reduce((closest, item, index) => {
          const itemTime = Date.parse(item.timestamp); // 获取当前项的时间戳
          const currentDiff = Math.abs(itemTime - now); // 计算当前时间与目标时间的差值

          // 如果是第一次比较或者当前差值小于之前找到的最小差值，则更新closest
          if (closest === -1 || currentDiff < Math.abs(itemTime - now)) {
            return index; // 更新最接近的索引
          }
          // 返回当前最接近的索引
          return closest;
        }, -1); // 初始值为 -1，表示未找到
        // let flagIndex = Object.values(this.factorDataTimeMap).findIndex(
        //   (item) => Date.parse(item.timestamp) === now
        // );
        flagIndex = flagIndex === -1 ? 0 : flagIndex;
        this.setCurrentData(Object.values(this.factorDataTimeMap)[flagIndex]);
        sessionStorage.setItem('nowTime', this.currentData.timestamp);
      }
    },

    upPageTime(time1, time2) {
      const time = Math.ceil((time2 - time1) / (1000 * 60));
      this.$sensors.track('tj_pageTime', {
        tj_stayTime: time,
        page_type: '天机1',
        space_type: '二维',
        info: mapForCn[this.factor],
        mode: this.t1Mode,
      });
      this.startTime = time2;
      // 计算相差的分钟数
    },

    getNowHasBaseTime() {
      // 获取当前时间
      const currentDate = new Date();

      // 获取当前小时数和分钟数
      let currentHour = currentDate.getHours();
      const currentMinute = currentDate.getMinutes();

      // 判断是否需要将小时数加1
      if (currentMinute > 0) {
        currentHour++;
      }

      // 设置分钟数和秒数为0，获取整点时间
      currentDate.setHours(currentHour, 0, 0, 0);

      // 获取整点时间的时间戳
      const timestamp = currentDate.getTime();

      return timestamp;
    },

    handlerPrecipData(originalData) {
      // originalData['forecast'].shift()
      return originalData.forecast.map((forecastItem) => ({
        ...forecastItem,
        meta: {
          latMin: originalData.latMin,
          latMax: originalData.latMax,
          lonMin: originalData.lonMin,
          lonMax: originalData.lonMax,
        },

        unit: originalData.unit,
        baseTimeString: originalData.baseTimeString,
        region: originalData.region,
        timestamp: formatDateString(forecastItem.forecastTimeString),
      }));
    },

    initTimeLine() {
      if (!sessionStorage.getItem('nowTime') || !sessionStorage.getItem('baseTime')) {
        this.setCurrentBaseTime(this.baseTime[0]);
        this.getFactorcast(this.baseTime[0], true);
        return;
      }

      const baseIndex = this.baseTime.findIndex((item) => {
        item = item.baseTime;
        return Date.parse(item) === Date.parse(sessionStorage.getItem('baseTime'));
      });

      if (baseIndex !== -1) {
        this.getFactorcast(this.baseTime[baseIndex], false, true);
      } else {
        this.setCurrentBaseTime(this.baseTime[0]);
        this.getFactorcast(this.baseTime[0], true);
        return false;
      }
    },

    factorChange(metric) {
      this.upPageTime(this.startTime, Date.now());
      this.setFactor(metric.id);
      this.setStatus('pause');
      Bus.$emit('removePopup');
      sessionStorage.setItem('factor', metric.id);
      this.init();
    },

    getFirstData() {
      getFirst().then((res) => {
        this.setFactorFirstData(res);
        this.setCurrentData(this.firstDataMap[this.factor]);
      });
    },

    handleBaseTimeChange(value) {
      const time = this.baseTimeMap[value];
      this.setCurrentBaseTime(time);
      Bus.$emit('getFactorcast', time, true);
    },

    mapMouseMove(e) {
      this.lnglatText = e;
    },

    controlTimeLine(flag) {
      this.isShowTimeLine = flag;
    },
  },
};
</script>

<style lang="scss" scoped>
.home {
  height: 100%;
  width: 100%;
  position: relative;
}
.lnglattext {
  position: fixed;
  z-index: 999;
  left: 80px;
  bottom: 50px;
  color: lightgrey;
  font-size: 11px;
  //font-weight: 500;
  //text-shadow: 0 0 1px rgba(0, 0, 0, .5),
  //1px 0 1px rgba(0, 0, 0, .5),
  //-1px 0 1px rgba(0, 0, 0, .5),
  //0 1px 1px rgba(0, 0, 0, .5),
  //0 -1px 1px rgba(0, 0, 0, .5);
}
</style>
