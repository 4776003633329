<template>
  <div class="login-page">
    <Login v-if="isShowLogin" @register="controlIsShow" />
    <Register v-else @loginShow="isShowLogin = true" />
  </div>
</template>

<script>
import Login from './login.vue';
import Register from './register.vue';
import loginVue from './login.vue';

export default {
  name: '',
  components: {
    Login,
    Register,
  },
  emits: {
    controlShow: null,
  },
  data() {
    return {
      isShowLogin: true,
    };
  },
  mounted() {
  },
  methods: {
    controlIsShow(flag) {
      this.$emit('controlShow', flag);
    },
  },
};
</script>

<style scoped lang='scss'>
.login-page {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
}
</style>
