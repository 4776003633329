<template>
  <div class="login-container">
    <el-card class="login-card">
      <i class="el-icon-close close" style="float: right;" @click="setLogin(false)" />

      <div class="head">
        <svg-icon
          class="input-svg"
          style="position: relative;height: 30px;width: 48px;top: 0px;left: -9px;"
          :icon-class="'titlesRed'"
        />
        <div class="title_box">
          <span>
            {{ '中科天机' }}
          </span>
        </div>
      </div>
      <el-tabs v-model="activeTab">
        <el-tab-pane label="账号" name="account">
          <el-form
            ref="accountFormRef"
            :model="accountForm"
            :rules="rules"
            label-width="80px"
            class="login-form"
          >
            <el-form-item prop="username">
              <svg-icon class="input-svg" :icon-class="'accountName'" />
              <el-input v-model="accountForm.username" placeholder="请输入账号" />
            </el-form-item>
            <el-form-item prop="password">
              <svg-icon class="input-svg" :icon-class="'accountPassword'" />
              <el-input
                v-model="accountForm.password"
                type="password"
                show-password
                placeholder="请输入密码"
              />
            </el-form-item>
            <el-form-item v-if="isShowCaptch" class="captchas-pid" prop="imageCaptcha">
              <svg-icon class="input-svg" :icon-class="'accountCaptchas'" />
              <el-input v-model="accountForm.imageCaptcha" placeholder="请输入图片验证码" />
              <img :src="imgSrc" alt="captcha" @click="getCaptchaInfo">
            </el-form-item>
            <el-form-item>
              <el-checkbox v-model="autoLogin">自动登录</el-checkbox>
            </el-form-item>
            <el-form-item class="loginButton">
              <el-button type="primary" @click="login">登 录</el-button>
            </el-form-item>
            <el-form-item class="bottomButton">
              <div class="register" @click="registerPush">立即注册</div>
              <div class="findPassword" @click="findPassWord">找回密码</div>
            </el-form-item>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="手机号" name="phone">
          <el-form
            ref="phoneFormRef"
            :model="phoneForm"
            :rules="rules"
            label-width="80px"
            class="login-form"
          >
            <el-form-item class="phoneNumber" prop="phone">
              <div class="country">+86</div>
              <el-input v-model="phoneForm.phone" placeholder="请输入手机号" />
            </el-form-item>
            <el-form-item class="captchas-pid-phone" style="margin-bottom: 52px;" prop="captcha">
              <el-input v-model="phoneForm.captcha" placeholder="请输入验证码" />
              <div class="getCaptch" :class="{ disabled: countDown > 0 }" @click="sendVerificationCode">
                {{ countDown > 0 ? `重新发送(${countDown}s)` : '获取验证码' }}
              </div>
              <div class="text">
                {{ countDown > 0 ? '短信验证码已发送，请耐心等待' : '验证手机号登录' }}
              </div>
            </el-form-item>
            <el-form-item class="loginButton">
              <el-button type="primary" @click="login">登 录</el-button>
            </el-form-item>
            <el-form-item class="bottomButton">
              <div class="register" @click="registerPush">立即注册</div>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </el-card>
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import { Message } from 'element-ui';
import { getCaptcha, toLogin, getCode } from '@/api/mannger';

export default {
  emits: {
    register: null,
  },
  data() {
    return {
      activeTab: 'account',
      isShowCaptch: '',
      autoLogin: false,
      imgSrc: '',
      codeKey: '',
      accountForm: {
        username: '',
        password: '',
        imageCaptcha: '',
      },
      phoneForm: {
        phone: '',
        captcha: '',
        imageCaptcha: '',
      },
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        phone: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { pattern: /^1[3-9]\d{9}$/, message: '手机号格式不正确', trigger: 'blur' },
        ],
        imageCaptcha: [{ required: true, message: '请输入图片验证码', trigger: 'blur' }],
      },
      countDown: 0,
    };
  },
  computed: {
    ...mapState(['myToken']),
  },
  created() {
    this.getCaptchaInfo();
  },
  methods: {
    ...mapMutations(['setUserInfo', 'setLogin', 'setToken']),
    getCaptchaInfo() {
      getCaptcha().then((result) => {
        console.log('获取到的图形验证码', result);
        this.isShowCaptch = result.enable;
        this.codeKey = result.codeKey;
        this.imgSrc = `data:image/png;base64,${result.codeValue}`;
      });
    },
    registerPush() {
      const routeData = this.$router.resolve({ path: '/register' });
      window.open(routeData.href, '_blank');
    },
    findPassWord() {
      const routeData = this.$router.resolve({ path: '/findPassword' });
      window.open(routeData.href, '_blank');
    },
    login() {
      console.log('1111111111111');
      let param;
      if (this.activeTab === 'account') {
        if (!this.accountForm.username || !this.accountForm.password || (this.isShowCaptch && !this.accountForm.imageCaptcha)) {
          Message({
            type: 'error',
            message: '请输入完整登录信息',
          });
          return;
        }
        param = {
          username: this.accountForm.username,
          password: this.accountForm.password,
          codeKey: this.codeKey,
          codeValue: this.accountForm.imageCaptcha,
          autoLogin: this.autoLogin ? 1 : 0,
          authType: 'password',
        };
      } else {
        const phoneNumberRegex = /^1[3-9]\d{9}$/;
        if (!this.phoneForm.phone || !this.phoneForm.captcha) {
          Message({
            type: 'error',
            message: '请输入完整登录信息',
          });
          return;
        }
        if (!phoneNumberRegex.test(this.phoneForm.phone)) {
          Message({
            type: 'error',
            message: '手机号格式不正确请重新输入',
          });
          return;
        }
        param = {
          mobile: this.phoneForm.phone,
          codeKey: this.phoneForm.phone,
          codeValue: this.phoneForm.captcha,
          authType: 'mobile',
        };
      }

      console.log('param', param);
      toLogin(param).then((resp) => {
        localStorage.setItem('isAutoLogin', this.autoLogin);
        this.setToken(resp.accessToken);
        this.setLogin(false);
        resp.username = param.username || param.mobile;
        this.setUserInfo(resp);
        Message({
          type: 'success',
          message: '登录成功',
        });
        console.log('登录返回', resp);
      }).catch((error) => {
        this.getCaptchaInfo();
      });
    },
    sendVerificationCode() {
      const phoneNumberRegex = /^1[3-9]\d{9}$/;
      if (!this.phoneForm.phone || !phoneNumberRegex.test(this.phoneForm.phone)) return;
      if (this.countDown === 0) {
        this.countDown = 60;
        getCode({ mobile: this.phoneForm.phone }).then((resp) => {
          console.log('获取验证码', resp);
        });

        const timer = setInterval(() => {
          if (this.countDown > 0) {
            this.countDown--;
          } else {
            clearInterval(timer);
          }
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.login-container {
  justify-content: center;
  align-items: center;
  position: relative;
  width: 396px;
  height: 489px;
  background-color: #f0f0f0;

  ::v-deep {
    .el-form-item__error {
      padding-top: 1px !important;
    }
  }

  .head {
    width: 100%;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;

    .title_box {
      font-size: 25px;
      line-height: 42px;
      font-weight: 600;
      padding-right: 10px;
      color: #C00019;
    }
  }
}

img {
  height: 45px;
  width: 83px;
  margin-left: 7px;
  border-radius: 4px;
}

.login-card {
  height: 100%;
  width: 100%;
  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 15px;
    cursor: pointer;
  }

  .country {
    width: 80px;
    font-size: 14px;
    color: #3B4048;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: 43px;
    border-radius: 4px 0px 0px 4px;
    border-right: 1px solid #E7E7E7;
  }

  .input-svg {
    position: absolute;
    left: 11px;
    top: 14px;
  }

  .loginButton {
    ::v-deep {
      button {
        width: 100%;
      }
    }
  }

  .bottomButton {
    height: 20px;
    ::v-deep {
      .el-form-item__content {
        display: flex;
        font-size: 14px;
        font-weight: 400;
        color: #265DEE;
      }

      .register {
        height: 20px;
        line-height: 20px;
        cursor: pointer;
      }

      .findPassword {
        height: 20px;
        line-height: 20px;
        cursor: pointer;
        position: absolute;
        right: 0;
      }

    }
  }

  ::v-deep {

    .el-checkbox__label {
      font-size: 13px;
    }

    .el-form-item {
      margin-bottom: 15px;
    }

    .el-tabs {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .el-tabs__header {
      width: 300px;
    }

    #tab-account {
      width: 150px;
      text-align: center;
      font-size: 16px;
    }

    .el-tabs__active-bar {
      width: 150px !important;
    }

    #tab-phone {
      width: 150px;
      text-align: center;
      font-size: 16px;
    }

    .el-input {
      input {
        width: 300px;
        height: 45px;
        border-radius: 4px;
        border: 1px solid #E7E7E7;
        padding-left: 38px;
      }

    }

    .el-card__body {
      padding: inherit;
    }

    .el-form-item__content {
      margin-left: 0 !important;
      flex-wrap: nowrap;

    }
  }

  // >>> .el-form-item__label {
  //   width: 100px !important;
  // }

  // >>> .el-form-item__content {
  //   flex-wrap: nowrap;
  // }

  .phoneNumber {
    ::v-deep {
      input {
        padding-left: 100px;
      }
    }
  }

  .disabled {
    color: #CCCCCC !important;
  }

  .getCaptch {
    cursor: pointer;
    position: absolute;
    width: 89px;
    height: 20px;
    font-size: 14px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-left: 1px solid #E7E7E7;
    top: 50%;
    transform: translateY(-50%);
    right: 8px;
    padding-left: 10px;
    white-space: nowrap; /* 强制一行显示 */
    overflow: hidden; /* 隐藏超出部分 */
    text-overflow: ellipsis; /* 超出显示省略号 */
  }

  .text {
    position: absolute;
    width: 168px;
    height: 12px;
    font-size: 12px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 400;
    color: #3B4048;
    line-height: 12px;
    bottom: -20px;
  }

  .captchas-pid-phone {
    ::v-deep {
      .el-form-item__content {
        display: flex;
      }

      input {
        padding-right: 107px;
        padding-left: 20px;
      }
    }
  }

  .captchas-pid {
    ::v-deep {
      .el-form-item__content {
        display: flex;
      }

      input {
        width: 210px;
      }
    }

    // >>> .el-input {
    //   width: 257px !important;
    // }
  }
}

.login-tabs {}

.login-form {
  width: 300px;
  margin-top: 15px;
}
</style>
