<template>
  <div
    class="player"
    :class="{
      mobile_player_t1: isMobile,
    }"
  >
    <div class="drag_wrap">
      <div class="drag_container">
        <div class="operator-wrap">
          <v-base-time-select v-if="!isMobile" ref="timeSelect" />
          <div class="player_wrap">
            <div v-if="!isMobile" class="picker_wrap">
              <div class="label">日期</div>
              <el-date-picker
                v-model="date"
                type="date"
                placeholder="选择日期"
                :picker-options="{ disabledDate }"
                :clearable="false"
              />
            </div>
            <div v-if="!isMobile && timeShow" class="timeLong">
              <div class="label">频率</div>
              <div
                v-click-outside="
                  () => {
                    showList = false;
                  }
                "
                class="timeItem"
                @click="showList = !showList"
              >
                {{ checkTimeName }}
                <div v-show="showList" class="timeCheck">
                  <div
                    v-for="(item, index) in timeCheckList"
                    :key="index"
                    class="item"
                    :class="{ chcek: checkType === item.type }"
                    @click.stop="checkTime(item)"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </div>
            <div class="now" @click="toNow">现在</div>
            <template v-if="isMobile">
              <v-icon-word
                v-if="status === 'pause'"
                icon="play"
                desc="播放"
                spec
                @click.native="play"
              />
              <v-icon-word
                v-else
                icon="pause"
                desc="暂停"
                spec
                @click.native="pause"
              />
            </template>
            <v-icon-word
              icon="p-first"
              desc="第一张"
              spec
              c-icon
              @click.native="first"
            />
            <v-icon-word
              icon="p-prev"
              desc="上一张"
              spec
              c-icon
              @click.native="prev"
            />
            <template v-if="!isMobile">
              <v-icon-word
                v-if="status === 'pause'"
                icon="play"
                desc="播放"
                spec
                center
                @click.native="play"
              />
              <v-icon-word
                v-else
                icon="pause"
                desc="暂停"
                spec
                @click.native="pause"
              />
            </template>
            <el-select v-else v-model="time">
              <el-option
                v-for="item in currentFactorData"
                :key="item.timestamp"
                :label="item.timestamp | formatTimestamp"
                :value="item.timestamp"
              />
            </el-select>

            <v-icon-word
              icon="p-next"
              desc="下一张"
              spec
              c-icon
              @click.native="next"
            />

            <v-icon-word
              icon="p-last"
              desc="最后一张"
              spec
              c-icon
              @click.native="last"
            />
          </div>
          <!--          <v-windy-select-->
          <!--            v-if="(currentFactor === 'wind' || factor === 'wgrd10m' || factor === 'pratesfc') && !isMobile"-->
          <!--            :windy="windy"-->
          <!--            @change="setWindy"-->
          <!--          />-->
        </div>
      </div>
    </div>
    <!-- <v-timeline v-if="!isMobile" :index="hourIndex" model-type="t1" @change="setTime" /> -->

    <v-moment-time-line
      v-if="!isMobile && checkType === 15 "
      :index="hourIndex"
      model-type="t1"
      @change="setTime"
    />
    <v-timeline
      v-else-if="!isMobile && checkType !== 24"
      :index="hourIndex"
      model-type="t1"
      @change="setTime"
    />
    <v-day-timeline v-else-if="!isMobile && checkType === 24" :index="dayIndex" />
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';
import moment from 'moment';
import vClickOutside from '@/plugins/v-click-outside/v-click-outside';
import Bus from '@/bus/index';
import { drag } from '@/utils/drag';
import VIconWord from '@/components/Map/IconWord.vue';
import { mapForCn } from '@/config/MapConfigT4';
import { factorMap } from '@/config/MapConfig';
// import VTimeline from "./Timeline.vue";
import VTimeline from '@/components/Map/Timeline.vue';
import VDayTimeline from './DayTimeLine.vue';
import VBaseTimeSelect from './BaseTImeSelectNew.vue';
import VMomentTimeLine from './MomentTimeLine.vue';

export default {
  directives: {
    clickOutside: vClickOutside,
  },
  components: {
    VIconWord,
    VTimeline,
    VDayTimeline,
    VBaseTimeSelect,
    VMomentTimeLine,
  },
  filters: {
    formatTimestamp(val) {
      return moment(val).format('YYYY-MM-DD HH:mm:ss');
    },
  },

  data() {
    return {
      index: 1,
      hourIndex: 1,
      showList: false,
      timeCheckList: [
        { type: 1, name: '1小时' },
        { type: 3, name: '3小时' },
        { type: 6, name: '6小时' },
        { type: 24, name: '24小时' },
      ],
    };
  },

  computed: {
    ...mapState([
      't1Mode',
      'precipIndex',
      'status',
      'currentFactorData',
      'currentData',
      'factor',
      'timeTypeMap',
      'timeType',
      'windy',
      'isMobile',
    ]),
    ...mapGetters(['factorDataTimeMap']),
    timeShow() {
      return false;
      // return ['dust_conc', 'dod', 'dust_emis', 't2mz', 'tmp2m', 'wgrd10m', 'w100m'].includes(this.factor);
    },
    // 时间选择器绑定的日期
    date: {
      get() {
        return new Date(this.currentData.timestamp);
      },
      set(value) {
        const time = moment(value).format('YYYYMMDD');
        const timestamp = this.dayMap[time][0];
        // const data = this.factorDataTimeMap[timestamp];
        if (this.factor !== 'precp') {
          Bus.$emit('removePopup');
        }
        // Bus.$emit("removePopup");
        this.setCurrentData(this.factorDataTimeMap[timestamp]);
      },
    },
    checkType() {
      if (this.factor === 'precp') {
        const minute = moment(this.currentData?.timestamp).minute();
        this.index = minute / 5;
        this.hourIndex = minute / 5;
        return 15;
      }
      const hour = moment(this.currentData?.timestamp).hour();
      this.index = hour * 2 + 1;
      this.hourIndex = hour / this.timeType.type;
      return this.timeType.type;
    },
    checkTimeName() {
      return this.timeCheckList.find((item, index) => item.type === this.timeType.type).name;
    },
    time: {
      get() {
        return this.currentData.timestamp;
      },
      set(value) {
        Bus.$emit('removePopup');
        this.setCurrentData(this.factorDataTimeMap[moment(value).valueOf()]);
      },
    },
    // 根据返回的时间生成map，为禁用时间提供hash表，降低查询的时间复杂度
    timeMap() {
      if (this.factor === 'precp') {
        return Object.keys(this.factorDataTimeMap).reduce((target, item) => {
          const time = moment(+item);
          // const timestamp = time.add(-time.minutes(), "m").second(0).millisecond(0).valueOf();
          target.add(time.valueOf());
          return target;
        }, new Set());
      }
      return Object.keys(this.factorDataTimeMap).reduce((target, item) => {
        const time = moment(+item);
        const timestamp = time.add(-time.hours(), 'h').valueOf();
        target.add(timestamp);
        return target;
      }, new Set());
    },
    dayIndex() {
      if (!this.timeTypeMap[this.timeType.type]) return 0;
      if (this.timeType.type === 24) {
        const sameDateMidnightIndex = this.timeTypeMap[this.timeType.type].findIndex((timeObj) => this.currentData.timestamp === timeObj.timestamp);
        console.log(
          'index',
          sameDateMidnightIndex,
          this.timeTypeMap[this.timeType.type],
          this.currentData,
        );
        return sameDateMidnightIndex || 0;
      }
      return 0;
    },
    dayMap() {
      return Object.keys(this.factorDataTimeMap).reduce((target, item) => {
        const time = moment(+item);
        const str = time.format('YYYYMMDD');
        if (target[str]) {
          target[str].push(time.valueOf());
        } else {
          target[str] = [time.valueOf()];
        }

        return target;
      }, {});
    },
    // 当前要素对应要素
    currentFactor() {
      return factorMap[this.factor];
    },
  },

  watch: {
    currentData: {
      handler(value) {
        const hour = moment(value?.timestamp).hour();
        this.index = hour * 2 + 1;
        this.hourIndex = hour / this.timeType.type;
      },
      immediate: true,
    },
  },

  mounted() {
    drag(this.$el.querySelector('.drag_wrap'));
  },

  methods: {
    ...mapMutations([
      'setStatus',
      'setPrecipIndex',
      'setTimeType',
      'setCurrentData',
      'setWindy',
      'setCurrentTimestamp',
    ]),
    toNow() {
      // this.toNowIndex();
      // const now = this.getNowHasBaseTime();
      const nowTimestamp = +new Date();
      const haveNowTime = this.factorDataTimeMap[nowTimestamp];

      if (!haveNowTime) {
        let nearestIndex = -1;
        if (this.timeType.type !== 24) {
          let minDiff = Infinity;

          this.timeTypeMap[this.timeType.type].forEach((item, index) => {
            const itemTimestamp = new Date(item.timestamp);
            const diff = Math.abs(nowTimestamp - itemTimestamp);
            if (diff < minDiff) {
              minDiff = diff;
              nearestIndex = item;
            }
          });
        } else {
          const sameDateMidnightIndex = this.timeTypeMap[this.timeType.type].findIndex(
            (timeObj) => {
              const time = new Date(timeObj.timestamp);
              const nowTime = new Date(nowTimestamp);
              return time.getDate() === nowTime.getDate();
            },
          );

          if (sameDateMidnightIndex !== -1) {
            nearestIndex = this.timeTypeMap[this.timeType.type][sameDateMidnightIndex]; // Set closest index to the found midnight time index
          } else {
            nearestIndex = this.timeTypeMap[this.timeType.type][0]; // If no midnight time found, set index to 0
          }
        }
        console.log('nearestIndex', nearestIndex);
        this.setCurrentData(nearestIndex);
        return;
      }
      this.setCurrentData(haveNowTime);
    },
    getNowHasBaseTime() {
      const base = this.timeList[0].forecastTime;
      const date = base.split('T')[0].split('-');
      const time = base.split('T')[1].split(':');
      const now = new Date();

      date[1] = `0${now.getMonth() + 1}`.slice(-2);
      date[2] = `0${now.getDate()}`.slice(-2);

      time[0] = `0${Number(now.getHours()) + 1}`.slice(-2);

      return `${date.join('-')}T${time.join(':')}`;
    },
    checkTime(item) {
      this.setTimeType({ type: item.type, timeLength: 24 / item.type });
    },
    /**
     * @description 播放
     * @returns {void}
     * @author yujie
     */
    play() {
      this.$sensors.track('timePlayer', {
        info: mapForCn[this.factor],
        mode: this.t1Mode,
        space_type: this.$route.name === 'earth' ? '三维' : '二维',
        page_type: '天机1',
      });
      this.setStatus('play');
      Bus.$emit('clearLayerAndOverlay', 'layer');
      if (this.factor !== 'precp') {
        Bus.$emit('removePopup');
      }
    },
    /**
     * @description 暂停
     * @returns {void}
     * @author yujie
     */
    pause() {
      this.setStatus('pause');
    },
    /**
     * @description 播放上一张
     * @returns {void}
     * @author yujie
     */
    prev() {
      const index = this.currentData.index - 1;
      this.goPageByIndex(index);
    },
    /**
     * @description 播放下一张
     * @returns {void}
     * @author yujie
     */
    next() {
      const index = this.currentData.index + 1;
      this.goPageByIndex(index);
    },
    /**
     * @description 跳转到最后一张
     * @returns {void}
     * @author yujie
     */
    first() {
      this.goPageByIndex(0);
    },
    /**
     * @description 跳转到最后一张
     * @returns {void}
     * @author yujie
     */
    last() {
      this.goPageByIndex(this.currentFactorData.length - 1);
    },
    /**
     * @description 跳转到指定页数
     * @param {Number} index 索引
     * @returns {void}
     * @author yujie
     */
    goPageByIndex(index) {
      if (index < 0) {
        this.$message('已经在第一张！');
      } else if (index >= this.currentFactorData.length) {
        this.$message('已经在最后一张！');
      } else {
        this.setCurrentData(this.currentFactorData[index]);
        this.setCurrentTimestamp(new Date(this.currentFactorData[index].timestamp).getTime());
        if (this.factor !== 'precp') {
          Bus.$emit('removePopup');
        }
      }
      // console.log(this.currentFactorData[index].timestamp,"425");
    },
    /**
     * @description 禁用时间方法，timepicke提供
     * @param {Date} date 时间参数
     * @returns {Boolean} 当前日期是否禁用
     * @author yujie
     */
    disabledDate(date) {
      return !this.timeMap.has(+date);
    },

    /**
     * @description 设置时间
     * @returns {void}
     * @author yujie
     * @param index
     */
    setTime(index) {
      const time = moment(this.currentData.timestamp);
      console.log('set current time', 445);
      if (this.factor === 'precp') {
        const timestamp = time.minute(0).second(0).millisecond(0).format('YYYY-MM-DD HH:mm:ss');
        const str = moment(timestamp)
          .add((index - 1) * 5, 'minutes')
          .format('YYYY-MM-DDTHH:mm:ss+08:00');
        const calTime = moment(str).valueOf();
        if (this.factorDataTimeMap[calTime]) {
          this.$sendPoint({
            category: 'button',
            action: '时间轴-天机1',
            opt_label: `${str}`,
          });
          this.setCurrentData(this.factorDataTimeMap[calTime]);
          this.index = index * 2 - 1;
          console.log(calTime, 460);
          this.setCurrentTimestamp(calTime);
          // Bus.$emit("removePopup");
        }
      } else {
        const timestamp = time.add((index - 1) * this.timeType.type - time.hour(), 'h').valueOf();
        const str = time
          .add((index - 1) * this.timeType.type - time.hour(), 'h')
          .format('YYYY-MM-DDTHH:mm:ss+08:00');
        console.log('111111111111111111', str, this.timeMap[str]);

        if (this.factorDataTimeMap[timestamp]) {
          this.$sendPoint({
            category: 'button',
            action: '时间轴-天机1',
            opt_label: `${str}`,
          });
          this.setCurrentData(this.factorDataTimeMap[timestamp]);
          this.index = index * 2 - 1;
          this.setCurrentTimestamp(timestamp);
          Bus.$emit('removePopup');
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.player {
  position: absolute;
  bottom: 0;
  height: 45px;
  width: 100%;
  padding: 0 30px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.55);
  backdrop-filter: blur(1px);
  left: 0;
  display: flex;
  z-index: 1000;
  align-items: center;

  .drag_wrap {
    position: relative;

    .drag_container {
      display: flex;
      width: 100%;
      justify-content: center;

      .operator-wrap {
        display: flex;
        justify-content: center;
      }
    }
  }

  .player_wrap {
    margin-left: 10px;
    display: flex;
    align-items: center;
    height: 30px;
    border-radius: 21px;
    padding: 0 5px;
    .now {
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border-radius: 50px;
      font-size: 12px;
      color: #fff;
      margin-left: 10px;
      height: 24px;
      width: 50px;
      background: #027aff;
    }
    .timeLong {
      display: flex;
      align-items: center;
      margin-left: 10px;
      .label {
        font-size: 12px;
        color: #fff;
        margin-right: 5px;
        cursor: move;
        width: 30px;
      }
      .timeItem {
        font-size: 12px;
        text-align: center;
        line-height: 30px;
        .timeCheck {
          position: absolute;
          height: 30px;
          z-index: 99999;
          display: flex;
          border-radius: 5px;
          padding: 3px 0 3px 5px;
          left: 50%;
          transform: translateX(-50%);
          top: -45px;
          background: #1a1a1a;
          .chcek {
            background: #027aff;
          }
          .item {
            width: 50px;
            height: 30px;
            margin-right: 5px;
            text-align: center;
            line-height: 30px;
            border-radius: 10px;
            color: #ffffff;
          }
        }
        width: 55px;
        height: 30px;
        position: relative;
        border-radius: 4px;
        cursor: pointer;
        border: none;
        background: #1a1a1a;
        color: #ffffff;
      }
    }
    .timeLong {
      display: flex;
      align-items: center;
      .label {
        font-size: 12px;
        color: #fff;
        margin-right: 5px;
        cursor: move;
        width: 30px;
      }
      .timeItem {
        width: 90px;
      }
    }

    .picker_wrap {
      display: flex;
      align-items: center;

      .label {
        font-size: 12px;
        color: #fff;
        margin-right: 5px;
        cursor: move;
        width: 30px;
      }

      ::v-deep {
        .el-date-editor.el-input,
        .el-date-editor.el-input__inner {
          width: 120px;
        }
      }
    }
  }
}

.mobile_player_t1 {
  bottom: 23px;
}

.player.mobile_player_t2 {
  height: 62px;
  padding: 0;
  bottom: 6px;

  .drag_wrap {
    width: 100%;
    left: 0;
    background: #fff;

    .player_wrap {
      margin: 0;
      padding: 0 10px;
      border-radius: 0;
      width: 100%;
      justify-content: space-between;

      .icon_wrap {
        margin-left: 10px;
      }
    }
  }
}
</style>
