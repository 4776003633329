<template>
  <div class="mode" :style="isMobile ? 'right: 0;bottom: 72px;background-color: rgba(0, 0, 0, 0.45);' : (factor === 'precp' ? 'background-color: rgba(0, 0, 0, 0);' : 'background-color: rgba(0, 0, 0, 0.45);')">
    <div
      v-for="item in menu"
      :key="item"
      class="item"
      :style="isMobile ? 'padding: 0px 10px;' : ''"
      :class="realMode === item ? 'active' : ''"
      @click="setModeType(item)"
    >
      {{ menuName(item) }}
      <div v-show="t2Mode === item" class="text">{{ ratio }}</div>
    </div>
    <div v-if="factor === 'precp'" class="text precp-text">{{ CY }}<a style="color: rgb(2, 255, 158);text-decoration: underline " href="https://caiyunapp.com/" target="_blank">彩云天气</a></div>
  </div>
</template>
<script>
import { mapMutations, mapState } from 'vuex';
import { TIANJI2_DA, TIANJI2_MERGE, TIANJI2_ND, TIANJI1_DA, TIANJI1_ND, CY } from '@/config';
import { drag } from '@/utils/drag';
import { factorMapT1, mapForCn, dust_factorMap } from '@/config/MapConfigT4';

export default {
  data() {
    return {
      types: [TIANJI2_MERGE, TIANJI2_ND, TIANJI2_DA, TIANJI1_ND, TIANJI1_DA],
      isMove: false,
      CY,
    };
  },
  computed: {
    ...mapState('map', ['t2Mode', 'factor']),
    ...mapState(['t1Mode']),
    menu() {
      if (['max_reflectivity', 'base_reflectivity', 'rh2m'].includes(this.factor)) {
        return [TIANJI2_MERGE, TIANJI2_ND, TIANJI2_DA];
      } if (['vis_cats'].includes(this.factor)) {
        return [TIANJI2_MERGE];
      } if (['ri_min'].includes(this.factor)) {
        return [TIANJI2_ND, TIANJI2_DA, TIANJI1_ND, TIANJI1_DA];
      } if (dust_factorMap[this.factor]) {
        return [TIANJI2_MERGE, TIANJI1_ND, TIANJI1_DA];
      } if (['vis_cats'].includes(this.factor)) {
        return [TIANJI2_MERGE];
      } if (['precp'].includes(this.factor)) {
        return [];
      }
      return this.types;
    },
    isMobile() {
      return this.$store.state.isMobile;
    },

    realMode() {
      return dust_factorMap[this.factor] ? this.t2Mode.replace(2, 1) : this.t2Mode;
    },

    ratio() {
      if (this.t2Mode === TIANJI2_MERGE) {
        if (['tmp2m', 't2mz'].includes(this.factor)) {
          return '1km';
        } if (['dust_conc', 'dod', 'dust_ddep', 'dust_emis'].includes(this.factor)) {
          return '12km';
        } if (['pratesfc', 'pregrt', 'prerrt', 'presrt', 'preirt', 'pre_total', 'pres_all', 'prer', 'max_reflectivity', 'base_reflectivity'].includes(this.factor)) {
          return '2.5km';
        }
        return '10km';
      }
      // if (['dust_conc', 'dod', 'dust_ddep', 'dust_emis'].includes(this.factor)) {
      //   return '12km';
      // }
      if (this.t2Mode.includes('天机1')) {
        return '12km';
      }
      return '2.5km';
    },
  },

  filters: {

  },

  mounted() {
    drag(this.$el);
    this.$el.addEventListener('mousedown', (event) => {
      this.handleMouseDown();
    });
    this.$el.addEventListener('mouseup', (event) => {
      this.handleMouseUp();
      setTimeout(() => {
        this.isMove = false;
      }, 500);
    });
  },
  methods: {
    ...mapMutations(['setFactor']),
    ...mapMutations('map', ['setMode']),
    // 组件中的方法

    menuName(name) {
      if (['dust_conc', 'dod', 'dust_ddep', 'dust_emis'].includes(this.factor)) {
        return name.replace(2, 1);
      }
      return name;
    },

    handleMouseDown() {
      this.$el.addEventListener('mousemove', this.handleMouseMove);
    },
    handleMouseMove(event) {
      // 监听鼠标移动事件时的逻辑
      this.isMove = true;
    },
    handleMouseUp() {
      this.$el.removeEventListener('mousemove', this.handleMouseMove);
    },
    setModeType(item) {
      sessionStorage.setItem('current-mode', item);
      if (this.isMove) return;
      this.$sensors.track('currentMode', {
        info: mapForCn[this.factor],
        mode: this.t2Mode,
        space_type: this.$route.name === 'earth' ? '三维' : '二维',
        page_type: '天机2',
      });
      this.$sensors.track('Jump_volume', {
        from_factor: mapForCn[this.factor],
        from_mode: this.t2Mode,
        from_space_type: this.$route.name === 'earth' ? '三维' : '二维',
        to_mode: item,
        to_factor: mapForCn[this.factor],
        to_space_type: this.$route.name === 'earth' ? '三维' : '二维',
      });
      if (item === TIANJI1_DA || item === TIANJI1_ND) {
        sessionStorage.setItem('tmode', 't1');
        if (dust_factorMap[this.factor]) {
          this.setMode(item);
        } else {
          const factor = this.factor === 'tmp2m' ? 't2mz' : this.factor;
          if (factorMapT1[factor]) {
            this.setFactor(factor);
          }
          this.setMode(item);
          console.log(item, 'item');
          // this.$store.commit("setMode", item);
          // this.$router.push({ name: "weather" });
        }
        // this.setFactor
      } else {
        console.log('&&&&&&&&&&');
        sessionStorage.setItem('tmode', 't2');
        this.setMode(item);
      }
    },
    getRatio() {
      // 以天机2为准
      return this.t2Ratio();
    },
  },
};
</script>

<style scoped lang="scss">
.mode {
  position: fixed;
  z-index: 998;
  right: 70px;
  bottom: 67px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  //background-color: rgba(0, 0, 0, 0.45);
  border-radius: 6px;
  .text {
    font-size: 8px;
    margin-left: 5px;
  }

  .precp-text{
    font-size: 22px;
    background-color: rgba(255, 255, 255, 0) !important;
    border: none !important;
    line-height: 42px;
    font-weight: 600;
    color: #FFF;
  }

  .item {
    padding: 0 16px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 12px;
    color: #fff;
    border-radius: 6px;
    text-shadow: 0px 0px 4px black;
  }

  .active {
    background-color: $theme-color;
    display: flex;
    padding: 0 11px;
  }
}
</style>
